import React, { useState } from "react";
import QuoteModal from "../ProfileEdits/QuoteModal";
import { ContactsSectionModal } from "../ProfileEdits/ContactSectionModal";
import Button from "react-bootstrap/Button";
import { EditButton } from "../Buttons/EditButton";
import { UserImageModal } from "../ProfileEdits/UserImageModal";
import { useAuth } from "../../utility/useMsalAuth";
import { UserHeaderImageModal } from "../ProfileEdits/UserHeaderImageModal";
import { useModalContext } from "../../utility/useModalLauncherContext";
const ChatIcon = require("../../images/chat.svg") as string;
const ShareIcon = require("../../images/share.svg") as string;
const VideoIcon = require("../../images/video-chat.svg") as string;
const LinkedInIcon = require("../../images/linkedin.svg") as string;
const WorldIcon = require("../../images/world.svg") as string;
const DefaultBG = require("../../images/default_bg.png");
const DefaultProfileImg = require("../../images/default_profile_img.png") as string;
const CameraIcon = require("../../images/camera.svg") as string;

const UserProfileHeader = ({
  bgImg,
  quote,
  quoteAuthor,
  profile_photo,
  first_name,
  last_name,
  title,
  department,
  location,
  email,
  phone,
  alternatePhone,
  socialUrl,
  websiteUrl,
  id,
}) => {
  const auth = useAuth();
  const modalContext = useModalContext();

  let isEditable = auth.userId === id;

  return (
    <div>
      <div className="profile-bg">
        <img src={bgImg || DefaultBG} alt="bg" />
      </div>

      <div className="container position-relative">
        <div className="user-profile-header">
          <div className="user-quote">
            {quote && quoteAuthor ? (
              <React.Fragment>
                <p className="quote">{quote}</p>
                <p className="quote-author mb-0">-{quoteAuthor}</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className="quote">{"Add your favorite quote here"}</p>
              </React.Fragment>
            )}
            {isEditable && (
              <>
                <EditButton
                  className="mt-2"
                  text="Edit Quote"
                  onClick={() => {
                    modalContext.openModal("quote");
                  }}
                />
              </>
            )}
          </div>
          <div className="row header-container">
            <div className="col-sm-12 col-md-4 col-lg-3 photo-container">
              <img className="profile-photo img-fluid" src={profile_photo || DefaultProfileImg} alt="profile" />
              {isEditable && (
                <>
                  <EditButton
                    text="Edit Photo"
                    className="mt-2 profile-photo-prompt"
                    onClick={() => {
                      modalContext.openModal("profilephoto");
                    }}
                    icon={CameraIcon}
                  />
                </>
              )}
            </div>

            <div className="col-md-4 d-flex align-items-end">
              <div className="row">
                <div className="col-12">
                  <h1 className="name">{first_name}</h1>
                </div>
                <div className="col-12">
                  <div className="work-details">
                    <h5 className="title">{title}</h5>, <h5 className="department">{department}</h5>
                  </div>
                </div>
                <div className="col-12">
                  <p className="location mb-0">{location}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 offset-lg-1 d-flex align-items-end">
              <div className="row">
                <div className="col-12 mb-3 contact-section">
                  <div>
                    <img className="contact-icon" src={ChatIcon} alt="chat" /> {email}
                  </div>
                  <div>{phone}</div>
                </div>
                <div className="col-12 mb-3">
                  <div className="contact-section">
                    <div>
                      <img className="contact-icon" src={ChatIcon} alt="icon" />
                      Chat
                    </div>
                    <div>
                      <img className="contact-icon" src={VideoIcon} alt="video" />
                      video
                    </div>
                    <div>
                      <img className="contact-icon" src={ShareIcon} alt="icon" />
                      Share
                    </div>
                    {socialUrl && (
                      <div>
                        <a href={socialUrl} target={"_blank"}>
                          <img className="contact-icon" src={LinkedInIcon} alt="icon" />
                          LinkedIn
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-3 contact-section">
                  {websiteUrl && (
                    <div>
                      <a href={websiteUrl} target={"_blank"}>
                        <img className="contact-icon" src={WorldIcon} alt="personal-site" /> {/*personal website*/}
                        Website
                      </a>
                    </div>
                  )}
                </div>
                <div className="col-12 edit-contact-section d-flex justify-content-between">
                  {isEditable && (
                    <div className="d-inline-block">
                      <EditButton onClick={() => modalContext.openModal("contacts")} />
                    </div>
                  )}

                  {isEditable && (
                    <>
                      <EditButton
                        className="d-inline-block"
                        text={"Edit User Header Image"}
                        onClick={() => {
                          modalContext.openModal("headerimage");
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserProfileHeader;
