import React, { useEffect, useState } from "react";
import { useFireTeamsMeetingNotificationMutation, usePeopleInChatIdSubscription } from "../../generated/graphql";
import { useMsTeams } from "../../utility/useMicrosoftTeams";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { SoundThing } from "./SoundThing";
import { Timeline } from "./Timeline";
import { EditButton } from "../Buttons/EditButton";
import { useModalContext } from "../../utility/useModalLauncherContext";
import { Link } from "react-router-dom";
import { SidePanelToolBar } from "./SidePanelToolBar";
import * as microsoftTeams from "@microsoft/teams-js";

function GetBackMyTime() {
  return (
    <div>
      <h4>Meeting Reactions</h4>
      <p>
        Choose all that apply, When x% or more of the people in a meeting select something, we'll put the brakes on this
        meeting. We won't show who chose what options.
      </p>
      <div>
        <input checked={false} type={"checkbox"} /> I don't need to be here
      </div>
      <div>
        <input checked={false} type={"checkbox"} /> I don't want to be here
      </div>
      <div>
        <input checked={false} type={"checkbox"} /> Somebody asked me to be here, but I don't know why
      </div>
      <div>
        <input checked={false} type={"checkbox"} /> I shouldn't be here
      </div>
      <div>
        <input checked={false} type={"checkbox"} /> This could have been an email
      </div>
      <div>
        <input checked={false} type={"checkbox"} /> This doesn't apply to me
      </div>
      <div>
        <input checked={false} type={"checkbox"} /> We don't have the right people to make this decision
      </div>
      <div>
        <input checked={false} type={"checkbox"} /> Somebody else really should know about this
      </div>
    </div>
  );
}

function SoundBoard() {
  return (
    <div>
      <h4>Multiplayer Sound Board</h4>
      <p>audio plays from the tabs of whoever has the window open</p>
      <SoundThing />
    </div>
  );
}

export function SidePanel() {
  const [mutation] = useFireTeamsMeetingNotificationMutation();
  const teams = useMsTeams();
  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm({
    mode: "onBlur",
  });
  const modalContext = useModalContext();

  const [meetingRoomState, setMeetingRoomState] = useState("");

  useEffect(() => {
    microsoftTeams.meetingRoom.registerMeetingRoomStatesUpdateHandler((state) => {
      setMeetingRoomState(JSON.stringify(state));
    });
  }, []);
  let queryObject = {};
  let variableObject: any = {};

  if (teams.context && teams.context["chatId"]) {
    queryObject = { chatId: teams.context["chatId"] };
    variableObject = { sessionId: teams.context["chatId"], teams_context: teams.context };
  } else if (teams.context && teams.context["channelId"]) {
    queryObject = { channelId: teams.context["channelId"] };
    variableObject = { sessionId: teams.context["channelId"], teams_context: teams.context };
  } else if (teams.context && teams.context["entityId"]) {
    queryObject = { entityId: teams.context["entityId"] };
    variableObject = { sessionId: teams.context["entityId"], teams_context: teams.context };
  }

  const { data } = usePeopleInChatIdSubscription({
    variables: {
      chatId: queryObject,
    },
  });

  const handleFormSubmit = (data) => {
    mutation({
      variables: {
        input: {
          url: window.location.origin + "/" + data.url,
          text: data.notificationText,
          height: 500,
          width: 600,
          title: data.titleText,
          conversationId: teams.context?.chatId as string,
        },
      },
    });
  };

  if (!data) return null;

  return (
    <div>
      <SidePanelToolBar />
      <div className="container">
        <p>Room State: {meetingRoomState}</p>
        <div className={"row mb-5"}>
          <div className={"col-xs-12 ml-2 mr-2"}>
            <h4>You</h4>
            <div className="button-min">
              <EditButton
                onClick={() => {
                  modalContext.openModal("aboutme");
                }}
              />
            </div>
            <div className="button-min">
              <EditButton
                text={"Edit Header Image"}
                onClick={() => {
                  modalContext.openModal("headerimage");
                }}
              />
            </div>
            <div className="button-min">
              <EditButton
                text={"Edit Image"}
                onClick={() => {
                  modalContext.openModal("profilephoto");
                }}
              />
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-xs-12 ml-2 mr-2"}>
            <h4>In this meeting</h4>

            {data?.teams_sessions.map((teamsSession) => {
              return (
                <div>
                  {teamsSession.user.user_images && teamsSession.user.user_images[0] && (
                    <div>
                      <img
                        className="profile-photo img-fluid"
                        style={{ height: "50px", width: "50px" }}
                        src={"data:image/png;base64," + teamsSession.user.user_images[0].image_base64}
                      />
                    </div>
                  )}
                  {teamsSession.user.name}
                  <p>{teamsSession.user.extended_profiles && teamsSession.user.extended_profiles.about_me}</p>
                  <Link to={`/user/${teamsSession.user.id}`}>Go to profile</Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className={"row"}>
          <EditButton
            text={"Fire Notification"}
            onClick={() => {
              mutation({
                variables: {
                  input: {
                    url: window.location.origin + "/" + "remotecontrol",
                    text: "This is a test of text",
                    height: 500,
                    width: 600,
                    title: "This is a test title",
                    conversationId: teams.context?.chatId as string,
                  },
                },
              });
            }}
          />
          <EditButton
            text={"Fire Notification 2"}
            onClick={() => {
              mutation({
                variables: {
                  input: {
                    url: "https://tunnel.sparkworkspace.com",
                    text: "Test",
                    height: 500,
                    width: 600,
                    title: "Test",
                    conversationId: teams.context?.chatId as string,
                  },
                },
              });
            }}
          />
          <EditButton
            text={"Get Deep Link"}
            onClick={() => {
              microsoftTeams.shareDeepLink({
                subEntityId: "SidePanelSubENtity",
                subEntityLabel: "SidePanel",
                subEntityWebUrl: "https://sparkworkspace.com/sidepanel",
              });
              alert("shared deeplink");
            }}
          />
        </div>
      </div>
    </div>
  );
}
