import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSingleQuoteForUserIdLazyQuery, useSingleQuoteForUserIdQuery } from "../../generated/graphql";
import { useUpsertUserQuoteMutation } from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";
import { useForm } from "react-hook-form";

const QuoteModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();

  const [loadData, { called, loading, data }] = useSingleQuoteForUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });

  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm({
    mode: "onBlur",
  });

  const [mutation] = useUpsertUserQuoteMutation();

  const handleQuoteSubmit = (formData) => {
    mutation({
      variables: { userId: auth.userId, quoteAuthor: formData.quoteAuthor, quoteText: formData.quote },
      refetchQueries: ["UserProfileEdit"],
    }).then(() => {
      handleModalClose();
    });
  };

  useEffect(() => {
    if (!called) loadData({ variables: { userId: auth.userId } });
    if (data) {
      reset({
        quote: data.users[0].user_quotes[0].quote_text,
        quoteAuthor: data.users[0].user_quotes[0].quote_author,
      });
    }
  }, [data]);

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="quote-modal">
      <Modal.Header closeButton>
        <h4>Edit Quote</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label className="form-label-modal">Type your favorite quote</Form.Label>
            <Form.Control name={"quote"} as="textarea" rows={3} ref={register()} />
          </Form.Group>
          <Form.Group className="mb-5">
            <Form.Label className="form-label-modal">Who's the author?</Form.Label>
            <Form.Control name={"quoteAuthor"} as="textarea" rows={2} ref={register()} />
          </Form.Group>

          <Button variant="modal-submit" onClick={handleSubmit(handleQuoteSubmit)}>
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default QuoteModal;
