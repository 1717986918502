import React, { useState } from "react";
import { useLifeOutsideWorkQuery } from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";
import { EditButton } from "../Buttons/EditButton";
import { PersonalModal } from "../ProfileEdits/PersonalModal";

const PersonalTab = ({ userId }) => {
  const auth = useAuth();
  const { data } = useLifeOutsideWorkQuery({ variables: { userId: userId } });

  const initialModalState = {
    PersonalModal: false,
  };

  const [editStates, setEditStates] = useState({
    PersonalModal: false,
  });

  const handleModalClose = () => {
    setEditStates(initialModalState);
  };

  let isEditable = auth.userId === userId;
  let lifeOutsideWorkText = "";
  if (data?.users[0].extended_profiles && data?.users[0].extended_profiles.life_outside_work_text) {
    lifeOutsideWorkText = data?.users[0].extended_profiles.life_outside_work_text;
  }
  return (
    <div className="row">
      <div className="col-12">
        <h4>My Life Outside Work</h4>
        <div>
          {isEditable && (
            <EditButton
              onClick={() => {
                setEditStates({ ...editStates, PersonalModal: true });
              }}
            />
          )}
        </div>
      </div>

      <div className="col-md-5 col-12">
        <p className="copy">{lifeOutsideWorkText}</p>
      </div>
      <div className="col-md-6 offset-md-1 col-12">
        <img className="personal-img" src="https://via.placeholder.com/580x390" alt="about me" />

        <p className="caption">Image Caption</p>
      </div>
      {editStates.PersonalModal && (
        <PersonalModal showModal={editStates.PersonalModal} handleModalClose={handleModalClose} />
      )}
    </div>
  );
};

export default PersonalTab;
