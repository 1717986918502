import React, { useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import {
  useUpdateUserFirstRunMutation,
  useUpsertUserAboutMeMutation,
  useUserAboutMeQuery,
} from "../../generated/graphql";
import { useModalContext } from "../../utility/useModalLauncherContext";
import { useAuth } from "../../utility/useMsalAuth";

export const SetupSection = () => {
  const auth = useAuth();
  const { data, error } = useUserAboutMeQuery({ variables: { userId: auth.userId } });
  const [updateUserFirstRun] = useUpdateUserFirstRunMutation();
  const modalContext = useModalContext();

  useEffect(() => {
    if (data) {
      if (data.users[0].extended_profiles?.about_me) {
      }
    }
  }, [data]);

  return (
    <>
      <div className={" col-xs-12 col-md-6 d-flex flex-wrap justify-content-center align-items-center"}>
        <div className={"setup-welcome"}>
          <h2>Welcome to your new Peeps Profile!</h2>
          <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin libero ante, finibus vel nisl fermentum,
                aliquam vehicula lacus. In quis gravida dui. Vivamus nec consectetur.</p>
          <br />
          <Button
            variant="modal-submit"
            onClick={() => {
              updateUserFirstRun({
                variables: {
                  firstRun: true,
                  userId: auth.userId,
                },
                refetchQueries: ["UserAboutMe"],
              });
            }}>
            Done With Setup
          </Button>
        </div>
      </div>
      <div className={"col-xs-12 col-md-6 d-flex flex-wrap justify-content-center align-items-center"}>
        <div className={"row"}>
          <div className={"col-xs-12 col-md-6 col-lg-4"}>
            <h6 className={"setup-step"}>1.</h6>
            <p className={"setup-task"}>Add Photos</p>
            <p>Upload a profile photo! Add a cover photo to add some pizazz.</p>
            <Button
              onClick={() => {
                modalContext.openModal("profilephoto");
              }}
              variant="modal-submit">
              Add Profile Photo
            </Button>
            {data?.users[0].extended_profiles?.about_me && <div></div>}
            {/* TODO - launch profile modals from here */}
            {/* TODO - make a better 'done' state */}
          </div>
          <div className={"col-xs-12 col-md-6 col-lg-4"}>
            <h6 className={"setup-step"}>2.</h6>
            <p className={"setup-task"}>Add About Me</p>
            <p>Add an intro, years of experience, and your education and training.</p>
            <Button
              onClick={() => {
                modalContext.openModal("aboutme");
              }}
              variant="modal-submit">
              Add About Me
            </Button>
            {data?.users[0].extended_profiles?.experience_start_date && <div></div>}
          </div>
          <div className={"col-xs-12 col-md-6 col-lg-4"}>
            <h6 className={"setup-step"}>3.</h6>
            <p className={"setup-task"}>Add Experience</p>
            <p>Add your experience and be proud of how far you've come.</p>
            <Button
              onClick={() => {
                modalContext.openModal("experience");
              }}
              variant="modal-submit">
              Add Experience
            </Button>
            {data?.users[0] && data?.users[0].user_experiences && data?.users[0].user_experiences.length > 0 && (
              <div>x</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
