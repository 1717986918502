import { useAuth } from "../utility/useMsalAuth";
import { blobToBase64 } from "../utility/Utility";
export function useMsGraphService() {
  const auth = useAuth();

  const getMyPhoto = async () => {
    return auth.msal.acquireTokenSilent({ scopes: ["User.Read"], account: auth.user }).then(async (tokenResponse) => {
      const photo = await fetch(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
          "Content-Type": "application/json",
        },
      });

      if (photo.status === 401) {
        return "https://placehold.it/96x96";
      }

      const photoResult = await photo.blob();
      var objectURL = URL.createObjectURL(photoResult);
      return objectURL;
    });
  };

  const getUserProfileById = async (id: string) => {
    return auth.msal.acquireTokenSilent({ scopes: ["User.Read"], account: auth.user }).then(async (tokenResponse) => {
      const resp = await fetch(
        `https://graph.microsoft.com/beta/users/${id}?$select=displayName,skills,interests,jobTitle,officeLocation,id,mail,businessPhones,mobilePhone,department`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.status === 400) {
        throw new Error("Error getting user");
      }
      return await resp.json();
    });
  };

  const getMyPhotoBase64 = async () => {
    return auth.msal.acquireTokenSilent({ scopes: ["User.Read"], account: auth.user }).then(async (tokenResponse) => {
      // https://docs.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
      const photo = await fetch(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
          "Content-Type": "application/json",
        },
      });

      if (photo.status === 401) {
        return "https://placehold.it/96x96";
      }

      const photoResult = await photo.blob();
      return await blobToBase64(photoResult);
    });
  };

  const getUserPhotoById = async (userId: string) => {
    return auth.msal.acquireTokenPopup({ scopes: ["User.ReadBasic.All"] }).then(async (tokenResponse) => {
      const photo = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
          "Content-Type": "application/json",
        },
      });

      if (photo.status === 401 || photo.status === 404) {
        return "";
      }

      const photoResult = await photo.blob();
      return await blobToBase64(photoResult);
    });
  };

  const updateMyPhoto = async (binaryData: Blob) => {
    return auth.msal
      .acquireTokenSilent({ scopes: ["User.ReadWrite"], account: auth.user })
      .then(async (tokenResponse) => {
        const result = await fetch(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
            "Content-Type": "image/png",
          },
          body: binaryData,
        });

        if (result.status === 500) {
          throw new Error("Unable to update image");
        }
      });
  };

  const searchPeople = (searchQuery: string) => {
    return auth.msal.acquireTokenSilent({ scopes: ["People.Read"], account: auth.user }).then(async (tokenResponse) => {
      const resp = await fetch(
        `https://graph.microsoft.com/v1.0/me/people?$search="${searchQuery}"&$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.status === 400) {
        throw new Error("Error getting users");
      }
      return (await resp.json()).value;
    });
  };

  const consentPrompt = () => {
    return auth.msal.acquireTokenPopup({ scopes: ["Sites.Read.All"] }).then(() => {
      alert("ok");
    });
  };

  const getChatsForUser = () => {
    return auth.msal.acquireTokenSilent({ scopes: ["Chat.Read"], account: auth.user }).then(async (tokenResponse) => {
      const resp = await fetch(`https://graph.microsoft.com/beta/me/chats`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
          "Content-Type": "application/json",
        },
      });
      if (resp.status === 400) {
        throw new Error("Error getting chats");
      }
      return (await resp.json()).value;
    });
  };

  const getMembersInChat = (id) => {
    return auth.msal.acquireTokenSilent({ scopes: ["Chat.Read"], account: auth.user }).then(async (tokenResponse) => {
      const resp = await fetch(`https://graph.microsoft.com/beta/me/chats/${id}/members`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
          "Content-Type": "application/json",
        },
      });
      if (resp.status === 400) {
        throw new Error("Error getting chat members");
      }
      return (await resp.json()).value;
    });
  };

  const getMessagesInChat = (id) => {
    return auth.msal.acquireTokenSilent({ scopes: ["Chat.Read"], account: auth.user }).then(async (tokenResponse) => {
      const resp = await fetch(`https://graph.microsoft.com/beta/me/chats/${id}/messages`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
          "Content-Type": "application/json",
        },
      });
      if (resp.status === 400) {
        throw new Error("Error getting chat messages");
      }
      return (await resp.json()).value;
    });
  };

  const getMyJoinedTeams = () => {
    return auth.msal
      .acquireTokenSilent({ scopes: ["Team.ReadBasic.All"], account: auth.user })
      .then(async (tokenResponse) => {
        const resp = await fetch(`https://graph.microsoft.com/v1.0/me/joinedTeams`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
            "Content-Type": "application/json",
          },
        });
        if (resp.status === 400) {
          throw new Error("Error getting joined teams");
        }
        return (await resp.json()).value;
      });
  };

  const getMyLastMessagePreviews = () => {
    return auth.msal.acquireTokenSilent({ scopes: ["Chat.Read"], account: auth.user }).then(async (tokenResponse) => {
      const resp = await fetch(`https://graph.microsoft.com/beta/me/chats?$expand=lastMessagePreview`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
          "Content-Type": "application/json",
        },
      });
      if (resp.status === 400) {
        throw new Error("Error getting last message previews");
      }
      return (await resp.json()).value;
    });
  };

  return {
    getMyPhoto: getMyPhoto,
    getUserProfileById: getUserProfileById,
    getMyPhotoBase64: getMyPhotoBase64,
    getUserPhotoById: getUserPhotoById,
    updateMyPhoto: updateMyPhoto,
    searchPeople: searchPeople,
    consentPrompt: consentPrompt,
    getChatsForUser: getChatsForUser,
    getMembersInChat: getMembersInChat,
    getMessagesInChat: getMessagesInChat,
    getMyJoinedTeams: getMyJoinedTeams,
    getMyLastMessagePreviews: getMyLastMessagePreviews,
  };
}
