import React, { useState, useEffect } from "react";
import UserProfileHeader from "./userprofileheader";
import UserTab from "../UserTab/UserTab";

import { match } from "react-router";
import { useMsGraphService } from "../../services/msGraphService";
import { useUserProfileEditQuery } from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";
import { useMsTeams } from "../../utility/useMicrosoftTeams";

const DefaultProfileImg = require("../../images/default_profile_img.png") as string;

export const UserProfile = ({ userId }) => {
  const msGraphService = useMsGraphService();
  const [profile, setProfile] = useState<any>();
  const { data } = useUserProfileEditQuery({ variables: { userId: userId } });
  const [doneLoading, setDoneLoadingState] = useState(false);

  useEffect(() => {
    if (!doneLoading) {
      msGraphService.getUserProfileById(userId).then((profile) => {
        setProfile(profile);
      });
      if (data?.users[0] && profile) {
        setDoneLoadingState(true);
      }
    }
  }, [data, profile, doneLoading]);

  let extendedProfile: any = {};
  if (data?.users[0].extended_profiles) {
    extendedProfile = data?.users[0].extended_profiles;
  }

  if (doneLoading && profile && data) {
    return (
      <div>
        <UserProfileHeader
          id={profile.id}
          bgImg={data.users[0].extended_profiles ? (data.users[0].extended_profiles.header_image_base64 as string) : ""}
          location={profile.officeLocation}
          department={profile.department}
          email={profile.mail}
          first_name={profile.displayName}
          last_name={"lastname"}
          phone={profile.mobilePhone ? profile.mobilePhone : profile.businessPhones[0]}
          profile_photo={`data:image/png;base64,${data.users[0]?.user_images[0]?.image_base64 || ""}`}
          title={profile.jobTitle}
          quote={data.users[0].user_quotes[0] ? data.users[0].user_quotes[0].quote_text : ""}
          quoteAuthor={data.users[0].user_quotes[0] ? data.users[0].user_quotes[0].quote_author : ""}
          socialUrl={extendedProfile.social_url}
          websiteUrl={extendedProfile.website_url}
          alternatePhone={extendedProfile.alternate_phone}
        />
        <UserTab userId={userId} />
      </div>
    );
  }

  return (
    <div>
      {/* {`${doneLoading} - ${profile}`}
      Done Loading
      {JSON.stringify(doneLoading)}
      profile
      {JSON.stringify(profile)}
      data
      {JSON.stringify(data)} */}
    </div>
  );
};
// export default UserProfile;
