import React from "react";
import { Chrono } from "react-chrono";

export function Timeline() {
  const items = [
    {
      title: "Item 1",
      contentTitle: "This is a test",
      contentText: "Tom is testing out this timeline",
      //   media: {
      //     type: "IMAGE",
      //     source: {
      //       url: "https://placehold.it/100x100",
      //     },
      //   },
    } as any,
    {
      title: "Item 2",
      contentTitle: "This is a test",
      contentText: "Tom is testing out this timeline",
      //   media: {
      //     type: "IMAGE",
      //     source: {
      //       url: "https://placehold.it/100x100",
      //     },
      //   },
    } as any,
    {
      title: "Item 3",
      contentTitle: "This is a test",
      contentText: "Tom is testing out this timeline",
      //   media: {
      //     type: "IMAGE",
      //     source: {
      //       url: "https://placehold.it/100x100",
      //     },
      //   },
    } as any,
    {
      title: "Item 4",
      contentTitle: "This is a test",
      contentText: "Tom is testing out this timeline",
      //   media: {
      //     type: "IMAGE",
      //     source: {
      //       url: "https://placehold.it/100x100",
      //     },
      //   },
    } as any,
    {
      title: "Item 5",
      contentTitle: "This is a test",
      contentText: "Tom is testing out this timeline",
      //   media: {
      //     type: "IMAGE",
      //     source: {
      //       url: "https://placehold.it/100x100",
      //     },
      //   },
    } as any,
  ];

  return (
    <div style={{ width: "320px", height: "400px" }}>
      <Chrono items={items} cardHeight={300} mode="VERTICAL" />
    </div>
  );
}
