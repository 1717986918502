var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { ProviderConsumer as FluentUIThemeConsumer, Box, } from "@fluentui/react-northstar";
import { Widget, WidgetTitle, WidgetBody, WidgetFooter, } from "./DashboardWidget";
import { DashboardTheme } from "./DashboardTheme";
import Toolbar from "../Toolbar";
var toolbarConfig = {
    actionGroups: {
        h1: {
            b1: { title: "Edit dashboard", icon: "Edit" },
        },
    },
    filters: [],
    find: false,
};
export function Dashboard(_a) {
    var widgets = _a.widgets;
    return (React.createElement(FluentUIThemeConsumer, { render: function (globalTheme) { return (React.createElement(DashboardTheme, { globalTheme: globalTheme },
            React.createElement(Toolbar, __assign({}, toolbarConfig)),
            React.createElement(Box, { styles: {
                    display: "grid",
                    gridGap: ".5rem",
                    gridTemplate: "repeat(auto-fill, 26rem) / repeat(auto-fill, minmax(18.75rem, 1fr))",
                    gridAutoFlow: "dense",
                    gridAutoRows: "26rem",
                    padding: " 1rem 1rem 1.25rem",
                    minWidth: "20rem",
                    "@media (max-width: 986px)": {
                        gridTemplate: "repeat(auto-fill, 25rem) / repeat(auto-fill, minmax(15.75rem, 1fr))",
                    },
                } }, widgets &&
                widgets.map(function (_a, key) {
                    var title = _a.title, desc = _a.desc, widgetActionGroup = _a.widgetActionGroup, size = _a.size, body = _a.body, link = _a.link;
                    return (React.createElement(Widget, { key: key, size: size },
                        React.createElement(WidgetTitle, { title: title, desc: desc, globalTheme: globalTheme, widgetActionGroup: widgetActionGroup }),
                        React.createElement(WidgetBody, { body: body, siteVariables: globalTheme.siteVariables }),
                        link && React.createElement(WidgetFooter, { link: link })));
                })))); } }));
}
