import React from "react";
import AboutMe from "./AboutMe";
import ProfProjects from "./ProfProjects";
import Colleagues from "./Colleagues";
import Resume from "./Resume";

const ProfessionalTab = ({ userId }) => {
  return (
    <React.Fragment>
      <AboutMe userId={userId} />
      <ProfProjects userId={userId} />
      <Colleagues />
      <Resume userId={userId} />
    </React.Fragment>
  );
};

export default ProfessionalTab;
