import React from "react";
import { AddIcon, EditIcon, GalleryNewIcon, GalleryNewLargeIcon, ShareGenericIcon, TrashCanIcon, } from "@fluentui/react-icons-northstar";
export default (function (_a) {
    var icon = _a.icon;
    var iconElement = null;
    switch (icon) {
        case "Add":
            iconElement = React.createElement(AddIcon, { outline: true });
            break;
        case "Edit":
            iconElement = React.createElement(EditIcon, { outline: true });
            break;
        case "GalleryNew":
            iconElement = React.createElement(GalleryNewIcon, { outline: true });
            break;
        case "GalleryNewLarge":
            iconElement = React.createElement(GalleryNewLargeIcon, { outline: true });
            break;
        case "ShareGeneric":
            iconElement = React.createElement(ShareGenericIcon, { outline: true });
            break;
        case "TrashCan":
            iconElement = React.createElement(TrashCanIcon, { outline: true });
            break;
    }
    return iconElement;
});
