import React, { useEffect, useState } from "react";
import { useFireTeamsMeetingNotificationMutation, usePeopleInChatIdSubscription } from "../../generated/graphql";
import { useMsTeams } from "../../utility/useMicrosoftTeams";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { SoundThing } from "./SoundThing";
import { Timeline } from "./Timeline";
import { EditButton } from "../Buttons/EditButton";
import { useModalContext } from "../../utility/useModalLauncherContext";
import { Link, useRouteMatch } from "react-router-dom";
import { SidePanelToolBar } from "./SidePanelToolBar";
import * as microsoftTeams from "@microsoft/teams-js";
import { useMsGraphService } from "../../services/msGraphService";
import { Messages } from "./Messages";

export type Chat = {
  chatType: string;
  id: string;
  topic: string;
  webUrl: string;
  createdDateTime: Date;
  lastUpdatedDateTime: Date;
  viewpoint: { lastMessageReadTime: string };
};
export function Chats() {
  const msGraphService = useMsGraphService();
  let [chatsState, setChatsState] = useState<Array<Chat>>([]);
  const match = useRouteMatch<{ id: string }>("/chats/:id");

  useEffect(() => {
    msGraphService.getChatsForUser().then((chats) => {
      console.log(chats);
      let chatArray = chats;
      setChatsState(chatArray);
    });

    msGraphService.getMyLastMessagePreviews().then((chats) => {
      console.log(chats);
      // let chatArray = chats;
      // setChatsState(chatArray);
    });
  }, []);

  return (
    <div>
      {chatsState.map((chat) => {
        return (
          <div>
            {chat.id} - {chat.chatType}
            <a href={chat.webUrl}>Link</a>
            <Link to={"/chats/" + chat.id}>Manage</Link>
          </div>
        );
      })}
      <div>{JSON.stringify(match?.params.id)}</div>
      {match?.params.id && <Messages id={match?.params.id} />}
    </div>
  );
}
