import React from "react";
import { Board } from "@fluentui/teams/lib";
import range from "lodash/range";
import shuffle from "lodash/shuffle";
import { IBoardItem, IBoardItemCardLayout } from "@fluentui/teams/lib/components/Board/Board";
import { StorybookThemeProvider } from "@fluentui/teams/lib/lib/withTheme";
import { TUsers } from "@fluentui/teams/lib/types/types";
import { useUsersWithAnImageQuery } from "../../generated/graphql";
import {
  Provider as FluentUIThemeProvider,
  mergeThemes,
  teamsTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
} from "@fluentui/react-northstar";
import { ThemeProvider } from "./ThemeProvider";

export function BoardThing() {
  const { data } = useUsersWithAnImageQuery();
  const boardItemCardLayout: IBoardItemCardLayout = {
    previewPosition: "top",
    overflowPosition: "footer",
  };

  if (data) {
    console.log("USERS", data);
  }
  const usersRange = range(1, 25);

  const users = () => shuffle(usersRange.filter(() => Math.random() > 0.67).map((ui) => `u${ui}`));
  const boardContent = {
    // [v-wishow] todo: developer-users can define how board item data maps to card content/layout.
    users: usersRange.reduce((acc: TUsers, i) => {
      acc[`u${i}`] = {
        name: "TOM",
        ...(Math.random() > 0.33 ? { image: "" } : {}),
      };
      return acc;
    }, {}),
    lanes: {
      l1: {
        title: "Lane 1",
      },
      l2: {
        title: "Lane 2",
      },
      l3: {
        title: "Lane 3",
      },
      l4: {
        title: "Lane 4",
      },
      l5: {
        title: "Lane 5",
      },
    },
    items: range(2, 6).reduce(
      (acc: { ii: number; items: { [itemKey: string]: IBoardItem } }, li: number) => {
        for (let lo = 0; lo < (li - 1) * 2; lo++) {
          acc.items[`t${acc.ii + lo}`] = {
            lane: `l${li}`,
            order: lo,
            title: "Fake board item",
            ...(Math.random() > 0.33 ? { subtitle: "Subtitle" } : {}),
            ...(Math.random() > 0.33 ? { body: "Body" } : {}),
            ...(Math.random() > 0.33 ? { preview: "" } : {}),
            ...(Math.random() > 0.33 ? { users: users() } : {}),
            ...(Math.random() > 0.5
              ? {
                  badges: {
                    attachments: Math.max(1, Math.floor(999 * Math.random())),
                  },
                }
              : {}),
          };
        }
        acc.ii += (li - 1) * 2;
        return acc;
      },
      { ii: 0, items: {} }
    ).items,
  };

  return (
    <div className={"board-wrapper"}>
      <StorybookThemeProvider>
        <Board
          users={boardContent.users}
          lanes={boardContent.lanes}
          items={boardContent.items}
          boardItemCardLayout={boardItemCardLayout}
        />
      </StorybookThemeProvider>
    </div>
  );
}
