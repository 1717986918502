import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import Button from "react-bootstrap/esm/Button";
import { useForm } from "react-hook-form";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useAllProjectsLazyQuery, useProjectsForUserByUserIdLazyQuery } from "../../generated/graphql";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { BoardThing } from "./BoardThing";

export function MeetingAppConfigPage() {
  // useEffect(() => {
  //   microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
  //     microsoftTeams.settings.setSettings({
  //       contentUrl: "https://dev.sparkintranet.com/meeting",
  //       websiteUrl: "https://dev.sparkintranet.com/meeting",
  //       entityId: "PeepsPeople2Test",
  //       suggestedDisplayName: "Peeps",
  //     });
  //     saveEvent.notifySuccess();
  //   });
  // }, []);

  const { register, control, handleSubmit, reset, trigger, setError, errors, formState } = useForm({
    mode: "onBlur",
  });

  const [loadProjects, { called, loading, data }] = useProjectsForUserByUserIdLazyQuery({
    fetchPolicy: "no-cache",
  });

  const handleFormSubmit = (data) => {
    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
      let url = window.location.origin + "/" + data.sampleName;

      if (data.sampleName === "tunnel") {
        url = "https://tunnel.sparkworkspace.com";
      }

      if (data.sampleName === "tomflix") {
        url = "https://app.sparkworkspace.com";
      }

      if (data.sampleName === "collabtris") {
        url = "https://collabtris.sparkworkspace.com";
      }
      microsoftTeams.settings.setSettings({
        contentUrl: url,
        websiteUrl: url,
        entityId: "Peeps" + data.sampleName,
        suggestedDisplayName: data.tabName,
      });
      saveEvent.notifySuccess();
    });
    microsoftTeams.settings.setValidityState(true);
  };
  useEffect(() => {
    if (!called) {
      loadProjects();
    }
  }, []);

  return (
    <div className={"container"}>
      <h3 className={"mb-5"}>Configure the Peeps Meeting App</h3>

      <p>
        This app is in development. The Teams features it uses are in developer preview. Tom takes no responsibility for
        the usefulness of this app right now.
      </p>

      <Form>
        <Form.Group>
          <Form.Label className="form-label-modal">Tab Title</Form.Label>
          <Form.Control
            name={`tabName`}
            type="text"
            placeholder="Enter Title"
            ref={register({ required: true })}
            defaultValue={"Teams Custom Tab"}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="form-label-modal">Select Tab Type</Form.Label>

          <Form.Control name={"sampleName"} ref={register} as="select">
            <option>{"peeps"}</option>
            <option>{"meeting"}</option>
            <option>{"chart.html"}</option>
            <option>{"board"}</option>
            <option>{"tunnel"}</option>
            <option>{"remotecontrol"}</option>
            <option>{"tomflix"}</option>
            <option>{"collabtris"}</option>
          </Form.Control>
        </Form.Group>

        <br />
        <br />
        <Button variant="modal-submit" onClick={handleSubmit(handleFormSubmit)}>
          Acknowledge
        </Button>
      </Form>
    </div>
  );
}
