import { ComponentEventHandler, ToolbarItemProps } from "@fluentui/react-northstar";
import { Toolbar } from "@fluentui/teams/lib/components/Toolbar/Toolbar";
import { StorybookThemeProvider } from "@fluentui/teams/lib/lib/withTheme";
import React, { useState } from "react";
import { useModalContext } from "../../utility/useModalLauncherContext";

export function SidePanelToolBar() {
  const modalContext = useModalContext();
  const toolbarConfig = {
    actionGroups: {
      g1: {
        a1: {
          title: "Edit About Me",
          icon: "GalleryNewLarge",
          __internal_callback__: "aboutMe",
        },
        a2: { title: "Carrot", icon: "GalleryNewLarge" },
        a3: { title: "Parsnip", icon: "GalleryNew" },
        a4: { title: "Potato", icon: "GalleryNewLarge" },
        a5: { title: "Radish", icon: "GalleryNew" },
        a6: { title: "Yam", icon: "GalleryNewLarge" },
      },
      g2: {
        a1: { title: "Cucumber", icon: "GalleryNew" },
        a2: { title: "Avocado", icon: "GalleryNewLarge" },
        a3: { title: "Pumpkin", icon: "GalleryNew" },
        a4: { title: "Squash", icon: "GalleryNewLarge" },
        a5: { title: "Tomato", icon: "GalleryNew" },
        a6: { title: "Watermelon", icon: "GalleryNewLarge" },
      },
    },
    filters: [
      {
        id: "f1",
        title:
          "Fruits (any sweet, edible part of a plant that resembles fruit, even if it does not develop from a floral ovary)",
        items: [
          { id: "f1f1", title: "Pomes" },
          { id: "f1f2", title: "Drupes" },
          { id: "f1f3", title: "Citruses" },
          { id: "f1f4", title: "Berries" },
          { id: "f1f5", title: "Melons" },
        ],
      },
      {
        id: "f3",
        title: "Cacti",
      },
      {
        id: "f2",
        title: "Roots and tubers",
        items: [
          { id: "f2f1", title: "True roots" },
          {
            id: "f2f2",
            title: "Modified stems",
            items: [
              { id: "f2f2f1", title: "Tubers" },
              { id: "f2f3f2", title: "Corms" },
              { id: "f2f4f3", title: "Rhizomes" },
            ],
          },
        ],
      },
    ],
    find: true,
  };

  const [toolBarsState, setToolbarState] = useState("none");

  return (
    <StorybookThemeProvider>
      <Toolbar
        {...(toolbarConfig as any)}
        onFindQueryChange={(findQuery) => {
          console.log("find query cnange");
          return "cool";
        }}
        onSelectedFiltersChange={(selectedFilters) => {
          console.log("SELECTED", selectedFilters);
          return ["ok", "test"];
        }}
        __internal_callbacks__={{
          aboutMe: () => modalContext.openModal("aboutme"),
          carrot: () => setToolbarState("Carrot"),
        }}
      />
      {toolBarsState === "Ginger" && <div>Ginger</div>}
      {toolBarsState === "Carrot" && <div>Carrot</div>}
    </StorybookThemeProvider>
  );
}
