import React from 'react';
import UserTile from '../UserTile/usertile'

const Colleagues = () => {
    return (
        <div className="row">
            <div className="col-12 mb-5">
                <h4>Colleagues</h4>
            </div>
            {/* <UserTile /> */}
        </div>
    )
}

export default Colleagues