import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ImageUploader from "react-images-upload";

import {
  useDeleteUserExperiencesMutation,
  useGetUserExperiencesLazyQuery,
  useInsertUserExperienceMutation,
} from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";

export type ExperienceForm = {
  experiences: Array<{
    id: string | null;
    startDate: Date | null;
    endDate: Date | null;
    name: string | null;
    orgName: string | null;
    imageBase64: string | null;
  }>;
};

export const ExperienceModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();
  const [loadExperiences, { called, loading, data: experienceData }] = useGetUserExperiencesLazyQuery({
    fetchPolicy: "no-cache",
  });
  const { register, control, handleSubmit, reset, trigger, setError, errors, setValue } = useForm<ExperienceForm>({
    mode: "onBlur",
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "experiences",
  });

  const [mutation] = useInsertUserExperienceMutation();
  const [deleteExperiences] = useDeleteUserExperiencesMutation();

  useEffect(() => {
    if (!called)
      loadExperiences({
        variables: { userId: auth.userId },
      });
    if (experienceData) {
      let mappedExperienceData = experienceData.users[0].user_experiences.map((exp) => {
        return {
          id: exp.id,
          startDate: exp.start_date,
          endDate: exp.end_date,
          name: exp.name,
          orgName: exp.org_name,
          imageBase64: exp.org_image_base64,
        };
      });

      reset({
        experiences: mappedExperienceData,
      });
    }
  }, [experienceData]);

  const handleExperienceSubmit = (data) => {
    if (!data.experiences) {
      data.experiences = [];
    }
    let mappedDataToSubmit = data.experiences.map((ed) => {
      let defaultObject = {
        name: ed.name,
        org_name: ed.orgName,
        start_date: ed.startDate === "" ? undefined : ed.startDate, // TODO - revisit logic
        end_date: ed.endDate === "" ? undefined : ed.endDate,
        org_image_base64: ed.imageBase64,
        id: ed.id,
      };
      return defaultObject;
    });

    deleteExperiences({ variables: { userId: auth.userId } }).then(() => {
      mutation({
        variables: {
          objects: mappedDataToSubmit,
        },
        refetchQueries: ["GetUserExperiences"],
      })
        .catch((err) => {
          alert(JSON.stringify(err));
        })
        .then((res) => {
          handleModalClose();
        });
    });
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="add-experience-modal">
      <Modal.Header closeButton>
        <h4>Your Experience</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-5">
            <h4 className="mb-4">Add your Experience</h4>
            {fields.map((item, index) => (
              <div key={item.id}>
                <div className="row">
                  <div className="col-md-8 col-12 mb-3">
                    <Form.Label className="form-label-modal">Title</Form.Label>
                    <Form.Control
                      name={`experiences[${index}].name`}
                      type="text"
                      placeholder="Enter Title"
                      ref={register({ required: true })}
                      defaultValue={item.name}
                    />
                    {errors.experiences && errors.experiences[index] && errors.experiences[index]?.name
                      ? "Title can't be empty"
                      : ""}
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <Form.Label className="form-label-modal">Org Name</Form.Label>
                    <Form.Control
                      name={`experiences[${index}].orgName`}
                      type="text"
                      placeholder="Enter Organization Name"
                      ref={register({ required: true })}
                      defaultValue={item.orgName}
                    />
                    {errors.experiences && errors.experiences[index] && errors.experiences[index]?.orgName
                      ? "Org name can't be empty"
                      : ""}
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <Form.Label className="form-label-modal">Start Date</Form.Label>
                    {/* TODO - styling start date and end date labels */}
                    <Controller
                      control={control}
                      name={`experiences[${index}].startDate`}
                      render={({ onChange, onBlur, value }) => {
                        let dateValue: string | Date = "";
                        if (value) {
                          dateValue = new Date(value);
                        }

                        // dateValue = new Date();
                        return (
                          <DatePicker
                            className={"form-control"}
                            onChange={onChange}
                            selectsStart
                            onBlur={onBlur}
                            selected={dateValue}
                            showMonthYearPicker
                            dateFormat="MM/yyyy"
                          />
                        );
                      }}
                      defaultValue={item.startDate}
                    />
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <Form.Label className="form-label-modal">End Date</Form.Label>

                    <Controller
                      control={control}
                      name={`experiences[${index}].endDate`}
                      ref={register()}
                      render={({ onChange, onBlur, value }) => {
                        let dateValue: string | Date = "";
                        if (value) {
                          console.log("VAL", value);
                          dateValue = new Date(value);
                        }
                        // dateValue = new Date();

                        return (
                          <DatePicker
                            className={"form-control"}
                            onChange={onChange}
                            selectsEnd
                            onBlur={onBlur}
                            selected={dateValue}
                            showMonthYearPicker
                            dateFormat="MM/yyyy"
                          />
                        );
                      }}
                      defaultValue={item.endDate}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <Form.Label className="form-label-modal">Image</Form.Label>

                    <Controller
                      control={control}
                      name={`experiences[${index}].imageBase64`}
                      ref={register()}
                      render={({ onChange, onBlur, value }) => {
                        return (
                          <>
                            {/* TODO - real styling */}
                            <div className={"row"}>
                              <div className={"col-3"}>
                                {value ? (
                                  <img
                                    width={200}
                                    className="img-fluid img-thumbnail"
                                    src={value}
                                    alt={"organization image"}
                                  />
                                ) : (
                                  "Upload an image for your work experience"
                                )}
                              </div>
                            </div>
                            <ImageUploader
                              withIcon={false}
                              onChange={(_, pictures: string[]) => {
                                if (pictures.length > 0) {
                                  onChange(pictures[0]);
                                }
                              }}
                              imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".jfif"]}
                              maxFileSize={5242880}
                              label={"Upload new image"}
                              buttonText={"Upload new image"}
                              withLabel={false}
                              singleImage={true}
                              fileContainerStyle={{
                                fontFamily: "SegoeUI Bold",
                                padding: "0",
                                boxShadow: "none",
                                borderRadius: "0",
                                margin: "0",
                                display: "block",
                                background: "none",
                              }}
                            />
                            <div>
                              {/* TODO - better remove image functionality, maybe an X button over the image? */}
                              <Button
                                variant="modal-submit"
                                onClick={() => {
                                  setValue(`experiences[${index}].imageBase64`, "", { shouldValidate: true });
                                }}>
                                Remove image
                              </Button>
                            </div>
                          </>
                        );
                      }}
                    />
                  </div>

                  {/* // TODO - better Move buttons */}
                  {fields.length > 1 && (
                    <div>
                      {index > 0 && (
                        <button type="button" onClick={() => move(index, index - 1)}>
                          Move Up
                        </button>
                      )}
                      {index + 1 !== fields.length && (
                        <button type="button" onClick={() => move(index, index + 1)}>
                          Move Down
                        </button>
                      )}
                    </div>
                  )}

                  <div className={"col-12"}>
                    <Button variant="modal-submit" onClick={() => remove(index)}>
                      Delete Work Experience
                    </Button>
                    {/* TODO: better Delete button */}
                  </div>
                </div>
              </div>
            ))}

            {/* TODO: better Add button */}
          </Form.Group>
          <div className={"row"}>
            <div className={"col-12"}>
              <Button
                variant="modal-submit"
                onClick={() => append({ name: "", orgName: "", startDate: "", endDate: "" })}>
                Add New Work Experience
              </Button>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12"}>
              <Button variant="modal-submit" onClick={handleSubmit(handleExperienceSubmit)}>
                Save Changes
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
