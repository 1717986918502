import React, { useEffect, useState } from "react";
import {
  useAllProjectsLazyQuery,
  usePeopleInChatIdSubscription,
  useProjectsForUserByUserIdLazyQuery,
} from "../../generated/graphql";
import { useMsTeams } from "../../utility/useMicrosoftTeams";
import { SidePanel } from "./SidePanel";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { SoundThing } from "./SoundThing";
import { useAuth } from "../../utility/useMsalAuth";
import { SidePanelToolBar } from "./SidePanelToolBar";
import * as microsoftTeams from "@microsoft/teams-js";
import { EditButton } from "../Buttons/EditButton";

function ProjectInfoForMeeting() {
  const auth = useAuth();
  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm({
    mode: "onBlur",
  });

  const [loadProjects, { called, loading, data: projectData }] = useProjectsForUserByUserIdLazyQuery();
  const [meetingRoomState, setMeetingRoomState] = useState("");

  useEffect(() => {
    microsoftTeams.meetingRoom.registerMeetingRoomStatesUpdateHandler((state) => {
      setMeetingRoomState(JSON.stringify(state));
    });

    if (!called) {
      loadProjects({ variables: { userId: auth.userId } });
    }
  }, []);

  const handleFormSubmit = (data) => {};

  return (
    <div className={"col-xs-12"}>
      <h4>Meeting Categorization</h4>
      <p>
        This makes sure everybody in the meeting bills to the right place. It will automatically add this to OpenAir for
        you.
      </p>
      <p>Room State: {meetingRoomState}</p>

      <div className="button-min">
        <EditButton
          text={"Request Camera"}
          onClick={() => {
            navigator.permissions.query({ name: "camera" }).then(function (result) {
              if (result.state == "granted") {
                // Access granted
                var constraints = { audio: true, video: { width: 1280, height: 720 } };

                navigator.mediaDevices
                  .getUserMedia(constraints)
                  .then(function (mediaStream) {
                    var video: any = document.querySelector("video");
                    if (video) {
                      video.srcObject = mediaStream;
                      video.onloadedmetadata = function (e) {
                        video.play();
                      };
                    } else {
                      alert("couldnt find video");
                    }
                  })
                  .catch(function (err) {
                    console.log(err.name + ": " + err.message);
                  });
              } else if (result.state == "prompt") {
                // Access has not been granted
              }
            });
          }}
        />
      </div>
      <Form className="ml-2 mr-2">
        <Form.Group className="mb-5">
          <Form.Label className="form-label-modal">Project Name</Form.Label>
          <Form.Control name={"titleText"} as="select" ref={register()}>
            {projectData?.projects.map((project) => {
              return <option>{project.project_name}</option>;
            })}
          </Form.Control>
        </Form.Group>

        <Button variant="modal-submit" onClick={handleSubmit(handleFormSubmit)}>
          Save
        </Button>

        <Button
          variant="modal-submit"
          onClick={() => {
            microsoftTeams.shareDeepLink({
              subEntityId: "PeepsPeople",
              subEntityLabel: "SidePanelLabel",
              subEntityWebUrl: "https://sparkworkspace.com/sidepanel",
            });
          }}>
          Get Deeplink
        </Button>

        <Button
          variant="modal-submit"
          onClick={() => {
            microsoftTeams.menus.showActionMenu(
              {
                title: "Toms Menu",
                items: [
                  {
                    title: "Title 1",
                    id: "id1",
                    icon: "<svg/>",
                    enabled: true,
                    viewData: {
                      listTitle: "Test",
                      listType: microsoftTeams.menus.MenuListType.dropDown,
                      listItems: [],
                    },
                    selected: false,
                  },
                ],
              },
              (id: string) => {
                return true;
              }
            );
            alert("after menu");
          }}>
          Show Action Menu
        </Button>

        <Button
          variant="modal-submit"
          onClick={() => {
            microsoftTeams.meeting.getAuthenticationTokenForAnonymousUser((error, token) => {
              if (error) {
                alert("ERROR");
                alert(JSON.stringify(error));
              }
              console.log(token);
              alert(token);
            });
          }}>
          getAuthenticationTokenForAnonymousUser
        </Button>
        <Button
          variant="modal-submit"
          onClick={() => {
            microsoftTeams.meeting.getMeetingDetails((error, details) => {
              if (error) {
                alert("ERROR");
                alert(JSON.stringify(error));
              }
              alert(JSON.stringify(details));
            });
          }}>
          getMeetingDetails
        </Button>
      </Form>
      <video></video>
    </div>
  );
}

export function CostOfThisMeeting() {
  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm({
    mode: "onBlur",
  });

  const handleFormSubmit = (data) => {};

  return (
    <div className={"col-xs-12"}>
      <h4>Cost of this meeting</h4>
      <p>
        This calculates an estimated financial cost for this meeting.{" "}
        <del> The cost is accurate to the penny because this app knows all the corporate salary data.</del>
        The scale is from $ to $$$$$$
      </p>
      <h6>Total: $$</h6>
      <br />
      <br />
      <br />
      <h4>Mental Cost of this meeting</h4>
      <p>This calculates an estimated mental cost for the meeting.</p>
      The scale is from 🧠 to 🧠🧠🧠🧠🧠🧠
      <h6>Total: 🧠🧠🧠</h6>
      <br />
      <br />
    </div>
  );
}

export function MeetingAppPage() {
  const teams = useMsTeams();

  let queryObject = {};
  let variableObject: any = {};

  if (teams.context && teams.context["chatId"]) {
    queryObject = { chatId: teams.context["chatId"] };
    variableObject = { sessionId: teams.context["chatId"], teams_context: teams.context };
  } else if (teams.context && teams.context["channelId"]) {
    queryObject = { channelId: teams.context["channelId"] };
    variableObject = { sessionId: teams.context["channelId"], teams_context: teams.context };
  } else if (teams.context && teams.context["entityId"]) {
    queryObject = { entityId: teams.context["entityId"] };
    variableObject = { sessionId: teams.context["entityId"], teams_context: teams.context };
  }

  const { data } = usePeopleInChatIdSubscription({
    variables: {
      chatId: queryObject,
    },
  });

  if (teams.context?.frameContext === "sidePanel") {
    return <SidePanel />;
  }

  if ((teams.context?.meetingId && teams.context.frameContext === "content") || !teams.context) {
    return (
      <div className={"m-5"}>
        <SidePanelToolBar />

        <h4>Welcome to Peeps 2 Meetings!</h4>
        <ProjectInfoForMeeting />
      </div>
    );
  } else
    return (
      <div>
        <h4>You shouldn't be seeing this page.</h4>
      </div>
    );
}
