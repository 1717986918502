import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import DeleteIcon from "../../images/trashcan-red.svg";
import {
  useDeleteUserEducationsMutation,
  useGetUserEducationLazyQuery,
  useGetUserEducationQuery,
  useInsertUserEducationMutation,
  useUpsertUserAboutMeMutation,
  useUpsertUserExperienceStartMutation,
  useUserAboutMeLazyQuery,
  useUserAboutMeQuery,
} from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";
import { Controller, useFieldArray, useForm } from "react-hook-form";

export const BioModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();
  const [mutation] = useUpsertUserAboutMeMutation();

  const [loadData, { called, loading, data }] = useUserAboutMeLazyQuery({
    fetchPolicy: "no-cache",
  });
  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm({
    mode: "onBlur",
  });
  useEffect(() => {
    if (!called) loadData({ variables: { userId: auth.userId } });
    if (data) {
      reset({
        aboutMe: data.users[0].extended_profiles?.about_me,
      });
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    mutation({
      variables: {
        about_me: formData.aboutMe,
      },
      refetchQueries: ["UserAboutMe"],
    })
      .catch((err) => {
        alert(JSON.stringify(err));
      })
      .then((res) => {
        handleModalClose();
      });
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <h4>About Me</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-5">
            <Form.Label className="form-label-modal">Write and intro or short bio</Form.Label>
            <Form.Control name={"aboutMe"} ref={register()} as="textarea" rows={5} />
          </Form.Group>
          <Button variant="modal-submit" onClick={handleSubmit(handleFormSubmit)}>
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export type UserEducationsForm = {
  educations: Array<{ schoolName: string; degreeName: string; fieldOfStudy: string }>;
  experienceStartDate: Date | null;
};
export const UserQualificationsModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();
  const [loadEducation, { called, loading, data: educationData }] = useGetUserEducationLazyQuery({
    fetchPolicy: "no-cache",
  });
  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm<UserEducationsForm>({
    mode: "onBlur",
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "educations",
  });

  const [mutation] = useInsertUserEducationMutation();
  const [deleteEducationsMutation] = useDeleteUserEducationsMutation();
  const [upsertUserExperienceStartMutation] = useUpsertUserExperienceStartMutation();
  const [experienceDate, setExperienceDate] = useState();

  useEffect(() => {
    if (!called)
      loadEducation({
        variables: { userId: auth.userId },
      });
    if (educationData) {
      let mappedEducationData = educationData.users[0].user_educations.map((ed) => {
        return {
          schoolName: ed.school_name,
          fieldOfStudy: ed.field_of_study,
          degreeName: ed.degree_name,
        };
      });

      if (educationData.users[0].extended_profiles && educationData.users[0].extended_profiles.experience_start_date) {
        setExperienceDate(educationData.users[0].extended_profiles.experience_start_date);
      }
      reset({
        educations: mappedEducationData,
        experienceStartDate: experienceDate,
      });
    }
  }, [educationData, experienceDate]);

  const handleExperienceSubmit = (data) => {
    if (!data.educations) {
      data.educations = [];
    }
    let mappedDataToSubmit = data.educations.map((ed) => {
      return {
        school_name: ed.schoolName,
        degree_name: ed.degreeName,
        field_of_study: ed.fieldOfStudy,
      };
    });

    deleteEducationsMutation({ variables: { userId: auth.userId } }).then(() => {
      mutation({
        variables: {
          objects: mappedDataToSubmit,
          experienceStartDate: data.experienceStartDate,
        },
        refetchQueries: ["UserAboutMe"],
      })
        .catch((err) => {
          alert(JSON.stringify(err));
        })
        .then((res) => {
          handleModalClose();
        });
    });
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <h4>Your Experience</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-5">
            <Form.Label className="form-label-modal">Select date of your first job</Form.Label>
            <div>
              <Controller
                control={control}
                name="experienceStartDate"
                render={({ onChange, onBlur, value }) => {
                  let dateValue: string | Date = "";
                  if (value) {
                    dateValue = new Date(value);
                  }
                  return (
                    <DatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={dateValue}
                    // dateFormat="YYYY-MM-DD"
                    />
                  );
                }}
                defaultValue={experienceDate}
              />
              {/* <DatePicker
                name={"experienceStartDate"}
                dateFormat="dd/MM/yyyy"
                selected={experienceDate}
                ref={register()}
                defaulValue={experienceDate}
              /> */}
            </div>
          </Form.Group>
          <Form.Group className="mb-5">
            <h4 className="mb-4">Add your Education</h4>
            {fields.map((item, index) => (
              <div className="mb-4" key={item.id}>
                <div className="row">
                  <div className="col-md-8 col-12 mb-3">
                    <Form.Label className="form-label-modal">School</Form.Label>
                    <Form.Control
                      name={`educations[${index}].schoolName`}
                      type="text"
                      placeholder="Enter School"
                      ref={register({ required: true })}
                      defaultValue={item.schoolName}
                    />
                    {errors.educations && errors.educations[index] && errors.educations[index]?.schoolName
                      ? "School name can't be empty"
                      : ""}
                  </div>
                  <div className="col-md-4 col-12 mb-3">
                    <Form.Label className="form-label-modal">Degree</Form.Label>
                    <Form.Control
                      name={`educations[${index}].degreeName`}
                      type="text"
                      placeholder="Enter Degree"
                      ref={register({ required: true })}
                      defaultValue={item.degreeName}
                    />
                    {errors.educations && errors.educations[index] && errors.educations[index]?.degreeName
                      ? "Degree name can't be empty"
                      : ""}
                  </div>

                  <div className="col-12 mb-3">
                    <Form.Label className="form-label-modal">Field of Study</Form.Label>
                    <Form.Control
                      name={`educations[${index}].fieldOfStudy`}
                      type="text"
                      placeholder="Field of Study"
                      ref={register({ required: true })}
                      defaultValue={item.fieldOfStudy}
                    />
                    {errors.educations && errors.educations[index] && errors.educations[index]?.fieldOfStudy
                      ? "Field of Study can't be empty"
                      : ""}
                  </div>
                </div>

                
                {fields.length > 1 && (
                  <div className="mb-2">
                    {index > 0 && (
                      <Button className="btn-move" variant="move_up" onClick={() => move(index, index - 1)}>
                    </Button>
                    )}
                    {index + 1 !== fields.length && (
                      <Button className="btn-move" variant="move_down" onClick={() => move(index, index + 1)}>
                      </Button>
                    )}
                    <Button variant="delete" onClick={() => remove(index)}>
                  <div className="label">Delete</div>
                </Button>
                  </div>
                )}

                
                
              </div>
            ))}


            <Button className="mt-5" variant="add" onClick={() => append({ schoolName: "", degreeName: "", fieldOfStudy: "" })}>
                  <div className="label">Add new school</div>
                </Button>
            
          </Form.Group>
          <Button variant="modal-submit" onClick={handleSubmit(handleExperienceSubmit)}>
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
