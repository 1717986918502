import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useFieldArray, useForm } from "react-hook-form";

import {
  useDeleteUserAchievementsMutation,
  useGetUserWithAchievementsLazyQuery,
  useInsertUserAchievementsMutation,
} from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";

export const AchievementsModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();
  const [loadAchievements, { called, loading, data: achievementsData }] = useGetUserWithAchievementsLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [insertAchievementsMutation] = useInsertUserAchievementsMutation();
  const [deleteAchievementsMutation] = useDeleteUserAchievementsMutation();

  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm({
    mode: "onBlur",
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "achievements",
  });

  useEffect(() => {
    if (!called) loadAchievements({ variables: { userId: auth.userId } });
    if (achievementsData) {
      reset({
        achievements: achievementsData.users[0].user_achievements,
      });
    }
  }, [achievementsData]);

  const handleFormSubmit = (data) => {
    if (!data.achievements) {
      data.achievements = [];
    }
    deleteAchievementsMutation({ variables: { userId: auth.userId } }).then(() => {
      insertAchievementsMutation({
        variables: {
          objects: data.achievements,
        },
        refetchQueries: ["GetUserExperiences"],
      }).then(() => {
        handleModalClose();
      });
    });
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="add-achievements-modal">
      <Modal.Header closeButton>
        <h4>Add Achievements</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-5">
            {fields.map((item, index) => (
              <div key={item.id}>
                <div className="row">
                  <div className="col-md-8 col-12 mb-3">
                    <Form.Label className="form-label-modal">Name</Form.Label>
                    <Form.Control
                      name={`achievements[${index}].name`}
                      type="text"
                      placeholder="Enter name"
                      ref={register({ required: true })}
                      defaultValue={item.name}
                    />
                    {errors.achievements && errors.achievements[index] && errors.achievements[index]?.name
                      ? "Name can't be empty"
                      : ""}

                    <div className="col-md-4 col-12 mb-3">
                      <Form.Label className="form-label-modal">Description</Form.Label>
                      <Form.Control
                        name={`achievements[${index}].description`}
                        type="text"
                        placeholder="Enter description"
                        ref={register({ required: true })}
                        defaultValue={item.description}
                      />
                    </div>
                    <Form.Label className="form-label-modal">Url</Form.Label>
                    <Form.Control
                      name={`achievements[${index}].url`}
                      type="text"
                      placeholder="Enter url"
                      ref={register({ required: true })}
                      defaultValue={item.url}
                    />
                    {errors.achievements && errors.achievements[index] && errors.achievements[index]?.url
                      ? "Url can't be empty"
                      : ""}
                  </div>
                </div>

                {/* // TODO - better Move buttons */}
                {fields.length > 1 && (
                  <div>
                    {index > 0 && (
                      <button type="button" onClick={() => move(index, index - 1)}>
                        Move Up
                      </button>
                    )}
                    {index + 1 !== fields.length && (
                      <button type="button" onClick={() => move(index, index + 1)}>
                        Move Down
                      </button>
                    )}
                  </div>
                )}

                <button type="button" onClick={() => remove(index)}>
                  Delete
                </button>
                {/* TODO: better Delete button */}
              </div>
            ))}

            <button type="button" onClick={() => append({ name: "" })}>
              Add new achievement
            </button>
          </Form.Group>
          <Button variant="modal-submit" onClick={handleSubmit(handleFormSubmit)}>
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
