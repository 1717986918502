import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import {
  useAllProjectsForUserIdIncludingNotShownLazyQuery,
  useUpdateProjectIsShownMutation,
} from "../../generated/graphql";
import { useMsGraphService } from "../../services/msGraphService";
import { useAuth } from "../../utility/useMsalAuth";
const DefaultProfileImg = require("../../images/default_profile_img.png") as string;

export const UserProjectsModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();
  const [loadData, { called, loading, data }] = useAllProjectsForUserIdIncludingNotShownLazyQuery({
    variables: { userId: auth.userId },
    fetchPolicy: "no-cache",
  });
  const [mutation] = useUpdateProjectIsShownMutation();

  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm({
    mode: "onBlur",
  });

  const handleFormSubmit = (formData) => {
    data?.project_users.forEach((project, index) => {
      if (project.is_shown !== formData.projects[index].is_shown) {
        mutation({
          variables: { userId: auth.userId, projectId: project.project.id, isShown: formData.projects[index].is_shown },
          refetchQueries: ["AllProjectsForUserIdIncludingNotShown"],
        });
      }
    });

    handleModalClose();
  };

  useEffect(() => {
    if (!called) loadData();
    if (data) {
      reset({
        projects: data.project_users,
      });
    }
  }, [data]);

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="user-projects-modal">
      <Modal.Header closeButton>
        <h4>Update Displayed Projects</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-5">
            {data?.project_users.map((project, index) => {
              return (
                <Form.Check
                  name={`projects[${index}].is_shown`}
                  label={project.project.project_alias || project.project.project_name}
                  defaultChecked={project.is_shown}
                  ref={register}
                />
              );
            })}
          </Form.Group>
          <Button variant="modal-submit" onClick={handleSubmit(handleFormSubmit)}>
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
