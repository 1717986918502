var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useLayoutEffect, useState, useRef } from "react";
import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import { Box, ButtonContent, ProviderConsumer as FluentUIThemeConsumer, Toolbar as FluentUIToolbar, Tooltip, tooltipAsLabelBehavior, } from "@fluentui/react-northstar";
import Icon from "../../lib/Icon";
import { ToolbarFilter } from "./ToolbarFilter";
import { ToolbarFind } from "./ToolbarFind";
import { ToolbarTheme } from "./ToolbarTheme";
import "./toolbar.css";
var slugSeparator = "__";
var toolbarMenuProps = {
    offset: [0, 4],
    position: "below",
};
var toolbarActionTooltipProps = (function () {
    var props = cloneDeep(toolbarMenuProps);
    props.offset[1] += 10;
    return props;
})();
var toolbarButtonStyles = {
    padding: ".5rem",
    borderWidth: "1px",
    marginTop: 0,
    marginBottom: 0,
    height: "3rem",
    minWidth: 0,
    "&:focus:before": {
        top: "calc(.5rem - 1px)",
        bottom: "calc(.5rem - 1px)",
    },
    "&:focus:after": {
        top: "calc(.5rem - 1px)",
        bottom: "calc(.5rem - 1px)",
    },
};
function flattenedActions(actionGroups) {
    return Object.keys(actionGroups).reduce(function (acc_i, actionGroupSlug) {
        var actionGroup = actionGroups[actionGroupSlug];
        return Object.keys(actionGroup).reduce(function (acc_j, actionSlug) {
            var action = actionGroup[actionSlug];
            acc_j["" + actionGroupSlug + slugSeparator + actionSlug] = action;
            return acc_j;
        }, acc_i);
    }, {});
}
function needsSeparator(actionSlug, index, actionSlugs) {
    var _a;
    return index === 0 ? false : ((_a = actionSlugs[index - 1]) === null || _a === void 0 ? void 0 : _a.split(slugSeparator)[0]) !== actionSlug.split(slugSeparator)[0];
}
var InFlowToolbarItem = function (_a) {
    var action = _a.action, layout = _a.layout;
    var icon = action.icon, title = action.title;
    var contentIcon = (React.createElement(Box, { styles: {
            width: "1rem",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            "@media (min-width: 640px)": {
                marginRight: ".5rem",
            },
        }, className: "extended-toolbar__near-side__item__icon" },
        React.createElement(Icon, { icon: icon })));
    switch (layout) {
        case "verbose":
            return (React.createElement(React.Fragment, null,
                contentIcon,
                React.createElement(ButtonContent, { content: title })));
        default:
        case "compact":
            return (React.createElement(Tooltip, __assign({}, toolbarActionTooltipProps, { trigger: contentIcon, content: title, accessibility: tooltipAsLabelBehavior })));
    }
};
export var Toolbar = function (props) {
    var actionGroups = props.actionGroups, filters = props.filters, filtersSingleSelect = props.filtersSingleSelect, find = props.find;
    var allActions = flattenedActions(actionGroups);
    var _a = useState(false), overflowOpen = _a[0], setOverflowOpen = _a[1];
    var _b = useState(false), filtersOpen = _b[0], setFiltersOpen = _b[1];
    var _c = useState("compact"), layout = _c[0], setLayout = _c[1];
    var _d = useState(false), findActive = _d[0], setFindActive = _d[1];
    var layoutQuery = useRef(null);
    var onChangeLayout = function () {
        setLayout(layoutQuery.current && layoutQuery.current.matches ? "verbose" : "compact");
    };
    useLayoutEffect(function () {
        layoutQuery.current = window.matchMedia("(min-width: 640px)");
        layoutQuery.current.addEventListener("change", onChangeLayout);
        onChangeLayout();
        return function () { var _a; return (_a = layoutQuery.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("change", onChangeLayout); };
    });
    var inFlowToolbarItems = Object.keys(allActions).reduce(function (acc, actionSlug, index, actionSlugs) {
        var action = allActions[actionSlug];
        var onClick = action.__internal_callback__ &&
            props.__internal_callbacks__ &&
            props.__internal_callbacks__[action.__internal_callback__];
        acc.push(__assign({ key: actionSlug, children: React.createElement(InFlowToolbarItem, { action: action, layout: layout }), title: action.title, "aria-label": action.title, className: "extended-toolbar__near-side__item", styles: __assign(__assign({}, toolbarButtonStyles), { flex: "0 0 auto", margin: "0 .0625rem", display: "inline-flex", justifyContent: "center", alignItems: "center" }) }, (onClick ? { onClick: onClick } : {})));
        if (needsSeparator(actionSlug, index, actionSlugs))
            acc.push({
                key: "divider" + slugSeparator + index,
                kind: "divider",
            });
        return acc;
    }, []);
    var overflowToolbarItems = Object.keys(allActions).reduce(function (acc, actionSlug, index, actionSlugs) {
        var action = allActions[actionSlug];
        acc.push({
            key: actionSlug,
            content: action.title,
            icon: React.createElement(Icon, { icon: action.icon }),
            title: action.title,
            "aria-label": action.title,
            styles: { padding: ".375rem .5rem" },
        });
        if (needsSeparator(actionSlug, index, actionSlugs))
            acc.push({
                key: "divider" + slugSeparator + index,
                kind: "divider",
                styles: { margin: ".25rem 0", "&:first-child": { display: "none" } },
            });
        return acc;
    }, []);
    var displayFindOnly = find && layout === "compact" && findActive;
    return (React.createElement(FluentUIThemeConsumer, { render: function (globalTheme) { return (React.createElement(ToolbarTheme, { globalTheme: globalTheme },
            React.createElement(Box, __assign({ className: "extended-toolbar", variables: function (_a) {
                    var colorScheme = _a.colorScheme, theme = _a.theme;
                    return ({
                        backgroundColor: theme === "teamsHighContrastTheme" ? colorScheme.grey.background : colorScheme.grey.background2,
                        elevation: colorScheme.elevations[16],
                    });
                }, styles: {
                    padding: "0 1.25rem",
                    marginBottom: "1.25rem",
                    display: "flex",
                    justifyContent: "space-between",
                } }, omit(props, [
                "actionGroups",
                "filters",
                "find",
                "filtersSingleSelect",
                "onSelectedFiltersChange",
                "onFindQueryChange",
            ])),
                !displayFindOnly && (React.createElement(FluentUIToolbar, { "aria-label": "Extended toolbar", className: "extended-toolbar__near-side", items: inFlowToolbarItems, overflow: true, overflowOpen: overflowOpen, overflowItem: {
                        title: "More",
                        menu: toolbarMenuProps,
                        styles: toolbarButtonStyles,
                    }, onOverflowOpenChange: function (e, props) {
                        var open = !!(props === null || props === void 0 ? void 0 : props.overflowOpen);
                        setOverflowOpen(open);
                        if (open)
                            setFiltersOpen(false);
                    }, getOverflowItems: function (startIndex) { return overflowToolbarItems.slice(startIndex); }, styles: {
                        flex: "1 0 0",
                        overflow: "hidden",
                        maxWidth: "40rem",
                        minWidth: "2rem",
                    } })),
                React.createElement(Box, { className: "extended-toolbar__far-side", styles: {
                        flex: displayFindOnly ? "1 1 100%" : "0 1 auto",
                        display: "flex",
                        flexFlow: "row nowrap",
                        overflow: "hidden",
                        paddingLeft: displayFindOnly ? "0" : "2.5rem",
                    } },
                    !displayFindOnly && filters && (React.createElement(ToolbarFilter, { layout: layout, filters: filters, singleSelect: !!filtersSingleSelect, open: filtersOpen, onOpenChange: function (_e, props) {
                            var open = !!(props === null || props === void 0 ? void 0 : props.open);
                            setFiltersOpen(open);
                            if (open)
                                setOverflowOpen(false);
                        }, onSelectedFiltersChange: props.onSelectedFiltersChange, toolbarMenuProps: toolbarMenuProps, toolbarButtonStyles: toolbarButtonStyles })),
                    find && (React.createElement(ToolbarFind, __assign({}, {
                        layout: layout,
                        findActive: findActive,
                        setFindActive: setFindActive,
                        toolbarButtonStyles: toolbarButtonStyles,
                        onFindQueryChange: props.onFindQueryChange,
                    }))))))); } }));
};
