import React, { createContext, useContext, useState, useEffect } from "react";
import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { getAzureADResourcePath } from "./Utility";

function loggerCallback(logLevel, message, containsPii) {
  console.log(logLevel, message, containsPii);
}

const config: msal.Configuration = {
  auth: {
    authority: "https://login.microsoftonline.com/common",
    // authority: "https://login.microsoftonline.com/2c70ddde-a0db-42b5-98ef-0ee5d08465b1", // "https://login.microsoftonline.com/common",
    clientId: process.env["REACT_APP_AZUREAD_APP_ID"] || "",
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: loggerCallback,
      logLevel: msal.LogLevel.Verbose,
    },
  },
};

const msalInstance = new msal.PublicClientApplication(config);

type AuthContextProps = {
  user: msal.AccountInfo;
  setUser: (user: msal.AccountInfo) => void;
  userId: string;
  logininProgress: boolean;
  signin: () => void;
  signinPopup: () => void;

  signout: () => void;
  msal: msal.PublicClientApplication;
  multipleUsers: Array<msal.AccountInfo>;
  acquireSilentIdToken: () => Promise<string>;
};
const authContext = createContext<Required<AuthContextProps>>({
  user: { homeAccountId: "", environment: "", username: "", tenantId: "", localAccountId: "" },
  setUser: (user: {}) => {},
  logininProgress: false,
  userId: "",
  signin: () => {},

  signinPopup: () => {},
  msal: msalInstance,
  signout: () => {},
  multipleUsers: [],
  acquireSilentIdToken: () => Promise.resolve(""),
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... update when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

export type UserAuthState = {};

// Provider hook that creates auth object and handles state
export function useProvideAuth() {
  const [user, setUser] = useState<any>();
  const [userId, setUserId] = useState<any>();
  const [logininProgress, setLoginInProgress] = useState(false);
  const [multipleUsers, setMultipleUsers] = useState<any>();

  const [state, setState] = useState<AuthContextProps>();

  const authProvider = msalInstance;

  const signin = () => {
    if (!logininProgress) {
      setLoginInProgress(true);
      return authProvider.loginRedirect({
        scopes: ["https://graph.microsoft.com/.default"],
      });
    }
  };

  const signinPopup = () => {
    return authProvider.loginPopup({
      scopes: ["https://graph.microsoft.com/.default"],
    });
  };

  const signout = () => {
    return authProvider.logout();
  };

  const acquireSilentIdToken = async () => {
    try {
      let resourcePath = getAzureADResourcePath();
      const token = await authProvider.acquireTokenSilent({
        scopes: [`${resourcePath}/access_as_user`], //[process.env["REACT_APP_AZUREAD_APP_ID"] as string],
        account: user,
      });

      return token.idToken;
    } catch (err) {
      console.log(err);
      if (err instanceof InteractionRequiredAuthError) {
        authProvider.loginRedirect();
      }
      throw new Error(err);
    }
  };

  useEffect(() => {
    setLoginInProgress(true);
    msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        setLoginInProgress(false);
        console.log("RETURN FROM HANDLEREDIRECTPROMISE");
        let accountObj: any = null;
        if (tokenResponse !== null) {
          accountObj = tokenResponse.account;

          setUser(accountObj);
          setUserId(accountObj.homeAccountId.split(".")[0]);
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          if (!currentAccounts || currentAccounts.length === 0) {
            // No user signed in
            console.log("NO USER SIGNED IN");
            signin();
            return;
          } else if (currentAccounts.length > 1) {
            // More than one user signed in, find desired user with getAccountByUsername(username)
            setMultipleUsers(currentAccounts);
          } else {
            accountObj = currentAccounts[0];
            setUser(accountObj);
            setUserId(accountObj.homeAccountId.split(".")[0]);
          }
        }
      })
      .catch((error) => {
        debugger;
      });
  }, []);

  return {
    msal: msalInstance,
    user,
    setUser: setUser,
    logininProgress: logininProgress,
    signin,
    signinPopup,
    signout,
    userId,
    multipleUsers,
    acquireSilentIdToken: acquireSilentIdToken,
  };
}
