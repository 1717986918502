import React from "react";
import { Flex, Card, Text, Box, tabListBehavior, Menu, } from "@fluentui/react-northstar";
import { DashboardCallout } from "./DashboardCallout";
export var WidgetSize;
(function (WidgetSize) {
    WidgetSize["Single"] = "single";
    WidgetSize["Double"] = "double";
    WidgetSize["Triple"] = "triple";
    WidgetSize["Box"] = "box";
})(WidgetSize || (WidgetSize = {}));
export var Widget = function (_a) {
    var children = _a.children, size = _a.size;
    var cardStyle = {
        gridColumnEnd: "auto",
        gridRowEnd: "auto",
        "@media (max-width: 842px)": {
            gridColumnEnd: "span 3",
        },
    };
    if (size === WidgetSize.Double) {
        cardStyle.gridColumnEnd = "span 2";
    }
    if (size === WidgetSize.Box) {
        cardStyle.gridColumnEnd = "span 2";
        cardStyle.gridRowEnd = "span 2";
    }
    if (size === WidgetSize.Triple) {
        cardStyle.gridColumnEnd = "span 3";
    }
    return (React.createElement(Card, { styles: cardStyle, fluid: true }, children));
};
export var WidgetTitle = function (_a) {
    var title = _a.title, desc = _a.desc, globalTheme = _a.globalTheme, widgetActionGroup = _a.widgetActionGroup;
    var _b = React.useState(false), calloutOpen = _b[0], setCalloutOpen = _b[1];
    return (React.createElement(Card.Header, null,
        React.createElement(Flex, { gap: "gap.small", space: "between", style: { minHeight: "2rem" } },
            React.createElement(Flex, { gap: "gap.small", column: true },
                React.createElement(Text, { content: title, style: { margin: 0 }, weight: "bold" }),
                desc && React.createElement(Text, { content: desc, size: "small" })),
            React.createElement(DashboardCallout, { open: calloutOpen, globalTheme: globalTheme, onOpenChange: function (_a, props) {
                    var currentTarget = _a.currentTarget;
                    var open = !!(props === null || props === void 0 ? void 0 : props.open);
                    setCalloutOpen(open);
                }, menuProps: {
                    offset: [0, 0],
                    position: "below",
                }, widgetActionGroup: widgetActionGroup }))));
};
var EmptyState = function (_a) {
    var borderColor = _a.borderColor;
    return (React.createElement(Box, { styles: {
            height: "100%",
            border: "1px dashed " + borderColor,
        } }));
};
export var WidgetBody = function (_a) {
    var body = _a.body, siteVariables = _a.siteVariables;
    var _b = React.useState(0), activeTabId = _b[0], setActiveTabId = _b[1];
    return (React.createElement(Card.Body, { style: {
            marginBottom: "0.75rem",
            height: "100%",
            overflow: "hidden",
        }, fitted: true }, body ? (React.createElement(React.Fragment, null,
        body.length > 1 && (React.createElement(Menu, { style: {
                border: "none",
                background: "none",
                marginBottom: "1.25rem",
            }, items: Array.from(body, function (_a) {
                var id = _a.id, title = _a.title;
                return Object.assign({ key: id, content: title });
            }), activeIndex: activeTabId, onItemClick: function (_a, props) {
                var currentTarget = _a.currentTarget;
                return setActiveTabId(props && props.index ? props.index : 0);
            }, accessibility: tabListBehavior, underlined: true, primary: true })),
        body.map(function (_a, i) {
            var id = _a.id, content = _a.content;
            return (React.createElement(Flex, { key: id, styles: {
                    height: "100%",
                    display: activeTabId === i ? "flex" : "none",
                }, column: true }, content));
        }))) : (React.createElement(EmptyState, { borderColor: siteVariables.colors.grey["300"] }))));
};
export var WidgetFooter = function (_a) {
    var link = _a.link;
    return (React.createElement(Card.Footer, { fitted: true },
        React.createElement(Flex, { space: "between", vAlign: "center" },
            React.createElement(Text, { as: "a", href: link.href, target: "_blank", content: "View more", size: "small", color: "brand", style: { textDecoration: "none" } }))));
};
