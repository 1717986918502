import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { Link, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import {
  useInsertTeamsSessionMutation,
  useInsertTenantWithUserMutation,
  useInsertUserPhotoMutation,
  useInsertUserWithTenantMutation,
  useFireTeamsMeetingNotificationMutation,
  useMyQueryQuery,
} from "../../generated/graphql";
import { useMsGraphService } from "../../services/msGraphService";
import { GraphQLProvider } from "../../utility/GraphQLProvider";
import { ProvideMsTeams, useMsTeams } from "../../utility/useMicrosoftTeams";
import { ProvideModalContext } from "../../utility/useModalLauncherContext";
import { ProvideAuth, useAuth } from "../../utility/useMsalAuth";
import { useUserStartup } from "../../utility/useUserStartup";
import Header from "../LoginHeader/loginHeader";
import { ModalLauncher } from "../ProfileEdits/ModalLauncher";
import { UserProfile } from "../UserProfile/UserProfile";
import { UserSearchBox2 } from "../UserSearch/usersearch";
import { UsersWithQuery } from "../UserTile/usertile";
import * as microsoftTeams from "@microsoft/teams-js";
import { NotificationTypes } from "@microsoft/teams-js";
import { UxUtils } from "../../utility/UxUtils";
import { MeetingAppConfigPage } from "../TeamsApp/MeetingAppConfigPage";
import { SidePanel } from "../TeamsApp/SidePanel";
import { CostOfThisMeeting, MeetingAppPage } from "../TeamsApp/MeetingAppPage";
import { Timeline } from "../TeamsApp/Timeline";
import { BoardThing } from "../TeamsApp/BoardThing";
import { DashboardThing } from "../TeamsApp/DashboardThing";
import { SidePanelToolBar } from "../TeamsApp/SidePanelToolBar";
import { RemoteControl } from "../TeamsApp/RemoteControl";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { CameraPage } from "../TeamsApp/CameraPage";
import { Notifications } from "../TeamsApp/Notifications";
import { Chats } from "../TeamsApp/Chats";
import { Messages } from "../TeamsApp/Messages";

const Redirected = () => {
  const teams = useMsTeams();

  useEffect(() => {
    if (teams.context && teams.ssoStatus === "successful") {
      microsoftTeams.authentication.notifySuccess();
    }
  }, [teams.context, teams.ssoStatus]);
  return <div>I was redirected to here!!!</div>;
};

const App = () => {
  const auth = useAuth();
  const { isFirstRun, insertUserFailed } = useUserStartup();
  const msGraphService = useMsGraphService();
  const [photoUrl, setPhotoUrl] = useState<string>();
  const [profile, setProfile] = useState<any>();
  const [userPhotoMutation] = useInsertUserPhotoMutation();
  const [mutation] = useInsertTeamsSessionMutation();
  const [mutation2] = useFireTeamsMeetingNotificationMutation();

  const teams = useMsTeams();
  const { data } = useMyQueryQuery();

  const [searchText, setSearchText] = useState("*");
  const history = useHistory();
  useEffect(() => {
    if (auth.userId) {
      if (insertUserFailed) {
        history.push("/setuptenant");
      }

      msGraphService.getMyPhoto().then((photo) => setPhotoUrl(photo));
      msGraphService.getUserProfileById(auth.userId).then((profile) => {
        setProfile(profile);
      });

      msGraphService.getMyPhotoBase64().then((photo) => {
        userPhotoMutation({ variables: { image_base64: photo } });
      });
    }
  }, [auth.user, insertUserFailed]);

  useEffect(() => {
    if (teams.context) {
      if (teams.teamsState === "inTeams" && teams.context?.meetingId) {
        console.log("INSERTING SESSION MUTATION");
        mutation({ variables: { sessionId: teams.context.meetingId, teams_context: teams.context } });
      }
      if (teams.teamsState === "inTeams" && !teams.context?.meetingId) {
        console.log("INSERTING TAB SESSION MUTATION");
        mutation({ variables: { sessionId: teams.context.entityId, teams_context: teams.context } });
      }
    }
  }, [teams]);
  const match = useRouteMatch<{ id: string }>("/user/:id");

  return (
    <ProvideModalContext>
      <div>
        {JSON.stringify(teams)}
        <Switch>
          <Route path="/user/:id">
            {profile && photoUrl && <Header name={profile?.displayName} profile_photo={photoUrl || ""} />}

            <UserProfile userId={match?.params.id} />
          </Route>
          <Route path="/me">
            {profile && photoUrl && <Header name={profile?.displayName} profile_photo={photoUrl || ""} />}

            <UserProfile userId={auth.userId} />
          </Route>
          <Route path="/redirect">
            <Redirected />
          </Route>
          <Route path="/configure">
            <MeetingAppConfigPage />
          </Route>
          <Route path="/camera">
            <CameraPage />
          </Route>
          <Route path="/peeps">
            {profile && photoUrl && <Header name={profile?.displayName} profile_photo={photoUrl || ""} />}

            <UserProfile userId={auth.userId} />
          </Route>
          <Route path="/timeline">
            <Timeline />
          </Route>
          <Route path="/board">
            <BoardThing />
          </Route>
          <Route path="/dashboard">
            <DashboardThing />
          </Route>
          <Route path="/remotecontrol">
            <RemoteControl />
          </Route>
          <Route path="/sidepanel">
            <SidePanel />
          </Route>
          <Route path="/redcard">
            <div style={{ backgroundColor: "red" }}>
              You have been given a Teams red card for breaking Teams meeting etiquette! You should be ashamed of
              yourself.
            </div>
          </Route>
          <Route path="/greencard">
            <div style={{ backgroundColor: "green" }}>You have been given a Teams green card for being awesome!</div>
          </Route>
          <Route path="/yellowcard">
            <div style={{ backgroundColor: "yellow" }}>
              You have been given a Teams yellow card! This is a warning that the meeting is getting off track!
            </div>
          </Route>
          <Route path="/offtherails">
            <div>
              <div className={"container"}>
                <div className="d-flex flex-column justify-content-center">
                  <h1>This meeting is off the rails</h1>
                  <img className={"img-fluid"} src={"offtherails.jpg"} />
                </div>
              </div>
            </div>
          </Route>
          <Route path="/chats">
            <Chats />
          </Route>

          <Route path="/costestimator">
            <CostOfThisMeeting />
          </Route>
          <Route path="/nda">
            <div>
              <h4>NDA</h4>
              <p>This meeting is governed by a non disclosure agreement.</p>
              <Button variant="modal-submit" onClick={() => {}}>
                Accept
              </Button>
            </div>
          </Route>
          <Route path="/consent">
            <div>
              <h4>Consent</h4>
              <p>Do you consent to let Tom use your voice and image recorded from this session?</p>
              <Button variant="modal-submit" onClick={() => {}}>
                Yes
              </Button>
              <Button variant="modal-submit" onClick={() => {}}>
                No
              </Button>
            </div>
          </Route>
          <Route path="/wander">
            <div className={"container"}>
              <div className="d-flex flex-column justify-content-center">
                <h1>This meeting has wandered off the beaten path</h1>
                <img className={"img-fluid"} src={"offthebeatenpath.jpg"} />
              </div>
            </div>
          </Route>
          <Route path="/meeting">
            <MeetingAppPage />
          </Route>
          <Route path="/terms">
            <div>This is the Terms of Use page.</div>
          </Route>
          <Route path="/privacy">
            <div>This is the Privacy page.</div>
          </Route>
          <Route path="/notifications">
            <div>
              <Notifications />
            </div>
          </Route>
          <Route path="/task">
            <div>This is a task!</div>

            <button
              onClick={() => {
                microsoftTeams.tasks.submitTask(
                  { result: "This is my result" },
                  "01f159b5-c487-4d48-b3a8-ee4398971689"
                );
              }}>
              Submit Task!
            </button>
          </Route>
          <Route path="/setuptenant">
            <div>
              Your tenant is not allowed to use this app yet.
              <Button
                variant="modal-submit"
                onClick={() => {
                  auth.signout();
                }}>
                Sign out
              </Button>
            </div>
          </Route>
          <Route path="/">
            {profile && photoUrl && <Header name={profile?.displayName} profile_photo={photoUrl || ""} />}
            <div className="container">
              <UserSearchBox2
                onSearch={({ searchString }) => {
                  setSearchText("%" + searchString + "%");
                }}
              />
              <UsersWithQuery searchQuery={searchText} />
              <Link to="/chats">Chats</Link>
            </div>
          </Route>
        </Switch>

        <ModalLauncher />
      </div>
    </ProvideModalContext>
  );
};

export function AppInner() {
  const auth = useAuth();
  const teams = useMsTeams();

  const [state, setState] = useState({
    ready: false,
    tokenError: "",
  });

  useEffect(() => {
    if (
      (!state.ready && auth.user && teams.teamsState === "notInTeams") ||
      (!state.ready && auth.user && teams.teamsState === "inTeams" && teams.ssoStatus === "successful")
    ) {
      auth
        .acquireSilentIdToken()
        .then((token) => {
          setState({ ...state, ready: true });
        })
        .catch((err) => {
          setState({ ...state, ready: false, tokenError: JSON.stringify(err) });
          console.log("ERROR GETTING SILENT TOKEN");
        });
    }
  }, [auth.user, teams.teamsState, state.ready, teams.ssoStatus, teams.context]);

  if (state.ready) {
    return (
      <div>
        {/* {teams.teamsState === "notInTeams" && state.ready && (
          <div style={{ backgroundColor: "blue", minHeight: "100px", color: "white" }}>
            We are NOT in Teams
            <a
              onClick={() => {
                auth.signout();
              }}>
              Logout
            </a>
          </div>
        )} */}
        <GraphQLProvider>
          <App />
        </GraphQLProvider>
      </div>
    );
  }
  if (!state.ready && auth.user) {
    return <div>{state.tokenError}</div>;
  }
  if (!state.ready && (teams.ssoStatus === "notAttempted" || teams.ssoStatus === "inProgress")) {
    // return <div style={{ backgroundColor: "blue" }}>TEAMS IS HERE</div>;
    return <Skeleton duration={2} />;
  }
  const s = {
    display: "flex",
    alignItems: " ",
    justifyContent: "center",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: "red", // TODO - real signin page styling?
  };
  return (
    <div>
      {!auth.user && !auth.logininProgress ? (
        <div style={s}>
          <div style={{ cursor: "pointer" }} onClick={() => auth.signin()}>
            <Button variant="modal-submit">Sign in</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export function AppWrapper() {
  return (
    <ProvideAuth>
      <ProvideMsTeams>
        <AppInner />
      </ProvideMsTeams>
    </ProvideAuth>
  );
}

export default AppWrapper;
