import React, { useEffect, useState } from "react";
import { useFireTeamsMeetingNotificationMutation, usePeopleInChatIdSubscription } from "../../generated/graphql";
import { useMsTeams } from "../../utility/useMicrosoftTeams";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { SoundThing } from "./SoundThing";
import { Timeline } from "./Timeline";
import { EditButton } from "../Buttons/EditButton";
import { useModalContext } from "../../utility/useModalLauncherContext";
import { Link, useRouteMatch } from "react-router-dom";
import { SidePanelToolBar } from "./SidePanelToolBar";
import * as microsoftTeams from "@microsoft/teams-js";
import { useMsGraphService } from "../../services/msGraphService";

export type Chat = {
  chatType: string;
  id: string;
  topic: string;
  webUrl: string;
  createdDateTime: Date;
  lastUpdatedDateTime: Date;
  viewpoint: { lastMessageReadTime: string };
};

export type TeamsMessage = {
  body: { contentType: "html" | "text"; content: string };
  messageType: string;
};
export function Messages({ id }: { id: string }) {
  const msGraphService = useMsGraphService();
  let [messagesState, setMessagesState] = useState<Array<TeamsMessage>>([]);
  const match = useRouteMatch<{ id: string }>("/chats/:id");

  useEffect(() => {
    msGraphService.getMembersInChat(id).then((members) => {
      console.log(members);
    });
    msGraphService.getMessagesInChat(id).then((messages) => {
      setMessagesState(messages);
    });
  }, [id]);

  return (
    <div>
      {messagesState.map((message) => {
        return <div dangerouslySetInnerHTML={{ __html: message.body.content }}></div>;
      })}
    </div>
  );
}
