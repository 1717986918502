import React from "react";
import "./buttons.scss";

const PencilIcon = require("../../images/Pencil.svg") as string;

export function EditButton(props) {
  return (
    <div {...props}>
      <div className={"edit-button"}>
        <img className={"edit-button-icon"} src={props.icon || PencilIcon} alt="edit" />
        {props.text || "Edit"}
      </div>
    </div>
  );
}
