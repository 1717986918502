import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { EditButton } from "../Buttons/EditButton";

export function CameraPage() {
  let [accessGranted, setAccessGranted] = useState(false);
  let [message, setMessage] = useState("");

  useEffect(() => {
    setTimeout(() => {
      navigator.permissions.query({ name: "camera" }).then((result) => {
        if (result.state == "granted") {
          setAccessGranted(true);

          // Access granted
          var constraints = { audio: true, video: { width: 1280, height: 720 } };

          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (mediaStream) {
              var video: any = document.querySelector("video");
              if (video) {
                setMessage("before play video");
                video.srcObject = mediaStream;
                video.onloadedmetadata = function (e) {
                  video.play();
                };
              } else {
                setMessage("couldnt find video");
              }
            })
            .catch(function (err) {
              console.log(err.name + ": " + err.message);
              setMessage(err.message);
            });
        } else if (result.state == "prompt") {
          // Access has not been granted
          setAccessGranted(false);
          setMessage("prompt");
        }
      });
    }, 1000);
  }, []);
  return (
    <div>
      <h1>Camera Page</h1>
      <div>Access Granted: {JSON.stringify(accessGranted)}</div>
      <div>Message: {message}</div>

      <video></video>

      <EditButton
        text={"Request Camera"}
        onClick={() => {
          navigator.permissions.query({ name: "camera" }).then(function (result) {
            if (result.state == "granted") {
              // Access granted
              var constraints = { audio: true, video: { width: 1280, height: 720 } };

              navigator.mediaDevices
                .getUserMedia(constraints)
                .then(function (mediaStream) {
                  var video: any = document.querySelector("video");
                  if (video) {
                    video.srcObject = mediaStream;
                    video.onloadedmetadata = function (e) {
                      video.play();
                    };
                  } else {
                    alert("couldnt find video");
                  }
                })
                .catch(function (err) {
                  console.log(err.name + ": " + err.message);
                });
            } else if (result.state == "prompt") {
              // Access has not been granted
            }
          });
        }}
      />
    </div>
  );
}
