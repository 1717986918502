import React from "react";
import { useModalContext } from "../../utility/useModalLauncherContext";
import { useAuth } from "../../utility/useMsalAuth";
import { BioModal, UserQualificationsModal } from "./AboutMeModals";
import { AchievementsModal } from "./AchievementsModal";
import { ContactsSectionModal } from "./ContactSectionModal";
import { ExperienceModal } from "./ExperienceModal";
import QuoteModal from "./QuoteModal";
import { UserHeaderImageModal } from "./UserHeaderImageModal";
import { UserImageModal } from "./UserImageModal";
import { UserProjectsModal } from "./UserProjectsModal";

export const ModalLauncher = () => {
  const modal = useModalContext();
  const auth = useAuth();

  if (!modal.isModalOpen) {
    return null;
  }

  let modalToLaunch: any = null;

  switch (modal.modalType) {
    case "profilephoto":
      modalToLaunch = <UserImageModal showModal={true} handleModalClose={modal.closeModals} />;
      break;
    case "headerimage":
      modalToLaunch = <UserHeaderImageModal showModal={true} handleModalClose={modal.closeModals} />;
      break;
    case "quote":
      modalToLaunch = <QuoteModal showModal={true} handleModalClose={modal.closeModals} />;
      break;

    case "contacts":
      modalToLaunch = <ContactsSectionModal showModal={true} handleModalClose={modal.closeModals} />;
      break;

    case "aboutme":
      modalToLaunch = <BioModal showModal={true} handleModalClose={() => modal.closeModals()} />;
      break;

    case "userqualifications":
      modalToLaunch = <UserQualificationsModal showModal={true} handleModalClose={modal.closeModals} />;
      break;

    case "experience":
      modalToLaunch = <ExperienceModal showModal={true} handleModalClose={modal.closeModals} />;
      break;

    case "achievements":
      modalToLaunch = <AchievementsModal showModal={true} handleModalClose={modal.closeModals} />;
      break;

    case "projects":
      modalToLaunch = <UserProjectsModal showModal={true} handleModalClose={modal.closeModals} />;
      break;

    default:
      throw new Error("Invalid modal, make sure the named modal is available in ModalLauncher.");
  }

  return modalToLaunch ? modalToLaunch : null;
};
