import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ImageUploader from "react-images-upload";
import { useUpsertHeaderImageMutation } from "../../generated/graphql";
import { useMsGraphService } from "../../services/msGraphService";
import { useAuth } from "../../utility/useMsalAuth";
const DefaultProfileImg = require("../../images/default_profile_img.png") as string;

export const UserHeaderImageModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();
  const msGraphService = useMsGraphService();
  const [existingPhoto, setExistingPhoto] = useState("");
  const [newPhoto, setNewPhoto] = useState("");
  const [mutation] = useUpsertHeaderImageMutation();

  const onImageUploadChange = (picture, pictureDataURLs) => {
    if (pictureDataURLs[0]) {
      setNewPhoto(pictureDataURLs[0]);
    } else {
      setNewPhoto("");
    }
  };

  const handleFormSubmit = async () => {
    if (newPhoto) {
      mutation({ variables: { header_image_base64: newPhoto } })
        .then(() => {
          debugger;
        })
        .then(() => {
          debugger;
        });
    }
    handleModalClose();
  };

  useEffect(() => {
    msGraphService.getMyPhoto().then((photo) => setExistingPhoto(photo));
  }, [auth.user]);
  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="user-image-modal">
      <Modal.Header closeButton>
        <h4>Change User Header Image</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-5">
            {/* TODO - better styling */}
            <img className="login__photo" src={existingPhoto || DefaultProfileImg} alt="userImg" />
            {newPhoto && <img className="login__photo" src={newPhoto || ""} alt="userImg" />}

            <ImageUploader
              withIcon={false}
              onChange={onImageUploadChange}
              imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif", ".jfif"]}
              maxFileSize={5242880}
              label={"Upload new image"}
              buttonText={"Upload new image"}
              withLabel={false}
              singleImage={true}
            />
          </Form.Group>
          <Button variant="modal-submit" onClick={handleFormSubmit}>
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
