import React, { useEffect, useState } from "react";
import {
  useAccountsForUserQuery,
  useProjectsForUserByUserIdQuery,
  useAllProjectsForUserIdIncludingNotShownQuery,
  useUpdateProjectIsShownMutation,
} from "../../generated/graphql";
import { useModalContext } from "../../utility/useModalLauncherContext";
import { useAuth } from "../../utility/useMsalAuth";
import { EditButton } from "../Buttons/EditButton";
import { UserProjectsModal } from "../ProfileEdits/UserProjectsModal";

export type ProjectsState = {
  projects: Array<{ id: string; is_shown: boolean; name: string; project_id: string; description: string }>;
};
const ProfProjects = ({ userId }) => {
  const auth = useAuth();

  // TODO - Popup + react-hook-form for editing
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [projectsState, setProjectState] = useState<ProjectsState>({
    projects: [],
  });

  const { data } = useAllProjectsForUserIdIncludingNotShownQuery({ variables: { userId: userId } });
  const [mutation] = useUpdateProjectIsShownMutation();
  const modalContext = useModalContext();

  useEffect(() => {
    if (data) {
      let mappedData = data
        ? data.project_users.map((user) => {
            return {
              id: user.id,
              is_shown: user.is_shown,
              name: user.project.project_alias || user.project.project_name,
              project_id: user.project.id,
              description: user.project.project_description,
            };
          })
        : [];
      setProjectState({ projects: mappedData as any });
    }
  }, [data]);

  let isEditable = auth.userId === userId;
  let filter = isInEditMode ? (item) => true : (item) => item.is_shown === true;

  return (
    <div className="row user-projects">
      <div className="col-12 mb-5">
        <h4>Projects</h4>
        {isEditable && !isInEditMode && (
          <EditButton
            onClick={() => {
              modalContext.openModal("projects");
            }}
          />
        )}
        {isInEditMode && (
          <div
            onClick={() => {
              projectsState.projects.map((projectRef) => {
                mutation({
                  variables: { userId: auth.userId, projectId: projectRef.project_id, isShown: projectRef.is_shown },
                });
              });

              setIsInEditMode(false);
            }}>
            Save
          </div>
        )}
      </div>
      <div className="col-md-4 col-lg-12">
        <div className="row">
          {projectsState.projects.filter(filter).map((projectRef) => {
            return (
              <div className="col-md-4 mb-3" key={projectRef.project_id}>
                <div className="row">
                  <div className="col-3">
                    {isInEditMode && (
                      <input
                        checked={projectRef.is_shown}
                        type={"checkbox"}
                        onClick={() => {
                          let item = projectsState.projects.findIndex((p) => p.id === projectRef.id);
                          projectsState.projects[item].is_shown = !projectsState.projects[item].is_shown;
                          setProjectState({ ...projectsState });
                        }}
                      />
                    )}
                    <div className="logo">
                      <img src="https://via.placeholder.com/130x130" />
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="company-content">
                      <h5>{projectRef.name}</h5>
                      <p>{projectRef.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Delete after using data */}
      {/* 
      <div className="col-md-4 col-12">
        <div className="row">
          <div className="col-3">
            <div className="logo">
              <img src="https://via.placeholder.com/130x130" />
            </div>
          </div>
          <div className="col-9">
            <div className="company-content">
              <h5>Company Name</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin libero ante, finibus vel nisl fermentum,
                aliquam vehicula lacus. In quis gravida dui. Vivamus nec consectetur.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="col-md-4 col-12">
        <div className="row">
          <div className="col-3">
            <div className="logo">
              <img src="https://via.placeholder.com/130x130" />
            </div>
          </div>
          <div className="col-9">
            <div className="company-content">
              <h5>Company Name</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin libero ante, finibus vel nisl fermentum,
                aliquam vehicula lacus. In quis gravida dui. Vivamus nec consectetur.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ProfProjects;
