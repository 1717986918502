var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useLayoutEffect, useState, useRef } from "react";
import omit from "lodash/omit";
import { Button, Checkbox, Popup, Menu, ProviderConsumer as FluentUIThemeConsumer, Table as FluentUITable, dialogBehavior, gridNestedBehavior, gridCellWithFocusableElementBehavior, } from "@fluentui/react-northstar";
import { ArrowDownIcon, ArrowUpIcon, MoreIcon, OpenOutsideIcon, } from "@fluentui/react-icons-northstar";
import Icon from "../../lib/Icon";
import { TableTheme } from "./TableTheme";
import getBreakpoints, { accessoryWidth, columnMinWidth, } from "./tableBreakpoints";
var SortOrderIndicator = function (_a) {
    var columnKey = _a.columnKey, sortOrder = _a.sortOrder;
    var sortOrderKey = sortOrder[0], sortOrderDirection = sortOrder[1];
    if (columnKey === sortOrderKey) {
        if (sortOrderDirection === "asc")
            return React.createElement(ArrowUpIcon, { outline: true });
        else
            return React.createElement(ArrowDownIcon, { outline: true });
    }
    else
        return null;
};
var ariaSort = function (_a) {
    var columnKey = _a.columnKey, sortOrder = _a.sortOrder;
    var sortOrderKey = sortOrder[0], sortOrderDirection = sortOrder[1];
    if (columnKey === sortOrderKey) {
        if (sortOrderDirection === "asc")
            return { "aria-sort": "ascending" };
        else
            return { "aria-sort": "descending" };
    }
    else
        return { "aria-sort": undefined };
};
var defaultSortOrder = ["__rowKey__", "desc"];
var passThrough = function (arg) { return arg; };
export var Table = function (props) {
    var rowKeys = Object.keys(props.rows);
    var columnKeys = Object.keys(props.columns);
    var _a = useState(defaultSortOrder), sortOrder = _a[0], setSortOrder = _a[1];
    var _b = useState(new Set()), selected = _b[0], setSelected = _b[1];
    var propagateSetSelected = function (selected) {
        return setSelected((props.onSelectedChange || passThrough)(selected));
    };
    var selectedIndeterminate = selected.size > 0 && selected.size < rowKeys.length;
    var hasActions = rowKeys.findIndex(function (rowKey) {
        return props.rows[rowKey].hasOwnProperty("actions");
    }) >= 0;
    var breakpoints = getBreakpoints(props.columns, hasActions, !!props.selectable);
    var $tableWrapper = useRef(null);
    var _c = useState(
    // start by displaying all columns (in case of SSR)
    breakpoints.get(Infinity)), inFlowColumns = _c[0], setInFlowColumns = _c[1];
    var onResize = function () {
        if ($tableWrapper.current !== null) {
            var widths = Array.from(breakpoints.keys()).sort(function (a, b) { return a - b; });
            var firstBreak = widths.findIndex(function (width) { return width > $tableWrapper.current.clientWidth; });
            // use the last width to not be greater than the client width, or zero if they all were
            var nextInFlowColumns = breakpoints.get(widths[Math.max(0, firstBreak - 1)]);
            // update the set of in-flow columns if the next set is a different size or contains different elements
            if (nextInFlowColumns.size !== inFlowColumns.size ||
                new Set(Array.from(nextInFlowColumns).filter(function (x) { return !inFlowColumns.has(x); })).size > 0)
                setInFlowColumns(nextInFlowColumns);
        }
    };
    useLayoutEffect(function () {
        window.addEventListener("resize", onResize);
        onResize();
        return function () { return window.removeEventListener("resize", onResize); };
    });
    var rowWidthStyles = function (truncate) {
        var minWidth = Array.from(inFlowColumns).reduce(function (acc, columnKey) { return acc + columnMinWidth(columnKey, props.columns); }, 0);
        return {
            padding: "0 1.25rem",
            minWidth: minWidth + "px",
        };
    };
    var columnWidthStyles = function (columnKey, truncate) {
        var minWidth = columnMinWidth(columnKey, props.columns);
        return {
            flexGrow: minWidth,
            minWidth: minWidth + "px",
        };
    };
    var accessoryStyles = {
        flexShrink: 0,
        flexGrow: 0,
        width: accessoryWidth + "px",
        minWidth: accessoryWidth + "px",
        display: "flex",
        justifyContent: "center",
        height: "calc(3rem - 2px)",
    };
    var columnOrder = __spreadArrays(["selection"], columnKeys, ["overflow"]);
    var rowOrder = sortOrder[0] === defaultSortOrder[0]
        ? rowKeys
        : rowKeys.sort(function (rowKeyA, rowKeyB) {
            var rowKeyI = rowKeyA, rowKeyJ = rowKeyB;
            if (sortOrder[1] === "asc") {
                rowKeyI = rowKeyB;
                rowKeyJ = rowKeyA;
            }
            return props.rows[rowKeyI][sortOrder[0]].localeCompare(props.rows[rowKeyJ][sortOrder[0]]);
        });
    var hiddenColumns = new Set(columnKeys.filter(function (columnKey) { return !inFlowColumns.has(columnKey); }));
    var setRowSelected = function (rowSelected, rowKey) {
        if (rowSelected)
            propagateSetSelected(new Set(selected.add(rowKey)));
        else {
            selected.delete(rowKey);
            propagateSetSelected(new Set(selected));
        }
    };
    var includeRow = function (row) {
        return props.filterBy ? props.filterBy(row) : true;
    };
    return (React.createElement(FluentUIThemeConsumer, { render: function (globalTheme) { return (React.createElement(TableTheme, { globalTheme: globalTheme },
            React.createElement("div", { ref: $tableWrapper, style: { width: "100%", overflowX: "auto" } },
                React.createElement(FluentUITable, __assign({ header: {
                        key: "header",
                        compact: true,
                        variables: { compactRow: true },
                        styles: rowWidthStyles(!!props.truncate),
                        items: columnOrder.reduce(function (acc, columnKey) {
                            var column = props.columns[columnKey];
                            if (inFlowColumns.has(columnKey))
                                switch (columnKey) {
                                    case "overflow":
                                        acc.push({
                                            key: "header__overflow",
                                            content: "",
                                            styles: accessoryStyles,
                                            "data-is-focusable": false,
                                        });
                                        break;
                                    case "selection":
                                        acc.push({
                                            key: "header__selection",
                                            accessibility: gridCellWithFocusableElementBehavior,
                                            content: (React.createElement(Checkbox, { "aria-label": "Select all", checked: selected.size > 0, variables: {
                                                    margin: 0,
                                                    indeterminate: selectedIndeterminate,
                                                }, onChange: function (_e, props) {
                                                    if (props === null || props === void 0 ? void 0 : props.checked)
                                                        propagateSetSelected(new Set(rowKeys));
                                                    else if (selectedIndeterminate)
                                                        propagateSetSelected(new Set(rowKeys));
                                                    else
                                                        propagateSetSelected(new Set());
                                                }, styles: {
                                                    gridTemplateColumns: "1fr",
                                                } })),
                                            styles: __assign(__assign({}, accessoryStyles), { height: "calc(2.5rem - 2px)" }),
                                        });
                                        break;
                                    default:
                                        acc.push(__assign({ key: "header__" + columnKey, content: column.sortable ? (React.createElement(Button, { content: column.title, icon: React.createElement(SortOrderIndicator, __assign({}, { sortOrder: sortOrder, columnKey: columnKey })), iconPosition: "after", text: true, styles: { padding: 0 }, onClick: function () {
                                                    if (sortOrder[0] === columnKey) {
                                                        if (sortOrder[1] === "desc") {
                                                            setSortOrder([columnKey, "asc"]);
                                                        }
                                                        else {
                                                            setSortOrder(defaultSortOrder);
                                                        }
                                                    }
                                                    else {
                                                        setSortOrder([columnKey, "desc"]);
                                                    }
                                                } })) : (column.title), styles: columnWidthStyles(columnKey, !!props.truncate) }, (column.sortable
                                            ? __assign({ accessibility: gridCellWithFocusableElementBehavior }, ariaSort({ sortOrder: sortOrder, columnKey: columnKey })) : {})));
                                        break;
                                }
                            return acc;
                        }, []),
                    }, rows: rowOrder.reduce(function (acc, rowKey) {
                        var row = props.rows[rowKey];
                        var rowActionKeys = (hiddenColumns.size
                            ? ["__details__"]
                            : []).concat(row.actions ? Object.keys(row.actions) : []);
                        if (includeRow(row))
                            acc.push({
                                key: rowKey,
                                styles: rowWidthStyles(!!props.truncate),
                                variables: function (_a) {
                                    var colorScheme = _a.colorScheme, theme = _a.theme;
                                    return selected.has(rowKey) &&
                                        theme !== "teamsHighContrastTheme"
                                        ? {
                                            backgroundColor: colorScheme.grey.backgroundFocus,
                                            color: colorScheme.grey.foregroundFocus,
                                        }
                                        : {};
                                },
                                onClick: function (_a) {
                                    var type = _a.type;
                                    if (type === "click")
                                        return;
                                    // respond only to keyboard space & enter for selection
                                    props.selectable &&
                                        setRowSelected(!selected.has(rowKey), rowKey);
                                },
                                items: columnOrder.reduce(function (acc, columnKey) {
                                    if (inFlowColumns.has(columnKey))
                                        switch (columnKey) {
                                            case "overflow":
                                                acc.push({
                                                    key: rowKey + "__overflow",
                                                    content: rowActionKeys.length ? (React.createElement(Popup, { trigger: React.createElement(Button, { icon: React.createElement(MoreIcon, { outline: true }), text: true, "aria-label": "More actions", styles: { color: "currentColor" } }), content: React.createElement(Menu, { items: rowActionKeys.map(function (rowActionKey) {
                                                                switch (rowActionKey) {
                                                                    case "__details__":
                                                                        return {
                                                                            key: rowKey + "__details__",
                                                                            icon: (React.createElement(OpenOutsideIcon, { outline: true })),
                                                                            content: "Details",
                                                                        };
                                                                    default:
                                                                        return {
                                                                            key: rowKey + "__" + rowActionKey,
                                                                            icon: (React.createElement(Icon, { icon: row.actions[rowActionKey].icon })),
                                                                            content: row.actions[rowActionKey].title,
                                                                        };
                                                                }
                                                            }), vertical: true }), offset: [-4, 4], position: "below", accessibility: dialogBehavior, autoFocus: true })) : (""),
                                                    styles: accessoryStyles,
                                                    accessibility: gridCellWithFocusableElementBehavior,
                                                });
                                                break;
                                            case "selection":
                                                acc.push({
                                                    key: rowKey + "__selection",
                                                    content: (React.createElement(Checkbox, { "aria-label": "Select", variables: { margin: 0 }, checked: selected.has(rowKey), onChange: function (_e, props) {
                                                            setRowSelected(!!(props === null || props === void 0 ? void 0 : props.checked), rowKey);
                                                        }, styles: {
                                                            gridTemplateColumns: "1fr",
                                                        } })),
                                                    accessibility: gridCellWithFocusableElementBehavior,
                                                    styles: accessoryStyles,
                                                });
                                                break;
                                            default:
                                                var cell = row[columnKey];
                                                acc.push({
                                                    key: rowKey + "__" + columnKey,
                                                    content: cell,
                                                    truncateContent: !!props.truncate,
                                                    styles: columnWidthStyles(columnKey, !!props.truncate),
                                                });
                                                break;
                                        }
                                    return acc;
                                }, []),
                            });
                        return acc;
                    }, []), variables: function (_a) {
                        var theme = _a.theme, colorScheme = _a.colorScheme;
                        return __assign(__assign(__assign({ 
                            // box model
                            compactRowHeight: "2.5rem", defaultRowMinHeight: "3rem", defaultRowVerticalPadding: ".8125rem" }, (props.truncate
                            ? {
                                defaultRowHeight: "3rem",
                            }
                            : {
                                defaultRowHeight: "auto",
                                cellVerticalAlignment: "flex-start",
                            })), { 
                            // colors
                            backgroundColor: theme === "teamsHighContrastTheme"
                                ? colorScheme.grey.background
                                : colorScheme.grey.background2 }), (theme === "teamsHighContrastTheme"
                            ? {
                                rowBorderColor: colorScheme.grey.foreground,
                                rowBorderHoverColor: colorScheme.grey.foreground,
                            }
                            : {}));
                    }, styles: {
                        width: "auto",
                    }, accessibility: gridNestedBehavior }, omit(props, [
                    "columns",
                    "rows",
                    "truncate",
                    "selectable",
                    "onSelectedChange",
                    "findQuery",
                    "filterBy",
                ])))))); } }));
};
