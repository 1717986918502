import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useUpdateUserFirstRunMutation,
  useUpsertUserAboutMeMutation,
  useUserAboutMeQuery,
} from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";
import { EditButton } from "../Buttons/EditButton";
import DatePicker from "react-datepicker";
import { BioModal, UserQualificationsModal } from "../ProfileEdits/AboutMeModals";
import "react-datepicker/dist/react-datepicker.css";
import { AchievementsModal } from "../ProfileEdits/AchievementsModal";
import { useUserStartup } from "../../utility/useUserStartup";
import Button from "react-bootstrap/esm/Button";
import "./aboutme.scss";
import { SetupSection } from "./SetupSection";
import { useModalContext } from "../../utility/useModalLauncherContext";

export type AboutMeState = {
  aboutMeText: string | null | undefined;
  experienceStartDate: Date | null;
  firstRun: boolean | null;
};

function AboutMe({ userId }) {
  const now = useRef(new Date());
  const auth = useAuth();
  const { data, error } = useUserAboutMeQuery({ variables: { userId: userId } });
  const [updateUserFirstRun] = useUpdateUserFirstRunMutation();
  const modalContext = useModalContext();

  const [state, setState] = useState<AboutMeState>({
    aboutMeText: null,
    experienceStartDate: null,
    firstRun: null,
  });

  useMemo(() => {
    if (data) {
      setState({
        aboutMeText: data?.users[0].extended_profiles ? data?.users[0].extended_profiles.about_me : null,
        experienceStartDate: data?.users[0].extended_profiles
          ? new Date(data?.users[0].extended_profiles.experience_start_date)
          : null,
        firstRun: data?.users[0].first_run || null,
      });
    }
  }, [data]);

  let isEditable = auth.userId === userId;
  let isFirstTimeEditable = isEditable && (state.firstRun === null || state.firstRun === false);
  // TODO - revisit this logic, this is definitely broken
  let years = 0;

  if (state.experienceStartDate) {
    years = new Date((new Date() as any) - (new Date(state.experienceStartDate) as any)).getFullYear() - 1970;
  }

  let resetButton = isEditable && state.firstRun;

  return (
    <>
      {isFirstTimeEditable && (
        <div className={"row setup-section"}>
          <SetupSection />
        </div>
      )}
      {resetButton && (
        <Button
          variant={"modal-submit"}
          onClick={() => {
            updateUserFirstRun({
              variables: {
                userId: auth.userId,
                firstRun: false,
              },
              refetchQueries: ["UserAboutMe"],
            });
          }}>
          Reset Setup State
          {/* TODO - eventually get rid of this */}
        </Button>
      )}
      <div className="row about-me">
        <div className="col-12">
          <h4>About Me</h4>
          <div className="row">
            <div className="col-6">
              {isEditable && (
                <div className="mb-3 button-min">
                  <EditButton
                    onClick={() => {
                      modalContext.openModal("aboutme");
                    }}
                  />
                </div>
              )}
              {!state.aboutMeText && "User has no About Me information set."}
            </div>
            <div className="col-6">
              {isEditable && (
                <div className="mb-3 button-min">
                  <EditButton
                    onClick={() => {
                      modalContext.openModal("userqualifications");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ whiteSpace: "pre-wrap" }} className="col-md-5 col-12">
          {state.aboutMeText}
        </div>
        <div className="col-md-6 offset-md-1 col-12">
          <div className="row user-qualifications">
            {state.experienceStartDate ? (
              <div className="col-md-4">
                <div className="stat">
                  <h1>{years}</h1>
                  <p className="descriptor">Years Experience</p>
                  <a href="#">See My Portfolio</a>
                </div>
              </div>
            ) : (
              "User hasn't setup education information."
            )}
            {data?.users[0].user_educations.map((ed) => {
              return (
                <div className="col-md-4" key={ed.degree_name}>
                  <div className="stat">
                    <h1>{ed.degree_name}</h1>
                    <p className="descriptor">{ed.field_of_study}</p>
                    <a href="#">{ed.school_name}</a>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="user-badges">
            {/* TODO - replace with SP List driven approach */}
            <p className="label">Recent Badges</p>
            <ul className="badge-list">
              <li>
                <img src="https://via.placeholder.com/130x130" />
              </li>
              <li>
                <img src="https://via.placeholder.com/130x130" />
              </li>
              <li>
                <img src="https://via.placeholder.com/130x130" />
              </li>
              <li>
                <img src="https://via.placeholder.com/130x130" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMe;
