import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { useAuth } from "../../utility/useMsalAuth";
import { useMsGraphService } from "../../services/msGraphService";
import useMouse from "@react-hook/mouse-position";
import { useFireTeamsMeetingNotificationMutation } from "../../generated/graphql";
import { useMsTeams } from "../../utility/useMicrosoftTeams";

export function RemoteControl() {
  const ref = React.useRef(null);
  const teams = useMsTeams();

  const [mutation] = useFireTeamsMeetingNotificationMutation();

  const mouse = useMouse(ref, {
    enterDelay: 0,
    leaveDelay: 0,
  });
  const auth = useAuth();
  const msGraphService = useMsGraphService();
  let [profile, setProfile] = useState(null);
  useEffect(() => {
    msGraphService.getUserProfileById(auth.userId).then((profile) => {
      setProfile(profile);
    });
  }, []);

  const handleFormSubmit = () => {
    // mutation({
    //   variables: {
    //     input: {
    //       url: window.location.origin + "/" + "consent",
    //       text: "Consent",
    //       height: 500,
    //       width: 600,
    //       title: "Consent",
    //       conversationId: teams.context?.chatId as string,
    //     },
    //   },
    // });

    fetch("https://tunnel.sparkworkspace.com/notification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        conversationId: teams.context?.chatId,
      }),
    });
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log("This will run every second!");

  //     if (!profile) return;
  //     fetch("https://tunnel.sparkworkspace.com/mouse", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ profile: profile, mouse: mouse }),
  //     });
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [profile, mouse]);

  useEffect(() => {
    fetch("https://tunnel.sparkworkspace.com/mouse", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ profile: profile, mouse: mouse }),
    });
  }, [profile, mouse.x, mouse.y]);

  let url = `https://tunnel.sparkworkspace.com`;
  let [payload, setPayload] = useState({
    event: {
      op: "PROCESSING",
      data: {
        new: {
          text: "pretty cool",
          scene: "In a World",
        },
      },
    },
  });

  let [textPayload, setTextPayload] = useState("");
  let [sceneName, setSceneName] = useState("In a World");

  const postMessage = (text, sceneName) => {
    let toPost = { ...payload };
    toPost.event.data.new.text = text;
    toPost.event.data.new.scene = sceneName;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(toPost),
    });
  };

  const waterPlant = () => {
    let toPost = {
      event: {
        op: "WATER",
        data: {
          new: {
            text: "pretty cool",
            scene: "In a World",
          },
        },
      },
    };
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(toPost),
    });
  };

  const boxWidth = 1920 / 7; /// 9;
  const boxHeight = 1080 / 7; /// 16;
  return (
    <div>
      <div
        ref={ref}
        className={"d-inline-flex m-4 flex-wrap"}
        style={{ width: boxWidth + "px", height: boxHeight + "px", backgroundColor: "red" }}></div>

      <div className={"d-inline-flex p-4 flex-wrap"}>
        <>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => postMessage(textPayload, "Empty")} active>
              None
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => postMessage(textPayload, "Meeting Begins Now")} active>
              Get Hyped for the Meeting
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => postMessage(textPayload, "In a World")} active>
              Introduce Yourself!
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => postMessage(textPayload, "Jesse Murray")} active>
              Thank You
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => postMessage(textPayload, "Olympics")} active>
              Awards Ceremony!
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => postMessage(textPayload, "Tom Entered")} active>
              Tom has Entered the Meeting
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button
              variant="primary"
              size="lg"
              onClick={() => postMessage(textPayload, "Coffee is for Closers")}
              active>
              Put that Coffee Down
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button
              variant="primary"
              size="lg"
              onClick={() => postMessage(textPayload, "Jurassic Park Scene 2")}
              active>
              Welcome to Jurassic Park
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => postMessage(textPayload, "Walkup Scene")} active>
              90's Bulls Intro
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => postMessage(textPayload, "Plant")} active>
              Office Plant
            </Button>{" "}
          </div>
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => waterPlant()} active>
              Water the Plant
            </Button>{" "}
          </div>
          <br />
          <br />
          <div className={"p-2"}>
            <Button variant="primary" size="lg" onClick={() => handleFormSubmit()} active>
              Consent Popup
            </Button>{" "}
          </div>
          <InputGroup className="mb-3">
            <FormControl
              onChange={(e) => {
                setTextPayload(e.target.value);
              }}
              placeholder="Display Text"
              aria-label="Display Text"
              aria-describedby="basic-addon2"
            />
            <InputGroup.Append>
              <Button
                onClick={() => {
                  postMessage(textPayload, "In a World");
                }}
                variant="outline-secondary">
                Set text
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </>
      </div>
    </div>
  );
}
