import React, { useEffect, useContext, useState, useMemo, createContext } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { useAuth } from "./useMsalAuth";
import jwt_decode from "jwt-decode";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { getAzureADResourcePath } from "./Utility";

export type PossibleTeamsStates = "unknown" | "inTeams" | "notInTeams";
export type PossibleTeamsSSOStatus = "notAttempted" | "inProgress" | "failed" | "successful";

export type MicrosoftTeamsContext = {
  context: microsoftTeams.Context | undefined;
  teamsState: PossibleTeamsStates;
  ssoStatus: PossibleTeamsSSOStatus;
};

const teamsContext = createContext<MicrosoftTeamsContext>({
  context: undefined,
  teamsState: "unknown",
  ssoStatus: "notAttempted",
});

export function ProvideMsTeams({ children }) {
  const auth = useMsTeamsAuth();
  return <teamsContext.Provider value={auth}>{children}</teamsContext.Provider>;
}

export const useMsTeams = () => {
  return useContext(teamsContext);
};

export function useMsTeamsAuth() {
  const auth = useAuth();
  const [context, setContext] = useState<microsoftTeams.Context>();
  const [microsoftTeamsState, setMicrosoftTeamsState] = useState<PossibleTeamsStates>("unknown");
  const [teamsSSoSuccess, setTeamsSSOSuccess] = useState<{
    status: "notAttempted" | "inProgress" | "failed" | "successful";
  }>({ status: "notAttempted" });
  const [loginInProgress, setLoginInProgress] = useState(false);

  let resourceApi = `api://${window.location.origin}/${process.env.REACT_APP_AZUREAD_APP_ID}/access_as_user`;
  const tryTeamsSSO = async () => {
    setLoginInProgress(true);
    setTeamsSSOSuccess({ status: "inProgress" });
    console.log("TRYING TEAMS SSO");
    const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
      successCallback: async (result) => {
        const decodedToken = jwt_decode(result);

        try {
          const tokens = await auth.msal.ssoSilent({
            scopes: [decodedToken.aud + "/" + decodedToken.scp],
            loginHint: decodedToken.upn,
          });

          console.log(`ACQUIRED SILENT TOKEN`, tokens);

          setTeamsSSOSuccess({ status: "successful" });
          auth.setUser(tokens.account as AccountInfo);
        } catch (err) {
          console.log("ERROR, couldn't acquire tokens silently");
          debugger;
          console.log(err);

          if (err instanceof InteractionRequiredAuthError) {
            microsoftTeams.authentication.authenticate({
              url: window.location.origin + `/auth.html?clientId=${process.env["REACT_APP_AZUREAD_APP_ID"]}`,
              width: 600,
              height: 535,
              successCallback: function (result) {
                // alert(JSON.stringify(result));
                console.log("SUCCESS CALLBACK", result);
                setTeamsSSOSuccess({ status: "successful" });
                window.location.reload();
              },
              failureCallback: function (reason) {
                alert(JSON.stringify(reason));

                console.log("failureCallback", reason);
                setTeamsSSOSuccess({ status: "failed" });
              },
            });
          }
          // alert("Please turn on Developer Preview in Settings -> About -> Developer Preview");
        }
      },
      failureCallback: async (err) => {
        console.log("FAILURE CALLBACK", err);
        setLoginInProgress(false);
        setTeamsSSOSuccess({ status: "failed" });
      },
      resources: [resourceApi],
      silent: false,
    };

    if (!loginInProgress) {
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
    }
  };

  useEffect(() => {
    if (!context && microsoftTeamsState === "unknown") {
      let inTeams = false;

      microsoftTeams.initialize(() => {
        inTeams = true;
        setMicrosoftTeamsState("inTeams");

        microsoftTeams.getContext((context) => {
          setContext(context);
        });
      });

      setTimeout(() => {
        if (!inTeams) {
          setMicrosoftTeamsState("notInTeams");
        }
      }, 100);
    }

    if (microsoftTeamsState === "inTeams" && teamsSSoSuccess.status === "notAttempted") {
      if (auth.user) {
        auth.msal
          .acquireTokenSilent({
            account: auth.user,
            scopes: [getAzureADResourcePath() + "/access_as_user"],
          })
          .then(() => {
            setTeamsSSOSuccess({ status: "successful" });
          })
          .catch((err) => {
            console.log(err);
            tryTeamsSSO();
          });
      } else {
        tryTeamsSSO();
      }
    }
  }, [auth.user, teamsSSoSuccess.status, microsoftTeamsState]);

  return { context: context, teamsState: microsoftTeamsState, ssoStatus: teamsSSoSuccess.status };
}
