import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./components/App/App";
import * as microsoftTeams from "@microsoft/teams-js";

import { BrowserRouter as Router } from "react-router-dom";

import "./index.scss";
import "./scripts/main";

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
