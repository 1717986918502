import React from "react";
import { useContext, useState, useEffect } from "react";

export type ModalType =
  | ""
  | "profilephoto"
  | "headerimage"
  | "quote"
  | "contacts"
  | "aboutme"
  | "userqualifications"
  | "experience"
  | "achievements"
  | "projects";

export type ModalProps = {
  closeModals: Function;
  isModalOpen: boolean;
  modalType: ModalType | null;
  openModal: (modalType: ModalType) => void;
};

const ModalContext = React.createContext<ModalProps>({
  closeModals: () => {},
  isModalOpen: false,
  modalType: null,
  openModal: () => {},
});

export function ProvideModalContext({ children }) {
  const provider = useProvideModalContext();
  return <ModalContext.Provider value={provider}>{children}</ModalContext.Provider>;
}

export function useProvideModalContext() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalType, setCurrentModalType] = useState<ModalType>("");

  const closeModals = () => {
    setIsModalOpen(false);
  };

  const openModal = (modalType: ModalType) => {
    setIsModalOpen(true);
    setCurrentModalType(modalType);
  };

  return {
    closeModals: closeModals,
    isModalOpen: isModalOpen,
    modalType: currentModalType,
    openModal: openModal,
  };
}

export const useModalContext = () => {
  return useContext(ModalContext);
};
