import React from "react";
import { Link } from "react-router-dom";
const DefaultProfileImg = require("../../images/default_profile_img.png") as string;

interface Props {
  profile_photo: string;
  name: string;
}

const Header: React.FC<Props> = ({ profile_photo, name }) => {
  return (
    <Link style={{ textDecoration: "none" }} to={"/"}>
      <div className="login-header">
        <div className="container">
          <img className="login__photo" src={profile_photo || DefaultProfileImg} alt="userImg" />
          <h1>Hi, {name}!</h1>
        </div>
      </div>
    </Link>
  );
};

export default Header;
