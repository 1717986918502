import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userService from "../../services/users";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

import { useGetUserSocialByIdLazyQuery, useUpsertUserSocialMutation } from "../../generated/graphql";
import { useUpsertUserQuoteMutation } from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";

export const ContactsSectionModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();

  const [loadSocial, { called, loading, data: socialData }] = useGetUserSocialByIdLazyQuery({
    variables: { userId: auth.userId },
    fetchPolicy: "no-cache",
  });

  const [mutation] = useUpsertUserSocialMutation();

  const { register, handleSubmit, errors, reset, formState } = useForm({
    mode: "onBlur",
  });
  useEffect(() => {
    if (!called) loadSocial();
    if (socialData && socialData.users[0] && socialData.users[0].extended_profiles) {
      reset({
        alternatePhone: socialData.users[0].extended_profiles.alternate_phone,
        socialLink: socialData.users[0].extended_profiles.social_url,
        websiteUrl: socialData.users[0].extended_profiles.website_url,
      });
    } else {
      reset({ alternatePhone: "", socialLink: "", websiteUrl: "" });
    }
  }, [socialData]);

  if ((socialData && !socialData.users[0]) || (socialData && !socialData.users[0])) {
    throw new Error("No user found");
  }

  const onSubmit = (data) => {
    mutation({
      variables: {
        alternatePhone: data.alternatePhone,
        socialUrl: data.socialLink,
        websiteUrl: data.websiteUrl,
      },
      refetchQueries: ["UserProfileEdit"],
    })
      .then(() => {
        handleModalClose();
      })
      .catch((err) => {
        // TODO - real error message with styling instead of alert?
        alert(JSON.stringify(err));
      });
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <h4>Edit Contacts and Links</h4>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-5">
            <h5>Contacts</h5>
            <Form.Label className="form-label-modal">
              {/* TODO - where should this show on the profile page? */}
              Alternate Phone &nbsp;&nbsp;<span className="label">Optional</span>{" "}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name={"alternatePhone"}
              ref={register({ pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im })}
            />
            {/* TODO: style as error message */}
            {errors.alternatePhone && "Invalid Phone Number"}
          </Form.Group>
          <Form.Group>
            <h5>Add your LinkedIn profile, website, or other social profiles.</h5>
            <Form.Label className="form-label-modal">
              LinkedIn &nbsp;&nbsp;<span className="label">Optional</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name={"socialLink"}
              ref={register({
                pattern: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
              })}
            />
            {errors.socialLink && "Invalid url. Url must start with https or http"}
          </Form.Group>
          <Form.Group className="mb-5">
            {/* TODO - this might need to be multiple inputs with add/remove/move up/move down */}
            <Form.Label className="form-label-modal">
              Website Link &nbsp;&nbsp;<span className="label">Optional</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name={"websiteUrl"}
              ref={register({
                pattern: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
              })}
            />
            {errors.websiteUrl && "Invalid url. Url must start with https or http"}
          </Form.Group>

          <Button disabled={!formState.isValid} variant="modal-submit" onClick={handleSubmit(onSubmit)}>
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
