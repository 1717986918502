var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Provider as FluentUIThemeProvider, mergeThemes, } from "@fluentui/react-northstar";
import { teamsNextVariableAssignments, themes } from "../../lib/withTheme";
var getLocalTheme = function (themeKey) {
    return {
        componentStyles: {
            Avatar: {
                root: {
                    backgroundColor: "var(--surface-background-color)",
                    borderRadius: "9999px",
                },
                image: {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "var(--surface-background-color)",
                },
                label: {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "var(--surface-background-color)",
                },
            },
            Box: {
                root: function (_a) {
                    var variables = _a.variables;
                    return ({
                        "&::before": {
                            borderColor: variables.borderFocus,
                        },
                        "&::after": {
                            backgroundColor: variables.separatorColor,
                            borderColor: variables.borderFocusWithin,
                        },
                    });
                },
            },
            Card: {
                root: function (_a) {
                    var variables = _a.variables, theme = _a.theme;
                    console.log("[Card root]", theme.siteVariables.theme);
                    return __assign({ padding: "0", backgroundColor: variables.backgroundColor, borderWidth: theme.siteVariables.theme === "teamsHighContrastTheme"
                            ? "1px"
                            : 0, "--separator-color": theme.siteVariables.colorScheme.default.border1, "--content-color-secondary": theme.siteVariables.colorScheme.default.foreground2, "--surface-background-color": theme.siteVariables.colorScheme.default.background, "&:hover": {
                            "--surface-background-color": theme.siteVariables.colorScheme.default.backgroundHover1,
                        } }, (theme.siteVariables.theme === "teamsHighContrastTheme"
                        ? {
                            borderColor: variables.borderColor,
                            "&:hover": {
                                backgroundColor: theme.siteVariables.colorScheme.default.background,
                                borderColor: theme.siteVariables.colorScheme.default.borderHover,
                            },
                        }
                        : {}));
                },
            },
            CardBody: {
                root: {
                    margin: "0 1.25rem .75rem 1.25rem",
                },
            },
            CardFooter: {
                root: {
                    marginTop: "1.625rem",
                    marginLeft: "1.25rem",
                    marginRight: "1.25rem",
                    marginBottom: 0,
                    "&::before": {
                        content: '""',
                        display: "block",
                        height: "1px",
                        backgroundColor: "var(--separator-color)",
                        position: "relative",
                        top: "-.5rem",
                    },
                },
            },
        },
    };
};
export var BoardTheme = function (_a) {
    var _b;
    var globalTheme = _a.globalTheme, children = _a.children, style = _a.style;
    var mainTheme = ((_b = globalTheme.siteVariables) === null || _b === void 0 ? void 0 : _b.theme) ? globalTheme
        : themes.teamsTheme;
    return (React.createElement(FluentUIThemeProvider, { theme: mergeThemes(mainTheme, teamsNextVariableAssignments, getLocalTheme(globalTheme.siteVariables.theme)), style: style }, children));
};
