var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useLayoutEffect, useRef, useState } from "react";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import omit from "lodash/omit";
import uniqueId from "lodash/uniqueId";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AutoFocusZone, Box, Button, Flex, Input, MenuButton, ProviderConsumer as FluentUIThemeConsumer, Ref, Text, gridCellWithFocusableElementBehavior, gridNestedBehavior, gridRowNestedBehavior, } from "@fluentui/react-northstar";
import { getCode, keyboardKey } from "@fluentui/keyboard-key";
import { AddIcon, MoreIcon, ArrowLeftIcon, ArrowRightIcon, TrashCanIcon } from "@fluentui/react-icons-northstar";
import { BoardTheme } from "./BoardTheme";
import "./board.css";
import { getText } from "../../translations";
import { Toolbar } from "../Toolbar/Toolbar";
import { BoardItem } from "./BoardItem";
var defaultBoardItemCardLayout = {
    previewPosition: "top",
    overflowPosition: "footer",
};
var separatorStyles = {
    position: "relative",
    "&::after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "1px",
    },
};
var Placeholder = function (_a) {
    var position = _a.position;
    return position && (React.createElement(Box, { variables: function (_a) {
            var colorScheme = _a.colorScheme;
            return ({
                backgroundColor: colorScheme.brand.background1,
                borderColor: colorScheme.brand.foreground3,
            });
        }, className: "board__placeholder", styles: {
            left: position[0] + "px",
            top: position[1] + "px",
            width: position[2] + "px",
            height: position[3] + "px",
        } }));
};
var BoardLane = function (props) {
    var users = props.users, lane = props.lane, preparedItems = props.preparedItems, t = props.t, laneKey = props.laneKey, last = props.last, first = props.first, boardItemCardLayout = props.boardItemCardLayout, placeholderPosition = props.placeholderPosition, exitPendingLane = props.exitPendingLane, deleteLane = props.deleteLane, moveLane = props.moveLane;
    var _a = useState(-1), layoutState = _a[0], setLayoutState = _a[1];
    var _b = useState(16), scrollbarWidth = _b[0], setScrollbarWidth = _b[1];
    var $laneContent = useRef(null);
    var laneContentWidth = useRef(null);
    var onResize = function () {
        setLayoutState(0);
    };
    useLayoutEffect(function () {
        // [v-wishow] The lane is rendered 3 times in order to measure the scrollbar and account for
        // its width, since it varies by the user agent and input situation:
        //
        // • 0: no content is rendered, in order to measure the lane width; entire lane is transparent
        // • 1: content is rendered in order to measure the width with scrollbar; lane is still transparent
        // • 2: content with adjusted scrollbar-side margin is rendered; lane is made visible.
        //
        // There is a possibility the adjusted scrollbar-side margin will change the overflow state due
        // to wrapping text. How to handle this case is to-be-designed.
        //
        // todo: Remove all of this when the custom scrollbar component is available.
        switch (layoutState) {
            case -1:
                window.addEventListener("resize", onResize);
                setLayoutState(0);
                break;
            case 0:
                if ($laneContent.current)
                    laneContentWidth.current = $laneContent.current.clientWidth;
                setLayoutState(1);
                break;
            case 1:
                if ($laneContent.current && laneContentWidth.current)
                    setScrollbarWidth(laneContentWidth.current - $laneContent.current.clientWidth);
                setLayoutState(2);
                break;
        }
        return function () {
            window.removeEventListener("resize", onResize);
        };
    });
    return (React.createElement(Box, { styles: {
            display: "flex",
            flexFlow: "column nowrap",
            minWidth: "15rem",
            maxWidth: "22.5rem",
            borderRight: "1px solid transparent",
            flex: "1 0 0",
            opacity: layoutState === 2 ? 1 : 0,
            position: "relative",
            ":focus": { outline: "none" },
        }, variables: function (_a) {
            var colorScheme = _a.colorScheme;
            return ({
                borderFocus: colorScheme.default.borderFocus,
                borderFocusWithin: colorScheme.default.borderFocusWithin,
            });
        }, accessibility: function (props) {
            return set(gridRowNestedBehavior(props), "focusZone.props.direction", 0 /* FocusZoneDirection.vertical */);
        }, className: "board__lane", "aria-label": t["board lane"] + ", " + getText(t.locale, lane ? lane.title : t["lane pending"]) },
        props.pending ? (React.createElement(AutoFocusZone, null,
            React.createElement(Input, { placeholder: t["name lane"], onBlur: function (e) {
                    exitPendingLane(e.target.value);
                }, onKeyDown: function (e) {
                    switch (e.key) {
                        case "Escape":
                            return exitPendingLane("");
                        case "Enter":
                            return exitPendingLane(e.target.value);
                    }
                }, fluid: true, styles: { padding: ".05rem 1.25rem .25rem 1.25rem" } }))) : (React.createElement(Flex, null,
            React.createElement(Text, { weight: "bold", content: getText(t.locale, lane.title), style: {
                    flex: "1 0 auto",
                    padding: ".375rem 1.25rem .75rem 1.25rem",
                } }),
            React.createElement(MenuButton, { trigger: React.createElement(Button, { text: true, iconOnly: true, icon: React.createElement(MoreIcon, { outline: true }), styles: { marginRight: "1.25rem" }, "aria-label": t["lane options"] }), menu: [
                    {
                        content: t["move lane left"],
                        icon: React.createElement(ArrowLeftIcon, { outline: true }),
                        disabled: first,
                        onClick: function () {
                            moveLane && moveLane(laneKey, -1);
                        },
                    },
                    {
                        content: t["move lane right"],
                        icon: React.createElement(ArrowRightIcon, { outline: true }),
                        disabled: last,
                        onClick: function () {
                            moveLane && moveLane(laneKey, 1);
                        },
                    },
                    {
                        kind: "divider",
                    },
                    {
                        content: t["delete"],
                        icon: React.createElement(TrashCanIcon, { outline: true }),
                        disabled: preparedItems === null || preparedItems === void 0 ? void 0 : preparedItems.length,
                        onClick: function () {
                            deleteLane && deleteLane(laneKey);
                        },
                    },
                ] }))),
        React.createElement(Box, { variables: function (_a) {
                var colorScheme = _a.colorScheme;
                return ({
                    backgroundColor: colorScheme.default.background2,
                    separatorColor: colorScheme.default.border2,
                });
            }, styles: __assign({ flex: "0 0 auto", padding: "0 1.25rem .75rem 1.25rem" }, (last ? {} : separatorStyles)), accessibility: gridCellWithFocusableElementBehavior },
            React.createElement(Button, { icon: React.createElement(AddIcon, { outline: true }), iconOnly: true, fluid: true, title: t["add board item"], "aria-label": t["add board item"] })),
        React.createElement(Box, { variables: function (_a) {
                var colorScheme = _a.colorScheme;
                return ({
                    separatorColor: colorScheme.default.border2,
                });
            }, styles: __assign({ flex: "1 0 0", overflow: "hidden" }, (last ? {} : separatorStyles)) },
            React.createElement(Droppable, { droppableId: laneKey }, function (provided, snapshot) { return (React.createElement(Box, __assign({ styles: {
                    height: "100%",
                    overflowY: "auto",
                    paddingTop: "1px",
                    position: "relative",
                }, ref: function (element) {
                    $laneContent.current = element;
                    provided.innerRef(element);
                } }, provided.droppableProps),
                layoutState > 0 && (preparedItems === null || preparedItems === void 0 ? void 0 : preparedItems.length)
                    ? preparedItems.map(function (item) { return (React.createElement(Draggable, { draggableId: item.itemKey, key: "Board__DraggableItem__" + item.itemKey, index: item.order }, function (provided, snapshot) { return (React.createElement(Ref, { innerRef: provided.innerRef },
                        React.createElement(BoardItem, __assign({ isDragging: snapshot.isDragging, draggableProps: provided.draggableProps, dragHandleProps: provided.dragHandleProps }, {
                            scrollbarWidth: scrollbarWidth,
                            item: item,
                            users: users,
                            t: t,
                            boardItemCardLayout: boardItemCardLayout,
                        })))); })); })
                    : null,
                provided.placeholder,
                snapshot.isDraggingOver && React.createElement(Placeholder, { position: placeholderPosition }))); }))));
};
var prepareBoardItems = function (items, lanes) {
    var unsortedPreparedBoardItems = Object.keys(items).reduce(function (acc, itemKey) {
        var item = items[itemKey];
        item.itemKey = itemKey;
        if (acc.hasOwnProperty(item.lane))
            acc[item.lane].push(item);
        else
            acc[item.lane] = [item];
        return acc;
    }, {});
    return Object.keys(lanes).reduce(function (acc, laneKey) {
        acc[laneKey] = unsortedPreparedBoardItems.hasOwnProperty(laneKey)
            ? unsortedPreparedBoardItems[laneKey].sort(function (a, b) { return a.order - b.order; })
            : [];
        return acc;
    }, {});
};
var resetOrder = function (item, newOrder) {
    item.order = newOrder;
    return item;
};
var getDraggable = function (draggableId) {
    return document.querySelector("[data-rbd-drag-handle-draggable-id='" + draggableId + "']");
};
var getDroppable = function (droppableId) { return document.querySelector("[data-rbd-droppable-id='" + droppableId + "']"); };
var getClientYChildren = function ($parent, draggableId, endIndex) {
    return Array.from($parent.children)
        .filter(function ($child) {
        var childDraggableId = $child.getAttribute("data-rbd-draggable-id");
        return typeof childDraggableId === "string" && childDraggableId !== draggableId;
    })
        .slice(0, endIndex);
};
var getPlaceholderPosition = function ($draggable, clientYChildren) {
    if (!$draggable || !$draggable.parentNode)
        return null;
    var clientHeight = $draggable.clientHeight, clientWidth = $draggable.clientWidth;
    var clientY = clientYChildren.reduce(function (acc, $child) {
        return acc + $child.clientHeight + 8;
    }, 0);
    var clientX = 20;
    return [clientX, clientY, clientWidth, clientHeight];
};
var BoardStandalone = function (props) {
    var users = props.users, arrangedLanes = props.arrangedLanes, setArrangedLanes = props.setArrangedLanes, arrangedItems = props.arrangedItems, setArrangedItems = props.setArrangedItems, addingLane = props.addingLane, setAddingLane = props.setAddingLane, t = props.t;
    var _a = useState(null), placeholderPosition = _a[0], setPlaceholderPosition = _a[1];
    var onDragStart = function (event) {
        var $draggable = getDraggable(event.draggableId);
        if (!$draggable || !$draggable.parentNode)
            return;
        setPlaceholderPosition(getPlaceholderPosition($draggable, getClientYChildren($draggable.parentNode, event.draggableId, event.source.index)));
    };
    var onDragUpdate = function (event) {
        if (!event.destination)
            return;
        var $draggable = getDraggable(event.draggableId);
        var $droppable = getDroppable(event.destination.droppableId);
        if (!$draggable || !$droppable)
            return;
        setPlaceholderPosition(getPlaceholderPosition($draggable, getClientYChildren($droppable, event.draggableId, event.destination.index)));
    };
    var onDragEnd = function (_a) {
        var draggableId = _a.draggableId, source = _a.source, destination = _a.destination;
        if (destination) {
            var sourceLaneKey = source.droppableId;
            var destinationLaneKey = destination.droppableId;
            var movingItems = arrangedItems[sourceLaneKey].splice(source.index, 1);
            arrangedItems[sourceLaneKey].map(resetOrder);
            arrangedItems[destinationLaneKey].splice(destination.index, 0, movingItems[0]);
            arrangedItems[destinationLaneKey].map(resetOrder);
            setPlaceholderPosition(null);
            return setArrangedItems(cloneDeep(arrangedItems));
        }
    };
    var moveLane = function (laneKey, delta) {
        var laneKeys = Object.keys(arrangedLanes);
        var from = laneKeys.indexOf(laneKey);
        laneKeys.splice(from + delta, 0, laneKeys.splice(from, 1)[0]);
        setArrangedLanes(laneKeys.reduce(function (nextArrangedLanes, currentLaneKey) {
            nextArrangedLanes[currentLaneKey] = arrangedLanes[currentLaneKey];
            return nextArrangedLanes;
        }, {}));
    };
    var deleteLane = function (laneKey) {
        setArrangedLanes(omit(arrangedLanes, [laneKey]));
    };
    return (React.createElement(DragDropContext, __assign({}, { onDragStart: onDragStart, onDragUpdate: onDragUpdate, onDragEnd: onDragEnd }),
        React.createElement(Box, { styles: { overflowX: "auto", flex: "1 0 0" } },
            React.createElement(Box, { styles: { height: "100%", display: "flex" }, accessibility: function (props) {
                    return set(gridNestedBehavior(props), "focusZone.props", {
                        shouldEnterInnerZone: function shouldEnterInnerZone(event) {
                            return getCode(event) === keyboardKey.ArrowDown;
                        },
                        direction: 1 /* FocusZoneDirection.horizontal */,
                        shouldResetActiveElementWhenTabFromZone: true,
                    });
                } },
                Object.keys(arrangedLanes).map(function (laneKey, laneIndex, laneKeys) {
                    var last = laneIndex === laneKeys.length - 1;
                    return (React.createElement(BoardLane, { first: laneIndex === 0, last: addingLane ? false : last, laneKey: laneKey, lane: arrangedLanes[laneKey], key: "BoardLane__" + laneKey, preparedItems: arrangedItems[laneKey], users: users, t: t, boardItemCardLayout: props.boardItemCardLayout || defaultBoardItemCardLayout, placeholderPosition: placeholderPosition, moveLane: moveLane, deleteLane: deleteLane }));
                }),
                addingLane && (React.createElement(BoardLane, { last: true, pending: true, laneKey: uniqueId("pl"), key: "BoardLane__pending_lane", preparedItems: [], users: users, t: t, boardItemCardLayout: props.boardItemCardLayout || defaultBoardItemCardLayout, placeholderPosition: null, exitPendingLane: function (value) {
                        var _a, _b;
                        if (value.length > 0) {
                            var newLaneKey = uniqueId("sl");
                            setArrangedLanes(Object.assign(arrangedLanes, (_a = {},
                                _a[newLaneKey] = { title: value },
                                _a)));
                            setArrangedItems(Object.assign(arrangedItems, (_b = {}, _b[newLaneKey] = [], _b)));
                        }
                        setAddingLane(false);
                    } }))))));
};
export var Board = function (props) {
    var _a = useState(props.lanes), arrangedLanes = _a[0], setArrangedLanes = _a[1];
    var _b = useState(prepareBoardItems(props.items, props.lanes)), arrangedItems = _b[0], setArrangedItems = _b[1];
    var _c = useState(false), addingLane = _c[0], setAddingLane = _c[1];
    return (React.createElement(FluentUIThemeConsumer, { render: function (globalTheme) {
            var t = globalTheme.siteVariables.t;
            console.log("T", t);
            console.log("G", globalTheme);
            return (React.createElement(BoardTheme, { globalTheme: globalTheme, style: { height: "100%" } },
                React.createElement(Flex, { column: true, variables: function (_a) {
                        var colorScheme = _a.colorScheme;
                        return ({
                            backgroundColor: colorScheme.default.background2,
                        });
                    }, styles: { height: "100%" } },
                    React.createElement(Toolbar, { actionGroups: {
                            g1: {
                                a1: {
                                    icon: "Add",
                                    title: t["add lane"],
                                    __internal_callback__: "add_column",
                                },
                            },
                        }, __internal_callbacks__: {
                            add_column: function () { return setAddingLane(true); },
                        } }),
                    React.createElement(BoardStandalone, __assign({}, {
                        t: t,
                        arrangedLanes: arrangedLanes,
                        arrangedItems: arrangedItems,
                        setArrangedItems: setArrangedItems,
                        addingLane: addingLane,
                        setAddingLane: setAddingLane,
                        setArrangedLanes: setArrangedLanes,
                    }, pick(props, ["users", "boardItemCardLayout"]))))));
        } }));
};
