/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { useSearchUsersQuery } from "../../generated/graphql";
import { useMsGraphService } from "../../services/msGraphService";

const ChatIcon = require("../../images/chat.svg") as string;
const VideoIcon = require("../../images/video-chat.svg") as string;
const PhoneIcon = require("../../images/phone.svg") as string;
const MailIcon = require("../../images/mail.svg") as string;
const DefaultBG = require("../../images/default_bg.png") as string;
const DefaultProfileImg = require("../../images/default_profile_img.png") as string;

const UserTile = ({ profile_photo, bgImg, displayName, title, department, id, location, handleClick }) => {
  return (
    <div key={id} className="usertile">
      <div className="profile-bg">
        <img src={bgImg || DefaultBG} alt="bg" />
      </div>
      <div className="user-content">
        <img className="profile-photo" src={profile_photo || DefaultProfileImg} alt="profile" />
        <h4>{displayName}</h4>
        <p className="title">{title}</p>
        <p className="department">{department}</p>
        <p className="location">{location}</p>
      </div>
      <div className="contact-bar">
        <div onClick={handleClick}>
          <img src={ChatIcon} alt="chat" />
        </div>
        <div onClick={handleClick}>
          <img src={MailIcon} alt="mail" />
        </div>
        {/* <div onClick={handleClick}>
          <img src={VideoIcon} alt="video" />
        </div>
        <div onClick={handleClick}>
          <img src={PhoneIcon} alt="phone" />
        </div> */}
      </div>
    </div>
  );
};

const Users = ({ searchQuery }: { searchQuery: string }) => {
  const { data } = useSearchUsersQuery({
    variables: {
      searchString: `%${searchQuery}%`,
    },
  });

  const handleContactBarClick = (e) => {
    e.preventDefault();
    console.log("Handle Microsoft Related Actions with this click");
  };

  const filter = useSelector((state) => state.filter);

  const users = useSelector(({ filter, users }) => {
    if (filter.locationFilter !== "all")
      users = users.filter((x) => (x.city || "").toLowerCase().includes(filter.locationFilter.toLowerCase()));
    if (filter.clientFilter !== "all")
      users = users.filter((x) => (x.client || "").toLowerCase().includes(filter.clientFilter.toLowerCase()));
    if (filter.departmentFilter !== "all")
      users = users.filter((x) => (x.department || "").toLowerCase().includes(filter.departmentFilter.toLowerCase()));

    if (filter.userInput) {
      users = users.filter(
        (x) =>
          x.first_name.toLowerCase().includes(filter.userInput.toLowerCase()) ||
          x.last_name.toLowerCase().includes(filter.userInput.toLowerCase())
      );
    }
    return users;
  });

  useEffect(() => {}, [filter]);

  return (
    <div className="row">
      {users.map((x) => {
        return (
          <div key={x.id} className="col-md-3 col-2">
            <Link className="user-link" to={`/user/${x.id}`}>
              <UserTile
                displayName={x.first_name}
                profile_photo={x.profile_photo}
                id={x.id}
                title={x.title}
                department={x.department}
                bgImg={x.bgImg}
                location={x.city}
                handleClick={handleContactBarClick}
              />
            </Link>
          </div>
        );
      })}
      {data?.users.map((user) => {
        return <div>{JSON.stringify(user)}</div>;
      })}
    </div>
  );
};

export const UsersWithQuery = ({ searchQuery }: { searchQuery: string }) => {
  const msGraph = useMsGraphService();
  const { data } = useSearchUsersQuery({
    variables: {
      searchString: `%${searchQuery}%`,
    },
  });
  const [peopleData, setPeopleData] = useState([]);
  useEffect(() => {
    if (searchQuery) {
      msGraph.searchPeople(searchQuery).then((resp) => {
        console.log(resp);
        setPeopleData(resp);

        // if (resp.length === 0) return;
        // debugger;
        // msGraph
        //   .getUserPhotoById(resp[0].id)
        //   .then(() => {
        //     debugger;
        //   })
        //   .catch(() => {
        //     debugger;
        //   });
      });
    }
  }, [searchQuery]);

  return (
    <div className="row">
      {/* {data?.users.map((user) => {
        return (
          <div key={user.id} className="col-md-3 col-2">
            <Link className="user-link" to={`/user/${user.id}`}>
              <UserTile
                first_name={user.name}
                last_name={"Some last name"}
                profile_photo={
                  user?.user_images[0] ? "data:image/png;base64," + user.user_images[0].image_base64 : DefaultProfileImg
                }
                id={user.id}
                title={"Some Title"}
                department={"Some department"}
                bgImg={user?.extended_profiles ? user.extended_profiles.header_image_base64 : null}
                city={""}
                state={""}
                handleClick={() => {}}
              />
            </Link>
          </div>
        );
      })} */}

      {peopleData?.map((user: any) => {
        return (
          <div key={user.id} className="col-md-3 col-2">
            <Link className="user-link" to={`/user/${user.id}`}>
              <UserTile
                displayName={user.displayName}
                profile_photo={""}
                id={user.id}
                title={user.jobTitle}
                department={user.department}
                bgImg={""}
                location={user.officeLocation}
                handleClick={() => {}}
              />
            </Link>
          </div>
        );
      })}
    </div>
  );
};
export default Users;
