import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  uuid: any;
  timestamptz: any;
  date: any;
  numeric: any;
  jsonb: any;
  json: any;
};

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename?: 'accounts';
  account_image_base64?: Maybe<Scalars['String']>;
  account_name: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregated array relationship */
  projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  projects_public: Array<Projects_Public>;
  /** An aggregated array relationship */
  projects_public_aggregate: Projects_Public_Aggregate;
  /** An object relationship */
  tenant?: Maybe<Tenants>;
  tenant_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "accounts" */
export type AccountsProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsProjects_PublicArgs = {
  distinct_on?: Maybe<Array<Projects_Public_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Public_Order_By>>;
  where?: Maybe<Projects_Public_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsProjects_Public_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Public_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Public_Order_By>>;
  where?: Maybe<Projects_Public_Bool_Exp>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  __typename?: 'accounts_aggregate';
  aggregate?: Maybe<Accounts_Aggregate_Fields>;
  nodes: Array<Accounts>;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  __typename?: 'accounts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Accounts_Max_Fields>;
  min?: Maybe<Accounts_Min_Fields>;
};


/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Accounts_Max_Order_By>;
  min?: Maybe<Accounts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accounts" */
export type Accounts_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Insert_Input>;
  on_conflict?: Maybe<Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Accounts_Bool_Exp>>>;
  _not?: Maybe<Accounts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Accounts_Bool_Exp>>>;
  account_image_base64?: Maybe<String_Comparison_Exp>;
  account_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  projects?: Maybe<Projects_Bool_Exp>;
  projects_public?: Maybe<Projects_Public_Bool_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey'
}

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  account_image_base64?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  projects?: Maybe<Projects_Arr_Rel_Insert_Input>;
  projects_public?: Maybe<Projects_Public_Arr_Rel_Insert_Input>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  __typename?: 'accounts_max_fields';
  account_image_base64?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  account_image_base64?: Maybe<Order_By>;
  account_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  __typename?: 'accounts_min_fields';
  account_image_base64?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  account_image_base64?: Maybe<Order_By>;
  account_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  __typename?: 'accounts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  data: Accounts_Insert_Input;
  on_conflict?: Maybe<Accounts_On_Conflict>;
};

/** on conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  constraint: Accounts_Constraint;
  update_columns: Array<Accounts_Update_Column>;
  where?: Maybe<Accounts_Bool_Exp>;
};

/** ordering options when selecting data from "accounts" */
export type Accounts_Order_By = {
  account_image_base64?: Maybe<Order_By>;
  account_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  projects_aggregate?: Maybe<Projects_Aggregate_Order_By>;
  projects_public_aggregate?: Maybe<Projects_Public_Aggregate_Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "accounts" */
export type Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  AccountImageBase64 = 'account_image_base64',
  /** column name */
  AccountName = 'account_name',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id'
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  account_image_base64?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  AccountImageBase64 = 'account_image_base64',
  /** column name */
  AccountName = 'account_name',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id'
}

export type AddResult = {
  __typename?: 'AddResult';
  result?: Maybe<Scalars['String']>;
};

/** columns and relationships of "alert_acknowledge" */
export type Alert_Acknowledge = {
  __typename?: 'alert_acknowledge';
  alert_id: Scalars['uuid'];
  id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** aggregated selection of "alert_acknowledge" */
export type Alert_Acknowledge_Aggregate = {
  __typename?: 'alert_acknowledge_aggregate';
  aggregate?: Maybe<Alert_Acknowledge_Aggregate_Fields>;
  nodes: Array<Alert_Acknowledge>;
};

/** aggregate fields of "alert_acknowledge" */
export type Alert_Acknowledge_Aggregate_Fields = {
  __typename?: 'alert_acknowledge_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Alert_Acknowledge_Max_Fields>;
  min?: Maybe<Alert_Acknowledge_Min_Fields>;
};


/** aggregate fields of "alert_acknowledge" */
export type Alert_Acknowledge_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Alert_Acknowledge_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "alert_acknowledge" */
export type Alert_Acknowledge_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Alert_Acknowledge_Max_Order_By>;
  min?: Maybe<Alert_Acknowledge_Min_Order_By>;
};

/** input type for inserting array relation for remote table "alert_acknowledge" */
export type Alert_Acknowledge_Arr_Rel_Insert_Input = {
  data: Array<Alert_Acknowledge_Insert_Input>;
  on_conflict?: Maybe<Alert_Acknowledge_On_Conflict>;
};

/** Boolean expression to filter rows from the table "alert_acknowledge". All fields are combined with a logical 'AND'. */
export type Alert_Acknowledge_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Alert_Acknowledge_Bool_Exp>>>;
  _not?: Maybe<Alert_Acknowledge_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Alert_Acknowledge_Bool_Exp>>>;
  alert_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "alert_acknowledge" */
export enum Alert_Acknowledge_Constraint {
  /** unique or primary key constraint */
  AlertAcknowledgePkey = 'alert_acknowledge_pkey'
}

/** input type for inserting data into table "alert_acknowledge" */
export type Alert_Acknowledge_Insert_Input = {
  alert_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Alert_Acknowledge_Max_Fields = {
  __typename?: 'alert_acknowledge_max_fields';
  alert_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "alert_acknowledge" */
export type Alert_Acknowledge_Max_Order_By = {
  alert_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Alert_Acknowledge_Min_Fields = {
  __typename?: 'alert_acknowledge_min_fields';
  alert_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "alert_acknowledge" */
export type Alert_Acknowledge_Min_Order_By = {
  alert_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "alert_acknowledge" */
export type Alert_Acknowledge_Mutation_Response = {
  __typename?: 'alert_acknowledge_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Alert_Acknowledge>;
};

/** input type for inserting object relation for remote table "alert_acknowledge" */
export type Alert_Acknowledge_Obj_Rel_Insert_Input = {
  data: Alert_Acknowledge_Insert_Input;
  on_conflict?: Maybe<Alert_Acknowledge_On_Conflict>;
};

/** on conflict condition type for table "alert_acknowledge" */
export type Alert_Acknowledge_On_Conflict = {
  constraint: Alert_Acknowledge_Constraint;
  update_columns: Array<Alert_Acknowledge_Update_Column>;
  where?: Maybe<Alert_Acknowledge_Bool_Exp>;
};

/** ordering options when selecting data from "alert_acknowledge" */
export type Alert_Acknowledge_Order_By = {
  alert_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "alert_acknowledge" */
export type Alert_Acknowledge_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "alert_acknowledge" */
export enum Alert_Acknowledge_Select_Column {
  /** column name */
  AlertId = 'alert_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "alert_acknowledge" */
export type Alert_Acknowledge_Set_Input = {
  alert_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "alert_acknowledge" */
export enum Alert_Acknowledge_Update_Column {
  /** column name */
  AlertId = 'alert_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "alerts" */
export type Alerts = {
  __typename?: 'alerts';
  /** An array relationship */
  alert_acknowledges: Array<Alert_Acknowledge>;
  /** An aggregated array relationship */
  alert_acknowledges_aggregate: Alert_Acknowledge_Aggregate;
  alert_text: Scalars['String'];
  id: Scalars['uuid'];
  tenant_id: Scalars['String'];
};


/** columns and relationships of "alerts" */
export type AlertsAlert_AcknowledgesArgs = {
  distinct_on?: Maybe<Array<Alert_Acknowledge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alert_Acknowledge_Order_By>>;
  where?: Maybe<Alert_Acknowledge_Bool_Exp>;
};


/** columns and relationships of "alerts" */
export type AlertsAlert_Acknowledges_AggregateArgs = {
  distinct_on?: Maybe<Array<Alert_Acknowledge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alert_Acknowledge_Order_By>>;
  where?: Maybe<Alert_Acknowledge_Bool_Exp>;
};

/** aggregated selection of "alerts" */
export type Alerts_Aggregate = {
  __typename?: 'alerts_aggregate';
  aggregate?: Maybe<Alerts_Aggregate_Fields>;
  nodes: Array<Alerts>;
};

/** aggregate fields of "alerts" */
export type Alerts_Aggregate_Fields = {
  __typename?: 'alerts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Alerts_Max_Fields>;
  min?: Maybe<Alerts_Min_Fields>;
};


/** aggregate fields of "alerts" */
export type Alerts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Alerts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "alerts" */
export type Alerts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Alerts_Max_Order_By>;
  min?: Maybe<Alerts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "alerts" */
export type Alerts_Arr_Rel_Insert_Input = {
  data: Array<Alerts_Insert_Input>;
  on_conflict?: Maybe<Alerts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "alerts". All fields are combined with a logical 'AND'. */
export type Alerts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Alerts_Bool_Exp>>>;
  _not?: Maybe<Alerts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Alerts_Bool_Exp>>>;
  alert_acknowledges?: Maybe<Alert_Acknowledge_Bool_Exp>;
  alert_text?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  tenant_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "alerts" */
export enum Alerts_Constraint {
  /** unique or primary key constraint */
  AlertsPkey = 'alerts_pkey'
}

/** input type for inserting data into table "alerts" */
export type Alerts_Insert_Input = {
  alert_acknowledges?: Maybe<Alert_Acknowledge_Arr_Rel_Insert_Input>;
  alert_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Alerts_Max_Fields = {
  __typename?: 'alerts_max_fields';
  alert_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "alerts" */
export type Alerts_Max_Order_By = {
  alert_text?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Alerts_Min_Fields = {
  __typename?: 'alerts_min_fields';
  alert_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "alerts" */
export type Alerts_Min_Order_By = {
  alert_text?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "alerts" */
export type Alerts_Mutation_Response = {
  __typename?: 'alerts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Alerts>;
};

/** input type for inserting object relation for remote table "alerts" */
export type Alerts_Obj_Rel_Insert_Input = {
  data: Alerts_Insert_Input;
  on_conflict?: Maybe<Alerts_On_Conflict>;
};

/** on conflict condition type for table "alerts" */
export type Alerts_On_Conflict = {
  constraint: Alerts_Constraint;
  update_columns: Array<Alerts_Update_Column>;
  where?: Maybe<Alerts_Bool_Exp>;
};

/** ordering options when selecting data from "alerts" */
export type Alerts_Order_By = {
  alert_acknowledges_aggregate?: Maybe<Alert_Acknowledge_Aggregate_Order_By>;
  alert_text?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "alerts" */
export type Alerts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "alerts" */
export enum Alerts_Select_Column {
  /** column name */
  AlertText = 'alert_text',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id'
}

/** input type for updating data in table "alerts" */
export type Alerts_Set_Input = {
  alert_text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** update columns of table "alerts" */
export enum Alerts_Update_Column {
  /** column name */
  AlertText = 'alert_text',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id'
}

/** columns and relationships of "app" */
export type App = {
  __typename?: 'app';
  id: Scalars['uuid'];
  tenant_id: Scalars['String'];
  title: Scalars['String'];
};

/** aggregated selection of "app" */
export type App_Aggregate = {
  __typename?: 'app_aggregate';
  aggregate?: Maybe<App_Aggregate_Fields>;
  nodes: Array<App>;
};

/** aggregate fields of "app" */
export type App_Aggregate_Fields = {
  __typename?: 'app_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<App_Max_Fields>;
  min?: Maybe<App_Min_Fields>;
};


/** aggregate fields of "app" */
export type App_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "app" */
export type App_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<App_Max_Order_By>;
  min?: Maybe<App_Min_Order_By>;
};

/** input type for inserting array relation for remote table "app" */
export type App_Arr_Rel_Insert_Input = {
  data: Array<App_Insert_Input>;
  on_conflict?: Maybe<App_On_Conflict>;
};

/** Boolean expression to filter rows from the table "app". All fields are combined with a logical 'AND'. */
export type App_Bool_Exp = {
  _and?: Maybe<Array<Maybe<App_Bool_Exp>>>;
  _not?: Maybe<App_Bool_Exp>;
  _or?: Maybe<Array<Maybe<App_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  tenant_id?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app" */
export enum App_Constraint {
  /** unique or primary key constraint */
  AppPkey = 'app_pkey'
}

/** input type for inserting data into table "app" */
export type App_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type App_Max_Fields = {
  __typename?: 'app_max_fields';
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "app" */
export type App_Max_Order_By = {
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type App_Min_Fields = {
  __typename?: 'app_min_fields';
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "app" */
export type App_Min_Order_By = {
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "app" */
export type App_Mutation_Response = {
  __typename?: 'app_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<App>;
};

/** input type for inserting object relation for remote table "app" */
export type App_Obj_Rel_Insert_Input = {
  data: App_Insert_Input;
  on_conflict?: Maybe<App_On_Conflict>;
};

/** on conflict condition type for table "app" */
export type App_On_Conflict = {
  constraint: App_Constraint;
  update_columns: Array<App_Update_Column>;
  where?: Maybe<App_Bool_Exp>;
};

/** ordering options when selecting data from "app" */
export type App_Order_By = {
  id?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "app" */
export type App_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "app" */
export enum App_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "app" */
export type App_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  tenant_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "app" */
export enum App_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "arm_templates" */
export type Arm_Templates = {
  __typename?: 'arm_templates';
  description: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  url: Scalars['String'];
};

/** aggregated selection of "arm_templates" */
export type Arm_Templates_Aggregate = {
  __typename?: 'arm_templates_aggregate';
  aggregate?: Maybe<Arm_Templates_Aggregate_Fields>;
  nodes: Array<Arm_Templates>;
};

/** aggregate fields of "arm_templates" */
export type Arm_Templates_Aggregate_Fields = {
  __typename?: 'arm_templates_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Arm_Templates_Max_Fields>;
  min?: Maybe<Arm_Templates_Min_Fields>;
};


/** aggregate fields of "arm_templates" */
export type Arm_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Arm_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "arm_templates" */
export type Arm_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Arm_Templates_Max_Order_By>;
  min?: Maybe<Arm_Templates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "arm_templates" */
export type Arm_Templates_Arr_Rel_Insert_Input = {
  data: Array<Arm_Templates_Insert_Input>;
  on_conflict?: Maybe<Arm_Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "arm_templates". All fields are combined with a logical 'AND'. */
export type Arm_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Arm_Templates_Bool_Exp>>>;
  _not?: Maybe<Arm_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Arm_Templates_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "arm_templates" */
export enum Arm_Templates_Constraint {
  /** unique or primary key constraint */
  ArmTemplatesPkey = 'arm_templates_pkey'
}

/** input type for inserting data into table "arm_templates" */
export type Arm_Templates_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Arm_Templates_Max_Fields = {
  __typename?: 'arm_templates_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "arm_templates" */
export type Arm_Templates_Max_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Arm_Templates_Min_Fields = {
  __typename?: 'arm_templates_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "arm_templates" */
export type Arm_Templates_Min_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "arm_templates" */
export type Arm_Templates_Mutation_Response = {
  __typename?: 'arm_templates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Arm_Templates>;
};

/** input type for inserting object relation for remote table "arm_templates" */
export type Arm_Templates_Obj_Rel_Insert_Input = {
  data: Arm_Templates_Insert_Input;
  on_conflict?: Maybe<Arm_Templates_On_Conflict>;
};

/** on conflict condition type for table "arm_templates" */
export type Arm_Templates_On_Conflict = {
  constraint: Arm_Templates_Constraint;
  update_columns: Array<Arm_Templates_Update_Column>;
  where?: Maybe<Arm_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "arm_templates" */
export type Arm_Templates_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: "arm_templates" */
export type Arm_Templates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "arm_templates" */
export enum Arm_Templates_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "arm_templates" */
export type Arm_Templates_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** update columns of table "arm_templates" */
export enum Arm_Templates_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url'
}

/** columns and relationships of "badge_awards" */
export type Badge_Awards = {
  __typename?: 'badge_awards';
  badge_id: Scalars['String'];
  badgee_user_id: Scalars['String'];
  badger_user_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_accepted: Scalars['Boolean'];
  message: Scalars['String'];
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByBadgeeUserId: Users;
};

/** aggregated selection of "badge_awards" */
export type Badge_Awards_Aggregate = {
  __typename?: 'badge_awards_aggregate';
  aggregate?: Maybe<Badge_Awards_Aggregate_Fields>;
  nodes: Array<Badge_Awards>;
};

/** aggregate fields of "badge_awards" */
export type Badge_Awards_Aggregate_Fields = {
  __typename?: 'badge_awards_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Badge_Awards_Max_Fields>;
  min?: Maybe<Badge_Awards_Min_Fields>;
};


/** aggregate fields of "badge_awards" */
export type Badge_Awards_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Badge_Awards_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "badge_awards" */
export type Badge_Awards_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Badge_Awards_Max_Order_By>;
  min?: Maybe<Badge_Awards_Min_Order_By>;
};

/** input type for inserting array relation for remote table "badge_awards" */
export type Badge_Awards_Arr_Rel_Insert_Input = {
  data: Array<Badge_Awards_Insert_Input>;
  on_conflict?: Maybe<Badge_Awards_On_Conflict>;
};

/** Boolean expression to filter rows from the table "badge_awards". All fields are combined with a logical 'AND'. */
export type Badge_Awards_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Badge_Awards_Bool_Exp>>>;
  _not?: Maybe<Badge_Awards_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Badge_Awards_Bool_Exp>>>;
  badge_id?: Maybe<String_Comparison_Exp>;
  badgee_user_id?: Maybe<String_Comparison_Exp>;
  badger_user_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_accepted?: Maybe<Boolean_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  userByBadgeeUserId?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "badge_awards" */
export enum Badge_Awards_Constraint {
  /** unique or primary key constraint */
  BadgeAwardsPkey = 'badge_awards_pkey'
}

/** input type for inserting data into table "badge_awards" */
export type Badge_Awards_Insert_Input = {
  badge_id?: Maybe<Scalars['String']>;
  badgee_user_id?: Maybe<Scalars['String']>;
  badger_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_accepted?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  userByBadgeeUserId?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Badge_Awards_Max_Fields = {
  __typename?: 'badge_awards_max_fields';
  badge_id?: Maybe<Scalars['String']>;
  badgee_user_id?: Maybe<Scalars['String']>;
  badger_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "badge_awards" */
export type Badge_Awards_Max_Order_By = {
  badge_id?: Maybe<Order_By>;
  badgee_user_id?: Maybe<Order_By>;
  badger_user_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Badge_Awards_Min_Fields = {
  __typename?: 'badge_awards_min_fields';
  badge_id?: Maybe<Scalars['String']>;
  badgee_user_id?: Maybe<Scalars['String']>;
  badger_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "badge_awards" */
export type Badge_Awards_Min_Order_By = {
  badge_id?: Maybe<Order_By>;
  badgee_user_id?: Maybe<Order_By>;
  badger_user_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
};

/** response of any mutation on the table "badge_awards" */
export type Badge_Awards_Mutation_Response = {
  __typename?: 'badge_awards_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Badge_Awards>;
};

/** input type for inserting object relation for remote table "badge_awards" */
export type Badge_Awards_Obj_Rel_Insert_Input = {
  data: Badge_Awards_Insert_Input;
  on_conflict?: Maybe<Badge_Awards_On_Conflict>;
};

/** on conflict condition type for table "badge_awards" */
export type Badge_Awards_On_Conflict = {
  constraint: Badge_Awards_Constraint;
  update_columns: Array<Badge_Awards_Update_Column>;
  where?: Maybe<Badge_Awards_Bool_Exp>;
};

/** ordering options when selecting data from "badge_awards" */
export type Badge_Awards_Order_By = {
  badge_id?: Maybe<Order_By>;
  badgee_user_id?: Maybe<Order_By>;
  badger_user_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_accepted?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  userByBadgeeUserId?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: "badge_awards" */
export type Badge_Awards_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "badge_awards" */
export enum Badge_Awards_Select_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  BadgeeUserId = 'badgee_user_id',
  /** column name */
  BadgerUserId = 'badger_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAccepted = 'is_accepted',
  /** column name */
  Message = 'message'
}

/** input type for updating data in table "badge_awards" */
export type Badge_Awards_Set_Input = {
  badge_id?: Maybe<Scalars['String']>;
  badgee_user_id?: Maybe<Scalars['String']>;
  badger_user_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_accepted?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

/** update columns of table "badge_awards" */
export enum Badge_Awards_Update_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  BadgeeUserId = 'badgee_user_id',
  /** column name */
  BadgerUserId = 'badger_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAccepted = 'is_accepted',
  /** column name */
  Message = 'message'
}

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "clicks" */
export type Clicks = {
  __typename?: 'clicks';
  id: Scalars['uuid'];
  user_id: Scalars['String'];
  x_position?: Maybe<Scalars['numeric']>;
  y_position?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "clicks" */
export type Clicks_Aggregate = {
  __typename?: 'clicks_aggregate';
  aggregate?: Maybe<Clicks_Aggregate_Fields>;
  nodes: Array<Clicks>;
};

/** aggregate fields of "clicks" */
export type Clicks_Aggregate_Fields = {
  __typename?: 'clicks_aggregate_fields';
  avg?: Maybe<Clicks_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Clicks_Max_Fields>;
  min?: Maybe<Clicks_Min_Fields>;
  stddev?: Maybe<Clicks_Stddev_Fields>;
  stddev_pop?: Maybe<Clicks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clicks_Stddev_Samp_Fields>;
  sum?: Maybe<Clicks_Sum_Fields>;
  var_pop?: Maybe<Clicks_Var_Pop_Fields>;
  var_samp?: Maybe<Clicks_Var_Samp_Fields>;
  variance?: Maybe<Clicks_Variance_Fields>;
};


/** aggregate fields of "clicks" */
export type Clicks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Clicks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clicks" */
export type Clicks_Aggregate_Order_By = {
  avg?: Maybe<Clicks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Clicks_Max_Order_By>;
  min?: Maybe<Clicks_Min_Order_By>;
  stddev?: Maybe<Clicks_Stddev_Order_By>;
  stddev_pop?: Maybe<Clicks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Clicks_Stddev_Samp_Order_By>;
  sum?: Maybe<Clicks_Sum_Order_By>;
  var_pop?: Maybe<Clicks_Var_Pop_Order_By>;
  var_samp?: Maybe<Clicks_Var_Samp_Order_By>;
  variance?: Maybe<Clicks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "clicks" */
export type Clicks_Arr_Rel_Insert_Input = {
  data: Array<Clicks_Insert_Input>;
  on_conflict?: Maybe<Clicks_On_Conflict>;
};

/** aggregate avg on columns */
export type Clicks_Avg_Fields = {
  __typename?: 'clicks_avg_fields';
  x_position?: Maybe<Scalars['Float']>;
  y_position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clicks" */
export type Clicks_Avg_Order_By = {
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clicks". All fields are combined with a logical 'AND'. */
export type Clicks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Clicks_Bool_Exp>>>;
  _not?: Maybe<Clicks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Clicks_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
  x_position?: Maybe<Numeric_Comparison_Exp>;
  y_position?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "clicks" */
export enum Clicks_Constraint {
  /** unique or primary key constraint */
  ClicksPkey = 'clicks_pkey'
}

/** input type for incrementing integer column in table "clicks" */
export type Clicks_Inc_Input = {
  x_position?: Maybe<Scalars['numeric']>;
  y_position?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "clicks" */
export type Clicks_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
  x_position?: Maybe<Scalars['numeric']>;
  y_position?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Clicks_Max_Fields = {
  __typename?: 'clicks_max_fields';
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
  x_position?: Maybe<Scalars['numeric']>;
  y_position?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "clicks" */
export type Clicks_Max_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Clicks_Min_Fields = {
  __typename?: 'clicks_min_fields';
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
  x_position?: Maybe<Scalars['numeric']>;
  y_position?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "clicks" */
export type Clicks_Min_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** response of any mutation on the table "clicks" */
export type Clicks_Mutation_Response = {
  __typename?: 'clicks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Clicks>;
};

/** input type for inserting object relation for remote table "clicks" */
export type Clicks_Obj_Rel_Insert_Input = {
  data: Clicks_Insert_Input;
  on_conflict?: Maybe<Clicks_On_Conflict>;
};

/** on conflict condition type for table "clicks" */
export type Clicks_On_Conflict = {
  constraint: Clicks_Constraint;
  update_columns: Array<Clicks_Update_Column>;
  where?: Maybe<Clicks_Bool_Exp>;
};

/** ordering options when selecting data from "clicks" */
export type Clicks_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** primary key columns input for table: "clicks" */
export type Clicks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clicks" */
export enum Clicks_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  XPosition = 'x_position',
  /** column name */
  YPosition = 'y_position'
}

/** input type for updating data in table "clicks" */
export type Clicks_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
  x_position?: Maybe<Scalars['numeric']>;
  y_position?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Clicks_Stddev_Fields = {
  __typename?: 'clicks_stddev_fields';
  x_position?: Maybe<Scalars['Float']>;
  y_position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clicks" */
export type Clicks_Stddev_Order_By = {
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Clicks_Stddev_Pop_Fields = {
  __typename?: 'clicks_stddev_pop_fields';
  x_position?: Maybe<Scalars['Float']>;
  y_position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clicks" */
export type Clicks_Stddev_Pop_Order_By = {
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Clicks_Stddev_Samp_Fields = {
  __typename?: 'clicks_stddev_samp_fields';
  x_position?: Maybe<Scalars['Float']>;
  y_position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clicks" */
export type Clicks_Stddev_Samp_Order_By = {
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Clicks_Sum_Fields = {
  __typename?: 'clicks_sum_fields';
  x_position?: Maybe<Scalars['numeric']>;
  y_position?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "clicks" */
export type Clicks_Sum_Order_By = {
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** update columns of table "clicks" */
export enum Clicks_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  XPosition = 'x_position',
  /** column name */
  YPosition = 'y_position'
}

/** aggregate var_pop on columns */
export type Clicks_Var_Pop_Fields = {
  __typename?: 'clicks_var_pop_fields';
  x_position?: Maybe<Scalars['Float']>;
  y_position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clicks" */
export type Clicks_Var_Pop_Order_By = {
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Clicks_Var_Samp_Fields = {
  __typename?: 'clicks_var_samp_fields';
  x_position?: Maybe<Scalars['Float']>;
  y_position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clicks" */
export type Clicks_Var_Samp_Order_By = {
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Clicks_Variance_Fields = {
  __typename?: 'clicks_variance_fields';
  x_position?: Maybe<Scalars['Float']>;
  y_position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clicks" */
export type Clicks_Variance_Order_By = {
  x_position?: Maybe<Order_By>;
  y_position?: Maybe<Order_By>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "extended_profile" */
export type Extended_Profile = {
  __typename?: 'extended_profile';
  about_me?: Maybe<Scalars['String']>;
  alternate_phone?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  experience_start_date?: Maybe<Scalars['date']>;
  header_image_base64?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  life_outside_work_text?: Maybe<Scalars['String']>;
  social_url?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
  website_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "extended_profile" */
export type Extended_Profile_Aggregate = {
  __typename?: 'extended_profile_aggregate';
  aggregate?: Maybe<Extended_Profile_Aggregate_Fields>;
  nodes: Array<Extended_Profile>;
};

/** aggregate fields of "extended_profile" */
export type Extended_Profile_Aggregate_Fields = {
  __typename?: 'extended_profile_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Extended_Profile_Max_Fields>;
  min?: Maybe<Extended_Profile_Min_Fields>;
};


/** aggregate fields of "extended_profile" */
export type Extended_Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Extended_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "extended_profile" */
export type Extended_Profile_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Extended_Profile_Max_Order_By>;
  min?: Maybe<Extended_Profile_Min_Order_By>;
};

/** input type for inserting array relation for remote table "extended_profile" */
export type Extended_Profile_Arr_Rel_Insert_Input = {
  data: Array<Extended_Profile_Insert_Input>;
  on_conflict?: Maybe<Extended_Profile_On_Conflict>;
};

/** Boolean expression to filter rows from the table "extended_profile". All fields are combined with a logical 'AND'. */
export type Extended_Profile_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Extended_Profile_Bool_Exp>>>;
  _not?: Maybe<Extended_Profile_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Extended_Profile_Bool_Exp>>>;
  about_me?: Maybe<String_Comparison_Exp>;
  alternate_phone?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  experience_start_date?: Maybe<Date_Comparison_Exp>;
  header_image_base64?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  life_outside_work_text?: Maybe<String_Comparison_Exp>;
  social_url?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
  website_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "extended_profile" */
export enum Extended_Profile_Constraint {
  /** unique or primary key constraint */
  ExtendedProfilePkey = 'extended_profile_pkey'
}

/** input type for inserting data into table "extended_profile" */
export type Extended_Profile_Insert_Input = {
  about_me?: Maybe<Scalars['String']>;
  alternate_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  experience_start_date?: Maybe<Scalars['date']>;
  header_image_base64?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  life_outside_work_text?: Maybe<Scalars['String']>;
  social_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
  website_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Extended_Profile_Max_Fields = {
  __typename?: 'extended_profile_max_fields';
  about_me?: Maybe<Scalars['String']>;
  alternate_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  experience_start_date?: Maybe<Scalars['date']>;
  header_image_base64?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  life_outside_work_text?: Maybe<Scalars['String']>;
  social_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  website_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "extended_profile" */
export type Extended_Profile_Max_Order_By = {
  about_me?: Maybe<Order_By>;
  alternate_phone?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  experience_start_date?: Maybe<Order_By>;
  header_image_base64?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  life_outside_work_text?: Maybe<Order_By>;
  social_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  website_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Extended_Profile_Min_Fields = {
  __typename?: 'extended_profile_min_fields';
  about_me?: Maybe<Scalars['String']>;
  alternate_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  experience_start_date?: Maybe<Scalars['date']>;
  header_image_base64?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  life_outside_work_text?: Maybe<Scalars['String']>;
  social_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  website_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "extended_profile" */
export type Extended_Profile_Min_Order_By = {
  about_me?: Maybe<Order_By>;
  alternate_phone?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  experience_start_date?: Maybe<Order_By>;
  header_image_base64?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  life_outside_work_text?: Maybe<Order_By>;
  social_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  website_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "extended_profile" */
export type Extended_Profile_Mutation_Response = {
  __typename?: 'extended_profile_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Extended_Profile>;
};

/** input type for inserting object relation for remote table "extended_profile" */
export type Extended_Profile_Obj_Rel_Insert_Input = {
  data: Extended_Profile_Insert_Input;
  on_conflict?: Maybe<Extended_Profile_On_Conflict>;
};

/** on conflict condition type for table "extended_profile" */
export type Extended_Profile_On_Conflict = {
  constraint: Extended_Profile_Constraint;
  update_columns: Array<Extended_Profile_Update_Column>;
  where?: Maybe<Extended_Profile_Bool_Exp>;
};

/** ordering options when selecting data from "extended_profile" */
export type Extended_Profile_Order_By = {
  about_me?: Maybe<Order_By>;
  alternate_phone?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  experience_start_date?: Maybe<Order_By>;
  header_image_base64?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  life_outside_work_text?: Maybe<Order_By>;
  social_url?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  website_url?: Maybe<Order_By>;
};

/** primary key columns input for table: "extended_profile" */
export type Extended_Profile_Pk_Columns_Input = {
  user_id: Scalars['String'];
};

/** select columns of table "extended_profile" */
export enum Extended_Profile_Select_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  AlternatePhone = 'alternate_phone',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExperienceStartDate = 'experience_start_date',
  /** column name */
  HeaderImageBase64 = 'header_image_base64',
  /** column name */
  Id = 'id',
  /** column name */
  LifeOutsideWorkText = 'life_outside_work_text',
  /** column name */
  SocialUrl = 'social_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WebsiteUrl = 'website_url'
}

/** input type for updating data in table "extended_profile" */
export type Extended_Profile_Set_Input = {
  about_me?: Maybe<Scalars['String']>;
  alternate_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  experience_start_date?: Maybe<Scalars['date']>;
  header_image_base64?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  life_outside_work_text?: Maybe<Scalars['String']>;
  social_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  website_url?: Maybe<Scalars['String']>;
};

/** update columns of table "extended_profile" */
export enum Extended_Profile_Update_Column {
  /** column name */
  AboutMe = 'about_me',
  /** column name */
  AlternatePhone = 'alternate_phone',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExperienceStartDate = 'experience_start_date',
  /** column name */
  HeaderImageBase64 = 'header_image_base64',
  /** column name */
  Id = 'id',
  /** column name */
  LifeOutsideWorkText = 'life_outside_work_text',
  /** column name */
  SocialUrl = 'social_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WebsiteUrl = 'website_url'
}

/** columns and relationships of "feedback" */
export type Feedback = {
  __typename?: 'feedback';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  text: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "feedback" */
export type Feedback_Aggregate = {
  __typename?: 'feedback_aggregate';
  aggregate?: Maybe<Feedback_Aggregate_Fields>;
  nodes: Array<Feedback>;
};

/** aggregate fields of "feedback" */
export type Feedback_Aggregate_Fields = {
  __typename?: 'feedback_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Feedback_Max_Fields>;
  min?: Maybe<Feedback_Min_Fields>;
};


/** aggregate fields of "feedback" */
export type Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feedback_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "feedback" */
export type Feedback_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Feedback_Max_Order_By>;
  min?: Maybe<Feedback_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feedback" */
export type Feedback_Arr_Rel_Insert_Input = {
  data: Array<Feedback_Insert_Input>;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feedback". All fields are combined with a logical 'AND'. */
export type Feedback_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Feedback_Bool_Exp>>>;
  _not?: Maybe<Feedback_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Feedback_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "feedback" */
export enum Feedback_Constraint {
  /** unique or primary key constraint */
  FeedbackPkey = 'feedback_pkey'
}

/** input type for inserting data into table "feedback" */
export type Feedback_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Feedback_Max_Fields = {
  __typename?: 'feedback_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "feedback" */
export type Feedback_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Feedback_Min_Fields = {
  __typename?: 'feedback_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "feedback" */
export type Feedback_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "feedback" */
export type Feedback_Mutation_Response = {
  __typename?: 'feedback_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Feedback>;
};

/** input type for inserting object relation for remote table "feedback" */
export type Feedback_Obj_Rel_Insert_Input = {
  data: Feedback_Insert_Input;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};

/** on conflict condition type for table "feedback" */
export type Feedback_On_Conflict = {
  constraint: Feedback_Constraint;
  update_columns: Array<Feedback_Update_Column>;
  where?: Maybe<Feedback_Bool_Exp>;
};

/** ordering options when selecting data from "feedback" */
export type Feedback_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "feedback" */
export type Feedback_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "feedback" */
export enum Feedback_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "feedback" */
export type Feedback_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "feedback" */
export enum Feedback_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  UserId = 'user_id'
}

export type GetAllChatsInput = {
  __typename?: 'GetAllChatsInput';
  accessToken: Scalars['String'];
};

export type GraphApiProfile = {
  __typename?: 'GraphApiProfile';
  id: Scalars['String'];
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "accounts" */
  delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "alert_acknowledge" */
  delete_alert_acknowledge?: Maybe<Alert_Acknowledge_Mutation_Response>;
  /** delete single row from the table: "alert_acknowledge" */
  delete_alert_acknowledge_by_pk?: Maybe<Alert_Acknowledge>;
  /** delete data from the table: "alerts" */
  delete_alerts?: Maybe<Alerts_Mutation_Response>;
  /** delete single row from the table: "alerts" */
  delete_alerts_by_pk?: Maybe<Alerts>;
  /** delete data from the table: "app" */
  delete_app?: Maybe<App_Mutation_Response>;
  /** delete single row from the table: "app" */
  delete_app_by_pk?: Maybe<App>;
  /** delete data from the table: "arm_templates" */
  delete_arm_templates?: Maybe<Arm_Templates_Mutation_Response>;
  /** delete single row from the table: "arm_templates" */
  delete_arm_templates_by_pk?: Maybe<Arm_Templates>;
  /** delete data from the table: "badge_awards" */
  delete_badge_awards?: Maybe<Badge_Awards_Mutation_Response>;
  /** delete single row from the table: "badge_awards" */
  delete_badge_awards_by_pk?: Maybe<Badge_Awards>;
  /** delete data from the table: "clicks" */
  delete_clicks?: Maybe<Clicks_Mutation_Response>;
  /** delete single row from the table: "clicks" */
  delete_clicks_by_pk?: Maybe<Clicks>;
  /** delete data from the table: "extended_profile" */
  delete_extended_profile?: Maybe<Extended_Profile_Mutation_Response>;
  /** delete single row from the table: "extended_profile" */
  delete_extended_profile_by_pk?: Maybe<Extended_Profile>;
  /** delete data from the table: "feedback" */
  delete_feedback?: Maybe<Feedback_Mutation_Response>;
  /** delete single row from the table: "feedback" */
  delete_feedback_by_pk?: Maybe<Feedback>;
  /** delete data from the table: "online" */
  delete_online?: Maybe<Online_Mutation_Response>;
  /** delete single row from the table: "online" */
  delete_online_by_pk?: Maybe<Online>;
  /** delete data from the table: "online_now" */
  delete_online_now?: Maybe<Online_Now_Mutation_Response>;
  /** delete data from the table: "project_tech" */
  delete_project_tech?: Maybe<Project_Tech_Mutation_Response>;
  /** delete single row from the table: "project_tech" */
  delete_project_tech_by_pk?: Maybe<Project_Tech>;
  /** delete data from the table: "project_users" */
  delete_project_users?: Maybe<Project_Users_Mutation_Response>;
  /** delete single row from the table: "project_users" */
  delete_project_users_by_pk?: Maybe<Project_Users>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "projects_public" */
  delete_projects_public?: Maybe<Projects_Public_Mutation_Response>;
  /** delete data from the table: "sound_requests" */
  delete_sound_requests?: Maybe<Sound_Requests_Mutation_Response>;
  /** delete single row from the table: "sound_requests" */
  delete_sound_requests_by_pk?: Maybe<Sound_Requests>;
  /** delete data from the table: "sound_requests_now" */
  delete_sound_requests_now?: Maybe<Sound_Requests_Now_Mutation_Response>;
  /** delete data from the table: "talents" */
  delete_talents?: Maybe<Talents_Mutation_Response>;
  /** delete single row from the table: "talents" */
  delete_talents_by_pk?: Maybe<Talents>;
  /** delete data from the table: "teams_context" */
  delete_teams_context?: Maybe<Teams_Context_Mutation_Response>;
  /** delete single row from the table: "teams_context" */
  delete_teams_context_by_pk?: Maybe<Teams_Context>;
  /** delete data from the table: "teams_sessions" */
  delete_teams_sessions?: Maybe<Teams_Sessions_Mutation_Response>;
  /** delete single row from the table: "teams_sessions" */
  delete_teams_sessions_by_pk?: Maybe<Teams_Sessions>;
  /** delete data from the table: "tech_tags" */
  delete_tech_tags?: Maybe<Tech_Tags_Mutation_Response>;
  /** delete single row from the table: "tech_tags" */
  delete_tech_tags_by_pk?: Maybe<Tech_Tags>;
  /** delete data from the table: "tenants" */
  delete_tenants?: Maybe<Tenants_Mutation_Response>;
  /** delete data from the table: "tenants_allowed" */
  delete_tenants_allowed?: Maybe<Tenants_Allowed_Mutation_Response>;
  /** delete single row from the table: "tenants_allowed" */
  delete_tenants_allowed_by_pk?: Maybe<Tenants_Allowed>;
  /** delete single row from the table: "tenants" */
  delete_tenants_by_pk?: Maybe<Tenants>;
  /** delete data from the table: "user_achievements" */
  delete_user_achievements?: Maybe<User_Achievements_Mutation_Response>;
  /** delete single row from the table: "user_achievements" */
  delete_user_achievements_by_pk?: Maybe<User_Achievements>;
  /** delete data from the table: "user_deselected_projects" */
  delete_user_deselected_projects?: Maybe<User_Deselected_Projects_Mutation_Response>;
  /** delete single row from the table: "user_deselected_projects" */
  delete_user_deselected_projects_by_pk?: Maybe<User_Deselected_Projects>;
  /** delete data from the table: "user_education" */
  delete_user_education?: Maybe<User_Education_Mutation_Response>;
  /** delete single row from the table: "user_education" */
  delete_user_education_by_pk?: Maybe<User_Education>;
  /** delete data from the table: "user_experience" */
  delete_user_experience?: Maybe<User_Experience_Mutation_Response>;
  /** delete single row from the table: "user_experience" */
  delete_user_experience_by_pk?: Maybe<User_Experience>;
  /** delete data from the table: "user_images" */
  delete_user_images?: Maybe<User_Images_Mutation_Response>;
  /** delete single row from the table: "user_images" */
  delete_user_images_by_pk?: Maybe<User_Images>;
  /** delete data from the table: "user_quote" */
  delete_user_quote?: Maybe<User_Quote_Mutation_Response>;
  /** delete single row from the table: "user_quote" */
  delete_user_quote_by_pk?: Maybe<User_Quote>;
  /** delete data from the table: "user_uploaded_images" */
  delete_user_uploaded_images?: Maybe<User_Uploaded_Images_Mutation_Response>;
  /** delete single row from the table: "user_uploaded_images" */
  delete_user_uploaded_images_by_pk?: Maybe<User_Uploaded_Images>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "accounts" */
  insert_accounts?: Maybe<Accounts_Mutation_Response>;
  /** insert a single row into the table: "accounts" */
  insert_accounts_one?: Maybe<Accounts>;
  /** insert data into the table: "alert_acknowledge" */
  insert_alert_acknowledge?: Maybe<Alert_Acknowledge_Mutation_Response>;
  /** insert a single row into the table: "alert_acknowledge" */
  insert_alert_acknowledge_one?: Maybe<Alert_Acknowledge>;
  /** insert data into the table: "alerts" */
  insert_alerts?: Maybe<Alerts_Mutation_Response>;
  /** insert a single row into the table: "alerts" */
  insert_alerts_one?: Maybe<Alerts>;
  /** insert data into the table: "app" */
  insert_app?: Maybe<App_Mutation_Response>;
  /** insert a single row into the table: "app" */
  insert_app_one?: Maybe<App>;
  /** insert data into the table: "arm_templates" */
  insert_arm_templates?: Maybe<Arm_Templates_Mutation_Response>;
  /** insert a single row into the table: "arm_templates" */
  insert_arm_templates_one?: Maybe<Arm_Templates>;
  /** insert data into the table: "badge_awards" */
  insert_badge_awards?: Maybe<Badge_Awards_Mutation_Response>;
  /** insert a single row into the table: "badge_awards" */
  insert_badge_awards_one?: Maybe<Badge_Awards>;
  /** insert data into the table: "clicks" */
  insert_clicks?: Maybe<Clicks_Mutation_Response>;
  /** insert a single row into the table: "clicks" */
  insert_clicks_one?: Maybe<Clicks>;
  /** insert data into the table: "extended_profile" */
  insert_extended_profile?: Maybe<Extended_Profile_Mutation_Response>;
  /** insert a single row into the table: "extended_profile" */
  insert_extended_profile_one?: Maybe<Extended_Profile>;
  /** insert data into the table: "feedback" */
  insert_feedback?: Maybe<Feedback_Mutation_Response>;
  /** insert a single row into the table: "feedback" */
  insert_feedback_one?: Maybe<Feedback>;
  /** insert data into the table: "online" */
  insert_online?: Maybe<Online_Mutation_Response>;
  /** insert data into the table: "online_now" */
  insert_online_now?: Maybe<Online_Now_Mutation_Response>;
  /** insert a single row into the table: "online_now" */
  insert_online_now_one?: Maybe<Online_Now>;
  /** insert a single row into the table: "online" */
  insert_online_one?: Maybe<Online>;
  /** insert data into the table: "project_tech" */
  insert_project_tech?: Maybe<Project_Tech_Mutation_Response>;
  /** insert a single row into the table: "project_tech" */
  insert_project_tech_one?: Maybe<Project_Tech>;
  /** insert data into the table: "project_users" */
  insert_project_users?: Maybe<Project_Users_Mutation_Response>;
  /** insert a single row into the table: "project_users" */
  insert_project_users_one?: Maybe<Project_Users>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "projects_public" */
  insert_projects_public?: Maybe<Projects_Public_Mutation_Response>;
  /** insert a single row into the table: "projects_public" */
  insert_projects_public_one?: Maybe<Projects_Public>;
  /** insert data into the table: "sound_requests" */
  insert_sound_requests?: Maybe<Sound_Requests_Mutation_Response>;
  /** insert data into the table: "sound_requests_now" */
  insert_sound_requests_now?: Maybe<Sound_Requests_Now_Mutation_Response>;
  /** insert a single row into the table: "sound_requests_now" */
  insert_sound_requests_now_one?: Maybe<Sound_Requests_Now>;
  /** insert a single row into the table: "sound_requests" */
  insert_sound_requests_one?: Maybe<Sound_Requests>;
  /** insert data into the table: "talents" */
  insert_talents?: Maybe<Talents_Mutation_Response>;
  /** insert a single row into the table: "talents" */
  insert_talents_one?: Maybe<Talents>;
  /** insert data into the table: "teams_context" */
  insert_teams_context?: Maybe<Teams_Context_Mutation_Response>;
  /** insert a single row into the table: "teams_context" */
  insert_teams_context_one?: Maybe<Teams_Context>;
  /** insert data into the table: "teams_sessions" */
  insert_teams_sessions?: Maybe<Teams_Sessions_Mutation_Response>;
  /** insert a single row into the table: "teams_sessions" */
  insert_teams_sessions_one?: Maybe<Teams_Sessions>;
  /** insert data into the table: "tech_tags" */
  insert_tech_tags?: Maybe<Tech_Tags_Mutation_Response>;
  /** insert a single row into the table: "tech_tags" */
  insert_tech_tags_one?: Maybe<Tech_Tags>;
  /** insert data into the table: "tenants" */
  insert_tenants?: Maybe<Tenants_Mutation_Response>;
  /** insert data into the table: "tenants_allowed" */
  insert_tenants_allowed?: Maybe<Tenants_Allowed_Mutation_Response>;
  /** insert a single row into the table: "tenants_allowed" */
  insert_tenants_allowed_one?: Maybe<Tenants_Allowed>;
  /** insert a single row into the table: "tenants" */
  insert_tenants_one?: Maybe<Tenants>;
  /** insert data into the table: "user_achievements" */
  insert_user_achievements?: Maybe<User_Achievements_Mutation_Response>;
  /** insert a single row into the table: "user_achievements" */
  insert_user_achievements_one?: Maybe<User_Achievements>;
  /** insert data into the table: "user_deselected_projects" */
  insert_user_deselected_projects?: Maybe<User_Deselected_Projects_Mutation_Response>;
  /** insert a single row into the table: "user_deselected_projects" */
  insert_user_deselected_projects_one?: Maybe<User_Deselected_Projects>;
  /** insert data into the table: "user_education" */
  insert_user_education?: Maybe<User_Education_Mutation_Response>;
  /** insert a single row into the table: "user_education" */
  insert_user_education_one?: Maybe<User_Education>;
  /** insert data into the table: "user_experience" */
  insert_user_experience?: Maybe<User_Experience_Mutation_Response>;
  /** insert a single row into the table: "user_experience" */
  insert_user_experience_one?: Maybe<User_Experience>;
  /** insert data into the table: "user_images" */
  insert_user_images?: Maybe<User_Images_Mutation_Response>;
  /** insert a single row into the table: "user_images" */
  insert_user_images_one?: Maybe<User_Images>;
  /** insert data into the table: "user_quote" */
  insert_user_quote?: Maybe<User_Quote_Mutation_Response>;
  /** insert a single row into the table: "user_quote" */
  insert_user_quote_one?: Maybe<User_Quote>;
  /** insert data into the table: "user_uploaded_images" */
  insert_user_uploaded_images?: Maybe<User_Uploaded_Images_Mutation_Response>;
  /** insert a single row into the table: "user_uploaded_images" */
  insert_user_uploaded_images_one?: Maybe<User_Uploaded_Images>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** perform the action: "login" */
  login?: Maybe<LoginResponse>;
  /** perform the action: "teamsMeetingNotification" */
  teamsMeetingNotification?: Maybe<TeamsMeetingNotificationOutput>;
  /** perform the action: "teamsMigrationCreateTeam" */
  teamsMigrationCreateTeam?: Maybe<TeamsMigrationCreateTeamOutput>;
  /** update data of the table: "accounts" */
  update_accounts?: Maybe<Accounts_Mutation_Response>;
  /** update single row of the table: "accounts" */
  update_accounts_by_pk?: Maybe<Accounts>;
  /** update data of the table: "alert_acknowledge" */
  update_alert_acknowledge?: Maybe<Alert_Acknowledge_Mutation_Response>;
  /** update single row of the table: "alert_acknowledge" */
  update_alert_acknowledge_by_pk?: Maybe<Alert_Acknowledge>;
  /** update data of the table: "alerts" */
  update_alerts?: Maybe<Alerts_Mutation_Response>;
  /** update single row of the table: "alerts" */
  update_alerts_by_pk?: Maybe<Alerts>;
  /** update data of the table: "app" */
  update_app?: Maybe<App_Mutation_Response>;
  /** update single row of the table: "app" */
  update_app_by_pk?: Maybe<App>;
  /** update data of the table: "arm_templates" */
  update_arm_templates?: Maybe<Arm_Templates_Mutation_Response>;
  /** update single row of the table: "arm_templates" */
  update_arm_templates_by_pk?: Maybe<Arm_Templates>;
  /** update data of the table: "badge_awards" */
  update_badge_awards?: Maybe<Badge_Awards_Mutation_Response>;
  /** update single row of the table: "badge_awards" */
  update_badge_awards_by_pk?: Maybe<Badge_Awards>;
  /** update data of the table: "clicks" */
  update_clicks?: Maybe<Clicks_Mutation_Response>;
  /** update single row of the table: "clicks" */
  update_clicks_by_pk?: Maybe<Clicks>;
  /** update data of the table: "extended_profile" */
  update_extended_profile?: Maybe<Extended_Profile_Mutation_Response>;
  /** update single row of the table: "extended_profile" */
  update_extended_profile_by_pk?: Maybe<Extended_Profile>;
  /** update data of the table: "feedback" */
  update_feedback?: Maybe<Feedback_Mutation_Response>;
  /** update single row of the table: "feedback" */
  update_feedback_by_pk?: Maybe<Feedback>;
  /** update data of the table: "online" */
  update_online?: Maybe<Online_Mutation_Response>;
  /** update single row of the table: "online" */
  update_online_by_pk?: Maybe<Online>;
  /** update data of the table: "online_now" */
  update_online_now?: Maybe<Online_Now_Mutation_Response>;
  /** update data of the table: "project_tech" */
  update_project_tech?: Maybe<Project_Tech_Mutation_Response>;
  /** update single row of the table: "project_tech" */
  update_project_tech_by_pk?: Maybe<Project_Tech>;
  /** update data of the table: "project_users" */
  update_project_users?: Maybe<Project_Users_Mutation_Response>;
  /** update single row of the table: "project_users" */
  update_project_users_by_pk?: Maybe<Project_Users>;
  /** update data of the table: "projects" */
  update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update data of the table: "projects_public" */
  update_projects_public?: Maybe<Projects_Public_Mutation_Response>;
  /** update data of the table: "sound_requests" */
  update_sound_requests?: Maybe<Sound_Requests_Mutation_Response>;
  /** update single row of the table: "sound_requests" */
  update_sound_requests_by_pk?: Maybe<Sound_Requests>;
  /** update data of the table: "sound_requests_now" */
  update_sound_requests_now?: Maybe<Sound_Requests_Now_Mutation_Response>;
  /** update data of the table: "talents" */
  update_talents?: Maybe<Talents_Mutation_Response>;
  /** update single row of the table: "talents" */
  update_talents_by_pk?: Maybe<Talents>;
  /** update data of the table: "teams_context" */
  update_teams_context?: Maybe<Teams_Context_Mutation_Response>;
  /** update single row of the table: "teams_context" */
  update_teams_context_by_pk?: Maybe<Teams_Context>;
  /** update data of the table: "teams_sessions" */
  update_teams_sessions?: Maybe<Teams_Sessions_Mutation_Response>;
  /** update single row of the table: "teams_sessions" */
  update_teams_sessions_by_pk?: Maybe<Teams_Sessions>;
  /** update data of the table: "tech_tags" */
  update_tech_tags?: Maybe<Tech_Tags_Mutation_Response>;
  /** update single row of the table: "tech_tags" */
  update_tech_tags_by_pk?: Maybe<Tech_Tags>;
  /** update data of the table: "tenants" */
  update_tenants?: Maybe<Tenants_Mutation_Response>;
  /** update data of the table: "tenants_allowed" */
  update_tenants_allowed?: Maybe<Tenants_Allowed_Mutation_Response>;
  /** update single row of the table: "tenants_allowed" */
  update_tenants_allowed_by_pk?: Maybe<Tenants_Allowed>;
  /** update single row of the table: "tenants" */
  update_tenants_by_pk?: Maybe<Tenants>;
  /** update data of the table: "user_achievements" */
  update_user_achievements?: Maybe<User_Achievements_Mutation_Response>;
  /** update single row of the table: "user_achievements" */
  update_user_achievements_by_pk?: Maybe<User_Achievements>;
  /** update data of the table: "user_deselected_projects" */
  update_user_deselected_projects?: Maybe<User_Deselected_Projects_Mutation_Response>;
  /** update single row of the table: "user_deselected_projects" */
  update_user_deselected_projects_by_pk?: Maybe<User_Deselected_Projects>;
  /** update data of the table: "user_education" */
  update_user_education?: Maybe<User_Education_Mutation_Response>;
  /** update single row of the table: "user_education" */
  update_user_education_by_pk?: Maybe<User_Education>;
  /** update data of the table: "user_experience" */
  update_user_experience?: Maybe<User_Experience_Mutation_Response>;
  /** update single row of the table: "user_experience" */
  update_user_experience_by_pk?: Maybe<User_Experience>;
  /** update data of the table: "user_images" */
  update_user_images?: Maybe<User_Images_Mutation_Response>;
  /** update single row of the table: "user_images" */
  update_user_images_by_pk?: Maybe<User_Images>;
  /** update data of the table: "user_quote" */
  update_user_quote?: Maybe<User_Quote_Mutation_Response>;
  /** update single row of the table: "user_quote" */
  update_user_quote_by_pk?: Maybe<User_Quote>;
  /** update data of the table: "user_uploaded_images" */
  update_user_uploaded_images?: Maybe<User_Uploaded_Images_Mutation_Response>;
  /** update single row of the table: "user_uploaded_images" */
  update_user_uploaded_images_by_pk?: Maybe<User_Uploaded_Images>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Alert_AcknowledgeArgs = {
  where: Alert_Acknowledge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Alert_Acknowledge_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AlertsArgs = {
  where: Alerts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Alerts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_AppArgs = {
  where: App_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Arm_TemplatesArgs = {
  where: Arm_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Arm_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Badge_AwardsArgs = {
  where: Badge_Awards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badge_Awards_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ClicksArgs = {
  where: Clicks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clicks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Extended_ProfileArgs = {
  where: Extended_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Extended_Profile_By_PkArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FeedbackArgs = {
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OnlineArgs = {
  where: Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Online_By_PkArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Online_NowArgs = {
  where: Online_Now_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_TechArgs = {
  where: Project_Tech_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Tech_By_PkArgs = {
  project_id: Scalars['String'];
  tech_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Project_UsersArgs = {
  where: Project_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Projects_PublicArgs = {
  where: Projects_Public_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sound_RequestsArgs = {
  where: Sound_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sound_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Sound_Requests_NowArgs = {
  where: Sound_Requests_Now_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TalentsArgs = {
  where: Talents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Talents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teams_ContextArgs = {
  where: Teams_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_Context_By_PkArgs = {
  session_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Teams_SessionsArgs = {
  where: Teams_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_Sessions_By_PkArgs = {
  id: Scalars['String'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Tech_TagsArgs = {
  where: Tech_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tech_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TenantsArgs = {
  where: Tenants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenants_AllowedArgs = {
  where: Tenants_Allowed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tenants_Allowed_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Tenants_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_AchievementsArgs = {
  where: User_Achievements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Achievements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Deselected_ProjectsArgs = {
  where: User_Deselected_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Deselected_Projects_By_PkArgs = {
  project_id: Scalars['String'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_EducationArgs = {
  where: User_Education_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Education_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_ExperienceArgs = {
  where: User_Experience_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Experience_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_ImagesArgs = {
  where: User_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Images_By_PkArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_QuoteArgs = {
  where: User_Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Quote_By_PkArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_Uploaded_ImagesArgs = {
  where: User_Uploaded_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Uploaded_Images_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: Array<Accounts_Insert_Input>;
  on_conflict?: Maybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: Maybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Alert_AcknowledgeArgs = {
  objects: Array<Alert_Acknowledge_Insert_Input>;
  on_conflict?: Maybe<Alert_Acknowledge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Alert_Acknowledge_OneArgs = {
  object: Alert_Acknowledge_Insert_Input;
  on_conflict?: Maybe<Alert_Acknowledge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AlertsArgs = {
  objects: Array<Alerts_Insert_Input>;
  on_conflict?: Maybe<Alerts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Alerts_OneArgs = {
  object: Alerts_Insert_Input;
  on_conflict?: Maybe<Alerts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppArgs = {
  objects: Array<App_Insert_Input>;
  on_conflict?: Maybe<App_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_OneArgs = {
  object: App_Insert_Input;
  on_conflict?: Maybe<App_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Arm_TemplatesArgs = {
  objects: Array<Arm_Templates_Insert_Input>;
  on_conflict?: Maybe<Arm_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Arm_Templates_OneArgs = {
  object: Arm_Templates_Insert_Input;
  on_conflict?: Maybe<Arm_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_AwardsArgs = {
  objects: Array<Badge_Awards_Insert_Input>;
  on_conflict?: Maybe<Badge_Awards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_Awards_OneArgs = {
  object: Badge_Awards_Insert_Input;
  on_conflict?: Maybe<Badge_Awards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClicksArgs = {
  objects: Array<Clicks_Insert_Input>;
  on_conflict?: Maybe<Clicks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clicks_OneArgs = {
  object: Clicks_Insert_Input;
  on_conflict?: Maybe<Clicks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Extended_ProfileArgs = {
  objects: Array<Extended_Profile_Insert_Input>;
  on_conflict?: Maybe<Extended_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Extended_Profile_OneArgs = {
  object: Extended_Profile_Insert_Input;
  on_conflict?: Maybe<Extended_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackArgs = {
  objects: Array<Feedback_Insert_Input>;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feedback_OneArgs = {
  object: Feedback_Insert_Input;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OnlineArgs = {
  objects: Array<Online_Insert_Input>;
  on_conflict?: Maybe<Online_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Online_NowArgs = {
  objects: Array<Online_Now_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Online_Now_OneArgs = {
  object: Online_Now_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Online_OneArgs = {
  object: Online_Insert_Input;
  on_conflict?: Maybe<Online_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_TechArgs = {
  objects: Array<Project_Tech_Insert_Input>;
  on_conflict?: Maybe<Project_Tech_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Tech_OneArgs = {
  object: Project_Tech_Insert_Input;
  on_conflict?: Maybe<Project_Tech_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_UsersArgs = {
  objects: Array<Project_Users_Insert_Input>;
  on_conflict?: Maybe<Project_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Users_OneArgs = {
  object: Project_Users_Insert_Input;
  on_conflict?: Maybe<Project_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: Array<Projects_Insert_Input>;
  on_conflict?: Maybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: Maybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_PublicArgs = {
  objects: Array<Projects_Public_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_Public_OneArgs = {
  object: Projects_Public_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Sound_RequestsArgs = {
  objects: Array<Sound_Requests_Insert_Input>;
  on_conflict?: Maybe<Sound_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sound_Requests_NowArgs = {
  objects: Array<Sound_Requests_Now_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Sound_Requests_Now_OneArgs = {
  object: Sound_Requests_Now_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Sound_Requests_OneArgs = {
  object: Sound_Requests_Insert_Input;
  on_conflict?: Maybe<Sound_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TalentsArgs = {
  objects: Array<Talents_Insert_Input>;
  on_conflict?: Maybe<Talents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Talents_OneArgs = {
  object: Talents_Insert_Input;
  on_conflict?: Maybe<Talents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_ContextArgs = {
  objects: Array<Teams_Context_Insert_Input>;
  on_conflict?: Maybe<Teams_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_Context_OneArgs = {
  object: Teams_Context_Insert_Input;
  on_conflict?: Maybe<Teams_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_SessionsArgs = {
  objects: Array<Teams_Sessions_Insert_Input>;
  on_conflict?: Maybe<Teams_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_Sessions_OneArgs = {
  object: Teams_Sessions_Insert_Input;
  on_conflict?: Maybe<Teams_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tech_TagsArgs = {
  objects: Array<Tech_Tags_Insert_Input>;
  on_conflict?: Maybe<Tech_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tech_Tags_OneArgs = {
  object: Tech_Tags_Insert_Input;
  on_conflict?: Maybe<Tech_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TenantsArgs = {
  objects: Array<Tenants_Insert_Input>;
  on_conflict?: Maybe<Tenants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenants_AllowedArgs = {
  objects: Array<Tenants_Allowed_Insert_Input>;
  on_conflict?: Maybe<Tenants_Allowed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenants_Allowed_OneArgs = {
  object: Tenants_Allowed_Insert_Input;
  on_conflict?: Maybe<Tenants_Allowed_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tenants_OneArgs = {
  object: Tenants_Insert_Input;
  on_conflict?: Maybe<Tenants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_AchievementsArgs = {
  objects: Array<User_Achievements_Insert_Input>;
  on_conflict?: Maybe<User_Achievements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Achievements_OneArgs = {
  object: User_Achievements_Insert_Input;
  on_conflict?: Maybe<User_Achievements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Deselected_ProjectsArgs = {
  objects: Array<User_Deselected_Projects_Insert_Input>;
  on_conflict?: Maybe<User_Deselected_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Deselected_Projects_OneArgs = {
  object: User_Deselected_Projects_Insert_Input;
  on_conflict?: Maybe<User_Deselected_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_EducationArgs = {
  objects: Array<User_Education_Insert_Input>;
  on_conflict?: Maybe<User_Education_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Education_OneArgs = {
  object: User_Education_Insert_Input;
  on_conflict?: Maybe<User_Education_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ExperienceArgs = {
  objects: Array<User_Experience_Insert_Input>;
  on_conflict?: Maybe<User_Experience_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Experience_OneArgs = {
  object: User_Experience_Insert_Input;
  on_conflict?: Maybe<User_Experience_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_ImagesArgs = {
  objects: Array<User_Images_Insert_Input>;
  on_conflict?: Maybe<User_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Images_OneArgs = {
  object: User_Images_Insert_Input;
  on_conflict?: Maybe<User_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_QuoteArgs = {
  objects: Array<User_Quote_Insert_Input>;
  on_conflict?: Maybe<User_Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Quote_OneArgs = {
  object: User_Quote_Insert_Input;
  on_conflict?: Maybe<User_Quote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Uploaded_ImagesArgs = {
  objects: Array<User_Uploaded_Images_Insert_Input>;
  on_conflict?: Maybe<User_Uploaded_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Uploaded_Images_OneArgs = {
  object: User_Uploaded_Images_Insert_Input;
  on_conflict?: Maybe<User_Uploaded_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTeamsMeetingNotificationArgs = {
  arg1: TeamsMeetingNotificationInput;
};


/** mutation root */
export type Mutation_RootTeamsMigrationCreateTeamArgs = {
  arg1: TeamsMigrationCreateTeamInput;
};


/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _set?: Maybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _set?: Maybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Alert_AcknowledgeArgs = {
  _set?: Maybe<Alert_Acknowledge_Set_Input>;
  where: Alert_Acknowledge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Alert_Acknowledge_By_PkArgs = {
  _set?: Maybe<Alert_Acknowledge_Set_Input>;
  pk_columns: Alert_Acknowledge_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AlertsArgs = {
  _set?: Maybe<Alerts_Set_Input>;
  where: Alerts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Alerts_By_PkArgs = {
  _set?: Maybe<Alerts_Set_Input>;
  pk_columns: Alerts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppArgs = {
  _set?: Maybe<App_Set_Input>;
  where: App_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_By_PkArgs = {
  _set?: Maybe<App_Set_Input>;
  pk_columns: App_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Arm_TemplatesArgs = {
  _set?: Maybe<Arm_Templates_Set_Input>;
  where: Arm_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Arm_Templates_By_PkArgs = {
  _set?: Maybe<Arm_Templates_Set_Input>;
  pk_columns: Arm_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_AwardsArgs = {
  _set?: Maybe<Badge_Awards_Set_Input>;
  where: Badge_Awards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Awards_By_PkArgs = {
  _set?: Maybe<Badge_Awards_Set_Input>;
  pk_columns: Badge_Awards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClicksArgs = {
  _inc?: Maybe<Clicks_Inc_Input>;
  _set?: Maybe<Clicks_Set_Input>;
  where: Clicks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clicks_By_PkArgs = {
  _inc?: Maybe<Clicks_Inc_Input>;
  _set?: Maybe<Clicks_Set_Input>;
  pk_columns: Clicks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Extended_ProfileArgs = {
  _set?: Maybe<Extended_Profile_Set_Input>;
  where: Extended_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Extended_Profile_By_PkArgs = {
  _set?: Maybe<Extended_Profile_Set_Input>;
  pk_columns: Extended_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackArgs = {
  _set?: Maybe<Feedback_Set_Input>;
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feedback_By_PkArgs = {
  _set?: Maybe<Feedback_Set_Input>;
  pk_columns: Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OnlineArgs = {
  _set?: Maybe<Online_Set_Input>;
  where: Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Online_By_PkArgs = {
  _set?: Maybe<Online_Set_Input>;
  pk_columns: Online_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Online_NowArgs = {
  _set?: Maybe<Online_Now_Set_Input>;
  where: Online_Now_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_TechArgs = {
  _set?: Maybe<Project_Tech_Set_Input>;
  where: Project_Tech_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Tech_By_PkArgs = {
  _set?: Maybe<Project_Tech_Set_Input>;
  pk_columns: Project_Tech_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_UsersArgs = {
  _set?: Maybe<Project_Users_Set_Input>;
  where: Project_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Users_By_PkArgs = {
  _set?: Maybe<Project_Users_Set_Input>;
  pk_columns: Project_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _set?: Maybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _set?: Maybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_PublicArgs = {
  _set?: Maybe<Projects_Public_Set_Input>;
  where: Projects_Public_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sound_RequestsArgs = {
  _set?: Maybe<Sound_Requests_Set_Input>;
  where: Sound_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sound_Requests_By_PkArgs = {
  _set?: Maybe<Sound_Requests_Set_Input>;
  pk_columns: Sound_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sound_Requests_NowArgs = {
  _set?: Maybe<Sound_Requests_Now_Set_Input>;
  where: Sound_Requests_Now_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TalentsArgs = {
  _set?: Maybe<Talents_Set_Input>;
  where: Talents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Talents_By_PkArgs = {
  _set?: Maybe<Talents_Set_Input>;
  pk_columns: Talents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_ContextArgs = {
  _set?: Maybe<Teams_Context_Set_Input>;
  where: Teams_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Context_By_PkArgs = {
  _set?: Maybe<Teams_Context_Set_Input>;
  pk_columns: Teams_Context_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_SessionsArgs = {
  _append?: Maybe<Teams_Sessions_Append_Input>;
  _delete_at_path?: Maybe<Teams_Sessions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Teams_Sessions_Delete_Elem_Input>;
  _delete_key?: Maybe<Teams_Sessions_Delete_Key_Input>;
  _prepend?: Maybe<Teams_Sessions_Prepend_Input>;
  _set?: Maybe<Teams_Sessions_Set_Input>;
  where: Teams_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Sessions_By_PkArgs = {
  _append?: Maybe<Teams_Sessions_Append_Input>;
  _delete_at_path?: Maybe<Teams_Sessions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Teams_Sessions_Delete_Elem_Input>;
  _delete_key?: Maybe<Teams_Sessions_Delete_Key_Input>;
  _prepend?: Maybe<Teams_Sessions_Prepend_Input>;
  _set?: Maybe<Teams_Sessions_Set_Input>;
  pk_columns: Teams_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tech_TagsArgs = {
  _set?: Maybe<Tech_Tags_Set_Input>;
  where: Tech_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tech_Tags_By_PkArgs = {
  _set?: Maybe<Tech_Tags_Set_Input>;
  pk_columns: Tech_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TenantsArgs = {
  _set?: Maybe<Tenants_Set_Input>;
  where: Tenants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenants_AllowedArgs = {
  _set?: Maybe<Tenants_Allowed_Set_Input>;
  where: Tenants_Allowed_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tenants_Allowed_By_PkArgs = {
  _set?: Maybe<Tenants_Allowed_Set_Input>;
  pk_columns: Tenants_Allowed_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tenants_By_PkArgs = {
  _set?: Maybe<Tenants_Set_Input>;
  pk_columns: Tenants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_AchievementsArgs = {
  _set?: Maybe<User_Achievements_Set_Input>;
  where: User_Achievements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Achievements_By_PkArgs = {
  _set?: Maybe<User_Achievements_Set_Input>;
  pk_columns: User_Achievements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Deselected_ProjectsArgs = {
  _set?: Maybe<User_Deselected_Projects_Set_Input>;
  where: User_Deselected_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Deselected_Projects_By_PkArgs = {
  _set?: Maybe<User_Deselected_Projects_Set_Input>;
  pk_columns: User_Deselected_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_EducationArgs = {
  _set?: Maybe<User_Education_Set_Input>;
  where: User_Education_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Education_By_PkArgs = {
  _set?: Maybe<User_Education_Set_Input>;
  pk_columns: User_Education_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ExperienceArgs = {
  _set?: Maybe<User_Experience_Set_Input>;
  where: User_Experience_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Experience_By_PkArgs = {
  _set?: Maybe<User_Experience_Set_Input>;
  pk_columns: User_Experience_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ImagesArgs = {
  _set?: Maybe<User_Images_Set_Input>;
  where: User_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Images_By_PkArgs = {
  _set?: Maybe<User_Images_Set_Input>;
  pk_columns: User_Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_QuoteArgs = {
  _set?: Maybe<User_Quote_Set_Input>;
  where: User_Quote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Quote_By_PkArgs = {
  _set?: Maybe<User_Quote_Set_Input>;
  pk_columns: User_Quote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Uploaded_ImagesArgs = {
  _set?: Maybe<User_Uploaded_Images_Set_Input>;
  where: User_Uploaded_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Uploaded_Images_By_PkArgs = {
  _set?: Maybe<User_Uploaded_Images_Set_Input>;
  pk_columns: User_Uploaded_Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "online" */
export type Online = {
  __typename?: 'online';
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

/** aggregated selection of "online" */
export type Online_Aggregate = {
  __typename?: 'online_aggregate';
  aggregate?: Maybe<Online_Aggregate_Fields>;
  nodes: Array<Online>;
};

/** aggregate fields of "online" */
export type Online_Aggregate_Fields = {
  __typename?: 'online_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Online_Max_Fields>;
  min?: Maybe<Online_Min_Fields>;
};


/** aggregate fields of "online" */
export type Online_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Online_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "online" */
export type Online_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Online_Max_Order_By>;
  min?: Maybe<Online_Min_Order_By>;
};

/** input type for inserting array relation for remote table "online" */
export type Online_Arr_Rel_Insert_Input = {
  data: Array<Online_Insert_Input>;
  on_conflict?: Maybe<Online_On_Conflict>;
};

/** Boolean expression to filter rows from the table "online". All fields are combined with a logical 'AND'. */
export type Online_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Online_Bool_Exp>>>;
  _not?: Maybe<Online_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Online_Bool_Exp>>>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "online" */
export enum Online_Constraint {
  /** unique or primary key constraint */
  OnlinePkey = 'online_pkey'
}

/** input type for inserting data into table "online" */
export type Online_Insert_Input = {
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Online_Max_Fields = {
  __typename?: 'online_max_fields';
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "online" */
export type Online_Max_Order_By = {
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Online_Min_Fields = {
  __typename?: 'online_min_fields';
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "online" */
export type Online_Min_Order_By = {
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "online" */
export type Online_Mutation_Response = {
  __typename?: 'online_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Online>;
};

/** columns and relationships of "online_now" */
export type Online_Now = {
  __typename?: 'online_now';
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  users?: Maybe<Users>;
};

/** aggregated selection of "online_now" */
export type Online_Now_Aggregate = {
  __typename?: 'online_now_aggregate';
  aggregate?: Maybe<Online_Now_Aggregate_Fields>;
  nodes: Array<Online_Now>;
};

/** aggregate fields of "online_now" */
export type Online_Now_Aggregate_Fields = {
  __typename?: 'online_now_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Online_Now_Max_Fields>;
  min?: Maybe<Online_Now_Min_Fields>;
};


/** aggregate fields of "online_now" */
export type Online_Now_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Online_Now_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "online_now" */
export type Online_Now_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Online_Now_Max_Order_By>;
  min?: Maybe<Online_Now_Min_Order_By>;
};

/** input type for inserting array relation for remote table "online_now" */
export type Online_Now_Arr_Rel_Insert_Input = {
  data: Array<Online_Now_Insert_Input>;
};

/** Boolean expression to filter rows from the table "online_now". All fields are combined with a logical 'AND'. */
export type Online_Now_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Online_Now_Bool_Exp>>>;
  _not?: Maybe<Online_Now_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Online_Now_Bool_Exp>>>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
};

/** input type for inserting data into table "online_now" */
export type Online_Now_Insert_Input = {
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  users?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Online_Now_Max_Fields = {
  __typename?: 'online_now_max_fields';
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "online_now" */
export type Online_Now_Max_Order_By = {
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Online_Now_Min_Fields = {
  __typename?: 'online_now_min_fields';
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "online_now" */
export type Online_Now_Min_Order_By = {
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "online_now" */
export type Online_Now_Mutation_Response = {
  __typename?: 'online_now_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Online_Now>;
};

/** input type for inserting object relation for remote table "online_now" */
export type Online_Now_Obj_Rel_Insert_Input = {
  data: Online_Now_Insert_Input;
};

/** ordering options when selecting data from "online_now" */
export type Online_Now_Order_By = {
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  users?: Maybe<Users_Order_By>;
};

/** select columns of table "online_now" */
export enum Online_Now_Select_Column {
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "online_now" */
export type Online_Now_Set_Input = {
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "online" */
export type Online_Obj_Rel_Insert_Input = {
  data: Online_Insert_Input;
  on_conflict?: Maybe<Online_On_Conflict>;
};

/** on conflict condition type for table "online" */
export type Online_On_Conflict = {
  constraint: Online_Constraint;
  update_columns: Array<Online_Update_Column>;
  where?: Maybe<Online_Bool_Exp>;
};

/** ordering options when selecting data from "online" */
export type Online_Order_By = {
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "online" */
export type Online_Pk_Columns_Input = {
  user_id: Scalars['String'];
};

/** select columns of table "online" */
export enum Online_Select_Column {
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "online" */
export type Online_Set_Input = {
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "online" */
export enum Online_Update_Column {
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "project_tech" */
export type Project_Tech = {
  __typename?: 'project_tech';
  created_by_user_id: Scalars['String'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['String'];
  tech_id: Scalars['uuid'];
  /** An object relationship */
  tech_tag: Tech_Tags;
};

/** aggregated selection of "project_tech" */
export type Project_Tech_Aggregate = {
  __typename?: 'project_tech_aggregate';
  aggregate?: Maybe<Project_Tech_Aggregate_Fields>;
  nodes: Array<Project_Tech>;
};

/** aggregate fields of "project_tech" */
export type Project_Tech_Aggregate_Fields = {
  __typename?: 'project_tech_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Project_Tech_Max_Fields>;
  min?: Maybe<Project_Tech_Min_Fields>;
};


/** aggregate fields of "project_tech" */
export type Project_Tech_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Tech_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_tech" */
export type Project_Tech_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Project_Tech_Max_Order_By>;
  min?: Maybe<Project_Tech_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_tech" */
export type Project_Tech_Arr_Rel_Insert_Input = {
  data: Array<Project_Tech_Insert_Input>;
  on_conflict?: Maybe<Project_Tech_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_tech". All fields are combined with a logical 'AND'. */
export type Project_Tech_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Project_Tech_Bool_Exp>>>;
  _not?: Maybe<Project_Tech_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Project_Tech_Bool_Exp>>>;
  created_by_user_id?: Maybe<String_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<String_Comparison_Exp>;
  tech_id?: Maybe<Uuid_Comparison_Exp>;
  tech_tag?: Maybe<Tech_Tags_Bool_Exp>;
};

/** unique or primary key constraints on table "project_tech" */
export enum Project_Tech_Constraint {
  /** unique or primary key constraint */
  ProjectTechPkey = 'project_tech_pkey'
}

/** input type for inserting data into table "project_tech" */
export type Project_Tech_Insert_Input = {
  created_by_user_id?: Maybe<Scalars['String']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['String']>;
  tech_id?: Maybe<Scalars['uuid']>;
  tech_tag?: Maybe<Tech_Tags_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Project_Tech_Max_Fields = {
  __typename?: 'project_tech_max_fields';
  created_by_user_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  tech_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_tech" */
export type Project_Tech_Max_Order_By = {
  created_by_user_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  tech_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Tech_Min_Fields = {
  __typename?: 'project_tech_min_fields';
  created_by_user_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  tech_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_tech" */
export type Project_Tech_Min_Order_By = {
  created_by_user_id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  tech_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_tech" */
export type Project_Tech_Mutation_Response = {
  __typename?: 'project_tech_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Project_Tech>;
};

/** input type for inserting object relation for remote table "project_tech" */
export type Project_Tech_Obj_Rel_Insert_Input = {
  data: Project_Tech_Insert_Input;
  on_conflict?: Maybe<Project_Tech_On_Conflict>;
};

/** on conflict condition type for table "project_tech" */
export type Project_Tech_On_Conflict = {
  constraint: Project_Tech_Constraint;
  update_columns: Array<Project_Tech_Update_Column>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};

/** ordering options when selecting data from "project_tech" */
export type Project_Tech_Order_By = {
  created_by_user_id?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  tech_id?: Maybe<Order_By>;
  tech_tag?: Maybe<Tech_Tags_Order_By>;
};

/** primary key columns input for table: "project_tech" */
export type Project_Tech_Pk_Columns_Input = {
  project_id: Scalars['String'];
  tech_id: Scalars['uuid'];
};

/** select columns of table "project_tech" */
export enum Project_Tech_Select_Column {
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TechId = 'tech_id'
}

/** input type for updating data in table "project_tech" */
export type Project_Tech_Set_Input = {
  created_by_user_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  tech_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "project_tech" */
export enum Project_Tech_Update_Column {
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TechId = 'tech_id'
}

/** columns and relationships of "project_users" */
export type Project_Users = {
  __typename?: 'project_users';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_shown: Scalars['Boolean'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['String'];
};

/** aggregated selection of "project_users" */
export type Project_Users_Aggregate = {
  __typename?: 'project_users_aggregate';
  aggregate?: Maybe<Project_Users_Aggregate_Fields>;
  nodes: Array<Project_Users>;
};

/** aggregate fields of "project_users" */
export type Project_Users_Aggregate_Fields = {
  __typename?: 'project_users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Project_Users_Max_Fields>;
  min?: Maybe<Project_Users_Min_Fields>;
};


/** aggregate fields of "project_users" */
export type Project_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_users" */
export type Project_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Project_Users_Max_Order_By>;
  min?: Maybe<Project_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_users" */
export type Project_Users_Arr_Rel_Insert_Input = {
  data: Array<Project_Users_Insert_Input>;
  on_conflict?: Maybe<Project_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_users". All fields are combined with a logical 'AND'. */
export type Project_Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Project_Users_Bool_Exp>>>;
  _not?: Maybe<Project_Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Project_Users_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_shown?: Maybe<Boolean_Comparison_Exp>;
  project?: Maybe<Projects_Bool_Exp>;
  project_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_users" */
export enum Project_Users_Constraint {
  /** unique or primary key constraint */
  ProjectUsersPkey = 'project_users_pkey'
}

/** input type for inserting data into table "project_users" */
export type Project_Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_shown?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Users_Max_Fields = {
  __typename?: 'project_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_users" */
export type Project_Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Users_Min_Fields = {
  __typename?: 'project_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_users" */
export type Project_Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "project_users" */
export type Project_Users_Mutation_Response = {
  __typename?: 'project_users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Project_Users>;
};

/** input type for inserting object relation for remote table "project_users" */
export type Project_Users_Obj_Rel_Insert_Input = {
  data: Project_Users_Insert_Input;
  on_conflict?: Maybe<Project_Users_On_Conflict>;
};

/** on conflict condition type for table "project_users" */
export type Project_Users_On_Conflict = {
  constraint: Project_Users_Constraint;
  update_columns: Array<Project_Users_Update_Column>;
  where?: Maybe<Project_Users_Bool_Exp>;
};

/** ordering options when selecting data from "project_users" */
export type Project_Users_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_shown?: Maybe<Order_By>;
  project?: Maybe<Projects_Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "project_users" */
export type Project_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_users" */
export enum Project_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "project_users" */
export type Project_Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_shown?: Maybe<Scalars['Boolean']>;
  project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "project_users" */
export enum Project_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsShown = 'is_shown',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  /** An object relationship */
  account?: Maybe<Accounts>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['String'];
  is_hidden?: Maybe<Scalars['Boolean']>;
  project_alias?: Maybe<Scalars['String']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name: Scalars['String'];
  /** An array relationship */
  project_teches: Array<Project_Tech>;
  /** An aggregated array relationship */
  project_teches_aggregate: Project_Tech_Aggregate;
  /** An array relationship */
  project_users: Array<Project_Users>;
  /** An aggregated array relationship */
  project_users_aggregate: Project_Users_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_deselected_projects: Array<User_Deselected_Projects>;
  /** An aggregated array relationship */
  user_deselected_projects_aggregate: User_Deselected_Projects_Aggregate;
};


/** columns and relationships of "projects" */
export type ProjectsProject_TechesArgs = {
  distinct_on?: Maybe<Array<Project_Tech_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tech_Order_By>>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Teches_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Tech_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tech_Order_By>>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_UsersArgs = {
  distinct_on?: Maybe<Array<Project_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Users_Order_By>>;
  where?: Maybe<Project_Users_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsProject_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Users_Order_By>>;
  where?: Maybe<Project_Users_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsUser_Deselected_ProjectsArgs = {
  distinct_on?: Maybe<Array<User_Deselected_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Deselected_Projects_Order_By>>;
  where?: Maybe<User_Deselected_Projects_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsUser_Deselected_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Deselected_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Deselected_Projects_Order_By>>;
  where?: Maybe<User_Deselected_Projects_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "projects" */
export type Projects_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Projects_Max_Order_By>;
  min?: Maybe<Projects_Min_Order_By>;
};

/** input type for inserting array relation for remote table "projects" */
export type Projects_Arr_Rel_Insert_Input = {
  data: Array<Projects_Insert_Input>;
  on_conflict?: Maybe<Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Projects_Bool_Exp>>>;
  _not?: Maybe<Projects_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Projects_Bool_Exp>>>;
  account?: Maybe<Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_hidden?: Maybe<Boolean_Comparison_Exp>;
  project_alias?: Maybe<String_Comparison_Exp>;
  project_description?: Maybe<String_Comparison_Exp>;
  project_location?: Maybe<String_Comparison_Exp>;
  project_name?: Maybe<String_Comparison_Exp>;
  project_teches?: Maybe<Project_Tech_Bool_Exp>;
  project_users?: Maybe<Project_Users_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_deselected_projects?: Maybe<User_Deselected_Projects_Bool_Exp>;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint */
  ProjectsPkey = 'projects_pkey'
}

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  account?: Maybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  project_alias?: Maybe<Scalars['String']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  project_teches?: Maybe<Project_Tech_Arr_Rel_Insert_Input>;
  project_users?: Maybe<Project_Users_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_deselected_projects?: Maybe<User_Deselected_Projects_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  project_alias?: Maybe<Scalars['String']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "projects" */
export type Projects_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_alias?: Maybe<Order_By>;
  project_description?: Maybe<Order_By>;
  project_location?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  project_alias?: Maybe<Scalars['String']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "projects" */
export type Projects_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_alias?: Maybe<Order_By>;
  project_description?: Maybe<Order_By>;
  project_location?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  __typename?: 'projects_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  data: Projects_Insert_Input;
  on_conflict?: Maybe<Projects_On_Conflict>;
};

/** on conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  constraint: Projects_Constraint;
  update_columns: Array<Projects_Update_Column>;
  where?: Maybe<Projects_Bool_Exp>;
};

/** ordering options when selecting data from "projects" */
export type Projects_Order_By = {
  account?: Maybe<Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_hidden?: Maybe<Order_By>;
  project_alias?: Maybe<Order_By>;
  project_description?: Maybe<Order_By>;
  project_location?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  project_teches_aggregate?: Maybe<Project_Tech_Aggregate_Order_By>;
  project_users_aggregate?: Maybe<Project_Users_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_deselected_projects_aggregate?: Maybe<User_Deselected_Projects_Aggregate_Order_By>;
};

/** primary key columns input for table: "projects" */
export type Projects_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** columns and relationships of "projects_public" */
export type Projects_Public = {
  __typename?: 'projects_public';
  /** An object relationship */
  account?: Maybe<Accounts>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "projects_public" */
export type Projects_Public_Aggregate = {
  __typename?: 'projects_public_aggregate';
  aggregate?: Maybe<Projects_Public_Aggregate_Fields>;
  nodes: Array<Projects_Public>;
};

/** aggregate fields of "projects_public" */
export type Projects_Public_Aggregate_Fields = {
  __typename?: 'projects_public_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Projects_Public_Max_Fields>;
  min?: Maybe<Projects_Public_Min_Fields>;
};


/** aggregate fields of "projects_public" */
export type Projects_Public_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Projects_Public_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "projects_public" */
export type Projects_Public_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Projects_Public_Max_Order_By>;
  min?: Maybe<Projects_Public_Min_Order_By>;
};

/** input type for inserting array relation for remote table "projects_public" */
export type Projects_Public_Arr_Rel_Insert_Input = {
  data: Array<Projects_Public_Insert_Input>;
};

/** Boolean expression to filter rows from the table "projects_public". All fields are combined with a logical 'AND'. */
export type Projects_Public_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Projects_Public_Bool_Exp>>>;
  _not?: Maybe<Projects_Public_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Projects_Public_Bool_Exp>>>;
  account?: Maybe<Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_hidden?: Maybe<Boolean_Comparison_Exp>;
  project_description?: Maybe<String_Comparison_Exp>;
  project_location?: Maybe<String_Comparison_Exp>;
  project_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "projects_public" */
export type Projects_Public_Insert_Input = {
  account?: Maybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Projects_Public_Max_Fields = {
  __typename?: 'projects_public_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "projects_public" */
export type Projects_Public_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_description?: Maybe<Order_By>;
  project_location?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Public_Min_Fields = {
  __typename?: 'projects_public_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "projects_public" */
export type Projects_Public_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_description?: Maybe<Order_By>;
  project_location?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "projects_public" */
export type Projects_Public_Mutation_Response = {
  __typename?: 'projects_public_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Projects_Public>;
};

/** input type for inserting object relation for remote table "projects_public" */
export type Projects_Public_Obj_Rel_Insert_Input = {
  data: Projects_Public_Insert_Input;
};

/** ordering options when selecting data from "projects_public" */
export type Projects_Public_Order_By = {
  account?: Maybe<Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_hidden?: Maybe<Order_By>;
  project_description?: Maybe<Order_By>;
  project_location?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "projects_public" */
export enum Projects_Public_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'is_hidden',
  /** column name */
  ProjectDescription = 'project_description',
  /** column name */
  ProjectLocation = 'project_location',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "projects_public" */
export type Projects_Public_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'is_hidden',
  /** column name */
  ProjectAlias = 'project_alias',
  /** column name */
  ProjectDescription = 'project_description',
  /** column name */
  ProjectLocation = 'project_location',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  project_alias?: Maybe<Scalars['String']>;
  project_description?: Maybe<Scalars['String']>;
  project_location?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsHidden = 'is_hidden',
  /** column name */
  ProjectAlias = 'project_alias',
  /** column name */
  ProjectDescription = 'project_description',
  /** column name */
  ProjectLocation = 'project_location',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "alert_acknowledge" */
  alert_acknowledge: Array<Alert_Acknowledge>;
  /** fetch aggregated fields from the table: "alert_acknowledge" */
  alert_acknowledge_aggregate: Alert_Acknowledge_Aggregate;
  /** fetch data from the table: "alert_acknowledge" using primary key columns */
  alert_acknowledge_by_pk?: Maybe<Alert_Acknowledge>;
  /** fetch data from the table: "alerts" */
  alerts: Array<Alerts>;
  /** fetch aggregated fields from the table: "alerts" */
  alerts_aggregate: Alerts_Aggregate;
  /** fetch data from the table: "alerts" using primary key columns */
  alerts_by_pk?: Maybe<Alerts>;
  /** fetch data from the table: "app" */
  app: Array<App>;
  /** fetch aggregated fields from the table: "app" */
  app_aggregate: App_Aggregate;
  /** fetch data from the table: "app" using primary key columns */
  app_by_pk?: Maybe<App>;
  /** fetch data from the table: "arm_templates" */
  arm_templates: Array<Arm_Templates>;
  /** fetch aggregated fields from the table: "arm_templates" */
  arm_templates_aggregate: Arm_Templates_Aggregate;
  /** fetch data from the table: "arm_templates" using primary key columns */
  arm_templates_by_pk?: Maybe<Arm_Templates>;
  /** fetch data from the table: "badge_awards" */
  badge_awards: Array<Badge_Awards>;
  /** fetch aggregated fields from the table: "badge_awards" */
  badge_awards_aggregate: Badge_Awards_Aggregate;
  /** fetch data from the table: "badge_awards" using primary key columns */
  badge_awards_by_pk?: Maybe<Badge_Awards>;
  /** fetch data from the table: "clicks" */
  clicks: Array<Clicks>;
  /** fetch aggregated fields from the table: "clicks" */
  clicks_aggregate: Clicks_Aggregate;
  /** fetch data from the table: "clicks" using primary key columns */
  clicks_by_pk?: Maybe<Clicks>;
  /** fetch data from the table: "extended_profile" */
  extended_profile: Array<Extended_Profile>;
  /** fetch aggregated fields from the table: "extended_profile" */
  extended_profile_aggregate: Extended_Profile_Aggregate;
  /** fetch data from the table: "extended_profile" using primary key columns */
  extended_profile_by_pk?: Maybe<Extended_Profile>;
  /** fetch data from the table: "feedback" */
  feedback: Array<Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  feedback_by_pk?: Maybe<Feedback>;
  /** perform the action: "getAllChats" */
  getAllChats?: Maybe<SampleOutput>;
  /** fetch data from the table: "online" */
  online: Array<Online>;
  /** fetch aggregated fields from the table: "online" */
  online_aggregate: Online_Aggregate;
  /** fetch data from the table: "online" using primary key columns */
  online_by_pk?: Maybe<Online>;
  /** fetch data from the table: "online_now" */
  online_now: Array<Online_Now>;
  /** fetch aggregated fields from the table: "online_now" */
  online_now_aggregate: Online_Now_Aggregate;
  /** perform the action: "profile" */
  profile?: Maybe<GraphApiProfile>;
  /** fetch data from the table: "project_tech" */
  project_tech: Array<Project_Tech>;
  /** fetch aggregated fields from the table: "project_tech" */
  project_tech_aggregate: Project_Tech_Aggregate;
  /** fetch data from the table: "project_tech" using primary key columns */
  project_tech_by_pk?: Maybe<Project_Tech>;
  /** fetch data from the table: "project_users" */
  project_users: Array<Project_Users>;
  /** fetch aggregated fields from the table: "project_users" */
  project_users_aggregate: Project_Users_Aggregate;
  /** fetch data from the table: "project_users" using primary key columns */
  project_users_by_pk?: Maybe<Project_Users>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "projects_public" */
  projects_public: Array<Projects_Public>;
  /** fetch aggregated fields from the table: "projects_public" */
  projects_public_aggregate: Projects_Public_Aggregate;
  /** perform the action: "queryName" */
  queryName?: Maybe<QueryOutput>;
  /** perform the action: "search" */
  search?: Maybe<SearchQuery>;
  /** execute function "search_projects" which returns "projects" */
  search_projects: Array<Projects>;
  /** execute function "search_projects" and query aggregates on result of table type "projects" */
  search_projects_aggregate: Projects_Aggregate;
  /** execute function "search_users" which returns "users" */
  search_users: Array<Users>;
  /** execute function "search_users" and query aggregates on result of table type "users" */
  search_users_aggregate: Users_Aggregate;
  /** fetch data from the table: "sound_requests" */
  sound_requests: Array<Sound_Requests>;
  /** fetch aggregated fields from the table: "sound_requests" */
  sound_requests_aggregate: Sound_Requests_Aggregate;
  /** fetch data from the table: "sound_requests" using primary key columns */
  sound_requests_by_pk?: Maybe<Sound_Requests>;
  /** fetch data from the table: "sound_requests_now" */
  sound_requests_now: Array<Sound_Requests_Now>;
  /** fetch aggregated fields from the table: "sound_requests_now" */
  sound_requests_now_aggregate: Sound_Requests_Now_Aggregate;
  /** fetch data from the table: "talents" */
  talents: Array<Talents>;
  /** fetch aggregated fields from the table: "talents" */
  talents_aggregate: Talents_Aggregate;
  /** fetch data from the table: "talents" using primary key columns */
  talents_by_pk?: Maybe<Talents>;
  /** fetch data from the table: "teams_context" */
  teams_context: Array<Teams_Context>;
  /** fetch aggregated fields from the table: "teams_context" */
  teams_context_aggregate: Teams_Context_Aggregate;
  /** fetch data from the table: "teams_context" using primary key columns */
  teams_context_by_pk?: Maybe<Teams_Context>;
  /** fetch data from the table: "teams_sessions" */
  teams_sessions: Array<Teams_Sessions>;
  /** fetch aggregated fields from the table: "teams_sessions" */
  teams_sessions_aggregate: Teams_Sessions_Aggregate;
  /** fetch data from the table: "teams_sessions" using primary key columns */
  teams_sessions_by_pk?: Maybe<Teams_Sessions>;
  /** fetch data from the table: "tech_tags" */
  tech_tags: Array<Tech_Tags>;
  /** fetch aggregated fields from the table: "tech_tags" */
  tech_tags_aggregate: Tech_Tags_Aggregate;
  /** fetch data from the table: "tech_tags" using primary key columns */
  tech_tags_by_pk?: Maybe<Tech_Tags>;
  /** fetch data from the table: "tenants" */
  tenants: Array<Tenants>;
  /** fetch aggregated fields from the table: "tenants" */
  tenants_aggregate: Tenants_Aggregate;
  /** fetch data from the table: "tenants_allowed" */
  tenants_allowed: Array<Tenants_Allowed>;
  /** fetch aggregated fields from the table: "tenants_allowed" */
  tenants_allowed_aggregate: Tenants_Allowed_Aggregate;
  /** fetch data from the table: "tenants_allowed" using primary key columns */
  tenants_allowed_by_pk?: Maybe<Tenants_Allowed>;
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>;
  /** perform the action: "termGroups" */
  termGroups: Array<TermGroup>;
  /** perform the action: "terms" */
  terms?: Maybe<Array<Maybe<Terms>>>;
  /** fetch data from the table: "user_achievements" */
  user_achievements: Array<User_Achievements>;
  /** fetch aggregated fields from the table: "user_achievements" */
  user_achievements_aggregate: User_Achievements_Aggregate;
  /** fetch data from the table: "user_achievements" using primary key columns */
  user_achievements_by_pk?: Maybe<User_Achievements>;
  /** fetch data from the table: "user_deselected_projects" */
  user_deselected_projects: Array<User_Deselected_Projects>;
  /** fetch aggregated fields from the table: "user_deselected_projects" */
  user_deselected_projects_aggregate: User_Deselected_Projects_Aggregate;
  /** fetch data from the table: "user_deselected_projects" using primary key columns */
  user_deselected_projects_by_pk?: Maybe<User_Deselected_Projects>;
  /** fetch data from the table: "user_education" */
  user_education: Array<User_Education>;
  /** fetch aggregated fields from the table: "user_education" */
  user_education_aggregate: User_Education_Aggregate;
  /** fetch data from the table: "user_education" using primary key columns */
  user_education_by_pk?: Maybe<User_Education>;
  /** fetch data from the table: "user_experience" */
  user_experience: Array<User_Experience>;
  /** fetch aggregated fields from the table: "user_experience" */
  user_experience_aggregate: User_Experience_Aggregate;
  /** fetch data from the table: "user_experience" using primary key columns */
  user_experience_by_pk?: Maybe<User_Experience>;
  /** fetch data from the table: "user_images" */
  user_images: Array<User_Images>;
  /** fetch aggregated fields from the table: "user_images" */
  user_images_aggregate: User_Images_Aggregate;
  /** fetch data from the table: "user_images" using primary key columns */
  user_images_by_pk?: Maybe<User_Images>;
  /** fetch data from the table: "user_quote" */
  user_quote: Array<User_Quote>;
  /** fetch aggregated fields from the table: "user_quote" */
  user_quote_aggregate: User_Quote_Aggregate;
  /** fetch data from the table: "user_quote" using primary key columns */
  user_quote_by_pk?: Maybe<User_Quote>;
  /** fetch data from the table: "user_uploaded_images" */
  user_uploaded_images: Array<User_Uploaded_Images>;
  /** fetch aggregated fields from the table: "user_uploaded_images" */
  user_uploaded_images_aggregate: User_Uploaded_Images_Aggregate;
  /** fetch data from the table: "user_uploaded_images" using primary key columns */
  user_uploaded_images_by_pk?: Maybe<User_Uploaded_Images>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** query root */
export type Query_RootAccountsArgs = {
  distinct_on?: Maybe<Array<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


/** query root */
export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


/** query root */
export type Query_RootAccounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAlert_AcknowledgeArgs = {
  distinct_on?: Maybe<Array<Alert_Acknowledge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alert_Acknowledge_Order_By>>;
  where?: Maybe<Alert_Acknowledge_Bool_Exp>;
};


/** query root */
export type Query_RootAlert_Acknowledge_AggregateArgs = {
  distinct_on?: Maybe<Array<Alert_Acknowledge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alert_Acknowledge_Order_By>>;
  where?: Maybe<Alert_Acknowledge_Bool_Exp>;
};


/** query root */
export type Query_RootAlert_Acknowledge_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAlertsArgs = {
  distinct_on?: Maybe<Array<Alerts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alerts_Order_By>>;
  where?: Maybe<Alerts_Bool_Exp>;
};


/** query root */
export type Query_RootAlerts_AggregateArgs = {
  distinct_on?: Maybe<Array<Alerts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alerts_Order_By>>;
  where?: Maybe<Alerts_Bool_Exp>;
};


/** query root */
export type Query_RootAlerts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAppArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


/** query root */
export type Query_RootApp_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


/** query root */
export type Query_RootApp_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootArm_TemplatesArgs = {
  distinct_on?: Maybe<Array<Arm_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arm_Templates_Order_By>>;
  where?: Maybe<Arm_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootArm_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Arm_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arm_Templates_Order_By>>;
  where?: Maybe<Arm_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootArm_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootBadge_AwardsArgs = {
  distinct_on?: Maybe<Array<Badge_Awards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Awards_Order_By>>;
  where?: Maybe<Badge_Awards_Bool_Exp>;
};


/** query root */
export type Query_RootBadge_Awards_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Awards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Awards_Order_By>>;
  where?: Maybe<Badge_Awards_Bool_Exp>;
};


/** query root */
export type Query_RootBadge_Awards_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootClicksArgs = {
  distinct_on?: Maybe<Array<Clicks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clicks_Order_By>>;
  where?: Maybe<Clicks_Bool_Exp>;
};


/** query root */
export type Query_RootClicks_AggregateArgs = {
  distinct_on?: Maybe<Array<Clicks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clicks_Order_By>>;
  where?: Maybe<Clicks_Bool_Exp>;
};


/** query root */
export type Query_RootClicks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootExtended_ProfileArgs = {
  distinct_on?: Maybe<Array<Extended_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Extended_Profile_Order_By>>;
  where?: Maybe<Extended_Profile_Bool_Exp>;
};


/** query root */
export type Query_RootExtended_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Extended_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Extended_Profile_Order_By>>;
  where?: Maybe<Extended_Profile_Bool_Exp>;
};


/** query root */
export type Query_RootExtended_Profile_By_PkArgs = {
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** query root */
export type Query_RootFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** query root */
export type Query_RootFeedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootGetAllChatsArgs = {
  arg1: SampleInput;
};


/** query root */
export type Query_RootOnlineArgs = {
  distinct_on?: Maybe<Array<Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Order_By>>;
  where?: Maybe<Online_Bool_Exp>;
};


/** query root */
export type Query_RootOnline_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Order_By>>;
  where?: Maybe<Online_Bool_Exp>;
};


/** query root */
export type Query_RootOnline_By_PkArgs = {
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootOnline_NowArgs = {
  distinct_on?: Maybe<Array<Online_Now_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Now_Order_By>>;
  where?: Maybe<Online_Now_Bool_Exp>;
};


/** query root */
export type Query_RootOnline_Now_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Now_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Now_Order_By>>;
  where?: Maybe<Online_Now_Bool_Exp>;
};


/** query root */
export type Query_RootProject_TechArgs = {
  distinct_on?: Maybe<Array<Project_Tech_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tech_Order_By>>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};


/** query root */
export type Query_RootProject_Tech_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Tech_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tech_Order_By>>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};


/** query root */
export type Query_RootProject_Tech_By_PkArgs = {
  project_id: Scalars['String'];
  tech_id: Scalars['uuid'];
};


/** query root */
export type Query_RootProject_UsersArgs = {
  distinct_on?: Maybe<Array<Project_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Users_Order_By>>;
  where?: Maybe<Project_Users_Bool_Exp>;
};


/** query root */
export type Query_RootProject_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Users_Order_By>>;
  where?: Maybe<Project_Users_Bool_Exp>;
};


/** query root */
export type Query_RootProject_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** query root */
export type Query_RootProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** query root */
export type Query_RootProjects_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootProjects_PublicArgs = {
  distinct_on?: Maybe<Array<Projects_Public_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Public_Order_By>>;
  where?: Maybe<Projects_Public_Bool_Exp>;
};


/** query root */
export type Query_RootProjects_Public_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Public_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Public_Order_By>>;
  where?: Maybe<Projects_Public_Bool_Exp>;
};


/** query root */
export type Query_RootSearchArgs = {
  fullSpSiteUrl: Scalars['String'];
  query: Scalars['String'];
};


/** query root */
export type Query_RootSearch_ProjectsArgs = {
  args: Search_Projects_Args;
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Projects_AggregateArgs = {
  args: Search_Projects_Args;
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootSound_RequestsArgs = {
  distinct_on?: Maybe<Array<Sound_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sound_Requests_Order_By>>;
  where?: Maybe<Sound_Requests_Bool_Exp>;
};


/** query root */
export type Query_RootSound_Requests_AggregateArgs = {
  distinct_on?: Maybe<Array<Sound_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sound_Requests_Order_By>>;
  where?: Maybe<Sound_Requests_Bool_Exp>;
};


/** query root */
export type Query_RootSound_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSound_Requests_NowArgs = {
  distinct_on?: Maybe<Array<Sound_Requests_Now_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sound_Requests_Now_Order_By>>;
  where?: Maybe<Sound_Requests_Now_Bool_Exp>;
};


/** query root */
export type Query_RootSound_Requests_Now_AggregateArgs = {
  distinct_on?: Maybe<Array<Sound_Requests_Now_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sound_Requests_Now_Order_By>>;
  where?: Maybe<Sound_Requests_Now_Bool_Exp>;
};


/** query root */
export type Query_RootTalentsArgs = {
  distinct_on?: Maybe<Array<Talents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Talents_Order_By>>;
  where?: Maybe<Talents_Bool_Exp>;
};


/** query root */
export type Query_RootTalents_AggregateArgs = {
  distinct_on?: Maybe<Array<Talents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Talents_Order_By>>;
  where?: Maybe<Talents_Bool_Exp>;
};


/** query root */
export type Query_RootTalents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootTeams_ContextArgs = {
  distinct_on?: Maybe<Array<Teams_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Context_Order_By>>;
  where?: Maybe<Teams_Context_Bool_Exp>;
};


/** query root */
export type Query_RootTeams_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<Teams_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Context_Order_By>>;
  where?: Maybe<Teams_Context_Bool_Exp>;
};


/** query root */
export type Query_RootTeams_Context_By_PkArgs = {
  session_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootTeams_SessionsArgs = {
  distinct_on?: Maybe<Array<Teams_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Sessions_Order_By>>;
  where?: Maybe<Teams_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootTeams_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Teams_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Sessions_Order_By>>;
  where?: Maybe<Teams_Sessions_Bool_Exp>;
};


/** query root */
export type Query_RootTeams_Sessions_By_PkArgs = {
  id: Scalars['String'];
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootTech_TagsArgs = {
  distinct_on?: Maybe<Array<Tech_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tech_Tags_Order_By>>;
  where?: Maybe<Tech_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootTech_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Tech_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tech_Tags_Order_By>>;
  where?: Maybe<Tech_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootTech_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


/** query root */
export type Query_RootTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


/** query root */
export type Query_RootTenants_AllowedArgs = {
  distinct_on?: Maybe<Array<Tenants_Allowed_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Allowed_Order_By>>;
  where?: Maybe<Tenants_Allowed_Bool_Exp>;
};


/** query root */
export type Query_RootTenants_Allowed_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Allowed_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Allowed_Order_By>>;
  where?: Maybe<Tenants_Allowed_Bool_Exp>;
};


/** query root */
export type Query_RootTenants_Allowed_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootTenants_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootUser_AchievementsArgs = {
  distinct_on?: Maybe<Array<User_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Achievements_Order_By>>;
  where?: Maybe<User_Achievements_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Achievements_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Achievements_Order_By>>;
  where?: Maybe<User_Achievements_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Achievements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUser_Deselected_ProjectsArgs = {
  distinct_on?: Maybe<Array<User_Deselected_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Deselected_Projects_Order_By>>;
  where?: Maybe<User_Deselected_Projects_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Deselected_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Deselected_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Deselected_Projects_Order_By>>;
  where?: Maybe<User_Deselected_Projects_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Deselected_Projects_By_PkArgs = {
  project_id: Scalars['String'];
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootUser_EducationArgs = {
  distinct_on?: Maybe<Array<User_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Education_Order_By>>;
  where?: Maybe<User_Education_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Education_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Education_Order_By>>;
  where?: Maybe<User_Education_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Education_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUser_ExperienceArgs = {
  distinct_on?: Maybe<Array<User_Experience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Experience_Order_By>>;
  where?: Maybe<User_Experience_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Experience_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Experience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Experience_Order_By>>;
  where?: Maybe<User_Experience_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Experience_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUser_ImagesArgs = {
  distinct_on?: Maybe<Array<User_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Images_Order_By>>;
  where?: Maybe<User_Images_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Images_Order_By>>;
  where?: Maybe<User_Images_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Images_By_PkArgs = {
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootUser_QuoteArgs = {
  distinct_on?: Maybe<Array<User_Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Quote_Order_By>>;
  where?: Maybe<User_Quote_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Quote_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Quote_Order_By>>;
  where?: Maybe<User_Quote_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Quote_By_PkArgs = {
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootUser_Uploaded_ImagesArgs = {
  distinct_on?: Maybe<Array<User_Uploaded_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Uploaded_Images_Order_By>>;
  where?: Maybe<User_Uploaded_Images_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Uploaded_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Uploaded_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Uploaded_Images_Order_By>>;
  where?: Maybe<User_Uploaded_Images_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Uploaded_Images_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

export type QueryOutput = {
  __typename?: 'QueryOutput';
  one: Scalars['String'];
  two: Scalars['String'];
};

export type SampleInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  data?: Maybe<Scalars['jsonb']>;
};

export type SampleOutput2 = {
  __typename?: 'SampleOutput2';
  one: Scalars['String'];
  two: Scalars['String'];
};

export type Search_Projects_Args = {
  search?: Maybe<Scalars['String']>;
};

export type Search_Users_Args = {
  search?: Maybe<Scalars['String']>;
};

export type SearchQuery = {
  __typename?: 'SearchQuery';
  result?: Maybe<Scalars['String']>;
};

export type SearchQueryResult = {
  __typename?: 'SearchQueryResult';
  result?: Maybe<Scalars['String']>;
};

/** columns and relationships of "sound_requests" */
export type Sound_Requests = {
  __typename?: 'sound_requests';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  sound_name: Scalars['String'];
  user_id: Scalars['String'];
};

/** aggregated selection of "sound_requests" */
export type Sound_Requests_Aggregate = {
  __typename?: 'sound_requests_aggregate';
  aggregate?: Maybe<Sound_Requests_Aggregate_Fields>;
  nodes: Array<Sound_Requests>;
};

/** aggregate fields of "sound_requests" */
export type Sound_Requests_Aggregate_Fields = {
  __typename?: 'sound_requests_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sound_Requests_Max_Fields>;
  min?: Maybe<Sound_Requests_Min_Fields>;
};


/** aggregate fields of "sound_requests" */
export type Sound_Requests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sound_Requests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sound_requests" */
export type Sound_Requests_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sound_Requests_Max_Order_By>;
  min?: Maybe<Sound_Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sound_requests" */
export type Sound_Requests_Arr_Rel_Insert_Input = {
  data: Array<Sound_Requests_Insert_Input>;
  on_conflict?: Maybe<Sound_Requests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sound_requests". All fields are combined with a logical 'AND'. */
export type Sound_Requests_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Sound_Requests_Bool_Exp>>>;
  _not?: Maybe<Sound_Requests_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Sound_Requests_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  sound_name?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sound_requests" */
export enum Sound_Requests_Constraint {
  /** unique or primary key constraint */
  SoundRequestsPkey = 'sound_requests_pkey'
}

/** input type for inserting data into table "sound_requests" */
export type Sound_Requests_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sound_Requests_Max_Fields = {
  __typename?: 'sound_requests_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sound_requests" */
export type Sound_Requests_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sound_name?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sound_Requests_Min_Fields = {
  __typename?: 'sound_requests_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sound_requests" */
export type Sound_Requests_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sound_name?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "sound_requests" */
export type Sound_Requests_Mutation_Response = {
  __typename?: 'sound_requests_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Sound_Requests>;
};

/** columns and relationships of "sound_requests_now" */
export type Sound_Requests_Now = {
  __typename?: 'sound_requests_now';
  created_at?: Maybe<Scalars['timestamptz']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "sound_requests_now" */
export type Sound_Requests_Now_Aggregate = {
  __typename?: 'sound_requests_now_aggregate';
  aggregate?: Maybe<Sound_Requests_Now_Aggregate_Fields>;
  nodes: Array<Sound_Requests_Now>;
};

/** aggregate fields of "sound_requests_now" */
export type Sound_Requests_Now_Aggregate_Fields = {
  __typename?: 'sound_requests_now_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sound_Requests_Now_Max_Fields>;
  min?: Maybe<Sound_Requests_Now_Min_Fields>;
};


/** aggregate fields of "sound_requests_now" */
export type Sound_Requests_Now_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sound_Requests_Now_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sound_requests_now" */
export type Sound_Requests_Now_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sound_Requests_Now_Max_Order_By>;
  min?: Maybe<Sound_Requests_Now_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sound_requests_now" */
export type Sound_Requests_Now_Arr_Rel_Insert_Input = {
  data: Array<Sound_Requests_Now_Insert_Input>;
};

/** Boolean expression to filter rows from the table "sound_requests_now". All fields are combined with a logical 'AND'. */
export type Sound_Requests_Now_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Sound_Requests_Now_Bool_Exp>>>;
  _not?: Maybe<Sound_Requests_Now_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Sound_Requests_Now_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  sound_name?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "sound_requests_now" */
export type Sound_Requests_Now_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sound_Requests_Now_Max_Fields = {
  __typename?: 'sound_requests_now_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sound_requests_now" */
export type Sound_Requests_Now_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  sound_name?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sound_Requests_Now_Min_Fields = {
  __typename?: 'sound_requests_now_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sound_requests_now" */
export type Sound_Requests_Now_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  sound_name?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "sound_requests_now" */
export type Sound_Requests_Now_Mutation_Response = {
  __typename?: 'sound_requests_now_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Sound_Requests_Now>;
};

/** input type for inserting object relation for remote table "sound_requests_now" */
export type Sound_Requests_Now_Obj_Rel_Insert_Input = {
  data: Sound_Requests_Now_Insert_Input;
};

/** ordering options when selecting data from "sound_requests_now" */
export type Sound_Requests_Now_Order_By = {
  created_at?: Maybe<Order_By>;
  sound_name?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "sound_requests_now" */
export enum Sound_Requests_Now_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SoundName = 'sound_name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "sound_requests_now" */
export type Sound_Requests_Now_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "sound_requests" */
export type Sound_Requests_Obj_Rel_Insert_Input = {
  data: Sound_Requests_Insert_Input;
  on_conflict?: Maybe<Sound_Requests_On_Conflict>;
};

/** on conflict condition type for table "sound_requests" */
export type Sound_Requests_On_Conflict = {
  constraint: Sound_Requests_Constraint;
  update_columns: Array<Sound_Requests_Update_Column>;
  where?: Maybe<Sound_Requests_Bool_Exp>;
};

/** ordering options when selecting data from "sound_requests" */
export type Sound_Requests_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sound_name?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "sound_requests" */
export type Sound_Requests_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sound_requests" */
export enum Sound_Requests_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SoundName = 'sound_name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "sound_requests" */
export type Sound_Requests_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  sound_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "sound_requests" */
export enum Sound_Requests_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SoundName = 'sound_name',
  /** column name */
  UserId = 'user_id'
}

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "alert_acknowledge" */
  alert_acknowledge: Array<Alert_Acknowledge>;
  /** fetch aggregated fields from the table: "alert_acknowledge" */
  alert_acknowledge_aggregate: Alert_Acknowledge_Aggregate;
  /** fetch data from the table: "alert_acknowledge" using primary key columns */
  alert_acknowledge_by_pk?: Maybe<Alert_Acknowledge>;
  /** fetch data from the table: "alerts" */
  alerts: Array<Alerts>;
  /** fetch aggregated fields from the table: "alerts" */
  alerts_aggregate: Alerts_Aggregate;
  /** fetch data from the table: "alerts" using primary key columns */
  alerts_by_pk?: Maybe<Alerts>;
  /** fetch data from the table: "app" */
  app: Array<App>;
  /** fetch aggregated fields from the table: "app" */
  app_aggregate: App_Aggregate;
  /** fetch data from the table: "app" using primary key columns */
  app_by_pk?: Maybe<App>;
  /** fetch data from the table: "arm_templates" */
  arm_templates: Array<Arm_Templates>;
  /** fetch aggregated fields from the table: "arm_templates" */
  arm_templates_aggregate: Arm_Templates_Aggregate;
  /** fetch data from the table: "arm_templates" using primary key columns */
  arm_templates_by_pk?: Maybe<Arm_Templates>;
  /** fetch data from the table: "badge_awards" */
  badge_awards: Array<Badge_Awards>;
  /** fetch aggregated fields from the table: "badge_awards" */
  badge_awards_aggregate: Badge_Awards_Aggregate;
  /** fetch data from the table: "badge_awards" using primary key columns */
  badge_awards_by_pk?: Maybe<Badge_Awards>;
  /** fetch data from the table: "clicks" */
  clicks: Array<Clicks>;
  /** fetch aggregated fields from the table: "clicks" */
  clicks_aggregate: Clicks_Aggregate;
  /** fetch data from the table: "clicks" using primary key columns */
  clicks_by_pk?: Maybe<Clicks>;
  /** fetch data from the table: "extended_profile" */
  extended_profile: Array<Extended_Profile>;
  /** fetch aggregated fields from the table: "extended_profile" */
  extended_profile_aggregate: Extended_Profile_Aggregate;
  /** fetch data from the table: "extended_profile" using primary key columns */
  extended_profile_by_pk?: Maybe<Extended_Profile>;
  /** fetch data from the table: "feedback" */
  feedback: Array<Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  feedback_by_pk?: Maybe<Feedback>;
  /** perform the action: "getAllChats" */
  getAllChats?: Maybe<SampleOutput>;
  /** fetch data from the table: "online" */
  online: Array<Online>;
  /** fetch aggregated fields from the table: "online" */
  online_aggregate: Online_Aggregate;
  /** fetch data from the table: "online" using primary key columns */
  online_by_pk?: Maybe<Online>;
  /** fetch data from the table: "online_now" */
  online_now: Array<Online_Now>;
  /** fetch aggregated fields from the table: "online_now" */
  online_now_aggregate: Online_Now_Aggregate;
  /** perform the action: "profile" */
  profile?: Maybe<GraphApiProfile>;
  /** fetch data from the table: "project_tech" */
  project_tech: Array<Project_Tech>;
  /** fetch aggregated fields from the table: "project_tech" */
  project_tech_aggregate: Project_Tech_Aggregate;
  /** fetch data from the table: "project_tech" using primary key columns */
  project_tech_by_pk?: Maybe<Project_Tech>;
  /** fetch data from the table: "project_users" */
  project_users: Array<Project_Users>;
  /** fetch aggregated fields from the table: "project_users" */
  project_users_aggregate: Project_Users_Aggregate;
  /** fetch data from the table: "project_users" using primary key columns */
  project_users_by_pk?: Maybe<Project_Users>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "projects_public" */
  projects_public: Array<Projects_Public>;
  /** fetch aggregated fields from the table: "projects_public" */
  projects_public_aggregate: Projects_Public_Aggregate;
  /** perform the action: "queryName" */
  queryName?: Maybe<QueryOutput>;
  /** perform the action: "search" */
  search?: Maybe<SearchQuery>;
  /** execute function "search_projects" which returns "projects" */
  search_projects: Array<Projects>;
  /** execute function "search_projects" and query aggregates on result of table type "projects" */
  search_projects_aggregate: Projects_Aggregate;
  /** execute function "search_users" which returns "users" */
  search_users: Array<Users>;
  /** execute function "search_users" and query aggregates on result of table type "users" */
  search_users_aggregate: Users_Aggregate;
  /** fetch data from the table: "sound_requests" */
  sound_requests: Array<Sound_Requests>;
  /** fetch aggregated fields from the table: "sound_requests" */
  sound_requests_aggregate: Sound_Requests_Aggregate;
  /** fetch data from the table: "sound_requests" using primary key columns */
  sound_requests_by_pk?: Maybe<Sound_Requests>;
  /** fetch data from the table: "sound_requests_now" */
  sound_requests_now: Array<Sound_Requests_Now>;
  /** fetch aggregated fields from the table: "sound_requests_now" */
  sound_requests_now_aggregate: Sound_Requests_Now_Aggregate;
  /** fetch data from the table: "talents" */
  talents: Array<Talents>;
  /** fetch aggregated fields from the table: "talents" */
  talents_aggregate: Talents_Aggregate;
  /** fetch data from the table: "talents" using primary key columns */
  talents_by_pk?: Maybe<Talents>;
  /** fetch data from the table: "teams_context" */
  teams_context: Array<Teams_Context>;
  /** fetch aggregated fields from the table: "teams_context" */
  teams_context_aggregate: Teams_Context_Aggregate;
  /** fetch data from the table: "teams_context" using primary key columns */
  teams_context_by_pk?: Maybe<Teams_Context>;
  /** fetch data from the table: "teams_sessions" */
  teams_sessions: Array<Teams_Sessions>;
  /** fetch aggregated fields from the table: "teams_sessions" */
  teams_sessions_aggregate: Teams_Sessions_Aggregate;
  /** fetch data from the table: "teams_sessions" using primary key columns */
  teams_sessions_by_pk?: Maybe<Teams_Sessions>;
  /** fetch data from the table: "tech_tags" */
  tech_tags: Array<Tech_Tags>;
  /** fetch aggregated fields from the table: "tech_tags" */
  tech_tags_aggregate: Tech_Tags_Aggregate;
  /** fetch data from the table: "tech_tags" using primary key columns */
  tech_tags_by_pk?: Maybe<Tech_Tags>;
  /** fetch data from the table: "tenants" */
  tenants: Array<Tenants>;
  /** fetch aggregated fields from the table: "tenants" */
  tenants_aggregate: Tenants_Aggregate;
  /** fetch data from the table: "tenants_allowed" */
  tenants_allowed: Array<Tenants_Allowed>;
  /** fetch aggregated fields from the table: "tenants_allowed" */
  tenants_allowed_aggregate: Tenants_Allowed_Aggregate;
  /** fetch data from the table: "tenants_allowed" using primary key columns */
  tenants_allowed_by_pk?: Maybe<Tenants_Allowed>;
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>;
  /** perform the action: "termGroups" */
  termGroups: Array<TermGroup>;
  /** perform the action: "terms" */
  terms?: Maybe<Array<Maybe<Terms>>>;
  /** fetch data from the table: "user_achievements" */
  user_achievements: Array<User_Achievements>;
  /** fetch aggregated fields from the table: "user_achievements" */
  user_achievements_aggregate: User_Achievements_Aggregate;
  /** fetch data from the table: "user_achievements" using primary key columns */
  user_achievements_by_pk?: Maybe<User_Achievements>;
  /** fetch data from the table: "user_deselected_projects" */
  user_deselected_projects: Array<User_Deselected_Projects>;
  /** fetch aggregated fields from the table: "user_deselected_projects" */
  user_deselected_projects_aggregate: User_Deselected_Projects_Aggregate;
  /** fetch data from the table: "user_deselected_projects" using primary key columns */
  user_deselected_projects_by_pk?: Maybe<User_Deselected_Projects>;
  /** fetch data from the table: "user_education" */
  user_education: Array<User_Education>;
  /** fetch aggregated fields from the table: "user_education" */
  user_education_aggregate: User_Education_Aggregate;
  /** fetch data from the table: "user_education" using primary key columns */
  user_education_by_pk?: Maybe<User_Education>;
  /** fetch data from the table: "user_experience" */
  user_experience: Array<User_Experience>;
  /** fetch aggregated fields from the table: "user_experience" */
  user_experience_aggregate: User_Experience_Aggregate;
  /** fetch data from the table: "user_experience" using primary key columns */
  user_experience_by_pk?: Maybe<User_Experience>;
  /** fetch data from the table: "user_images" */
  user_images: Array<User_Images>;
  /** fetch aggregated fields from the table: "user_images" */
  user_images_aggregate: User_Images_Aggregate;
  /** fetch data from the table: "user_images" using primary key columns */
  user_images_by_pk?: Maybe<User_Images>;
  /** fetch data from the table: "user_quote" */
  user_quote: Array<User_Quote>;
  /** fetch aggregated fields from the table: "user_quote" */
  user_quote_aggregate: User_Quote_Aggregate;
  /** fetch data from the table: "user_quote" using primary key columns */
  user_quote_by_pk?: Maybe<User_Quote>;
  /** fetch data from the table: "user_uploaded_images" */
  user_uploaded_images: Array<User_Uploaded_Images>;
  /** fetch aggregated fields from the table: "user_uploaded_images" */
  user_uploaded_images_aggregate: User_Uploaded_Images_Aggregate;
  /** fetch data from the table: "user_uploaded_images" using primary key columns */
  user_uploaded_images_by_pk?: Maybe<User_Uploaded_Images>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** subscription root */
export type Subscription_RootAccountsArgs = {
  distinct_on?: Maybe<Array<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accounts_Order_By>>;
  where?: Maybe<Accounts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAccounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAlert_AcknowledgeArgs = {
  distinct_on?: Maybe<Array<Alert_Acknowledge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alert_Acknowledge_Order_By>>;
  where?: Maybe<Alert_Acknowledge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAlert_Acknowledge_AggregateArgs = {
  distinct_on?: Maybe<Array<Alert_Acknowledge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alert_Acknowledge_Order_By>>;
  where?: Maybe<Alert_Acknowledge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAlert_Acknowledge_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAlertsArgs = {
  distinct_on?: Maybe<Array<Alerts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alerts_Order_By>>;
  where?: Maybe<Alerts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAlerts_AggregateArgs = {
  distinct_on?: Maybe<Array<Alerts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alerts_Order_By>>;
  where?: Maybe<Alerts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAlerts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAppArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootApp_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootApp_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootArm_TemplatesArgs = {
  distinct_on?: Maybe<Array<Arm_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arm_Templates_Order_By>>;
  where?: Maybe<Arm_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootArm_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Arm_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Arm_Templates_Order_By>>;
  where?: Maybe<Arm_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootArm_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootBadge_AwardsArgs = {
  distinct_on?: Maybe<Array<Badge_Awards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Awards_Order_By>>;
  where?: Maybe<Badge_Awards_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBadge_Awards_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Awards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Awards_Order_By>>;
  where?: Maybe<Badge_Awards_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBadge_Awards_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootClicksArgs = {
  distinct_on?: Maybe<Array<Clicks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clicks_Order_By>>;
  where?: Maybe<Clicks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootClicks_AggregateArgs = {
  distinct_on?: Maybe<Array<Clicks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clicks_Order_By>>;
  where?: Maybe<Clicks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootClicks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootExtended_ProfileArgs = {
  distinct_on?: Maybe<Array<Extended_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Extended_Profile_Order_By>>;
  where?: Maybe<Extended_Profile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootExtended_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Extended_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Extended_Profile_Order_By>>;
  where?: Maybe<Extended_Profile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootExtended_Profile_By_PkArgs = {
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFeedback_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootGetAllChatsArgs = {
  arg1: SampleInput;
};


/** subscription root */
export type Subscription_RootOnlineArgs = {
  distinct_on?: Maybe<Array<Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Order_By>>;
  where?: Maybe<Online_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOnline_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Order_By>>;
  where?: Maybe<Online_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOnline_By_PkArgs = {
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootOnline_NowArgs = {
  distinct_on?: Maybe<Array<Online_Now_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Now_Order_By>>;
  where?: Maybe<Online_Now_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOnline_Now_AggregateArgs = {
  distinct_on?: Maybe<Array<Online_Now_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Online_Now_Order_By>>;
  where?: Maybe<Online_Now_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProject_TechArgs = {
  distinct_on?: Maybe<Array<Project_Tech_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tech_Order_By>>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProject_Tech_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Tech_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tech_Order_By>>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProject_Tech_By_PkArgs = {
  project_id: Scalars['String'];
  tech_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootProject_UsersArgs = {
  distinct_on?: Maybe<Array<Project_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Users_Order_By>>;
  where?: Maybe<Project_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProject_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Users_Order_By>>;
  where?: Maybe<Project_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProject_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootProjectsArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProjects_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootProjects_PublicArgs = {
  distinct_on?: Maybe<Array<Projects_Public_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Public_Order_By>>;
  where?: Maybe<Projects_Public_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProjects_Public_AggregateArgs = {
  distinct_on?: Maybe<Array<Projects_Public_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Public_Order_By>>;
  where?: Maybe<Projects_Public_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearchArgs = {
  fullSpSiteUrl: Scalars['String'];
  query: Scalars['String'];
};


/** subscription root */
export type Subscription_RootSearch_ProjectsArgs = {
  args: Search_Projects_Args;
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Projects_AggregateArgs = {
  args: Search_Projects_Args;
  distinct_on?: Maybe<Array<Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Projects_Order_By>>;
  where?: Maybe<Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_UsersArgs = {
  args: Search_Users_Args;
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args;
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSound_RequestsArgs = {
  distinct_on?: Maybe<Array<Sound_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sound_Requests_Order_By>>;
  where?: Maybe<Sound_Requests_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSound_Requests_AggregateArgs = {
  distinct_on?: Maybe<Array<Sound_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sound_Requests_Order_By>>;
  where?: Maybe<Sound_Requests_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSound_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSound_Requests_NowArgs = {
  distinct_on?: Maybe<Array<Sound_Requests_Now_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sound_Requests_Now_Order_By>>;
  where?: Maybe<Sound_Requests_Now_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSound_Requests_Now_AggregateArgs = {
  distinct_on?: Maybe<Array<Sound_Requests_Now_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sound_Requests_Now_Order_By>>;
  where?: Maybe<Sound_Requests_Now_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTalentsArgs = {
  distinct_on?: Maybe<Array<Talents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Talents_Order_By>>;
  where?: Maybe<Talents_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTalents_AggregateArgs = {
  distinct_on?: Maybe<Array<Talents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Talents_Order_By>>;
  where?: Maybe<Talents_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTalents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootTeams_ContextArgs = {
  distinct_on?: Maybe<Array<Teams_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Context_Order_By>>;
  where?: Maybe<Teams_Context_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTeams_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<Teams_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Context_Order_By>>;
  where?: Maybe<Teams_Context_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTeams_Context_By_PkArgs = {
  session_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootTeams_SessionsArgs = {
  distinct_on?: Maybe<Array<Teams_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Sessions_Order_By>>;
  where?: Maybe<Teams_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTeams_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Teams_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teams_Sessions_Order_By>>;
  where?: Maybe<Teams_Sessions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTeams_Sessions_By_PkArgs = {
  id: Scalars['String'];
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootTech_TagsArgs = {
  distinct_on?: Maybe<Array<Tech_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tech_Tags_Order_By>>;
  where?: Maybe<Tech_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTech_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Tech_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tech_Tags_Order_By>>;
  where?: Maybe<Tech_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTech_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Order_By>>;
  where?: Maybe<Tenants_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTenants_AllowedArgs = {
  distinct_on?: Maybe<Array<Tenants_Allowed_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Allowed_Order_By>>;
  where?: Maybe<Tenants_Allowed_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTenants_Allowed_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Allowed_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tenants_Allowed_Order_By>>;
  where?: Maybe<Tenants_Allowed_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTenants_Allowed_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootTenants_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUser_AchievementsArgs = {
  distinct_on?: Maybe<Array<User_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Achievements_Order_By>>;
  where?: Maybe<User_Achievements_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Achievements_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Achievements_Order_By>>;
  where?: Maybe<User_Achievements_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Achievements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUser_Deselected_ProjectsArgs = {
  distinct_on?: Maybe<Array<User_Deselected_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Deselected_Projects_Order_By>>;
  where?: Maybe<User_Deselected_Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Deselected_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Deselected_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Deselected_Projects_Order_By>>;
  where?: Maybe<User_Deselected_Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Deselected_Projects_By_PkArgs = {
  project_id: Scalars['String'];
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUser_EducationArgs = {
  distinct_on?: Maybe<Array<User_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Education_Order_By>>;
  where?: Maybe<User_Education_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Education_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Education_Order_By>>;
  where?: Maybe<User_Education_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Education_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUser_ExperienceArgs = {
  distinct_on?: Maybe<Array<User_Experience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Experience_Order_By>>;
  where?: Maybe<User_Experience_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Experience_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Experience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Experience_Order_By>>;
  where?: Maybe<User_Experience_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Experience_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUser_ImagesArgs = {
  distinct_on?: Maybe<Array<User_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Images_Order_By>>;
  where?: Maybe<User_Images_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Images_Order_By>>;
  where?: Maybe<User_Images_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Images_By_PkArgs = {
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUser_QuoteArgs = {
  distinct_on?: Maybe<Array<User_Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Quote_Order_By>>;
  where?: Maybe<User_Quote_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Quote_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Quote_Order_By>>;
  where?: Maybe<User_Quote_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Quote_By_PkArgs = {
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUser_Uploaded_ImagesArgs = {
  distinct_on?: Maybe<Array<User_Uploaded_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Uploaded_Images_Order_By>>;
  where?: Maybe<User_Uploaded_Images_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Uploaded_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Uploaded_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Uploaded_Images_Order_By>>;
  where?: Maybe<User_Uploaded_Images_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Uploaded_Images_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "talents" */
export type Talents = {
  __typename?: 'talents';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  image_base64?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

/** aggregated selection of "talents" */
export type Talents_Aggregate = {
  __typename?: 'talents_aggregate';
  aggregate?: Maybe<Talents_Aggregate_Fields>;
  nodes: Array<Talents>;
};

/** aggregate fields of "talents" */
export type Talents_Aggregate_Fields = {
  __typename?: 'talents_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Talents_Max_Fields>;
  min?: Maybe<Talents_Min_Fields>;
};


/** aggregate fields of "talents" */
export type Talents_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Talents_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "talents" */
export type Talents_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Talents_Max_Order_By>;
  min?: Maybe<Talents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "talents" */
export type Talents_Arr_Rel_Insert_Input = {
  data: Array<Talents_Insert_Input>;
  on_conflict?: Maybe<Talents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "talents". All fields are combined with a logical 'AND'. */
export type Talents_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Talents_Bool_Exp>>>;
  _not?: Maybe<Talents_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Talents_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image_base64?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "talents" */
export enum Talents_Constraint {
  /** unique or primary key constraint */
  TalentsPkey = 'talents_pkey'
}

/** input type for inserting data into table "talents" */
export type Talents_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Talents_Max_Fields = {
  __typename?: 'talents_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "talents" */
export type Talents_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Talents_Min_Fields = {
  __typename?: 'talents_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "talents" */
export type Talents_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "talents" */
export type Talents_Mutation_Response = {
  __typename?: 'talents_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Talents>;
};

/** input type for inserting object relation for remote table "talents" */
export type Talents_Obj_Rel_Insert_Input = {
  data: Talents_Insert_Input;
  on_conflict?: Maybe<Talents_On_Conflict>;
};

/** on conflict condition type for table "talents" */
export type Talents_On_Conflict = {
  constraint: Talents_Constraint;
  update_columns: Array<Talents_Update_Column>;
  where?: Maybe<Talents_Bool_Exp>;
};

/** ordering options when selecting data from "talents" */
export type Talents_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "talents" */
export type Talents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "talents" */
export enum Talents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageBase64 = 'image_base64',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "talents" */
export type Talents_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "talents" */
export enum Talents_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageBase64 = 'image_base64',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "teams_context" */
export type Teams_Context = {
  __typename?: 'teams_context';
  session_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** aggregated selection of "teams_context" */
export type Teams_Context_Aggregate = {
  __typename?: 'teams_context_aggregate';
  aggregate?: Maybe<Teams_Context_Aggregate_Fields>;
  nodes: Array<Teams_Context>;
};

/** aggregate fields of "teams_context" */
export type Teams_Context_Aggregate_Fields = {
  __typename?: 'teams_context_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Teams_Context_Max_Fields>;
  min?: Maybe<Teams_Context_Min_Fields>;
};


/** aggregate fields of "teams_context" */
export type Teams_Context_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teams_Context_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_context" */
export type Teams_Context_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Teams_Context_Max_Order_By>;
  min?: Maybe<Teams_Context_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams_context" */
export type Teams_Context_Arr_Rel_Insert_Input = {
  data: Array<Teams_Context_Insert_Input>;
  on_conflict?: Maybe<Teams_Context_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams_context". All fields are combined with a logical 'AND'. */
export type Teams_Context_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Teams_Context_Bool_Exp>>>;
  _not?: Maybe<Teams_Context_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Teams_Context_Bool_Exp>>>;
  session_id?: Maybe<Uuid_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams_context" */
export enum Teams_Context_Constraint {
  /** unique or primary key constraint */
  TeamsContextPkey = 'teams_context_pkey'
}

/** input type for inserting data into table "teams_context" */
export type Teams_Context_Insert_Input = {
  session_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Teams_Context_Max_Fields = {
  __typename?: 'teams_context_max_fields';
  session_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "teams_context" */
export type Teams_Context_Max_Order_By = {
  session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Context_Min_Fields = {
  __typename?: 'teams_context_min_fields';
  session_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "teams_context" */
export type Teams_Context_Min_Order_By = {
  session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "teams_context" */
export type Teams_Context_Mutation_Response = {
  __typename?: 'teams_context_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Teams_Context>;
};

/** input type for inserting object relation for remote table "teams_context" */
export type Teams_Context_Obj_Rel_Insert_Input = {
  data: Teams_Context_Insert_Input;
  on_conflict?: Maybe<Teams_Context_On_Conflict>;
};

/** on conflict condition type for table "teams_context" */
export type Teams_Context_On_Conflict = {
  constraint: Teams_Context_Constraint;
  update_columns: Array<Teams_Context_Update_Column>;
  where?: Maybe<Teams_Context_Bool_Exp>;
};

/** ordering options when selecting data from "teams_context" */
export type Teams_Context_Order_By = {
  session_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "teams_context" */
export type Teams_Context_Pk_Columns_Input = {
  session_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "teams_context" */
export enum Teams_Context_Select_Column {
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "teams_context" */
export type Teams_Context_Set_Input = {
  session_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "teams_context" */
export enum Teams_Context_Update_Column {
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "teams_sessions" */
export type Teams_Sessions = {
  __typename?: 'teams_sessions';
  created_at: Scalars['timestamptz'];
  id: Scalars['String'];
  teams_context: Scalars['jsonb'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};


/** columns and relationships of "teams_sessions" */
export type Teams_SessionsTeams_ContextArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "teams_sessions" */
export type Teams_Sessions_Aggregate = {
  __typename?: 'teams_sessions_aggregate';
  aggregate?: Maybe<Teams_Sessions_Aggregate_Fields>;
  nodes: Array<Teams_Sessions>;
};

/** aggregate fields of "teams_sessions" */
export type Teams_Sessions_Aggregate_Fields = {
  __typename?: 'teams_sessions_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Teams_Sessions_Max_Fields>;
  min?: Maybe<Teams_Sessions_Min_Fields>;
};


/** aggregate fields of "teams_sessions" */
export type Teams_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teams_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_sessions" */
export type Teams_Sessions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Teams_Sessions_Max_Order_By>;
  min?: Maybe<Teams_Sessions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Teams_Sessions_Append_Input = {
  teams_context?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "teams_sessions" */
export type Teams_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Teams_Sessions_Insert_Input>;
  on_conflict?: Maybe<Teams_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams_sessions". All fields are combined with a logical 'AND'. */
export type Teams_Sessions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Teams_Sessions_Bool_Exp>>>;
  _not?: Maybe<Teams_Sessions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Teams_Sessions_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  teams_context?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams_sessions" */
export enum Teams_Sessions_Constraint {
  /** unique or primary key constraint */
  TeamsSessionsPkey = 'teams_sessions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Teams_Sessions_Delete_At_Path_Input = {
  teams_context?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type Teams_Sessions_Delete_Elem_Input = {
  teams_context?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Teams_Sessions_Delete_Key_Input = {
  teams_context?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "teams_sessions" */
export type Teams_Sessions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  teams_context?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Teams_Sessions_Max_Fields = {
  __typename?: 'teams_sessions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "teams_sessions" */
export type Teams_Sessions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Sessions_Min_Fields = {
  __typename?: 'teams_sessions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "teams_sessions" */
export type Teams_Sessions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "teams_sessions" */
export type Teams_Sessions_Mutation_Response = {
  __typename?: 'teams_sessions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Teams_Sessions>;
};

/** input type for inserting object relation for remote table "teams_sessions" */
export type Teams_Sessions_Obj_Rel_Insert_Input = {
  data: Teams_Sessions_Insert_Input;
  on_conflict?: Maybe<Teams_Sessions_On_Conflict>;
};

/** on conflict condition type for table "teams_sessions" */
export type Teams_Sessions_On_Conflict = {
  constraint: Teams_Sessions_Constraint;
  update_columns: Array<Teams_Sessions_Update_Column>;
  where?: Maybe<Teams_Sessions_Bool_Exp>;
};

/** ordering options when selecting data from "teams_sessions" */
export type Teams_Sessions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  teams_context?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "teams_sessions" */
export type Teams_Sessions_Pk_Columns_Input = {
  id: Scalars['String'];
  user_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Teams_Sessions_Prepend_Input = {
  teams_context?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "teams_sessions" */
export enum Teams_Sessions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamsContext = 'teams_context',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "teams_sessions" */
export type Teams_Sessions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  teams_context?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "teams_sessions" */
export enum Teams_Sessions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TeamsContext = 'teams_context',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type TeamsMeetingNotificationInput = {
  conversationId: Scalars['String'];
  height: Scalars['Int'];
  text: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type TeamsMeetingNotificationOutput = {
  __typename?: 'TeamsMeetingNotificationOutput';
  id: Scalars['String'];
};

export type TeamsMigrationCreateTeamInput = {
  teamDescription: Scalars['String'];
  teamName: Scalars['String'];
};

export type TeamsMigrationCreateTeamOutput = {
  __typename?: 'TeamsMigrationCreateTeamOutput';
  teamDescription: Scalars['String'];
  teamName: Scalars['String'];
};

/** columns and relationships of "tech_tags" */
export type Tech_Tags = {
  __typename?: 'tech_tags';
  created_at: Scalars['timestamptz'];
  created_by_id: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  project_teches: Array<Project_Tech>;
  /** An aggregated array relationship */
  project_teches_aggregate: Project_Tech_Aggregate;
  tech_description?: Maybe<Scalars['String']>;
  tech_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tech_tags" */
export type Tech_TagsProject_TechesArgs = {
  distinct_on?: Maybe<Array<Project_Tech_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tech_Order_By>>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};


/** columns and relationships of "tech_tags" */
export type Tech_TagsProject_Teches_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Tech_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Tech_Order_By>>;
  where?: Maybe<Project_Tech_Bool_Exp>;
};

/** aggregated selection of "tech_tags" */
export type Tech_Tags_Aggregate = {
  __typename?: 'tech_tags_aggregate';
  aggregate?: Maybe<Tech_Tags_Aggregate_Fields>;
  nodes: Array<Tech_Tags>;
};

/** aggregate fields of "tech_tags" */
export type Tech_Tags_Aggregate_Fields = {
  __typename?: 'tech_tags_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Tech_Tags_Max_Fields>;
  min?: Maybe<Tech_Tags_Min_Fields>;
};


/** aggregate fields of "tech_tags" */
export type Tech_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tech_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tech_tags" */
export type Tech_Tags_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tech_Tags_Max_Order_By>;
  min?: Maybe<Tech_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tech_tags" */
export type Tech_Tags_Arr_Rel_Insert_Input = {
  data: Array<Tech_Tags_Insert_Input>;
  on_conflict?: Maybe<Tech_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tech_tags". All fields are combined with a logical 'AND'. */
export type Tech_Tags_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Tech_Tags_Bool_Exp>>>;
  _not?: Maybe<Tech_Tags_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Tech_Tags_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  project_teches?: Maybe<Project_Tech_Bool_Exp>;
  tech_description?: Maybe<String_Comparison_Exp>;
  tech_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tech_tags" */
export enum Tech_Tags_Constraint {
  /** unique or primary key constraint */
  TechTagsPkey = 'tech_tags_pkey'
}

/** input type for inserting data into table "tech_tags" */
export type Tech_Tags_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_teches?: Maybe<Project_Tech_Arr_Rel_Insert_Input>;
  tech_description?: Maybe<Scalars['String']>;
  tech_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tech_Tags_Max_Fields = {
  __typename?: 'tech_tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tech_description?: Maybe<Scalars['String']>;
  tech_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tech_tags" */
export type Tech_Tags_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tech_description?: Maybe<Order_By>;
  tech_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tech_Tags_Min_Fields = {
  __typename?: 'tech_tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tech_description?: Maybe<Scalars['String']>;
  tech_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tech_tags" */
export type Tech_Tags_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tech_description?: Maybe<Order_By>;
  tech_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tech_tags" */
export type Tech_Tags_Mutation_Response = {
  __typename?: 'tech_tags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Tech_Tags>;
};

/** input type for inserting object relation for remote table "tech_tags" */
export type Tech_Tags_Obj_Rel_Insert_Input = {
  data: Tech_Tags_Insert_Input;
  on_conflict?: Maybe<Tech_Tags_On_Conflict>;
};

/** on conflict condition type for table "tech_tags" */
export type Tech_Tags_On_Conflict = {
  constraint: Tech_Tags_Constraint;
  update_columns: Array<Tech_Tags_Update_Column>;
  where?: Maybe<Tech_Tags_Bool_Exp>;
};

/** ordering options when selecting data from "tech_tags" */
export type Tech_Tags_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  project_teches_aggregate?: Maybe<Project_Tech_Aggregate_Order_By>;
  tech_description?: Maybe<Order_By>;
  tech_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "tech_tags" */
export type Tech_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tech_tags" */
export enum Tech_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  TechDescription = 'tech_description',
  /** column name */
  TechName = 'tech_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tech_tags" */
export type Tech_Tags_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tech_description?: Maybe<Scalars['String']>;
  tech_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "tech_tags" */
export enum Tech_Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  TechDescription = 'tech_description',
  /** column name */
  TechName = 'tech_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "tenants" */
export type Tenants = {
  __typename?: 'tenants';
  /** An array relationship */
  apps: Array<App>;
  /** An aggregated array relationship */
  apps_aggregate: App_Aggregate;
  id: Scalars['String'];
  name: Scalars['String'];
  sharepoint_root_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregated array relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "tenants" */
export type TenantsAppsArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsApps_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "tenants" */
export type TenantsUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "tenants" */
export type Tenants_Aggregate = {
  __typename?: 'tenants_aggregate';
  aggregate?: Maybe<Tenants_Aggregate_Fields>;
  nodes: Array<Tenants>;
};

/** aggregate fields of "tenants" */
export type Tenants_Aggregate_Fields = {
  __typename?: 'tenants_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Tenants_Max_Fields>;
  min?: Maybe<Tenants_Min_Fields>;
};


/** aggregate fields of "tenants" */
export type Tenants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenants" */
export type Tenants_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tenants_Max_Order_By>;
  min?: Maybe<Tenants_Min_Order_By>;
};

/** columns and relationships of "tenants_allowed" */
export type Tenants_Allowed = {
  __typename?: 'tenants_allowed';
  id: Scalars['String'];
  is_allowed: Scalars['Boolean'];
};

/** aggregated selection of "tenants_allowed" */
export type Tenants_Allowed_Aggregate = {
  __typename?: 'tenants_allowed_aggregate';
  aggregate?: Maybe<Tenants_Allowed_Aggregate_Fields>;
  nodes: Array<Tenants_Allowed>;
};

/** aggregate fields of "tenants_allowed" */
export type Tenants_Allowed_Aggregate_Fields = {
  __typename?: 'tenants_allowed_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Tenants_Allowed_Max_Fields>;
  min?: Maybe<Tenants_Allowed_Min_Fields>;
};


/** aggregate fields of "tenants_allowed" */
export type Tenants_Allowed_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenants_Allowed_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenants_allowed" */
export type Tenants_Allowed_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tenants_Allowed_Max_Order_By>;
  min?: Maybe<Tenants_Allowed_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenants_allowed" */
export type Tenants_Allowed_Arr_Rel_Insert_Input = {
  data: Array<Tenants_Allowed_Insert_Input>;
  on_conflict?: Maybe<Tenants_Allowed_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenants_allowed". All fields are combined with a logical 'AND'. */
export type Tenants_Allowed_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Tenants_Allowed_Bool_Exp>>>;
  _not?: Maybe<Tenants_Allowed_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Tenants_Allowed_Bool_Exp>>>;
  id?: Maybe<String_Comparison_Exp>;
  is_allowed?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenants_allowed" */
export enum Tenants_Allowed_Constraint {
  /** unique or primary key constraint */
  TenantsAllowedPkey = 'tenants_allowed_pkey'
}

/** input type for inserting data into table "tenants_allowed" */
export type Tenants_Allowed_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_allowed?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Tenants_Allowed_Max_Fields = {
  __typename?: 'tenants_allowed_max_fields';
  id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenants_allowed" */
export type Tenants_Allowed_Max_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tenants_Allowed_Min_Fields = {
  __typename?: 'tenants_allowed_min_fields';
  id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenants_allowed" */
export type Tenants_Allowed_Min_Order_By = {
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "tenants_allowed" */
export type Tenants_Allowed_Mutation_Response = {
  __typename?: 'tenants_allowed_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Tenants_Allowed>;
};

/** input type for inserting object relation for remote table "tenants_allowed" */
export type Tenants_Allowed_Obj_Rel_Insert_Input = {
  data: Tenants_Allowed_Insert_Input;
  on_conflict?: Maybe<Tenants_Allowed_On_Conflict>;
};

/** on conflict condition type for table "tenants_allowed" */
export type Tenants_Allowed_On_Conflict = {
  constraint: Tenants_Allowed_Constraint;
  update_columns: Array<Tenants_Allowed_Update_Column>;
  where?: Maybe<Tenants_Allowed_Bool_Exp>;
};

/** ordering options when selecting data from "tenants_allowed" */
export type Tenants_Allowed_Order_By = {
  id?: Maybe<Order_By>;
  is_allowed?: Maybe<Order_By>;
};

/** primary key columns input for table: "tenants_allowed" */
export type Tenants_Allowed_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "tenants_allowed" */
export enum Tenants_Allowed_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsAllowed = 'is_allowed'
}

/** input type for updating data in table "tenants_allowed" */
export type Tenants_Allowed_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_allowed?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "tenants_allowed" */
export enum Tenants_Allowed_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsAllowed = 'is_allowed'
}

/** input type for inserting array relation for remote table "tenants" */
export type Tenants_Arr_Rel_Insert_Input = {
  data: Array<Tenants_Insert_Input>;
  on_conflict?: Maybe<Tenants_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenants". All fields are combined with a logical 'AND'. */
export type Tenants_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Tenants_Bool_Exp>>>;
  _not?: Maybe<Tenants_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Tenants_Bool_Exp>>>;
  apps?: Maybe<App_Bool_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  sharepoint_root_url?: Maybe<String_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "tenants" */
export enum Tenants_Constraint {
  /** unique or primary key constraint */
  TenantsPkey = 'tenants_pkey'
}

/** input type for inserting data into table "tenants" */
export type Tenants_Insert_Input = {
  apps?: Maybe<App_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sharepoint_root_url?: Maybe<Scalars['String']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenants_Max_Fields = {
  __typename?: 'tenants_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sharepoint_root_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenants" */
export type Tenants_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sharepoint_root_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tenants_Min_Fields = {
  __typename?: 'tenants_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sharepoint_root_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenants" */
export type Tenants_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sharepoint_root_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "tenants" */
export type Tenants_Mutation_Response = {
  __typename?: 'tenants_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Tenants>;
};

/** input type for inserting object relation for remote table "tenants" */
export type Tenants_Obj_Rel_Insert_Input = {
  data: Tenants_Insert_Input;
  on_conflict?: Maybe<Tenants_On_Conflict>;
};

/** on conflict condition type for table "tenants" */
export type Tenants_On_Conflict = {
  constraint: Tenants_Constraint;
  update_columns: Array<Tenants_Update_Column>;
  where?: Maybe<Tenants_Bool_Exp>;
};

/** ordering options when selecting data from "tenants" */
export type Tenants_Order_By = {
  apps_aggregate?: Maybe<App_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sharepoint_root_url?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: "tenants" */
export type Tenants_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "tenants" */
export enum Tenants_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SharepointRootUrl = 'sharepoint_root_url'
}

/** input type for updating data in table "tenants" */
export type Tenants_Set_Input = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sharepoint_root_url?: Maybe<Scalars['String']>;
};

/** update columns of table "tenants" */
export enum Tenants_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SharepointRootUrl = 'sharepoint_root_url'
}

export type TermGroup = {
  __typename?: 'TermGroup';
  createdDateTime: Scalars['String'];
  description: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  scope: Scalars['String'];
};

export type Terms = {
  __typename?: 'Terms';
  createdDateTime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_achievements" */
export type User_Achievements = {
  __typename?: 'user_achievements';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image_base64?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

/** aggregated selection of "user_achievements" */
export type User_Achievements_Aggregate = {
  __typename?: 'user_achievements_aggregate';
  aggregate?: Maybe<User_Achievements_Aggregate_Fields>;
  nodes: Array<User_Achievements>;
};

/** aggregate fields of "user_achievements" */
export type User_Achievements_Aggregate_Fields = {
  __typename?: 'user_achievements_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Achievements_Max_Fields>;
  min?: Maybe<User_Achievements_Min_Fields>;
};


/** aggregate fields of "user_achievements" */
export type User_Achievements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Achievements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_achievements" */
export type User_Achievements_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Achievements_Max_Order_By>;
  min?: Maybe<User_Achievements_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_achievements" */
export type User_Achievements_Arr_Rel_Insert_Input = {
  data: Array<User_Achievements_Insert_Input>;
  on_conflict?: Maybe<User_Achievements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_achievements". All fields are combined with a logical 'AND'. */
export type User_Achievements_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Achievements_Bool_Exp>>>;
  _not?: Maybe<User_Achievements_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Achievements_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image_base64?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_achievements" */
export enum User_Achievements_Constraint {
  /** unique or primary key constraint */
  UserAchievementsPkey = 'user_achievements_pkey'
}

/** input type for inserting data into table "user_achievements" */
export type User_Achievements_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Achievements_Max_Fields = {
  __typename?: 'user_achievements_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_achievements" */
export type User_Achievements_Max_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Achievements_Min_Fields = {
  __typename?: 'user_achievements_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_achievements" */
export type User_Achievements_Min_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_achievements" */
export type User_Achievements_Mutation_Response = {
  __typename?: 'user_achievements_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Achievements>;
};

/** input type for inserting object relation for remote table "user_achievements" */
export type User_Achievements_Obj_Rel_Insert_Input = {
  data: User_Achievements_Insert_Input;
  on_conflict?: Maybe<User_Achievements_On_Conflict>;
};

/** on conflict condition type for table "user_achievements" */
export type User_Achievements_On_Conflict = {
  constraint: User_Achievements_Constraint;
  update_columns: Array<User_Achievements_Update_Column>;
  where?: Maybe<User_Achievements_Bool_Exp>;
};

/** ordering options when selecting data from "user_achievements" */
export type User_Achievements_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_achievements" */
export type User_Achievements_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_achievements" */
export enum User_Achievements_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageBase64 = 'image_base64',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_achievements" */
export type User_Achievements_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "user_achievements" */
export enum User_Achievements_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ImageBase64 = 'image_base64',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_deselected_projects" */
export type User_Deselected_Projects = {
  __typename?: 'user_deselected_projects';
  created_at: Scalars['timestamptz'];
  project_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

/** aggregated selection of "user_deselected_projects" */
export type User_Deselected_Projects_Aggregate = {
  __typename?: 'user_deselected_projects_aggregate';
  aggregate?: Maybe<User_Deselected_Projects_Aggregate_Fields>;
  nodes: Array<User_Deselected_Projects>;
};

/** aggregate fields of "user_deselected_projects" */
export type User_Deselected_Projects_Aggregate_Fields = {
  __typename?: 'user_deselected_projects_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Deselected_Projects_Max_Fields>;
  min?: Maybe<User_Deselected_Projects_Min_Fields>;
};


/** aggregate fields of "user_deselected_projects" */
export type User_Deselected_Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Deselected_Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_deselected_projects" */
export type User_Deselected_Projects_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Deselected_Projects_Max_Order_By>;
  min?: Maybe<User_Deselected_Projects_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_deselected_projects" */
export type User_Deselected_Projects_Arr_Rel_Insert_Input = {
  data: Array<User_Deselected_Projects_Insert_Input>;
  on_conflict?: Maybe<User_Deselected_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_deselected_projects". All fields are combined with a logical 'AND'. */
export type User_Deselected_Projects_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Deselected_Projects_Bool_Exp>>>;
  _not?: Maybe<User_Deselected_Projects_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Deselected_Projects_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  project_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_deselected_projects" */
export enum User_Deselected_Projects_Constraint {
  /** unique or primary key constraint */
  UserDeselectedProjectsPkey = 'user_deselected_projects_pkey'
}

/** input type for inserting data into table "user_deselected_projects" */
export type User_Deselected_Projects_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Deselected_Projects_Max_Fields = {
  __typename?: 'user_deselected_projects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_deselected_projects" */
export type User_Deselected_Projects_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Deselected_Projects_Min_Fields = {
  __typename?: 'user_deselected_projects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_deselected_projects" */
export type User_Deselected_Projects_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_deselected_projects" */
export type User_Deselected_Projects_Mutation_Response = {
  __typename?: 'user_deselected_projects_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Deselected_Projects>;
};

/** input type for inserting object relation for remote table "user_deselected_projects" */
export type User_Deselected_Projects_Obj_Rel_Insert_Input = {
  data: User_Deselected_Projects_Insert_Input;
  on_conflict?: Maybe<User_Deselected_Projects_On_Conflict>;
};

/** on conflict condition type for table "user_deselected_projects" */
export type User_Deselected_Projects_On_Conflict = {
  constraint: User_Deselected_Projects_Constraint;
  update_columns: Array<User_Deselected_Projects_Update_Column>;
  where?: Maybe<User_Deselected_Projects_Bool_Exp>;
};

/** ordering options when selecting data from "user_deselected_projects" */
export type User_Deselected_Projects_Order_By = {
  created_at?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_deselected_projects" */
export type User_Deselected_Projects_Pk_Columns_Input = {
  project_id: Scalars['String'];
  user_id: Scalars['String'];
};

/** select columns of table "user_deselected_projects" */
export enum User_Deselected_Projects_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_deselected_projects" */
export type User_Deselected_Projects_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "user_deselected_projects" */
export enum User_Deselected_Projects_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_education" */
export type User_Education = {
  __typename?: 'user_education';
  degree_name: Scalars['String'];
  field_of_study: Scalars['String'];
  id: Scalars['uuid'];
  school_name: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "user_education" */
export type User_Education_Aggregate = {
  __typename?: 'user_education_aggregate';
  aggregate?: Maybe<User_Education_Aggregate_Fields>;
  nodes: Array<User_Education>;
};

/** aggregate fields of "user_education" */
export type User_Education_Aggregate_Fields = {
  __typename?: 'user_education_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Education_Max_Fields>;
  min?: Maybe<User_Education_Min_Fields>;
};


/** aggregate fields of "user_education" */
export type User_Education_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Education_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_education" */
export type User_Education_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Education_Max_Order_By>;
  min?: Maybe<User_Education_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_education" */
export type User_Education_Arr_Rel_Insert_Input = {
  data: Array<User_Education_Insert_Input>;
  on_conflict?: Maybe<User_Education_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_education". All fields are combined with a logical 'AND'. */
export type User_Education_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Education_Bool_Exp>>>;
  _not?: Maybe<User_Education_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Education_Bool_Exp>>>;
  degree_name?: Maybe<String_Comparison_Exp>;
  field_of_study?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  school_name?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_education" */
export enum User_Education_Constraint {
  /** unique or primary key constraint */
  UserEducationPkey = 'user_education_pkey'
}

/** input type for inserting data into table "user_education" */
export type User_Education_Insert_Input = {
  degree_name?: Maybe<Scalars['String']>;
  field_of_study?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  school_name?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Education_Max_Fields = {
  __typename?: 'user_education_max_fields';
  degree_name?: Maybe<Scalars['String']>;
  field_of_study?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  school_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_education" */
export type User_Education_Max_Order_By = {
  degree_name?: Maybe<Order_By>;
  field_of_study?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  school_name?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Education_Min_Fields = {
  __typename?: 'user_education_min_fields';
  degree_name?: Maybe<Scalars['String']>;
  field_of_study?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  school_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_education" */
export type User_Education_Min_Order_By = {
  degree_name?: Maybe<Order_By>;
  field_of_study?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  school_name?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_education" */
export type User_Education_Mutation_Response = {
  __typename?: 'user_education_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Education>;
};

/** input type for inserting object relation for remote table "user_education" */
export type User_Education_Obj_Rel_Insert_Input = {
  data: User_Education_Insert_Input;
  on_conflict?: Maybe<User_Education_On_Conflict>;
};

/** on conflict condition type for table "user_education" */
export type User_Education_On_Conflict = {
  constraint: User_Education_Constraint;
  update_columns: Array<User_Education_Update_Column>;
  where?: Maybe<User_Education_Bool_Exp>;
};

/** ordering options when selecting data from "user_education" */
export type User_Education_Order_By = {
  degree_name?: Maybe<Order_By>;
  field_of_study?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  school_name?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_education" */
export type User_Education_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_education" */
export enum User_Education_Select_Column {
  /** column name */
  DegreeName = 'degree_name',
  /** column name */
  FieldOfStudy = 'field_of_study',
  /** column name */
  Id = 'id',
  /** column name */
  SchoolName = 'school_name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_education" */
export type User_Education_Set_Input = {
  degree_name?: Maybe<Scalars['String']>;
  field_of_study?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  school_name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "user_education" */
export enum User_Education_Update_Column {
  /** column name */
  DegreeName = 'degree_name',
  /** column name */
  FieldOfStudy = 'field_of_study',
  /** column name */
  Id = 'id',
  /** column name */
  SchoolName = 'school_name',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_experience" */
export type User_Experience = {
  __typename?: 'user_experience';
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  org_image_base64?: Maybe<Scalars['String']>;
  org_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  user_id: Scalars['String'];
};

/** aggregated selection of "user_experience" */
export type User_Experience_Aggregate = {
  __typename?: 'user_experience_aggregate';
  aggregate?: Maybe<User_Experience_Aggregate_Fields>;
  nodes: Array<User_Experience>;
};

/** aggregate fields of "user_experience" */
export type User_Experience_Aggregate_Fields = {
  __typename?: 'user_experience_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Experience_Max_Fields>;
  min?: Maybe<User_Experience_Min_Fields>;
};


/** aggregate fields of "user_experience" */
export type User_Experience_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Experience_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_experience" */
export type User_Experience_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Experience_Max_Order_By>;
  min?: Maybe<User_Experience_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_experience" */
export type User_Experience_Arr_Rel_Insert_Input = {
  data: Array<User_Experience_Insert_Input>;
  on_conflict?: Maybe<User_Experience_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_experience". All fields are combined with a logical 'AND'. */
export type User_Experience_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Experience_Bool_Exp>>>;
  _not?: Maybe<User_Experience_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Experience_Bool_Exp>>>;
  end_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  org_image_base64?: Maybe<String_Comparison_Exp>;
  org_name?: Maybe<String_Comparison_Exp>;
  start_date?: Maybe<Date_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_experience" */
export enum User_Experience_Constraint {
  /** unique or primary key constraint */
  UserExperiencePkey = 'user_experience_pkey'
}

/** input type for inserting data into table "user_experience" */
export type User_Experience_Insert_Input = {
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  org_image_base64?: Maybe<Scalars['String']>;
  org_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Experience_Max_Fields = {
  __typename?: 'user_experience_max_fields';
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  org_image_base64?: Maybe<Scalars['String']>;
  org_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_experience" */
export type User_Experience_Max_Order_By = {
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  org_image_base64?: Maybe<Order_By>;
  org_name?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Experience_Min_Fields = {
  __typename?: 'user_experience_min_fields';
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  org_image_base64?: Maybe<Scalars['String']>;
  org_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_experience" */
export type User_Experience_Min_Order_By = {
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  org_image_base64?: Maybe<Order_By>;
  org_name?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_experience" */
export type User_Experience_Mutation_Response = {
  __typename?: 'user_experience_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Experience>;
};

/** input type for inserting object relation for remote table "user_experience" */
export type User_Experience_Obj_Rel_Insert_Input = {
  data: User_Experience_Insert_Input;
  on_conflict?: Maybe<User_Experience_On_Conflict>;
};

/** on conflict condition type for table "user_experience" */
export type User_Experience_On_Conflict = {
  constraint: User_Experience_Constraint;
  update_columns: Array<User_Experience_Update_Column>;
  where?: Maybe<User_Experience_Bool_Exp>;
};

/** ordering options when selecting data from "user_experience" */
export type User_Experience_Order_By = {
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  org_image_base64?: Maybe<Order_By>;
  org_name?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_experience" */
export type User_Experience_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_experience" */
export enum User_Experience_Select_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgImageBase64 = 'org_image_base64',
  /** column name */
  OrgName = 'org_name',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_experience" */
export type User_Experience_Set_Input = {
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  org_image_base64?: Maybe<Scalars['String']>;
  org_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "user_experience" */
export enum User_Experience_Update_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrgImageBase64 = 'org_image_base64',
  /** column name */
  OrgName = 'org_name',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_images" */
export type User_Images = {
  __typename?: 'user_images';
  image_base64: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

/** aggregated selection of "user_images" */
export type User_Images_Aggregate = {
  __typename?: 'user_images_aggregate';
  aggregate?: Maybe<User_Images_Aggregate_Fields>;
  nodes: Array<User_Images>;
};

/** aggregate fields of "user_images" */
export type User_Images_Aggregate_Fields = {
  __typename?: 'user_images_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Images_Max_Fields>;
  min?: Maybe<User_Images_Min_Fields>;
};


/** aggregate fields of "user_images" */
export type User_Images_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Images_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_images" */
export type User_Images_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Images_Max_Order_By>;
  min?: Maybe<User_Images_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_images" */
export type User_Images_Arr_Rel_Insert_Input = {
  data: Array<User_Images_Insert_Input>;
  on_conflict?: Maybe<User_Images_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_images". All fields are combined with a logical 'AND'. */
export type User_Images_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Images_Bool_Exp>>>;
  _not?: Maybe<User_Images_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Images_Bool_Exp>>>;
  image_base64?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_images" */
export enum User_Images_Constraint {
  /** unique or primary key constraint */
  UserImagesPkey = 'user_images_pkey'
}

/** input type for inserting data into table "user_images" */
export type User_Images_Insert_Input = {
  image_base64?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Images_Max_Fields = {
  __typename?: 'user_images_max_fields';
  image_base64?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_images" */
export type User_Images_Max_Order_By = {
  image_base64?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Images_Min_Fields = {
  __typename?: 'user_images_min_fields';
  image_base64?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_images" */
export type User_Images_Min_Order_By = {
  image_base64?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_images" */
export type User_Images_Mutation_Response = {
  __typename?: 'user_images_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Images>;
};

/** input type for inserting object relation for remote table "user_images" */
export type User_Images_Obj_Rel_Insert_Input = {
  data: User_Images_Insert_Input;
  on_conflict?: Maybe<User_Images_On_Conflict>;
};

/** on conflict condition type for table "user_images" */
export type User_Images_On_Conflict = {
  constraint: User_Images_Constraint;
  update_columns: Array<User_Images_Update_Column>;
  where?: Maybe<User_Images_Bool_Exp>;
};

/** ordering options when selecting data from "user_images" */
export type User_Images_Order_By = {
  image_base64?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_images" */
export type User_Images_Pk_Columns_Input = {
  user_id: Scalars['String'];
};

/** select columns of table "user_images" */
export enum User_Images_Select_Column {
  /** column name */
  ImageBase64 = 'image_base64',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_images" */
export type User_Images_Set_Input = {
  image_base64?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "user_images" */
export enum User_Images_Update_Column {
  /** column name */
  ImageBase64 = 'image_base64',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_quote" */
export type User_Quote = {
  __typename?: 'user_quote';
  quote_author: Scalars['String'];
  quote_text: Scalars['String'];
  user_id: Scalars['String'];
};

/** aggregated selection of "user_quote" */
export type User_Quote_Aggregate = {
  __typename?: 'user_quote_aggregate';
  aggregate?: Maybe<User_Quote_Aggregate_Fields>;
  nodes: Array<User_Quote>;
};

/** aggregate fields of "user_quote" */
export type User_Quote_Aggregate_Fields = {
  __typename?: 'user_quote_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Quote_Max_Fields>;
  min?: Maybe<User_Quote_Min_Fields>;
};


/** aggregate fields of "user_quote" */
export type User_Quote_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Quote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_quote" */
export type User_Quote_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Quote_Max_Order_By>;
  min?: Maybe<User_Quote_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_quote" */
export type User_Quote_Arr_Rel_Insert_Input = {
  data: Array<User_Quote_Insert_Input>;
  on_conflict?: Maybe<User_Quote_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_quote". All fields are combined with a logical 'AND'. */
export type User_Quote_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Quote_Bool_Exp>>>;
  _not?: Maybe<User_Quote_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Quote_Bool_Exp>>>;
  quote_author?: Maybe<String_Comparison_Exp>;
  quote_text?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_quote" */
export enum User_Quote_Constraint {
  /** unique or primary key constraint */
  UserQuotePkey = 'user_quote_pkey'
}

/** input type for inserting data into table "user_quote" */
export type User_Quote_Insert_Input = {
  quote_author?: Maybe<Scalars['String']>;
  quote_text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Quote_Max_Fields = {
  __typename?: 'user_quote_max_fields';
  quote_author?: Maybe<Scalars['String']>;
  quote_text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_quote" */
export type User_Quote_Max_Order_By = {
  quote_author?: Maybe<Order_By>;
  quote_text?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Quote_Min_Fields = {
  __typename?: 'user_quote_min_fields';
  quote_author?: Maybe<Scalars['String']>;
  quote_text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_quote" */
export type User_Quote_Min_Order_By = {
  quote_author?: Maybe<Order_By>;
  quote_text?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_quote" */
export type User_Quote_Mutation_Response = {
  __typename?: 'user_quote_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Quote>;
};

/** input type for inserting object relation for remote table "user_quote" */
export type User_Quote_Obj_Rel_Insert_Input = {
  data: User_Quote_Insert_Input;
  on_conflict?: Maybe<User_Quote_On_Conflict>;
};

/** on conflict condition type for table "user_quote" */
export type User_Quote_On_Conflict = {
  constraint: User_Quote_Constraint;
  update_columns: Array<User_Quote_Update_Column>;
  where?: Maybe<User_Quote_Bool_Exp>;
};

/** ordering options when selecting data from "user_quote" */
export type User_Quote_Order_By = {
  quote_author?: Maybe<Order_By>;
  quote_text?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_quote" */
export type User_Quote_Pk_Columns_Input = {
  user_id: Scalars['String'];
};

/** select columns of table "user_quote" */
export enum User_Quote_Select_Column {
  /** column name */
  QuoteAuthor = 'quote_author',
  /** column name */
  QuoteText = 'quote_text',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_quote" */
export type User_Quote_Set_Input = {
  quote_author?: Maybe<Scalars['String']>;
  quote_text?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "user_quote" */
export enum User_Quote_Update_Column {
  /** column name */
  QuoteAuthor = 'quote_author',
  /** column name */
  QuoteText = 'quote_text',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user_uploaded_images" */
export type User_Uploaded_Images = {
  __typename?: 'user_uploaded_images';
  blob_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image_base64: Scalars['String'];
  user_id: Scalars['String'];
};

/** aggregated selection of "user_uploaded_images" */
export type User_Uploaded_Images_Aggregate = {
  __typename?: 'user_uploaded_images_aggregate';
  aggregate?: Maybe<User_Uploaded_Images_Aggregate_Fields>;
  nodes: Array<User_Uploaded_Images>;
};

/** aggregate fields of "user_uploaded_images" */
export type User_Uploaded_Images_Aggregate_Fields = {
  __typename?: 'user_uploaded_images_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Uploaded_Images_Max_Fields>;
  min?: Maybe<User_Uploaded_Images_Min_Fields>;
};


/** aggregate fields of "user_uploaded_images" */
export type User_Uploaded_Images_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Uploaded_Images_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_uploaded_images" */
export type User_Uploaded_Images_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Uploaded_Images_Max_Order_By>;
  min?: Maybe<User_Uploaded_Images_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_uploaded_images" */
export type User_Uploaded_Images_Arr_Rel_Insert_Input = {
  data: Array<User_Uploaded_Images_Insert_Input>;
  on_conflict?: Maybe<User_Uploaded_Images_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_uploaded_images". All fields are combined with a logical 'AND'. */
export type User_Uploaded_Images_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Uploaded_Images_Bool_Exp>>>;
  _not?: Maybe<User_Uploaded_Images_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Uploaded_Images_Bool_Exp>>>;
  blob_url?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image_base64?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_uploaded_images" */
export enum User_Uploaded_Images_Constraint {
  /** unique or primary key constraint */
  UserUploadedImagesPkey = 'user_uploaded_images_pkey'
}

/** input type for inserting data into table "user_uploaded_images" */
export type User_Uploaded_Images_Insert_Input = {
  blob_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Uploaded_Images_Max_Fields = {
  __typename?: 'user_uploaded_images_max_fields';
  blob_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_uploaded_images" */
export type User_Uploaded_Images_Max_Order_By = {
  blob_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Uploaded_Images_Min_Fields = {
  __typename?: 'user_uploaded_images_min_fields';
  blob_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_uploaded_images" */
export type User_Uploaded_Images_Min_Order_By = {
  blob_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_uploaded_images" */
export type User_Uploaded_Images_Mutation_Response = {
  __typename?: 'user_uploaded_images_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Uploaded_Images>;
};

/** input type for inserting object relation for remote table "user_uploaded_images" */
export type User_Uploaded_Images_Obj_Rel_Insert_Input = {
  data: User_Uploaded_Images_Insert_Input;
  on_conflict?: Maybe<User_Uploaded_Images_On_Conflict>;
};

/** on conflict condition type for table "user_uploaded_images" */
export type User_Uploaded_Images_On_Conflict = {
  constraint: User_Uploaded_Images_Constraint;
  update_columns: Array<User_Uploaded_Images_Update_Column>;
  where?: Maybe<User_Uploaded_Images_Bool_Exp>;
};

/** ordering options when selecting data from "user_uploaded_images" */
export type User_Uploaded_Images_Order_By = {
  blob_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_base64?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_uploaded_images" */
export type User_Uploaded_Images_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_uploaded_images" */
export enum User_Uploaded_Images_Select_Column {
  /** column name */
  BlobUrl = 'blob_url',
  /** column name */
  Id = 'id',
  /** column name */
  ImageBase64 = 'image_base64',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_uploaded_images" */
export type User_Uploaded_Images_Set_Input = {
  blob_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_base64?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "user_uploaded_images" */
export enum User_Uploaded_Images_Update_Column {
  /** column name */
  BlobUrl = 'blob_url',
  /** column name */
  Id = 'id',
  /** column name */
  ImageBase64 = 'image_base64',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  badge_awards: Array<Badge_Awards>;
  /** An aggregated array relationship */
  badge_awards_aggregate: Badge_Awards_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  extended_profiles?: Maybe<Extended_Profile>;
  /** An array relationship */
  feedbacks: Array<Feedback>;
  /** An aggregated array relationship */
  feedbacks_aggregate: Feedback_Aggregate;
  first_run?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  last_seen?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  /** An array relationship */
  projects: Array<Project_Users>;
  /** An aggregated array relationship */
  projects_aggregate: Project_Users_Aggregate;
  /** An array relationship */
  talents: Array<Talents>;
  /** An aggregated array relationship */
  talents_aggregate: Talents_Aggregate;
  /** An object relationship */
  tenant: Tenants;
  tenant_id: Scalars['String'];
  /** An array relationship */
  user_achievements: Array<User_Achievements>;
  /** An aggregated array relationship */
  user_achievements_aggregate: User_Achievements_Aggregate;
  /** An array relationship */
  user_educations: Array<User_Education>;
  /** An aggregated array relationship */
  user_educations_aggregate: User_Education_Aggregate;
  /** An array relationship */
  user_experiences: Array<User_Experience>;
  /** An aggregated array relationship */
  user_experiences_aggregate: User_Experience_Aggregate;
  /** An array relationship */
  user_images: Array<User_Images>;
  /** An aggregated array relationship */
  user_images_aggregate: User_Images_Aggregate;
  /** An array relationship */
  user_quotes: Array<User_Quote>;
  /** An aggregated array relationship */
  user_quotes_aggregate: User_Quote_Aggregate;
};


/** columns and relationships of "users" */
export type UsersBadge_AwardsArgs = {
  distinct_on?: Maybe<Array<Badge_Awards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Awards_Order_By>>;
  where?: Maybe<Badge_Awards_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersBadge_Awards_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Awards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Awards_Order_By>>;
  where?: Maybe<Badge_Awards_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFeedbacksArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFeedbacks_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjectsArgs = {
  distinct_on?: Maybe<Array<Project_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Users_Order_By>>;
  where?: Maybe<Project_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Users_Order_By>>;
  where?: Maybe<Project_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTalentsArgs = {
  distinct_on?: Maybe<Array<Talents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Talents_Order_By>>;
  where?: Maybe<Talents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTalents_AggregateArgs = {
  distinct_on?: Maybe<Array<Talents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Talents_Order_By>>;
  where?: Maybe<Talents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_AchievementsArgs = {
  distinct_on?: Maybe<Array<User_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Achievements_Order_By>>;
  where?: Maybe<User_Achievements_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Achievements_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Achievements_Order_By>>;
  where?: Maybe<User_Achievements_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_EducationsArgs = {
  distinct_on?: Maybe<Array<User_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Education_Order_By>>;
  where?: Maybe<User_Education_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Educations_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Education_Order_By>>;
  where?: Maybe<User_Education_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_ExperiencesArgs = {
  distinct_on?: Maybe<Array<User_Experience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Experience_Order_By>>;
  where?: Maybe<User_Experience_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Experiences_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Experience_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Experience_Order_By>>;
  where?: Maybe<User_Experience_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_ImagesArgs = {
  distinct_on?: Maybe<Array<User_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Images_Order_By>>;
  where?: Maybe<User_Images_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Images_Order_By>>;
  where?: Maybe<User_Images_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_QuotesArgs = {
  distinct_on?: Maybe<Array<User_Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Quote_Order_By>>;
  where?: Maybe<User_Quote_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Quotes_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Quote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Quote_Order_By>>;
  where?: Maybe<User_Quote_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  badge_awards?: Maybe<Badge_Awards_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  extended_profiles?: Maybe<Extended_Profile_Bool_Exp>;
  feedbacks?: Maybe<Feedback_Bool_Exp>;
  first_run?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  projects?: Maybe<Project_Users_Bool_Exp>;
  talents?: Maybe<Talents_Bool_Exp>;
  tenant?: Maybe<Tenants_Bool_Exp>;
  tenant_id?: Maybe<String_Comparison_Exp>;
  user_achievements?: Maybe<User_Achievements_Bool_Exp>;
  user_educations?: Maybe<User_Education_Bool_Exp>;
  user_experiences?: Maybe<User_Experience_Bool_Exp>;
  user_images?: Maybe<User_Images_Bool_Exp>;
  user_quotes?: Maybe<User_Quote_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  badge_awards?: Maybe<Badge_Awards_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  extended_profiles?: Maybe<Extended_Profile_Obj_Rel_Insert_Input>;
  feedbacks?: Maybe<Feedback_Arr_Rel_Insert_Input>;
  first_run?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  projects?: Maybe<Project_Users_Arr_Rel_Insert_Input>;
  talents?: Maybe<Talents_Arr_Rel_Insert_Input>;
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>;
  tenant_id?: Maybe<Scalars['String']>;
  user_achievements?: Maybe<User_Achievements_Arr_Rel_Insert_Input>;
  user_educations?: Maybe<User_Education_Arr_Rel_Insert_Input>;
  user_experiences?: Maybe<User_Experience_Arr_Rel_Insert_Input>;
  user_images?: Maybe<User_Images_Arr_Rel_Insert_Input>;
  user_quotes?: Maybe<User_Quote_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  tenant_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  badge_awards_aggregate?: Maybe<Badge_Awards_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  extended_profiles?: Maybe<Extended_Profile_Order_By>;
  feedbacks_aggregate?: Maybe<Feedback_Aggregate_Order_By>;
  first_run?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  projects_aggregate?: Maybe<Project_Users_Aggregate_Order_By>;
  talents_aggregate?: Maybe<Talents_Aggregate_Order_By>;
  tenant?: Maybe<Tenants_Order_By>;
  tenant_id?: Maybe<Order_By>;
  user_achievements_aggregate?: Maybe<User_Achievements_Aggregate_Order_By>;
  user_educations_aggregate?: Maybe<User_Education_Aggregate_Order_By>;
  user_experiences_aggregate?: Maybe<User_Experience_Aggregate_Order_By>;
  user_images_aggregate?: Maybe<User_Images_Aggregate_Order_By>;
  user_quotes_aggregate?: Maybe<User_Quote_Aggregate_Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstRun = 'first_run',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name',
  /** column name */
  TenantId = 'tenant_id'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  first_run?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstRun = 'first_run',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name',
  /** column name */
  TenantId = 'tenant_id'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'tenant_id' | 'name'>
  )> }
);

export type UserCountQueryVariables = Exact<{ [key: string]: never; }>;


export type UserCountQuery = (
  { __typename?: 'query_root' }
  & { users_aggregate: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type InsertTenantWithUserMutationVariables = Exact<{
  tenantName: Scalars['String'];
  userName: Scalars['String'];
}>;


export type InsertTenantWithUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tenants_one?: Maybe<(
    { __typename?: 'tenants' }
    & Pick<Tenants, 'id' | 'name'>
  )> }
);

export type InsertUserWithTenantMutationVariables = Exact<{
  tenantName: Scalars['String'];
  userName: Scalars['String'];
}>;


export type InsertUserWithTenantMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_one?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'name' | 'first_run'>
  )> }
);

export type UpdateUserFirstRunMutationVariables = Exact<{
  firstRun: Scalars['Boolean'];
  userId: Scalars['String'];
}>;


export type UpdateUserFirstRunMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'first_run'>
    )> }
  )> }
);

export type InsertUserPhotoMutationVariables = Exact<{
  image_base64: Scalars['String'];
}>;


export type InsertUserPhotoMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_images_one?: Maybe<(
    { __typename?: 'user_images' }
    & Pick<User_Images, 'updated_at'>
  )> }
);

export type CreateOrUpdateUserMutationMutationVariables = Exact<{
  name: Scalars['String'];
  tenant_id: Scalars['String'];
  id: Scalars['String'];
}>;


export type CreateOrUpdateUserMutationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'name' | 'tenant_id' | 'first_run'>
    )> }
  )> }
);

export type CurrentUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type CurrentUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name' | 'id' | 'tenant_id' | 'first_run'>
  )> }
);

export type InsertOnlineMutationVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type InsertOnlineMutation = (
  { __typename?: 'mutation_root' }
  & { insert_online?: Maybe<(
    { __typename?: 'online_mutation_response' }
    & { returning: Array<(
      { __typename?: 'online' }
      & Pick<Online, 'updated_at'>
    )> }
  )> }
);

export type WhoIsOnlineSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type WhoIsOnlineSubscription = (
  { __typename?: 'subscription_root' }
  & { online_now: Array<(
    { __typename?: 'online_now' }
    & { users?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'name' | 'tenant_id'>
    )> }
  )> }
);

export type InsertWhoIsOnlineMutationVariables = Exact<{
  url: Scalars['String'];
}>;


export type InsertWhoIsOnlineMutation = (
  { __typename?: 'mutation_root' }
  & { insert_online_one?: Maybe<(
    { __typename?: 'online' }
    & Pick<Online, 'updated_at' | 'url'>
  )> }
);

export type AppDataSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AppDataSubscription = (
  { __typename?: 'subscription_root' }
  & { tenants: Array<(
    { __typename?: 'tenants' }
    & Pick<Tenants, 'name'>
    & { apps: Array<(
      { __typename?: 'app' }
      & Pick<App, 'title'>
    )> }
  )> }
);

export type InsertAlertMutationVariables = Exact<{
  alert_text: Scalars['String'];
}>;


export type InsertAlertMutation = (
  { __typename?: 'mutation_root' }
  & { insert_alerts_one?: Maybe<(
    { __typename?: 'alerts' }
    & Pick<Alerts, 'id' | 'alert_text'>
  )> }
);

export type AcknowledgeAlertMutationVariables = Exact<{
  alert_id: Scalars['uuid'];
}>;


export type AcknowledgeAlertMutation = (
  { __typename?: 'mutation_root' }
  & { insert_alert_acknowledge?: Maybe<(
    { __typename?: 'alert_acknowledge_mutation_response' }
    & { returning: Array<(
      { __typename?: 'alert_acknowledge' }
      & Pick<Alert_Acknowledge, 'alert_id'>
    )> }
  )> }
);

export type AlertsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AlertsSubscription = (
  { __typename?: 'subscription_root' }
  & { alerts: Array<(
    { __typename?: 'alerts' }
    & Pick<Alerts, 'alert_text' | 'id'>
  )> }
);

export type InsertClickMutationVariables = Exact<{
  x_position: Scalars['numeric'];
  y_position: Scalars['numeric'];
}>;


export type InsertClickMutation = (
  { __typename?: 'mutation_root' }
  & { insert_clicks_one?: Maybe<(
    { __typename?: 'clicks' }
    & Pick<Clicks, 'x_position' | 'y_position'>
  )> }
);

export type ArmTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ArmTemplatesQuery = (
  { __typename?: 'query_root' }
  & { arm_templates: Array<(
    { __typename?: 'arm_templates' }
    & Pick<Arm_Templates, 'name' | 'url' | 'description'>
  )> }
);

export type AllClicksSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AllClicksSubscription = (
  { __typename?: 'subscription_root' }
  & { clicks: Array<(
    { __typename?: 'clicks' }
    & Pick<Clicks, 'x_position' | 'y_position'>
  )> }
);

export type InsertTeamsSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
  teams_context: Scalars['jsonb'];
}>;


export type InsertTeamsSessionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_teams_sessions_one?: Maybe<(
    { __typename?: 'teams_sessions' }
    & Pick<Teams_Sessions, 'id' | 'created_at' | 'updated_at'>
  )> }
);

export type InsertFeedbackMutationVariables = Exact<{
  text: Scalars['String'];
}>;


export type InsertFeedbackMutation = (
  { __typename?: 'mutation_root' }
  & { insert_feedback_one?: Maybe<(
    { __typename?: 'feedback' }
    & Pick<Feedback, 'text'>
  )> }
);

export type SearchUsersQueryVariables = Exact<{
  searchString: Scalars['String'];
}>;


export type SearchUsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name' | 'id'>
    & { badge_awards_aggregate: (
      { __typename?: 'badge_awards_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'badge_awards_aggregate_fields' }
        & Pick<Badge_Awards_Aggregate_Fields, 'count'>
      )> }
    ), badge_awards: Array<(
      { __typename?: 'badge_awards' }
      & Pick<Badge_Awards, 'badge_id'>
    )>, user_images: Array<(
      { __typename?: 'user_images' }
      & Pick<User_Images, 'image_base64'>
    )>, extended_profiles?: Maybe<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'header_image_base64'>
    )> }
  )> }
);

export type SessionsForChatIdQueryVariables = Exact<{
  chatId: Scalars['jsonb'];
}>;


export type SessionsForChatIdQuery = (
  { __typename?: 'query_root' }
  & { teams_sessions: Array<(
    { __typename?: 'teams_sessions' }
    & Pick<Teams_Sessions, 'teams_context' | 'created_at' | 'updated_at'>
  )> }
);

export type PeopleInChatIdSubscriptionVariables = Exact<{
  chatId: Scalars['jsonb'];
}>;


export type PeopleInChatIdSubscription = (
  { __typename?: 'subscription_root' }
  & { teams_sessions: Array<(
    { __typename?: 'teams_sessions' }
    & Pick<Teams_Sessions, 'user_id' | 'teams_context' | 'created_at' | 'updated_at'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'name'>
      & { user_images: Array<(
        { __typename?: 'user_images' }
        & Pick<User_Images, 'image_base64'>
      )>, extended_profiles?: Maybe<(
        { __typename?: 'extended_profile' }
        & Pick<Extended_Profile, 'about_me'>
      )> }
    ) }
  )> }
);

export type UserWithBadgesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserWithBadgesQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name'>
    & { user_images: Array<(
      { __typename?: 'user_images' }
      & Pick<User_Images, 'image_base64'>
    )>, badge_awards: Array<(
      { __typename?: 'badge_awards' }
      & Pick<Badge_Awards, 'badge_id'>
    )> }
  )> }
);

export type UserWithBadgesAndBadgersQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserWithBadgesAndBadgersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name'>
    & { user_images: Array<(
      { __typename?: 'user_images' }
      & Pick<User_Images, 'image_base64'>
    )>, extended_profiles?: Maybe<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'header_image_base64'>
    )>, badge_awards: Array<(
      { __typename?: 'badge_awards' }
      & Pick<Badge_Awards, 'badge_id' | 'created_at' | 'message'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'name' | 'id'>
        & { user_images: Array<(
          { __typename?: 'user_images' }
          & Pick<User_Images, 'image_base64'>
        )> }
      ) }
    )> }
  )> }
);

export type UserProfileEditQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserProfileEditQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name'>
    & { user_images: Array<(
      { __typename?: 'user_images' }
      & Pick<User_Images, 'image_base64'>
    )>, extended_profiles?: Maybe<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'header_image_base64' | 'about_me' | 'alternate_phone' | 'social_url' | 'website_url'>
    )>, talents: Array<(
      { __typename?: 'talents' }
      & Pick<Talents, 'id' | 'image_base64' | 'name' | 'description'>
    )>, user_quotes: Array<(
      { __typename?: 'user_quote' }
      & Pick<User_Quote, 'quote_author' | 'quote_text'>
    )> }
  )> }
);

export type UserAboutMeQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserAboutMeQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name' | 'first_run'>
    & { extended_profiles?: Maybe<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'about_me' | 'experience_start_date' | 'life_outside_work_text'>
    )>, user_educations: Array<(
      { __typename?: 'user_education' }
      & Pick<User_Education, 'degree_name' | 'field_of_study' | 'school_name' | 'id'>
    )>, user_experiences: Array<(
      { __typename?: 'user_experience' }
      & Pick<User_Experience, 'start_date' | 'end_date' | 'name' | 'org_name' | 'org_image_base64' | 'id'>
    )> }
  )> }
);

export type LifeOutsideWorkQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type LifeOutsideWorkQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name'>
    & { extended_profiles?: Maybe<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'life_outside_work_text'>
    )> }
  )> }
);

export type UserEducationsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserEducationsQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & { user_educations: Array<(
      { __typename?: 'user_education' }
      & Pick<User_Education, 'degree_name' | 'field_of_study' | 'school_name' | 'id'>
    )> }
  )> }
);

export type GetUserEducationQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserEducationQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & { user_educations: Array<(
      { __typename?: 'user_education' }
      & Pick<User_Education, 'degree_name' | 'field_of_study' | 'school_name' | 'id'>
    )>, extended_profiles?: Maybe<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'experience_start_date'>
    )> }
  )> }
);

export type DeleteUserEducationsMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteUserEducationsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user_education?: Maybe<(
    { __typename?: 'user_education_mutation_response' }
    & Pick<User_Education_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpsertUserAboutMeMutationVariables = Exact<{
  about_me: Scalars['String'];
}>;


export type UpsertUserAboutMeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_extended_profile?: Maybe<(
    { __typename?: 'extended_profile_mutation_response' }
    & { returning: Array<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'about_me'>
    )> }
  )> }
);

export type UpsertUserExperienceStartMutationVariables = Exact<{
  experience_start_date: Scalars['date'];
}>;


export type UpsertUserExperienceStartMutation = (
  { __typename?: 'mutation_root' }
  & { insert_extended_profile?: Maybe<(
    { __typename?: 'extended_profile_mutation_response' }
    & { returning: Array<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'experience_start_date'>
    )> }
  )> }
);

export type UpsertUserPersonalMutationVariables = Exact<{
  lifeOutsideWorkText: Scalars['String'];
}>;


export type UpsertUserPersonalMutation = (
  { __typename?: 'mutation_root' }
  & { insert_extended_profile?: Maybe<(
    { __typename?: 'extended_profile_mutation_response' }
    & { returning: Array<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'life_outside_work_text'>
    )> }
  )> }
);

export type InsertUserEducationMutationVariables = Exact<{
  objects: Array<User_Education_Insert_Input>;
  experienceStartDate?: Maybe<Scalars['date']>;
}>;


export type InsertUserEducationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_education?: Maybe<(
    { __typename?: 'user_education_mutation_response' }
    & { returning: Array<(
      { __typename?: 'user_education' }
      & Pick<User_Education, 'degree_name'>
    )> }
  )>, insert_extended_profile?: Maybe<(
    { __typename?: 'extended_profile_mutation_response' }
    & { returning: Array<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'experience_start_date'>
    )> }
  )> }
);

export type GetUserExperiencesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserExperiencesQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & { user_experiences: Array<(
      { __typename?: 'user_experience' }
      & Pick<User_Experience, 'start_date' | 'end_date' | 'name' | 'org_name' | 'org_image_base64' | 'id'>
    )>, user_achievements: Array<(
      { __typename?: 'user_achievements' }
      & Pick<User_Achievements, 'url' | 'name' | 'image_base64' | 'id' | 'description'>
    )> }
  )> }
);

export type InsertUserExperienceMutationVariables = Exact<{
  objects: Array<User_Experience_Insert_Input>;
}>;


export type InsertUserExperienceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_experience?: Maybe<(
    { __typename?: 'user_experience_mutation_response' }
    & { returning: Array<(
      { __typename?: 'user_experience' }
      & Pick<User_Experience, 'name'>
    )> }
  )> }
);

export type DeleteUserExperiencesMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteUserExperiencesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user_experience?: Maybe<(
    { __typename?: 'user_experience_mutation_response' }
    & Pick<User_Experience_Mutation_Response, 'affected_rows'>
  )> }
);

export type SingleQuoteForUserIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type SingleQuoteForUserIdQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & { user_quotes: Array<(
      { __typename?: 'user_quote' }
      & Pick<User_Quote, 'quote_author' | 'quote_text'>
    )> }
  )> }
);

export type UpsertUserQuoteMutationVariables = Exact<{
  userId: Scalars['String'];
  quoteAuthor: Scalars['String'];
  quoteText: Scalars['String'];
}>;


export type UpsertUserQuoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_quote_one?: Maybe<(
    { __typename?: 'user_quote' }
    & Pick<User_Quote, 'quote_author' | 'quote_text'>
  )> }
);

export type AllFeedbackSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AllFeedbackSubscription = (
  { __typename?: 'subscription_root' }
  & { feedback: Array<(
    { __typename?: 'feedback' }
    & Pick<Feedback, 'text' | 'created_at'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'name' | 'id'>
    ) }
  )> }
);

export type RecentBadgesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type RecentBadgesSubscription = (
  { __typename?: 'subscription_root' }
  & { badge_awards: Array<(
    { __typename?: 'badge_awards' }
    & Pick<Badge_Awards, 'id' | 'badge_id' | 'created_at'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'name' | 'id'>
    ), userByBadgeeUserId: (
      { __typename?: 'users' }
      & Pick<Users, 'name' | 'id'>
    ) }
  )> }
);

export type BadgeWhereUser2IsBadgerAndUser1IsBadgeeQueryVariables = Exact<{
  user1: Scalars['String'];
  user2: Scalars['String'];
}>;


export type BadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery = (
  { __typename?: 'query_root' }
  & { badge_awards: Array<(
    { __typename?: 'badge_awards' }
    & Pick<Badge_Awards, 'badge_id' | 'created_at'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'name' | 'id'>
    ), userByBadgeeUserId: (
      { __typename?: 'users' }
      & Pick<Users, 'name' | 'id'>
    ) }
  )> }
);

export type BadgeWhereUser1IsBadgerAndUser2IsBadgeeQueryVariables = Exact<{
  user1: Scalars['String'];
  user2: Scalars['String'];
}>;


export type BadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery = (
  { __typename?: 'query_root' }
  & { badge_awards: Array<(
    { __typename?: 'badge_awards' }
    & Pick<Badge_Awards, 'badge_id' | 'created_at'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'name' | 'id'>
    ), userByBadgeeUserId: (
      { __typename?: 'users' }
      & Pick<Users, 'name' | 'id'>
    ) }
  )> }
);

export type SingleBadgeAwardQueryVariables = Exact<{
  badgeAwardId: Scalars['uuid'];
}>;


export type SingleBadgeAwardQuery = (
  { __typename?: 'query_root' }
  & { badge_awards: Array<(
    { __typename?: 'badge_awards' }
    & Pick<Badge_Awards, 'message' | 'badge_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'name'>
      & { user_images: Array<(
        { __typename?: 'user_images' }
        & Pick<User_Images, 'image_base64'>
      )> }
    ), userByBadgeeUserId: (
      { __typename?: 'users' }
      & Pick<Users, 'name'>
      & { user_images: Array<(
        { __typename?: 'user_images' }
        & Pick<User_Images, 'image_base64'>
      )> }
    ) }
  )> }
);

export type InsertUserUploadedImageMutationVariables = Exact<{
  image_base64: Scalars['String'];
}>;


export type InsertUserUploadedImageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_uploaded_images_one?: Maybe<(
    { __typename?: 'user_uploaded_images' }
    & Pick<User_Uploaded_Images, 'image_base64'>
  )> }
);

export type UserUploadedImagesByUserIdQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type UserUploadedImagesByUserIdQuery = (
  { __typename?: 'query_root' }
  & { user_uploaded_images: Array<(
    { __typename?: 'user_uploaded_images' }
    & Pick<User_Uploaded_Images, 'id' | 'image_base64' | 'blob_url'>
  )> }
);

export type DeleteUserImageByIdMutationVariables = Exact<{
  imageId: Scalars['uuid'];
}>;


export type DeleteUserImageByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user_uploaded_images_by_pk?: Maybe<(
    { __typename?: 'user_uploaded_images' }
    & Pick<User_Uploaded_Images, 'id'>
  )> }
);

export type InsertTalentForUserMutationVariables = Exact<{
  description: Scalars['String'];
  image_base64: Scalars['String'];
  name: Scalars['String'];
}>;


export type InsertTalentForUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_talents_one?: Maybe<(
    { __typename?: 'talents' }
    & Pick<Talents, 'id'>
  )> }
);

export type AllTalentsForUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AllTalentsForUserQuery = (
  { __typename?: 'query_root' }
  & { talents: Array<(
    { __typename?: 'talents' }
    & Pick<Talents, 'id' | 'name' | 'description' | 'image_base64' | 'created_at'>
  )> }
);

export type DeleteTalentByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTalentByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_talents_by_pk?: Maybe<(
    { __typename?: 'talents' }
    & Pick<Talents, 'id'>
  )> }
);

export type LastSeenUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type LastSeenUsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name' | 'last_seen'>
  )> }
);

export type RunSearchQueryVariables = Exact<{
  fullSpSiteUrl: Scalars['String'];
  query: Scalars['String'];
}>;


export type RunSearchQuery = (
  { __typename?: 'query_root' }
  & { search?: Maybe<(
    { __typename?: 'SearchQuery' }
    & Pick<SearchQuery, 'result'>
  )> }
);

export type MyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProfileQuery = (
  { __typename?: 'query_root' }
  & { profile?: Maybe<(
    { __typename?: 'GraphApiProfile' }
    & Pick<GraphApiProfile, 'id'>
  )> }
);

export type GetMyTenantSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyTenantSettingsQuery = (
  { __typename?: 'query_root' }
  & { tenants: Array<(
    { __typename?: 'tenants' }
    & Pick<Tenants, 'id' | 'name' | 'sharepoint_root_url'>
  )> }
);

export type GetUserExtendedProfileQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserExtendedProfileQuery = (
  { __typename?: 'query_root' }
  & { extended_profile: Array<(
    { __typename?: 'extended_profile' }
    & Pick<Extended_Profile, 'header_image_base64' | 'id' | 'created_at' | 'about_me' | 'updated_at'>
  )> }
);

export type UpsertHeaderImageMutationVariables = Exact<{
  header_image_base64: Scalars['String'];
}>;


export type UpsertHeaderImageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_extended_profile_one?: Maybe<(
    { __typename?: 'extended_profile' }
    & Pick<Extended_Profile, 'id'>
  )> }
);

export type GetTermGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTermGroupsQuery = (
  { __typename?: 'query_root' }
  & { termGroups: Array<(
    { __typename?: 'TermGroup' }
    & Pick<TermGroup, 'displayName' | 'id' | 'scope' | 'description' | 'createdDateTime'>
  )> }
);

export type AccountsForUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AccountsForUserQuery = (
  { __typename?: 'query_root' }
  & { accounts: Array<(
    { __typename?: 'accounts' }
    & Pick<Accounts, 'account_name' | 'id'>
  )> }
);

export type AllAccountsWithProjectsAndUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAccountsWithProjectsAndUsersQuery = (
  { __typename?: 'query_root' }
  & { accounts: Array<(
    { __typename?: 'accounts' }
    & Pick<Accounts, 'account_name'>
    & { projects: Array<(
      { __typename?: 'projects' }
      & Pick<Projects, 'project_location' | 'project_name'>
    )>, projects_aggregate: (
      { __typename?: 'projects_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'projects_aggregate_fields' }
        & Pick<Projects_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type AccountsWithProjectsSearchQueryVariables = Exact<{
  searchQuery: Scalars['String'];
}>;


export type AccountsWithProjectsSearchQuery = (
  { __typename?: 'query_root' }
  & { accounts: Array<(
    { __typename?: 'accounts' }
    & Pick<Accounts, 'account_name'>
    & { projects: Array<(
      { __typename?: 'projects' }
      & Pick<Projects, 'project_location' | 'project_name' | 'project_alias' | 'project_description' | 'is_hidden' | 'id'>
    )>, projects_aggregate: (
      { __typename?: 'projects_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'projects_aggregate_fields' }
        & Pick<Projects_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type ProjectsForUserByUserIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ProjectsForUserByUserIdQuery = (
  { __typename?: 'query_root' }
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'project_name' | 'project_location' | 'project_description' | 'project_alias' | 'id'>
    & { account?: Maybe<(
      { __typename?: 'accounts' }
      & Pick<Accounts, 'account_name' | 'account_image_base64' | 'id'>
    )> }
  )> }
);

export type ProjectsForUserByUserIdWithUsersQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ProjectsForUserByUserIdWithUsersQuery = (
  { __typename?: 'query_root' }
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'project_name' | 'project_location' | 'project_description' | 'project_alias' | 'id'>
    & { account?: Maybe<(
      { __typename?: 'accounts' }
      & Pick<Accounts, 'account_name' | 'account_image_base64' | 'id'>
    )>, project_users: Array<(
      { __typename?: 'project_users' }
      & { user?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'name'>
      )> }
    )> }
  )> }
);

export type UpdateProjectIsShownMutationVariables = Exact<{
  userId: Scalars['String'];
  projectId: Scalars['String'];
  isShown: Scalars['Boolean'];
}>;


export type UpdateProjectIsShownMutation = (
  { __typename?: 'mutation_root' }
  & { update_project_users?: Maybe<(
    { __typename?: 'project_users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'project_users' }
      & Pick<Project_Users, 'id'>
    )> }
  )> }
);

export type AllProjectsForUserIdIncludingNotShownQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AllProjectsForUserIdIncludingNotShownQuery = (
  { __typename?: 'query_root' }
  & { project_users: Array<(
    { __typename?: 'project_users' }
    & Pick<Project_Users, 'id' | 'is_shown'>
    & { project: (
      { __typename?: 'projects' }
      & Pick<Projects, 'project_alias' | 'project_name' | 'project_description' | 'id'>
    ) }
  )> }
);

export type SearchUsersWithPostgresQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;


export type SearchUsersWithPostgresQuery = (
  { __typename?: 'query_root' }
  & { search_users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name' | 'id'>
    & { badge_awards_aggregate: (
      { __typename?: 'badge_awards_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'badge_awards_aggregate_fields' }
        & Pick<Badge_Awards_Aggregate_Fields, 'count'>
      )> }
    ), badge_awards: Array<(
      { __typename?: 'badge_awards' }
      & Pick<Badge_Awards, 'badge_id'>
    )>, user_images: Array<(
      { __typename?: 'user_images' }
      & Pick<User_Images, 'image_base64'>
    )> }
  )> }
);

export type UsersForProjectByIdQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type UsersForProjectByIdQuery = (
  { __typename?: 'query_root' }
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id' | 'project_name' | 'project_location' | 'project_description' | 'project_alias' | 'is_hidden'>
    & { project_users: Array<(
      { __typename?: 'project_users' }
      & Pick<Project_Users, 'user_id'>
      & { user?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'name'>
        & { user_images: Array<(
          { __typename?: 'user_images' }
          & Pick<User_Images, 'image_base64'>
        )> }
      )> }
    )>, account?: Maybe<(
      { __typename?: 'accounts' }
      & Pick<Accounts, 'account_name'>
    )>, project_teches: Array<(
      { __typename?: 'project_tech' }
      & Pick<Project_Tech, 'tech_id'>
      & { tech_tag: (
        { __typename?: 'tech_tags' }
        & Pick<Tech_Tags, 'id' | 'tech_description' | 'tech_name'>
      ) }
    )> }
  )> }
);

export type SingleProjectPageSubscriptionVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type SingleProjectPageSubscription = (
  { __typename?: 'subscription_root' }
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'id' | 'project_name' | 'project_location' | 'project_description' | 'is_hidden'>
    & { project_users: Array<(
      { __typename?: 'project_users' }
      & Pick<Project_Users, 'user_id'>
      & { user?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'name'>
        & { user_images: Array<(
          { __typename?: 'user_images' }
          & Pick<User_Images, 'image_base64'>
        )> }
      )> }
    )>, account?: Maybe<(
      { __typename?: 'accounts' }
      & Pick<Accounts, 'account_name'>
    )> }
  )> }
);

export type UpdateProjectDescriptionMutationVariables = Exact<{
  projectId: Scalars['String'];
  newDescription: Scalars['String'];
  is_hidden?: Maybe<Scalars['Boolean']>;
  newAlias?: Maybe<Scalars['String']>;
}>;


export type UpdateProjectDescriptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_projects_by_pk?: Maybe<(
    { __typename?: 'projects' }
    & Pick<Projects, 'project_description'>
  )> }
);

export type InsertTechTagMutationVariables = Exact<{
  tech_description: Scalars['String'];
  tech_name: Scalars['String'];
}>;


export type InsertTechTagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tech_tags?: Maybe<(
    { __typename?: 'tech_tags_mutation_response' }
    & { returning: Array<(
      { __typename?: 'tech_tags' }
      & Pick<Tech_Tags, 'id'>
    )> }
  )> }
);

export type GetMostPopularTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMostPopularTagsQuery = (
  { __typename?: 'query_root' }
  & { tech_tags: Array<(
    { __typename?: 'tech_tags' }
    & Pick<Tech_Tags, 'tech_name' | 'tech_description' | 'id'>
  )> }
);

export type SearchTechTagsQueryVariables = Exact<{
  searchQuery: Scalars['String'];
}>;


export type SearchTechTagsQuery = (
  { __typename?: 'query_root' }
  & { tech_tags: Array<(
    { __typename?: 'tech_tags' }
    & Pick<Tech_Tags, 'tech_name' | 'tech_description' | 'id'>
  )> }
);

export type InsertProjectTechMutationVariables = Exact<{
  toInsert: Array<Project_Tech_Insert_Input>;
}>;


export type InsertProjectTechMutation = (
  { __typename?: 'mutation_root' }
  & { insert_project_tech?: Maybe<(
    { __typename?: 'project_tech_mutation_response' }
    & { returning: Array<(
      { __typename?: 'project_tech' }
      & { tech_tag: (
        { __typename?: 'tech_tags' }
        & Pick<Tech_Tags, 'id' | 'tech_name'>
      ) }
    )> }
  )> }
);

export type DeleteProjectTechMutationVariables = Exact<{
  project_id: Scalars['String'];
  tech_id: Scalars['uuid'];
}>;


export type DeleteProjectTechMutation = (
  { __typename?: 'mutation_root' }
  & { delete_project_tech_by_pk?: Maybe<(
    { __typename?: 'project_tech' }
    & Pick<Project_Tech, 'project_id' | 'tech_id'>
  )> }
);

export type DeleteProjectTechTagsMutationVariables = Exact<{
  project_id: Scalars['String'];
}>;


export type DeleteProjectTechTagsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_project_tech?: Maybe<(
    { __typename?: 'project_tech_mutation_response' }
    & Pick<Project_Tech_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetProjectsForTagQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProjectsForTagQuery = (
  { __typename?: 'query_root' }
  & { tech_tags: Array<(
    { __typename?: 'tech_tags' }
    & Pick<Tech_Tags, 'tech_name' | 'tech_description' | 'id'>
    & { project_teches: Array<(
      { __typename?: 'project_tech' }
      & { project: (
        { __typename?: 'projects' }
        & Pick<Projects, 'project_name'>
        & { project_teches: Array<(
          { __typename?: 'project_tech' }
          & { tech_tag: (
            { __typename?: 'tech_tags' }
            & Pick<Tech_Tags, 'id' | 'tech_name'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GetUserSocialByIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserSocialByIdQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & { extended_profiles?: Maybe<(
      { __typename?: 'extended_profile' }
      & Pick<Extended_Profile, 'alternate_phone' | 'social_url' | 'website_url'>
    )> }
  )> }
);

export type UpsertUserSocialMutationVariables = Exact<{
  socialUrl?: Maybe<Scalars['String']>;
  alternatePhone?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
}>;


export type UpsertUserSocialMutation = (
  { __typename?: 'mutation_root' }
  & { insert_extended_profile_one?: Maybe<(
    { __typename?: 'extended_profile' }
    & Pick<Extended_Profile, 'about_me' | 'social_url' | 'alternate_phone' | 'website_url'>
  )> }
);

export type GetUserWithAchievementsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserWithAchievementsQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & { user_achievements: Array<(
      { __typename?: 'user_achievements' }
      & Pick<User_Achievements, 'url' | 'name' | 'image_base64' | 'id' | 'description'>
    )> }
  )> }
);

export type InsertUserAchievementsMutationVariables = Exact<{
  objects: Array<User_Achievements_Insert_Input>;
}>;


export type InsertUserAchievementsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_achievements?: Maybe<(
    { __typename?: 'user_achievements_mutation_response' }
    & Pick<User_Achievements_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'user_achievements' }
      & Pick<User_Achievements, 'name'>
    )> }
  )> }
);

export type DeleteUserAchievementsMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteUserAchievementsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user_achievements?: Maybe<(
    { __typename?: 'user_achievements_mutation_response' }
    & Pick<User_Achievements_Mutation_Response, 'affected_rows'>
  )> }
);

export type FireTeamsMeetingNotificationMutationVariables = Exact<{
  input: TeamsMeetingNotificationInput;
}>;


export type FireTeamsMeetingNotificationMutation = (
  { __typename?: 'mutation_root' }
  & { teamsMeetingNotification?: Maybe<(
    { __typename?: 'TeamsMeetingNotificationOutput' }
    & Pick<TeamsMeetingNotificationOutput, 'id'>
  )> }
);

export type AllProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllProjectsQuery = (
  { __typename?: 'query_root' }
  & { projects: Array<(
    { __typename?: 'projects' }
    & Pick<Projects, 'project_name' | 'project_alias' | 'id'>
  )> }
);

export type UsersWithAnImageQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersWithAnImageQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'name'>
    & { user_images: Array<(
      { __typename?: 'user_images' }
      & Pick<User_Images, 'image_base64'>
    )> }
  )> }
);

export type MyQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MyQueryQuery = (
  { __typename?: 'query_root' }
  & { getAllChats?: Maybe<(
    { __typename?: 'SampleOutput' }
    & Pick<SampleOutput, 'data'>
  )> }
);


export const UsersDocument = gql`
    query Users {
  users {
    id
    tenant_id
    name
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserCountDocument = gql`
    query UserCount {
  users_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useUserCountQuery__
 *
 * To run a query within a React component, call `useUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserCountQuery, UserCountQueryVariables>) {
        return ApolloReactHooks.useQuery<UserCountQuery, UserCountQueryVariables>(UserCountDocument, baseOptions);
      }
export function useUserCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserCountQuery, UserCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserCountQuery, UserCountQueryVariables>(UserCountDocument, baseOptions);
        }
export type UserCountQueryHookResult = ReturnType<typeof useUserCountQuery>;
export type UserCountLazyQueryHookResult = ReturnType<typeof useUserCountLazyQuery>;
export type UserCountQueryResult = ApolloReactCommon.QueryResult<UserCountQuery, UserCountQueryVariables>;
export const InsertTenantWithUserDocument = gql`
    mutation InsertTenantWithUser($tenantName: String!, $userName: String!) {
  insert_tenants_one(object: {name: $tenantName, users: {data: {name: $userName}, on_conflict: {constraint: users_pkey, update_columns: last_seen}}}, on_conflict: {constraint: tenants_pkey, update_columns: name}) {
    id
    name
  }
}
    `;
export type InsertTenantWithUserMutationFn = ApolloReactCommon.MutationFunction<InsertTenantWithUserMutation, InsertTenantWithUserMutationVariables>;

/**
 * __useInsertTenantWithUserMutation__
 *
 * To run a mutation, you first call `useInsertTenantWithUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTenantWithUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTenantWithUserMutation, { data, loading, error }] = useInsertTenantWithUserMutation({
 *   variables: {
 *      tenantName: // value for 'tenantName'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useInsertTenantWithUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTenantWithUserMutation, InsertTenantWithUserMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertTenantWithUserMutation, InsertTenantWithUserMutationVariables>(InsertTenantWithUserDocument, baseOptions);
      }
export type InsertTenantWithUserMutationHookResult = ReturnType<typeof useInsertTenantWithUserMutation>;
export type InsertTenantWithUserMutationResult = ApolloReactCommon.MutationResult<InsertTenantWithUserMutation>;
export type InsertTenantWithUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTenantWithUserMutation, InsertTenantWithUserMutationVariables>;
export const InsertUserWithTenantDocument = gql`
    mutation InsertUserWithTenant($tenantName: String!, $userName: String!) {
  insert_users_one(object: {name: $userName, tenant: {data: {name: $tenantName}, on_conflict: {constraint: tenants_pkey, update_columns: name}}}, on_conflict: {constraint: users_pkey, update_columns: last_seen}) {
    id
    name
    first_run
  }
}
    `;
export type InsertUserWithTenantMutationFn = ApolloReactCommon.MutationFunction<InsertUserWithTenantMutation, InsertUserWithTenantMutationVariables>;

/**
 * __useInsertUserWithTenantMutation__
 *
 * To run a mutation, you first call `useInsertUserWithTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserWithTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserWithTenantMutation, { data, loading, error }] = useInsertUserWithTenantMutation({
 *   variables: {
 *      tenantName: // value for 'tenantName'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useInsertUserWithTenantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUserWithTenantMutation, InsertUserWithTenantMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUserWithTenantMutation, InsertUserWithTenantMutationVariables>(InsertUserWithTenantDocument, baseOptions);
      }
export type InsertUserWithTenantMutationHookResult = ReturnType<typeof useInsertUserWithTenantMutation>;
export type InsertUserWithTenantMutationResult = ApolloReactCommon.MutationResult<InsertUserWithTenantMutation>;
export type InsertUserWithTenantMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUserWithTenantMutation, InsertUserWithTenantMutationVariables>;
export const UpdateUserFirstRunDocument = gql`
    mutation UpdateUserFirstRun($firstRun: Boolean!, $userId: String!) {
  update_users(where: {id: {_eq: $userId}}, _set: {first_run: $firstRun}) {
    returning {
      first_run
    }
  }
}
    `;
export type UpdateUserFirstRunMutationFn = ApolloReactCommon.MutationFunction<UpdateUserFirstRunMutation, UpdateUserFirstRunMutationVariables>;

/**
 * __useUpdateUserFirstRunMutation__
 *
 * To run a mutation, you first call `useUpdateUserFirstRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFirstRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFirstRunMutation, { data, loading, error }] = useUpdateUserFirstRunMutation({
 *   variables: {
 *      firstRun: // value for 'firstRun'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserFirstRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserFirstRunMutation, UpdateUserFirstRunMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserFirstRunMutation, UpdateUserFirstRunMutationVariables>(UpdateUserFirstRunDocument, baseOptions);
      }
export type UpdateUserFirstRunMutationHookResult = ReturnType<typeof useUpdateUserFirstRunMutation>;
export type UpdateUserFirstRunMutationResult = ApolloReactCommon.MutationResult<UpdateUserFirstRunMutation>;
export type UpdateUserFirstRunMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserFirstRunMutation, UpdateUserFirstRunMutationVariables>;
export const InsertUserPhotoDocument = gql`
    mutation InsertUserPhoto($image_base64: String!) {
  insert_user_images_one(object: {image_base64: $image_base64}, on_conflict: {constraint: user_images_pkey, update_columns: image_base64}) {
    updated_at
  }
}
    `;
export type InsertUserPhotoMutationFn = ApolloReactCommon.MutationFunction<InsertUserPhotoMutation, InsertUserPhotoMutationVariables>;

/**
 * __useInsertUserPhotoMutation__
 *
 * To run a mutation, you first call `useInsertUserPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserPhotoMutation, { data, loading, error }] = useInsertUserPhotoMutation({
 *   variables: {
 *      image_base64: // value for 'image_base64'
 *   },
 * });
 */
export function useInsertUserPhotoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUserPhotoMutation, InsertUserPhotoMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUserPhotoMutation, InsertUserPhotoMutationVariables>(InsertUserPhotoDocument, baseOptions);
      }
export type InsertUserPhotoMutationHookResult = ReturnType<typeof useInsertUserPhotoMutation>;
export type InsertUserPhotoMutationResult = ApolloReactCommon.MutationResult<InsertUserPhotoMutation>;
export type InsertUserPhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUserPhotoMutation, InsertUserPhotoMutationVariables>;
export const CreateOrUpdateUserMutationDocument = gql`
    mutation CreateOrUpdateUserMutation($name: String!, $tenant_id: String!, $id: String!) {
  insert_users(objects: {name: $name, tenant_id: $tenant_id, id: $id}, on_conflict: {constraint: users_pkey, update_columns: [name, last_seen], where: {}}) {
    returning {
      id
      name
      tenant_id
      first_run
    }
  }
}
    `;
export type CreateOrUpdateUserMutationMutationFn = ApolloReactCommon.MutationFunction<CreateOrUpdateUserMutationMutation, CreateOrUpdateUserMutationMutationVariables>;

/**
 * __useCreateOrUpdateUserMutationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateUserMutationMutation, { data, loading, error }] = useCreateOrUpdateUserMutationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tenant_id: // value for 'tenant_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateOrUpdateUserMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrUpdateUserMutationMutation, CreateOrUpdateUserMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrUpdateUserMutationMutation, CreateOrUpdateUserMutationMutationVariables>(CreateOrUpdateUserMutationDocument, baseOptions);
      }
export type CreateOrUpdateUserMutationMutationHookResult = ReturnType<typeof useCreateOrUpdateUserMutationMutation>;
export type CreateOrUpdateUserMutationMutationResult = ApolloReactCommon.MutationResult<CreateOrUpdateUserMutationMutation>;
export type CreateOrUpdateUserMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrUpdateUserMutationMutation, CreateOrUpdateUserMutationMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    name
    id
    tenant_id
    first_run
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const InsertOnlineDocument = gql`
    mutation InsertOnline($user_id: String!) {
  insert_online(objects: {user_id: $user_id}) {
    returning {
      updated_at
    }
  }
}
    `;
export type InsertOnlineMutationFn = ApolloReactCommon.MutationFunction<InsertOnlineMutation, InsertOnlineMutationVariables>;

/**
 * __useInsertOnlineMutation__
 *
 * To run a mutation, you first call `useInsertOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOnlineMutation, { data, loading, error }] = useInsertOnlineMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useInsertOnlineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertOnlineMutation, InsertOnlineMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertOnlineMutation, InsertOnlineMutationVariables>(InsertOnlineDocument, baseOptions);
      }
export type InsertOnlineMutationHookResult = ReturnType<typeof useInsertOnlineMutation>;
export type InsertOnlineMutationResult = ApolloReactCommon.MutationResult<InsertOnlineMutation>;
export type InsertOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertOnlineMutation, InsertOnlineMutationVariables>;
export const WhoIsOnlineDocument = gql`
    subscription WhoIsOnline {
  online_now(where: {users: {}}, order_by: {users: {name: asc}}) {
    users {
      name
      tenant_id
    }
  }
}
    `;

/**
 * __useWhoIsOnlineSubscription__
 *
 * To run a query within a React component, call `useWhoIsOnlineSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWhoIsOnlineSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoIsOnlineSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWhoIsOnlineSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<WhoIsOnlineSubscription, WhoIsOnlineSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<WhoIsOnlineSubscription, WhoIsOnlineSubscriptionVariables>(WhoIsOnlineDocument, baseOptions);
      }
export type WhoIsOnlineSubscriptionHookResult = ReturnType<typeof useWhoIsOnlineSubscription>;
export type WhoIsOnlineSubscriptionResult = ApolloReactCommon.SubscriptionResult<WhoIsOnlineSubscription>;
export const InsertWhoIsOnlineDocument = gql`
    mutation InsertWhoIsOnline($url: String!) {
  insert_online_one(object: {url: $url}, on_conflict: {constraint: online_pkey, update_columns: [updated_at, url]}) {
    updated_at
    url
  }
}
    `;
export type InsertWhoIsOnlineMutationFn = ApolloReactCommon.MutationFunction<InsertWhoIsOnlineMutation, InsertWhoIsOnlineMutationVariables>;

/**
 * __useInsertWhoIsOnlineMutation__
 *
 * To run a mutation, you first call `useInsertWhoIsOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertWhoIsOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertWhoIsOnlineMutation, { data, loading, error }] = useInsertWhoIsOnlineMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useInsertWhoIsOnlineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertWhoIsOnlineMutation, InsertWhoIsOnlineMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertWhoIsOnlineMutation, InsertWhoIsOnlineMutationVariables>(InsertWhoIsOnlineDocument, baseOptions);
      }
export type InsertWhoIsOnlineMutationHookResult = ReturnType<typeof useInsertWhoIsOnlineMutation>;
export type InsertWhoIsOnlineMutationResult = ApolloReactCommon.MutationResult<InsertWhoIsOnlineMutation>;
export type InsertWhoIsOnlineMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertWhoIsOnlineMutation, InsertWhoIsOnlineMutationVariables>;
export const AppDataDocument = gql`
    subscription AppData {
  tenants(limit: 1) {
    apps {
      title
    }
    name
  }
}
    `;

/**
 * __useAppDataSubscription__
 *
 * To run a query within a React component, call `useAppDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAppDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDataSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAppDataSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AppDataSubscription, AppDataSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AppDataSubscription, AppDataSubscriptionVariables>(AppDataDocument, baseOptions);
      }
export type AppDataSubscriptionHookResult = ReturnType<typeof useAppDataSubscription>;
export type AppDataSubscriptionResult = ApolloReactCommon.SubscriptionResult<AppDataSubscription>;
export const InsertAlertDocument = gql`
    mutation InsertAlert($alert_text: String!) {
  insert_alerts_one(object: {alert_text: $alert_text}) {
    id
    alert_text
  }
}
    `;
export type InsertAlertMutationFn = ApolloReactCommon.MutationFunction<InsertAlertMutation, InsertAlertMutationVariables>;

/**
 * __useInsertAlertMutation__
 *
 * To run a mutation, you first call `useInsertAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAlertMutation, { data, loading, error }] = useInsertAlertMutation({
 *   variables: {
 *      alert_text: // value for 'alert_text'
 *   },
 * });
 */
export function useInsertAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertAlertMutation, InsertAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertAlertMutation, InsertAlertMutationVariables>(InsertAlertDocument, baseOptions);
      }
export type InsertAlertMutationHookResult = ReturnType<typeof useInsertAlertMutation>;
export type InsertAlertMutationResult = ApolloReactCommon.MutationResult<InsertAlertMutation>;
export type InsertAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertAlertMutation, InsertAlertMutationVariables>;
export const AcknowledgeAlertDocument = gql`
    mutation AcknowledgeAlert($alert_id: uuid!) {
  insert_alert_acknowledge(objects: {alert_id: $alert_id}) {
    returning {
      alert_id
    }
  }
}
    `;
export type AcknowledgeAlertMutationFn = ApolloReactCommon.MutationFunction<AcknowledgeAlertMutation, AcknowledgeAlertMutationVariables>;

/**
 * __useAcknowledgeAlertMutation__
 *
 * To run a mutation, you first call `useAcknowledgeAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeAlertMutation, { data, loading, error }] = useAcknowledgeAlertMutation({
 *   variables: {
 *      alert_id: // value for 'alert_id'
 *   },
 * });
 */
export function useAcknowledgeAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcknowledgeAlertMutation, AcknowledgeAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<AcknowledgeAlertMutation, AcknowledgeAlertMutationVariables>(AcknowledgeAlertDocument, baseOptions);
      }
export type AcknowledgeAlertMutationHookResult = ReturnType<typeof useAcknowledgeAlertMutation>;
export type AcknowledgeAlertMutationResult = ApolloReactCommon.MutationResult<AcknowledgeAlertMutation>;
export type AcknowledgeAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<AcknowledgeAlertMutation, AcknowledgeAlertMutationVariables>;
export const AlertsDocument = gql`
    subscription Alerts {
  alerts(where: {_not: {alert_acknowledges: {}}}) {
    alert_text
    id
  }
}
    `;

/**
 * __useAlertsSubscription__
 *
 * To run a query within a React component, call `useAlertsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAlertsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAlertsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AlertsSubscription, AlertsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AlertsSubscription, AlertsSubscriptionVariables>(AlertsDocument, baseOptions);
      }
export type AlertsSubscriptionHookResult = ReturnType<typeof useAlertsSubscription>;
export type AlertsSubscriptionResult = ApolloReactCommon.SubscriptionResult<AlertsSubscription>;
export const InsertClickDocument = gql`
    mutation InsertClick($x_position: numeric!, $y_position: numeric!) {
  insert_clicks_one(object: {x_position: $x_position, y_position: $y_position}) {
    x_position
    y_position
  }
}
    `;
export type InsertClickMutationFn = ApolloReactCommon.MutationFunction<InsertClickMutation, InsertClickMutationVariables>;

/**
 * __useInsertClickMutation__
 *
 * To run a mutation, you first call `useInsertClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClickMutation, { data, loading, error }] = useInsertClickMutation({
 *   variables: {
 *      x_position: // value for 'x_position'
 *      y_position: // value for 'y_position'
 *   },
 * });
 */
export function useInsertClickMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertClickMutation, InsertClickMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertClickMutation, InsertClickMutationVariables>(InsertClickDocument, baseOptions);
      }
export type InsertClickMutationHookResult = ReturnType<typeof useInsertClickMutation>;
export type InsertClickMutationResult = ApolloReactCommon.MutationResult<InsertClickMutation>;
export type InsertClickMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertClickMutation, InsertClickMutationVariables>;
export const ArmTemplatesDocument = gql`
    query ArmTemplates {
  arm_templates(order_by: {name: asc}) {
    name
    url
    description
  }
}
    `;

/**
 * __useArmTemplatesQuery__
 *
 * To run a query within a React component, call `useArmTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArmTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArmTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useArmTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArmTemplatesQuery, ArmTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ArmTemplatesQuery, ArmTemplatesQueryVariables>(ArmTemplatesDocument, baseOptions);
      }
export function useArmTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArmTemplatesQuery, ArmTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArmTemplatesQuery, ArmTemplatesQueryVariables>(ArmTemplatesDocument, baseOptions);
        }
export type ArmTemplatesQueryHookResult = ReturnType<typeof useArmTemplatesQuery>;
export type ArmTemplatesLazyQueryHookResult = ReturnType<typeof useArmTemplatesLazyQuery>;
export type ArmTemplatesQueryResult = ApolloReactCommon.QueryResult<ArmTemplatesQuery, ArmTemplatesQueryVariables>;
export const AllClicksDocument = gql`
    subscription AllClicks {
  clicks {
    x_position
    y_position
  }
}
    `;

/**
 * __useAllClicksSubscription__
 *
 * To run a query within a React component, call `useAllClicksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllClicksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllClicksSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAllClicksSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AllClicksSubscription, AllClicksSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AllClicksSubscription, AllClicksSubscriptionVariables>(AllClicksDocument, baseOptions);
      }
export type AllClicksSubscriptionHookResult = ReturnType<typeof useAllClicksSubscription>;
export type AllClicksSubscriptionResult = ApolloReactCommon.SubscriptionResult<AllClicksSubscription>;
export const InsertTeamsSessionDocument = gql`
    mutation InsertTeamsSession($sessionId: String!, $teams_context: jsonb!) {
  insert_teams_sessions_one(object: {id: $sessionId, teams_context: $teams_context}, on_conflict: {constraint: teams_sessions_pkey, update_columns: teams_context}) {
    id
    created_at
    updated_at
  }
}
    `;
export type InsertTeamsSessionMutationFn = ApolloReactCommon.MutationFunction<InsertTeamsSessionMutation, InsertTeamsSessionMutationVariables>;

/**
 * __useInsertTeamsSessionMutation__
 *
 * To run a mutation, you first call `useInsertTeamsSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeamsSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeamsSessionMutation, { data, loading, error }] = useInsertTeamsSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      teams_context: // value for 'teams_context'
 *   },
 * });
 */
export function useInsertTeamsSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTeamsSessionMutation, InsertTeamsSessionMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertTeamsSessionMutation, InsertTeamsSessionMutationVariables>(InsertTeamsSessionDocument, baseOptions);
      }
export type InsertTeamsSessionMutationHookResult = ReturnType<typeof useInsertTeamsSessionMutation>;
export type InsertTeamsSessionMutationResult = ApolloReactCommon.MutationResult<InsertTeamsSessionMutation>;
export type InsertTeamsSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTeamsSessionMutation, InsertTeamsSessionMutationVariables>;
export const InsertFeedbackDocument = gql`
    mutation InsertFeedback($text: String!) {
  insert_feedback_one(object: {text: $text}) {
    text
  }
}
    `;
export type InsertFeedbackMutationFn = ApolloReactCommon.MutationFunction<InsertFeedbackMutation, InsertFeedbackMutationVariables>;

/**
 * __useInsertFeedbackMutation__
 *
 * To run a mutation, you first call `useInsertFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeedbackMutation, { data, loading, error }] = useInsertFeedbackMutation({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useInsertFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertFeedbackMutation, InsertFeedbackMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertFeedbackMutation, InsertFeedbackMutationVariables>(InsertFeedbackDocument, baseOptions);
      }
export type InsertFeedbackMutationHookResult = ReturnType<typeof useInsertFeedbackMutation>;
export type InsertFeedbackMutationResult = ApolloReactCommon.MutationResult<InsertFeedbackMutation>;
export type InsertFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertFeedbackMutation, InsertFeedbackMutationVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($searchString: String!) {
  users(where: {name: {_ilike: $searchString}}, order_by: {badge_awards_aggregate: {count: desc}}) {
    name
    id
    badge_awards_aggregate {
      aggregate {
        count
      }
    }
    badge_awards {
      badge_id
    }
    user_images {
      image_base64
    }
    extended_profiles {
      header_image_base64
    }
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const SessionsForChatIdDocument = gql`
    query SessionsForChatId($chatId: jsonb!) {
  teams_sessions(where: {teams_context: {_contains: $chatId}}, distinct_on: user_id) {
    teams_context
    created_at
    updated_at
  }
}
    `;

/**
 * __useSessionsForChatIdQuery__
 *
 * To run a query within a React component, call `useSessionsForChatIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsForChatIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsForChatIdQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useSessionsForChatIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SessionsForChatIdQuery, SessionsForChatIdQueryVariables>) {
        return ApolloReactHooks.useQuery<SessionsForChatIdQuery, SessionsForChatIdQueryVariables>(SessionsForChatIdDocument, baseOptions);
      }
export function useSessionsForChatIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SessionsForChatIdQuery, SessionsForChatIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SessionsForChatIdQuery, SessionsForChatIdQueryVariables>(SessionsForChatIdDocument, baseOptions);
        }
export type SessionsForChatIdQueryHookResult = ReturnType<typeof useSessionsForChatIdQuery>;
export type SessionsForChatIdLazyQueryHookResult = ReturnType<typeof useSessionsForChatIdLazyQuery>;
export type SessionsForChatIdQueryResult = ApolloReactCommon.QueryResult<SessionsForChatIdQuery, SessionsForChatIdQueryVariables>;
export const PeopleInChatIdDocument = gql`
    subscription PeopleInChatId($chatId: jsonb!) {
  teams_sessions(where: {teams_context: {_contains: $chatId}}, distinct_on: user_id) {
    user_id
    teams_context
    created_at
    updated_at
    user {
      id
      name
      user_images {
        image_base64
      }
      extended_profiles {
        about_me
      }
    }
  }
}
    `;

/**
 * __usePeopleInChatIdSubscription__
 *
 * To run a query within a React component, call `usePeopleInChatIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePeopleInChatIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleInChatIdSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function usePeopleInChatIdSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PeopleInChatIdSubscription, PeopleInChatIdSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PeopleInChatIdSubscription, PeopleInChatIdSubscriptionVariables>(PeopleInChatIdDocument, baseOptions);
      }
export type PeopleInChatIdSubscriptionHookResult = ReturnType<typeof usePeopleInChatIdSubscription>;
export type PeopleInChatIdSubscriptionResult = ApolloReactCommon.SubscriptionResult<PeopleInChatIdSubscription>;
export const UserWithBadgesDocument = gql`
    query UserWithBadges($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    name
    user_images(limit: 1) {
      image_base64
    }
    badge_awards(where: {badgee_user_id: {_eq: $userId}}) {
      badge_id
    }
  }
}
    `;

/**
 * __useUserWithBadgesQuery__
 *
 * To run a query within a React component, call `useUserWithBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithBadgesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserWithBadgesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserWithBadgesQuery, UserWithBadgesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserWithBadgesQuery, UserWithBadgesQueryVariables>(UserWithBadgesDocument, baseOptions);
      }
export function useUserWithBadgesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWithBadgesQuery, UserWithBadgesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserWithBadgesQuery, UserWithBadgesQueryVariables>(UserWithBadgesDocument, baseOptions);
        }
export type UserWithBadgesQueryHookResult = ReturnType<typeof useUserWithBadgesQuery>;
export type UserWithBadgesLazyQueryHookResult = ReturnType<typeof useUserWithBadgesLazyQuery>;
export type UserWithBadgesQueryResult = ApolloReactCommon.QueryResult<UserWithBadgesQuery, UserWithBadgesQueryVariables>;
export const UserWithBadgesAndBadgersDocument = gql`
    query UserWithBadgesAndBadgers($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    name
    user_images(limit: 1) {
      image_base64
    }
    extended_profiles {
      header_image_base64
    }
    badge_awards(order_by: {created_at: desc}) {
      badge_id
      user {
        name
        user_images(limit: 1) {
          image_base64
        }
        id
      }
      created_at
      message
    }
  }
}
    `;

/**
 * __useUserWithBadgesAndBadgersQuery__
 *
 * To run a query within a React component, call `useUserWithBadgesAndBadgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithBadgesAndBadgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithBadgesAndBadgersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserWithBadgesAndBadgersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserWithBadgesAndBadgersQuery, UserWithBadgesAndBadgersQueryVariables>) {
        return ApolloReactHooks.useQuery<UserWithBadgesAndBadgersQuery, UserWithBadgesAndBadgersQueryVariables>(UserWithBadgesAndBadgersDocument, baseOptions);
      }
export function useUserWithBadgesAndBadgersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWithBadgesAndBadgersQuery, UserWithBadgesAndBadgersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserWithBadgesAndBadgersQuery, UserWithBadgesAndBadgersQueryVariables>(UserWithBadgesAndBadgersDocument, baseOptions);
        }
export type UserWithBadgesAndBadgersQueryHookResult = ReturnType<typeof useUserWithBadgesAndBadgersQuery>;
export type UserWithBadgesAndBadgersLazyQueryHookResult = ReturnType<typeof useUserWithBadgesAndBadgersLazyQuery>;
export type UserWithBadgesAndBadgersQueryResult = ApolloReactCommon.QueryResult<UserWithBadgesAndBadgersQuery, UserWithBadgesAndBadgersQueryVariables>;
export const UserProfileEditDocument = gql`
    query UserProfileEdit($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    name
    user_images(limit: 1) {
      image_base64
    }
    extended_profiles {
      header_image_base64
      about_me
      alternate_phone
      social_url
      website_url
    }
    talents {
      id
      image_base64
      name
      description
    }
    user_quotes(limit: 1) {
      quote_author
      quote_text
    }
  }
}
    `;

/**
 * __useUserProfileEditQuery__
 *
 * To run a query within a React component, call `useUserProfileEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileEditQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserProfileEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserProfileEditQuery, UserProfileEditQueryVariables>) {
        return ApolloReactHooks.useQuery<UserProfileEditQuery, UserProfileEditQueryVariables>(UserProfileEditDocument, baseOptions);
      }
export function useUserProfileEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfileEditQuery, UserProfileEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserProfileEditQuery, UserProfileEditQueryVariables>(UserProfileEditDocument, baseOptions);
        }
export type UserProfileEditQueryHookResult = ReturnType<typeof useUserProfileEditQuery>;
export type UserProfileEditLazyQueryHookResult = ReturnType<typeof useUserProfileEditLazyQuery>;
export type UserProfileEditQueryResult = ApolloReactCommon.QueryResult<UserProfileEditQuery, UserProfileEditQueryVariables>;
export const UserAboutMeDocument = gql`
    query UserAboutMe($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    name
    first_run
    extended_profiles {
      about_me
      experience_start_date
      life_outside_work_text
    }
    user_educations {
      degree_name
      field_of_study
      school_name
      id
    }
    user_experiences {
      start_date
      end_date
      name
      org_name
      org_image_base64
      id
    }
  }
}
    `;

/**
 * __useUserAboutMeQuery__
 *
 * To run a query within a React component, call `useUserAboutMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAboutMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAboutMeQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserAboutMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserAboutMeQuery, UserAboutMeQueryVariables>) {
        return ApolloReactHooks.useQuery<UserAboutMeQuery, UserAboutMeQueryVariables>(UserAboutMeDocument, baseOptions);
      }
export function useUserAboutMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAboutMeQuery, UserAboutMeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserAboutMeQuery, UserAboutMeQueryVariables>(UserAboutMeDocument, baseOptions);
        }
export type UserAboutMeQueryHookResult = ReturnType<typeof useUserAboutMeQuery>;
export type UserAboutMeLazyQueryHookResult = ReturnType<typeof useUserAboutMeLazyQuery>;
export type UserAboutMeQueryResult = ApolloReactCommon.QueryResult<UserAboutMeQuery, UserAboutMeQueryVariables>;
export const LifeOutsideWorkDocument = gql`
    query LifeOutsideWork($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    name
    extended_profiles {
      life_outside_work_text
    }
  }
}
    `;

/**
 * __useLifeOutsideWorkQuery__
 *
 * To run a query within a React component, call `useLifeOutsideWorkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLifeOutsideWorkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLifeOutsideWorkQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLifeOutsideWorkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LifeOutsideWorkQuery, LifeOutsideWorkQueryVariables>) {
        return ApolloReactHooks.useQuery<LifeOutsideWorkQuery, LifeOutsideWorkQueryVariables>(LifeOutsideWorkDocument, baseOptions);
      }
export function useLifeOutsideWorkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LifeOutsideWorkQuery, LifeOutsideWorkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LifeOutsideWorkQuery, LifeOutsideWorkQueryVariables>(LifeOutsideWorkDocument, baseOptions);
        }
export type LifeOutsideWorkQueryHookResult = ReturnType<typeof useLifeOutsideWorkQuery>;
export type LifeOutsideWorkLazyQueryHookResult = ReturnType<typeof useLifeOutsideWorkLazyQuery>;
export type LifeOutsideWorkQueryResult = ApolloReactCommon.QueryResult<LifeOutsideWorkQuery, LifeOutsideWorkQueryVariables>;
export const UserEducationsDocument = gql`
    query UserEducations($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    user_educations {
      degree_name
      field_of_study
      school_name
      id
    }
  }
}
    `;

/**
 * __useUserEducationsQuery__
 *
 * To run a query within a React component, call `useUserEducationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEducationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEducationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserEducationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserEducationsQuery, UserEducationsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserEducationsQuery, UserEducationsQueryVariables>(UserEducationsDocument, baseOptions);
      }
export function useUserEducationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserEducationsQuery, UserEducationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserEducationsQuery, UserEducationsQueryVariables>(UserEducationsDocument, baseOptions);
        }
export type UserEducationsQueryHookResult = ReturnType<typeof useUserEducationsQuery>;
export type UserEducationsLazyQueryHookResult = ReturnType<typeof useUserEducationsLazyQuery>;
export type UserEducationsQueryResult = ApolloReactCommon.QueryResult<UserEducationsQuery, UserEducationsQueryVariables>;
export const GetUserEducationDocument = gql`
    query GetUserEducation($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    user_educations {
      degree_name
      field_of_study
      school_name
      id
    }
    extended_profiles {
      experience_start_date
    }
  }
}
    `;

/**
 * __useGetUserEducationQuery__
 *
 * To run a query within a React component, call `useGetUserEducationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEducationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEducationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserEducationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserEducationQuery, GetUserEducationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserEducationQuery, GetUserEducationQueryVariables>(GetUserEducationDocument, baseOptions);
      }
export function useGetUserEducationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserEducationQuery, GetUserEducationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserEducationQuery, GetUserEducationQueryVariables>(GetUserEducationDocument, baseOptions);
        }
export type GetUserEducationQueryHookResult = ReturnType<typeof useGetUserEducationQuery>;
export type GetUserEducationLazyQueryHookResult = ReturnType<typeof useGetUserEducationLazyQuery>;
export type GetUserEducationQueryResult = ApolloReactCommon.QueryResult<GetUserEducationQuery, GetUserEducationQueryVariables>;
export const DeleteUserEducationsDocument = gql`
    mutation DeleteUserEducations($userId: String!) {
  delete_user_education(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
}
    `;
export type DeleteUserEducationsMutationFn = ApolloReactCommon.MutationFunction<DeleteUserEducationsMutation, DeleteUserEducationsMutationVariables>;

/**
 * __useDeleteUserEducationsMutation__
 *
 * To run a mutation, you first call `useDeleteUserEducationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserEducationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserEducationsMutation, { data, loading, error }] = useDeleteUserEducationsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserEducationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserEducationsMutation, DeleteUserEducationsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserEducationsMutation, DeleteUserEducationsMutationVariables>(DeleteUserEducationsDocument, baseOptions);
      }
export type DeleteUserEducationsMutationHookResult = ReturnType<typeof useDeleteUserEducationsMutation>;
export type DeleteUserEducationsMutationResult = ApolloReactCommon.MutationResult<DeleteUserEducationsMutation>;
export type DeleteUserEducationsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserEducationsMutation, DeleteUserEducationsMutationVariables>;
export const UpsertUserAboutMeDocument = gql`
    mutation UpsertUserAboutMe($about_me: String!) {
  insert_extended_profile(objects: {about_me: $about_me}, on_conflict: {constraint: extended_profile_pkey, update_columns: [about_me]}) {
    returning {
      about_me
    }
  }
}
    `;
export type UpsertUserAboutMeMutationFn = ApolloReactCommon.MutationFunction<UpsertUserAboutMeMutation, UpsertUserAboutMeMutationVariables>;

/**
 * __useUpsertUserAboutMeMutation__
 *
 * To run a mutation, you first call `useUpsertUserAboutMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserAboutMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserAboutMeMutation, { data, loading, error }] = useUpsertUserAboutMeMutation({
 *   variables: {
 *      about_me: // value for 'about_me'
 *   },
 * });
 */
export function useUpsertUserAboutMeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertUserAboutMeMutation, UpsertUserAboutMeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertUserAboutMeMutation, UpsertUserAboutMeMutationVariables>(UpsertUserAboutMeDocument, baseOptions);
      }
export type UpsertUserAboutMeMutationHookResult = ReturnType<typeof useUpsertUserAboutMeMutation>;
export type UpsertUserAboutMeMutationResult = ApolloReactCommon.MutationResult<UpsertUserAboutMeMutation>;
export type UpsertUserAboutMeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertUserAboutMeMutation, UpsertUserAboutMeMutationVariables>;
export const UpsertUserExperienceStartDocument = gql`
    mutation UpsertUserExperienceStart($experience_start_date: date!) {
  insert_extended_profile(objects: {experience_start_date: $experience_start_date}, on_conflict: {constraint: extended_profile_pkey, update_columns: [experience_start_date]}) {
    returning {
      experience_start_date
    }
  }
}
    `;
export type UpsertUserExperienceStartMutationFn = ApolloReactCommon.MutationFunction<UpsertUserExperienceStartMutation, UpsertUserExperienceStartMutationVariables>;

/**
 * __useUpsertUserExperienceStartMutation__
 *
 * To run a mutation, you first call `useUpsertUserExperienceStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserExperienceStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserExperienceStartMutation, { data, loading, error }] = useUpsertUserExperienceStartMutation({
 *   variables: {
 *      experience_start_date: // value for 'experience_start_date'
 *   },
 * });
 */
export function useUpsertUserExperienceStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertUserExperienceStartMutation, UpsertUserExperienceStartMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertUserExperienceStartMutation, UpsertUserExperienceStartMutationVariables>(UpsertUserExperienceStartDocument, baseOptions);
      }
export type UpsertUserExperienceStartMutationHookResult = ReturnType<typeof useUpsertUserExperienceStartMutation>;
export type UpsertUserExperienceStartMutationResult = ApolloReactCommon.MutationResult<UpsertUserExperienceStartMutation>;
export type UpsertUserExperienceStartMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertUserExperienceStartMutation, UpsertUserExperienceStartMutationVariables>;
export const UpsertUserPersonalDocument = gql`
    mutation UpsertUserPersonal($lifeOutsideWorkText: String!) {
  insert_extended_profile(objects: {life_outside_work_text: $lifeOutsideWorkText}, on_conflict: {constraint: extended_profile_pkey, update_columns: [life_outside_work_text]}) {
    returning {
      life_outside_work_text
    }
  }
}
    `;
export type UpsertUserPersonalMutationFn = ApolloReactCommon.MutationFunction<UpsertUserPersonalMutation, UpsertUserPersonalMutationVariables>;

/**
 * __useUpsertUserPersonalMutation__
 *
 * To run a mutation, you first call `useUpsertUserPersonalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserPersonalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserPersonalMutation, { data, loading, error }] = useUpsertUserPersonalMutation({
 *   variables: {
 *      lifeOutsideWorkText: // value for 'lifeOutsideWorkText'
 *   },
 * });
 */
export function useUpsertUserPersonalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertUserPersonalMutation, UpsertUserPersonalMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertUserPersonalMutation, UpsertUserPersonalMutationVariables>(UpsertUserPersonalDocument, baseOptions);
      }
export type UpsertUserPersonalMutationHookResult = ReturnType<typeof useUpsertUserPersonalMutation>;
export type UpsertUserPersonalMutationResult = ApolloReactCommon.MutationResult<UpsertUserPersonalMutation>;
export type UpsertUserPersonalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertUserPersonalMutation, UpsertUserPersonalMutationVariables>;
export const InsertUserEducationDocument = gql`
    mutation InsertUserEducation($objects: [user_education_insert_input!]!, $experienceStartDate: date) {
  insert_user_education(objects: $objects) {
    returning {
      degree_name
    }
  }
  insert_extended_profile(objects: {experience_start_date: $experienceStartDate}, on_conflict: {constraint: extended_profile_pkey, update_columns: [experience_start_date]}) {
    returning {
      experience_start_date
    }
  }
}
    `;
export type InsertUserEducationMutationFn = ApolloReactCommon.MutationFunction<InsertUserEducationMutation, InsertUserEducationMutationVariables>;

/**
 * __useInsertUserEducationMutation__
 *
 * To run a mutation, you first call `useInsertUserEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserEducationMutation, { data, loading, error }] = useInsertUserEducationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      experienceStartDate: // value for 'experienceStartDate'
 *   },
 * });
 */
export function useInsertUserEducationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUserEducationMutation, InsertUserEducationMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUserEducationMutation, InsertUserEducationMutationVariables>(InsertUserEducationDocument, baseOptions);
      }
export type InsertUserEducationMutationHookResult = ReturnType<typeof useInsertUserEducationMutation>;
export type InsertUserEducationMutationResult = ApolloReactCommon.MutationResult<InsertUserEducationMutation>;
export type InsertUserEducationMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUserEducationMutation, InsertUserEducationMutationVariables>;
export const GetUserExperiencesDocument = gql`
    query GetUserExperiences($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    user_experiences {
      start_date
      end_date
      name
      org_name
      org_image_base64
      id
    }
    user_achievements {
      url
      name
      image_base64
      id
      description
    }
  }
}
    `;

/**
 * __useGetUserExperiencesQuery__
 *
 * To run a query within a React component, call `useGetUserExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserExperiencesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserExperiencesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserExperiencesQuery, GetUserExperiencesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserExperiencesQuery, GetUserExperiencesQueryVariables>(GetUserExperiencesDocument, baseOptions);
      }
export function useGetUserExperiencesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserExperiencesQuery, GetUserExperiencesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserExperiencesQuery, GetUserExperiencesQueryVariables>(GetUserExperiencesDocument, baseOptions);
        }
export type GetUserExperiencesQueryHookResult = ReturnType<typeof useGetUserExperiencesQuery>;
export type GetUserExperiencesLazyQueryHookResult = ReturnType<typeof useGetUserExperiencesLazyQuery>;
export type GetUserExperiencesQueryResult = ApolloReactCommon.QueryResult<GetUserExperiencesQuery, GetUserExperiencesQueryVariables>;
export const InsertUserExperienceDocument = gql`
    mutation InsertUserExperience($objects: [user_experience_insert_input!]!) {
  insert_user_experience(objects: $objects, on_conflict: {constraint: user_experience_pkey, update_columns: [name]}) {
    returning {
      name
    }
  }
}
    `;
export type InsertUserExperienceMutationFn = ApolloReactCommon.MutationFunction<InsertUserExperienceMutation, InsertUserExperienceMutationVariables>;

/**
 * __useInsertUserExperienceMutation__
 *
 * To run a mutation, you first call `useInsertUserExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserExperienceMutation, { data, loading, error }] = useInsertUserExperienceMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertUserExperienceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUserExperienceMutation, InsertUserExperienceMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUserExperienceMutation, InsertUserExperienceMutationVariables>(InsertUserExperienceDocument, baseOptions);
      }
export type InsertUserExperienceMutationHookResult = ReturnType<typeof useInsertUserExperienceMutation>;
export type InsertUserExperienceMutationResult = ApolloReactCommon.MutationResult<InsertUserExperienceMutation>;
export type InsertUserExperienceMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUserExperienceMutation, InsertUserExperienceMutationVariables>;
export const DeleteUserExperiencesDocument = gql`
    mutation DeleteUserExperiences($userId: String!) {
  delete_user_experience(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
}
    `;
export type DeleteUserExperiencesMutationFn = ApolloReactCommon.MutationFunction<DeleteUserExperiencesMutation, DeleteUserExperiencesMutationVariables>;

/**
 * __useDeleteUserExperiencesMutation__
 *
 * To run a mutation, you first call `useDeleteUserExperiencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserExperiencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserExperiencesMutation, { data, loading, error }] = useDeleteUserExperiencesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserExperiencesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserExperiencesMutation, DeleteUserExperiencesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserExperiencesMutation, DeleteUserExperiencesMutationVariables>(DeleteUserExperiencesDocument, baseOptions);
      }
export type DeleteUserExperiencesMutationHookResult = ReturnType<typeof useDeleteUserExperiencesMutation>;
export type DeleteUserExperiencesMutationResult = ApolloReactCommon.MutationResult<DeleteUserExperiencesMutation>;
export type DeleteUserExperiencesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserExperiencesMutation, DeleteUserExperiencesMutationVariables>;
export const SingleQuoteForUserIdDocument = gql`
    query SingleQuoteForUserId($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    user_quotes(limit: 1) {
      quote_author
      quote_text
    }
  }
}
    `;

/**
 * __useSingleQuoteForUserIdQuery__
 *
 * To run a query within a React component, call `useSingleQuoteForUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleQuoteForUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleQuoteForUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSingleQuoteForUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SingleQuoteForUserIdQuery, SingleQuoteForUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<SingleQuoteForUserIdQuery, SingleQuoteForUserIdQueryVariables>(SingleQuoteForUserIdDocument, baseOptions);
      }
export function useSingleQuoteForUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SingleQuoteForUserIdQuery, SingleQuoteForUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SingleQuoteForUserIdQuery, SingleQuoteForUserIdQueryVariables>(SingleQuoteForUserIdDocument, baseOptions);
        }
export type SingleQuoteForUserIdQueryHookResult = ReturnType<typeof useSingleQuoteForUserIdQuery>;
export type SingleQuoteForUserIdLazyQueryHookResult = ReturnType<typeof useSingleQuoteForUserIdLazyQuery>;
export type SingleQuoteForUserIdQueryResult = ApolloReactCommon.QueryResult<SingleQuoteForUserIdQuery, SingleQuoteForUserIdQueryVariables>;
export const UpsertUserQuoteDocument = gql`
    mutation UpsertUserQuote($userId: String!, $quoteAuthor: String!, $quoteText: String!) {
  insert_user_quote_one(object: {quote_author: $quoteAuthor, quote_text: $quoteText}, on_conflict: {constraint: user_quote_pkey, update_columns: [quote_text, quote_author]}) {
    quote_author
    quote_text
  }
}
    `;
export type UpsertUserQuoteMutationFn = ApolloReactCommon.MutationFunction<UpsertUserQuoteMutation, UpsertUserQuoteMutationVariables>;

/**
 * __useUpsertUserQuoteMutation__
 *
 * To run a mutation, you first call `useUpsertUserQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserQuoteMutation, { data, loading, error }] = useUpsertUserQuoteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      quoteAuthor: // value for 'quoteAuthor'
 *      quoteText: // value for 'quoteText'
 *   },
 * });
 */
export function useUpsertUserQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertUserQuoteMutation, UpsertUserQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertUserQuoteMutation, UpsertUserQuoteMutationVariables>(UpsertUserQuoteDocument, baseOptions);
      }
export type UpsertUserQuoteMutationHookResult = ReturnType<typeof useUpsertUserQuoteMutation>;
export type UpsertUserQuoteMutationResult = ApolloReactCommon.MutationResult<UpsertUserQuoteMutation>;
export type UpsertUserQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertUserQuoteMutation, UpsertUserQuoteMutationVariables>;
export const AllFeedbackDocument = gql`
    subscription AllFeedback {
  feedback(order_by: {created_at: desc}) {
    text
    user {
      name
      id
    }
    created_at
  }
}
    `;

/**
 * __useAllFeedbackSubscription__
 *
 * To run a query within a React component, call `useAllFeedbackSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllFeedbackSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeedbackSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAllFeedbackSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<AllFeedbackSubscription, AllFeedbackSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<AllFeedbackSubscription, AllFeedbackSubscriptionVariables>(AllFeedbackDocument, baseOptions);
      }
export type AllFeedbackSubscriptionHookResult = ReturnType<typeof useAllFeedbackSubscription>;
export type AllFeedbackSubscriptionResult = ApolloReactCommon.SubscriptionResult<AllFeedbackSubscription>;
export const RecentBadgesDocument = gql`
    subscription RecentBadges {
  badge_awards(order_by: {created_at: desc}, limit: 10) {
    user {
      name
      id
    }
    userByBadgeeUserId {
      name
      id
    }
    id
    badge_id
    created_at
  }
}
    `;

/**
 * __useRecentBadgesSubscription__
 *
 * To run a query within a React component, call `useRecentBadgesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecentBadgesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentBadgesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRecentBadgesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RecentBadgesSubscription, RecentBadgesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RecentBadgesSubscription, RecentBadgesSubscriptionVariables>(RecentBadgesDocument, baseOptions);
      }
export type RecentBadgesSubscriptionHookResult = ReturnType<typeof useRecentBadgesSubscription>;
export type RecentBadgesSubscriptionResult = ApolloReactCommon.SubscriptionResult<RecentBadgesSubscription>;
export const BadgeWhereUser2IsBadgerAndUser1IsBadgeeDocument = gql`
    query BadgeWhereUser2IsBadgerAndUser1IsBadgee($user1: String!, $user2: String!) {
  badge_awards(order_by: {created_at: desc}, where: {_or: {_and: {badgee_user_id: {_eq: $user1}, badger_user_id: {_eq: $user2}}}}) {
    user {
      name
      id
    }
    userByBadgeeUserId {
      name
      id
    }
    badge_id
    created_at
  }
}
    `;

/**
 * __useBadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery__
 *
 * To run a query within a React component, call `useBadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery({
 *   variables: {
 *      user1: // value for 'user1'
 *      user2: // value for 'user2'
 *   },
 * });
 */
export function useBadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery, BadgeWhereUser2IsBadgerAndUser1IsBadgeeQueryVariables>) {
        return ApolloReactHooks.useQuery<BadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery, BadgeWhereUser2IsBadgerAndUser1IsBadgeeQueryVariables>(BadgeWhereUser2IsBadgerAndUser1IsBadgeeDocument, baseOptions);
      }
export function useBadgeWhereUser2IsBadgerAndUser1IsBadgeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery, BadgeWhereUser2IsBadgerAndUser1IsBadgeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery, BadgeWhereUser2IsBadgerAndUser1IsBadgeeQueryVariables>(BadgeWhereUser2IsBadgerAndUser1IsBadgeeDocument, baseOptions);
        }
export type BadgeWhereUser2IsBadgerAndUser1IsBadgeeQueryHookResult = ReturnType<typeof useBadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery>;
export type BadgeWhereUser2IsBadgerAndUser1IsBadgeeLazyQueryHookResult = ReturnType<typeof useBadgeWhereUser2IsBadgerAndUser1IsBadgeeLazyQuery>;
export type BadgeWhereUser2IsBadgerAndUser1IsBadgeeQueryResult = ApolloReactCommon.QueryResult<BadgeWhereUser2IsBadgerAndUser1IsBadgeeQuery, BadgeWhereUser2IsBadgerAndUser1IsBadgeeQueryVariables>;
export const BadgeWhereUser1IsBadgerAndUser2IsBadgeeDocument = gql`
    query BadgeWhereUser1IsBadgerAndUser2IsBadgee($user1: String!, $user2: String!) {
  badge_awards(order_by: {created_at: desc}, where: {_or: {_and: {badgee_user_id: {_eq: $user2}, badger_user_id: {_eq: $user1}}}}) {
    user {
      name
      id
    }
    userByBadgeeUserId {
      name
      id
    }
    badge_id
    created_at
  }
}
    `;

/**
 * __useBadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery__
 *
 * To run a query within a React component, call `useBadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery({
 *   variables: {
 *      user1: // value for 'user1'
 *      user2: // value for 'user2'
 *   },
 * });
 */
export function useBadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery, BadgeWhereUser1IsBadgerAndUser2IsBadgeeQueryVariables>) {
        return ApolloReactHooks.useQuery<BadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery, BadgeWhereUser1IsBadgerAndUser2IsBadgeeQueryVariables>(BadgeWhereUser1IsBadgerAndUser2IsBadgeeDocument, baseOptions);
      }
export function useBadgeWhereUser1IsBadgerAndUser2IsBadgeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery, BadgeWhereUser1IsBadgerAndUser2IsBadgeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery, BadgeWhereUser1IsBadgerAndUser2IsBadgeeQueryVariables>(BadgeWhereUser1IsBadgerAndUser2IsBadgeeDocument, baseOptions);
        }
export type BadgeWhereUser1IsBadgerAndUser2IsBadgeeQueryHookResult = ReturnType<typeof useBadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery>;
export type BadgeWhereUser1IsBadgerAndUser2IsBadgeeLazyQueryHookResult = ReturnType<typeof useBadgeWhereUser1IsBadgerAndUser2IsBadgeeLazyQuery>;
export type BadgeWhereUser1IsBadgerAndUser2IsBadgeeQueryResult = ApolloReactCommon.QueryResult<BadgeWhereUser1IsBadgerAndUser2IsBadgeeQuery, BadgeWhereUser1IsBadgerAndUser2IsBadgeeQueryVariables>;
export const SingleBadgeAwardDocument = gql`
    query SingleBadgeAward($badgeAwardId: uuid!) {
  badge_awards(limit: 1, where: {id: {_eq: $badgeAwardId}}) {
    message
    badge_id
    user {
      name
      user_images(limit: 1) {
        image_base64
      }
    }
    userByBadgeeUserId {
      name
      user_images(limit: 1) {
        image_base64
      }
    }
  }
}
    `;

/**
 * __useSingleBadgeAwardQuery__
 *
 * To run a query within a React component, call `useSingleBadgeAwardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleBadgeAwardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleBadgeAwardQuery({
 *   variables: {
 *      badgeAwardId: // value for 'badgeAwardId'
 *   },
 * });
 */
export function useSingleBadgeAwardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SingleBadgeAwardQuery, SingleBadgeAwardQueryVariables>) {
        return ApolloReactHooks.useQuery<SingleBadgeAwardQuery, SingleBadgeAwardQueryVariables>(SingleBadgeAwardDocument, baseOptions);
      }
export function useSingleBadgeAwardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SingleBadgeAwardQuery, SingleBadgeAwardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SingleBadgeAwardQuery, SingleBadgeAwardQueryVariables>(SingleBadgeAwardDocument, baseOptions);
        }
export type SingleBadgeAwardQueryHookResult = ReturnType<typeof useSingleBadgeAwardQuery>;
export type SingleBadgeAwardLazyQueryHookResult = ReturnType<typeof useSingleBadgeAwardLazyQuery>;
export type SingleBadgeAwardQueryResult = ApolloReactCommon.QueryResult<SingleBadgeAwardQuery, SingleBadgeAwardQueryVariables>;
export const InsertUserUploadedImageDocument = gql`
    mutation InsertUserUploadedImage($image_base64: String!) {
  insert_user_uploaded_images_one(object: {image_base64: $image_base64}) {
    image_base64
  }
}
    `;
export type InsertUserUploadedImageMutationFn = ApolloReactCommon.MutationFunction<InsertUserUploadedImageMutation, InsertUserUploadedImageMutationVariables>;

/**
 * __useInsertUserUploadedImageMutation__
 *
 * To run a mutation, you first call `useInsertUserUploadedImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserUploadedImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserUploadedImageMutation, { data, loading, error }] = useInsertUserUploadedImageMutation({
 *   variables: {
 *      image_base64: // value for 'image_base64'
 *   },
 * });
 */
export function useInsertUserUploadedImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUserUploadedImageMutation, InsertUserUploadedImageMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUserUploadedImageMutation, InsertUserUploadedImageMutationVariables>(InsertUserUploadedImageDocument, baseOptions);
      }
export type InsertUserUploadedImageMutationHookResult = ReturnType<typeof useInsertUserUploadedImageMutation>;
export type InsertUserUploadedImageMutationResult = ApolloReactCommon.MutationResult<InsertUserUploadedImageMutation>;
export type InsertUserUploadedImageMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUserUploadedImageMutation, InsertUserUploadedImageMutationVariables>;
export const UserUploadedImagesByUserIdDocument = gql`
    query UserUploadedImagesByUserId($user_id: String!) {
  user_uploaded_images(where: {user_id: {_eq: $user_id}}) {
    id
    image_base64
    blob_url
  }
}
    `;

/**
 * __useUserUploadedImagesByUserIdQuery__
 *
 * To run a query within a React component, call `useUserUploadedImagesByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUploadedImagesByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUploadedImagesByUserIdQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserUploadedImagesByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserUploadedImagesByUserIdQuery, UserUploadedImagesByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<UserUploadedImagesByUserIdQuery, UserUploadedImagesByUserIdQueryVariables>(UserUploadedImagesByUserIdDocument, baseOptions);
      }
export function useUserUploadedImagesByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserUploadedImagesByUserIdQuery, UserUploadedImagesByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserUploadedImagesByUserIdQuery, UserUploadedImagesByUserIdQueryVariables>(UserUploadedImagesByUserIdDocument, baseOptions);
        }
export type UserUploadedImagesByUserIdQueryHookResult = ReturnType<typeof useUserUploadedImagesByUserIdQuery>;
export type UserUploadedImagesByUserIdLazyQueryHookResult = ReturnType<typeof useUserUploadedImagesByUserIdLazyQuery>;
export type UserUploadedImagesByUserIdQueryResult = ApolloReactCommon.QueryResult<UserUploadedImagesByUserIdQuery, UserUploadedImagesByUserIdQueryVariables>;
export const DeleteUserImageByIdDocument = gql`
    mutation DeleteUserImageById($imageId: uuid!) {
  delete_user_uploaded_images_by_pk(id: $imageId) {
    id
  }
}
    `;
export type DeleteUserImageByIdMutationFn = ApolloReactCommon.MutationFunction<DeleteUserImageByIdMutation, DeleteUserImageByIdMutationVariables>;

/**
 * __useDeleteUserImageByIdMutation__
 *
 * To run a mutation, you first call `useDeleteUserImageByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserImageByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserImageByIdMutation, { data, loading, error }] = useDeleteUserImageByIdMutation({
 *   variables: {
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useDeleteUserImageByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserImageByIdMutation, DeleteUserImageByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserImageByIdMutation, DeleteUserImageByIdMutationVariables>(DeleteUserImageByIdDocument, baseOptions);
      }
export type DeleteUserImageByIdMutationHookResult = ReturnType<typeof useDeleteUserImageByIdMutation>;
export type DeleteUserImageByIdMutationResult = ApolloReactCommon.MutationResult<DeleteUserImageByIdMutation>;
export type DeleteUserImageByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserImageByIdMutation, DeleteUserImageByIdMutationVariables>;
export const InsertTalentForUserDocument = gql`
    mutation InsertTalentForUser($description: String!, $image_base64: String!, $name: String!) {
  insert_talents_one(object: {description: $description, image_base64: $image_base64, name: $name}) {
    id
  }
}
    `;
export type InsertTalentForUserMutationFn = ApolloReactCommon.MutationFunction<InsertTalentForUserMutation, InsertTalentForUserMutationVariables>;

/**
 * __useInsertTalentForUserMutation__
 *
 * To run a mutation, you first call `useInsertTalentForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTalentForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTalentForUserMutation, { data, loading, error }] = useInsertTalentForUserMutation({
 *   variables: {
 *      description: // value for 'description'
 *      image_base64: // value for 'image_base64'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertTalentForUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTalentForUserMutation, InsertTalentForUserMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertTalentForUserMutation, InsertTalentForUserMutationVariables>(InsertTalentForUserDocument, baseOptions);
      }
export type InsertTalentForUserMutationHookResult = ReturnType<typeof useInsertTalentForUserMutation>;
export type InsertTalentForUserMutationResult = ApolloReactCommon.MutationResult<InsertTalentForUserMutation>;
export type InsertTalentForUserMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTalentForUserMutation, InsertTalentForUserMutationVariables>;
export const AllTalentsForUserDocument = gql`
    query AllTalentsForUser($userId: String!) {
  talents(where: {user_id: {_eq: $userId}}, order_by: {created_at: desc}) {
    id
    name
    description
    image_base64
    created_at
  }
}
    `;

/**
 * __useAllTalentsForUserQuery__
 *
 * To run a query within a React component, call `useAllTalentsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTalentsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTalentsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllTalentsForUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllTalentsForUserQuery, AllTalentsForUserQueryVariables>) {
        return ApolloReactHooks.useQuery<AllTalentsForUserQuery, AllTalentsForUserQueryVariables>(AllTalentsForUserDocument, baseOptions);
      }
export function useAllTalentsForUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTalentsForUserQuery, AllTalentsForUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllTalentsForUserQuery, AllTalentsForUserQueryVariables>(AllTalentsForUserDocument, baseOptions);
        }
export type AllTalentsForUserQueryHookResult = ReturnType<typeof useAllTalentsForUserQuery>;
export type AllTalentsForUserLazyQueryHookResult = ReturnType<typeof useAllTalentsForUserLazyQuery>;
export type AllTalentsForUserQueryResult = ApolloReactCommon.QueryResult<AllTalentsForUserQuery, AllTalentsForUserQueryVariables>;
export const DeleteTalentByIdDocument = gql`
    mutation DeleteTalentById($id: uuid!) {
  delete_talents_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteTalentByIdMutationFn = ApolloReactCommon.MutationFunction<DeleteTalentByIdMutation, DeleteTalentByIdMutationVariables>;

/**
 * __useDeleteTalentByIdMutation__
 *
 * To run a mutation, you first call `useDeleteTalentByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTalentByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTalentByIdMutation, { data, loading, error }] = useDeleteTalentByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTalentByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTalentByIdMutation, DeleteTalentByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTalentByIdMutation, DeleteTalentByIdMutationVariables>(DeleteTalentByIdDocument, baseOptions);
      }
export type DeleteTalentByIdMutationHookResult = ReturnType<typeof useDeleteTalentByIdMutation>;
export type DeleteTalentByIdMutationResult = ApolloReactCommon.MutationResult<DeleteTalentByIdMutation>;
export type DeleteTalentByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTalentByIdMutation, DeleteTalentByIdMutationVariables>;
export const LastSeenUsersDocument = gql`
    query LastSeenUsers {
  users(order_by: {last_seen: desc}) {
    name
    last_seen
  }
}
    `;

/**
 * __useLastSeenUsersQuery__
 *
 * To run a query within a React component, call `useLastSeenUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastSeenUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastSeenUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastSeenUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LastSeenUsersQuery, LastSeenUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<LastSeenUsersQuery, LastSeenUsersQueryVariables>(LastSeenUsersDocument, baseOptions);
      }
export function useLastSeenUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LastSeenUsersQuery, LastSeenUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LastSeenUsersQuery, LastSeenUsersQueryVariables>(LastSeenUsersDocument, baseOptions);
        }
export type LastSeenUsersQueryHookResult = ReturnType<typeof useLastSeenUsersQuery>;
export type LastSeenUsersLazyQueryHookResult = ReturnType<typeof useLastSeenUsersLazyQuery>;
export type LastSeenUsersQueryResult = ApolloReactCommon.QueryResult<LastSeenUsersQuery, LastSeenUsersQueryVariables>;
export const RunSearchDocument = gql`
    query RunSearch($fullSpSiteUrl: String!, $query: String!) {
  search(fullSpSiteUrl: $fullSpSiteUrl, query: $query) {
    result
  }
}
    `;

/**
 * __useRunSearchQuery__
 *
 * To run a query within a React component, call `useRunSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSearchQuery({
 *   variables: {
 *      fullSpSiteUrl: // value for 'fullSpSiteUrl'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRunSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSearchQuery, RunSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSearchQuery, RunSearchQueryVariables>(RunSearchDocument, baseOptions);
      }
export function useRunSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSearchQuery, RunSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSearchQuery, RunSearchQueryVariables>(RunSearchDocument, baseOptions);
        }
export type RunSearchQueryHookResult = ReturnType<typeof useRunSearchQuery>;
export type RunSearchLazyQueryHookResult = ReturnType<typeof useRunSearchLazyQuery>;
export type RunSearchQueryResult = ApolloReactCommon.QueryResult<RunSearchQuery, RunSearchQueryVariables>;
export const MyProfileDocument = gql`
    query MyProfile {
  profile {
    id
  }
}
    `;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, baseOptions);
      }
export function useMyProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, baseOptions);
        }
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileQueryResult = ApolloReactCommon.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export const GetMyTenantSettingsDocument = gql`
    query GetMyTenantSettings {
  tenants(limit: 1) {
    id
    name
    sharepoint_root_url
  }
}
    `;

/**
 * __useGetMyTenantSettingsQuery__
 *
 * To run a query within a React component, call `useGetMyTenantSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTenantSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTenantSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyTenantSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyTenantSettingsQuery, GetMyTenantSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyTenantSettingsQuery, GetMyTenantSettingsQueryVariables>(GetMyTenantSettingsDocument, baseOptions);
      }
export function useGetMyTenantSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyTenantSettingsQuery, GetMyTenantSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyTenantSettingsQuery, GetMyTenantSettingsQueryVariables>(GetMyTenantSettingsDocument, baseOptions);
        }
export type GetMyTenantSettingsQueryHookResult = ReturnType<typeof useGetMyTenantSettingsQuery>;
export type GetMyTenantSettingsLazyQueryHookResult = ReturnType<typeof useGetMyTenantSettingsLazyQuery>;
export type GetMyTenantSettingsQueryResult = ApolloReactCommon.QueryResult<GetMyTenantSettingsQuery, GetMyTenantSettingsQueryVariables>;
export const GetUserExtendedProfileDocument = gql`
    query GetUserExtendedProfile($userId: String!) {
  extended_profile(where: {user_id: {_eq: $userId}}) {
    header_image_base64
    id
    created_at
    about_me
    updated_at
  }
}
    `;

/**
 * __useGetUserExtendedProfileQuery__
 *
 * To run a query within a React component, call `useGetUserExtendedProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserExtendedProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserExtendedProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserExtendedProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserExtendedProfileQuery, GetUserExtendedProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserExtendedProfileQuery, GetUserExtendedProfileQueryVariables>(GetUserExtendedProfileDocument, baseOptions);
      }
export function useGetUserExtendedProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserExtendedProfileQuery, GetUserExtendedProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserExtendedProfileQuery, GetUserExtendedProfileQueryVariables>(GetUserExtendedProfileDocument, baseOptions);
        }
export type GetUserExtendedProfileQueryHookResult = ReturnType<typeof useGetUserExtendedProfileQuery>;
export type GetUserExtendedProfileLazyQueryHookResult = ReturnType<typeof useGetUserExtendedProfileLazyQuery>;
export type GetUserExtendedProfileQueryResult = ApolloReactCommon.QueryResult<GetUserExtendedProfileQuery, GetUserExtendedProfileQueryVariables>;
export const UpsertHeaderImageDocument = gql`
    mutation UpsertHeaderImage($header_image_base64: String!) {
  insert_extended_profile_one(object: {header_image_base64: $header_image_base64}, on_conflict: {constraint: extended_profile_pkey, update_columns: header_image_base64}) {
    id
  }
}
    `;
export type UpsertHeaderImageMutationFn = ApolloReactCommon.MutationFunction<UpsertHeaderImageMutation, UpsertHeaderImageMutationVariables>;

/**
 * __useUpsertHeaderImageMutation__
 *
 * To run a mutation, you first call `useUpsertHeaderImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertHeaderImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertHeaderImageMutation, { data, loading, error }] = useUpsertHeaderImageMutation({
 *   variables: {
 *      header_image_base64: // value for 'header_image_base64'
 *   },
 * });
 */
export function useUpsertHeaderImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertHeaderImageMutation, UpsertHeaderImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertHeaderImageMutation, UpsertHeaderImageMutationVariables>(UpsertHeaderImageDocument, baseOptions);
      }
export type UpsertHeaderImageMutationHookResult = ReturnType<typeof useUpsertHeaderImageMutation>;
export type UpsertHeaderImageMutationResult = ApolloReactCommon.MutationResult<UpsertHeaderImageMutation>;
export type UpsertHeaderImageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertHeaderImageMutation, UpsertHeaderImageMutationVariables>;
export const GetTermGroupsDocument = gql`
    query GetTermGroups {
  termGroups {
    displayName
    id
    scope
    description
    createdDateTime
  }
}
    `;

/**
 * __useGetTermGroupsQuery__
 *
 * To run a query within a React component, call `useGetTermGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTermGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTermGroupsQuery, GetTermGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTermGroupsQuery, GetTermGroupsQueryVariables>(GetTermGroupsDocument, baseOptions);
      }
export function useGetTermGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTermGroupsQuery, GetTermGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTermGroupsQuery, GetTermGroupsQueryVariables>(GetTermGroupsDocument, baseOptions);
        }
export type GetTermGroupsQueryHookResult = ReturnType<typeof useGetTermGroupsQuery>;
export type GetTermGroupsLazyQueryHookResult = ReturnType<typeof useGetTermGroupsLazyQuery>;
export type GetTermGroupsQueryResult = ApolloReactCommon.QueryResult<GetTermGroupsQuery, GetTermGroupsQueryVariables>;
export const AccountsForUserDocument = gql`
    query AccountsForUser($userId: String!) {
  accounts(where: {projects: {project_users: {user_id: {_eq: $userId}}}}, order_by: {account_name: asc}) {
    account_name
    id
  }
}
    `;

/**
 * __useAccountsForUserQuery__
 *
 * To run a query within a React component, call `useAccountsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAccountsForUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountsForUserQuery, AccountsForUserQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountsForUserQuery, AccountsForUserQueryVariables>(AccountsForUserDocument, baseOptions);
      }
export function useAccountsForUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountsForUserQuery, AccountsForUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountsForUserQuery, AccountsForUserQueryVariables>(AccountsForUserDocument, baseOptions);
        }
export type AccountsForUserQueryHookResult = ReturnType<typeof useAccountsForUserQuery>;
export type AccountsForUserLazyQueryHookResult = ReturnType<typeof useAccountsForUserLazyQuery>;
export type AccountsForUserQueryResult = ApolloReactCommon.QueryResult<AccountsForUserQuery, AccountsForUserQueryVariables>;
export const AllAccountsWithProjectsAndUsersDocument = gql`
    query AllAccountsWithProjectsAndUsers {
  accounts(order_by: {account_name: asc}) {
    account_name
    projects {
      project_location
      project_name
    }
    projects_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useAllAccountsWithProjectsAndUsersQuery__
 *
 * To run a query within a React component, call `useAllAccountsWithProjectsAndUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAccountsWithProjectsAndUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAccountsWithProjectsAndUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAccountsWithProjectsAndUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllAccountsWithProjectsAndUsersQuery, AllAccountsWithProjectsAndUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<AllAccountsWithProjectsAndUsersQuery, AllAccountsWithProjectsAndUsersQueryVariables>(AllAccountsWithProjectsAndUsersDocument, baseOptions);
      }
export function useAllAccountsWithProjectsAndUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllAccountsWithProjectsAndUsersQuery, AllAccountsWithProjectsAndUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllAccountsWithProjectsAndUsersQuery, AllAccountsWithProjectsAndUsersQueryVariables>(AllAccountsWithProjectsAndUsersDocument, baseOptions);
        }
export type AllAccountsWithProjectsAndUsersQueryHookResult = ReturnType<typeof useAllAccountsWithProjectsAndUsersQuery>;
export type AllAccountsWithProjectsAndUsersLazyQueryHookResult = ReturnType<typeof useAllAccountsWithProjectsAndUsersLazyQuery>;
export type AllAccountsWithProjectsAndUsersQueryResult = ApolloReactCommon.QueryResult<AllAccountsWithProjectsAndUsersQuery, AllAccountsWithProjectsAndUsersQueryVariables>;
export const AccountsWithProjectsSearchDocument = gql`
    query AccountsWithProjectsSearch($searchQuery: String!) {
  accounts(order_by: {account_name: asc}, where: {account_name: {_ilike: $searchQuery}}) {
    account_name
    projects(where: {_not: {is_hidden: {_eq: true, _is_null: false}}}) {
      project_location
      project_name
      project_alias
      project_description
      is_hidden
      id
    }
    projects_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useAccountsWithProjectsSearchQuery__
 *
 * To run a query within a React component, call `useAccountsWithProjectsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsWithProjectsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsWithProjectsSearchQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useAccountsWithProjectsSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountsWithProjectsSearchQuery, AccountsWithProjectsSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountsWithProjectsSearchQuery, AccountsWithProjectsSearchQueryVariables>(AccountsWithProjectsSearchDocument, baseOptions);
      }
export function useAccountsWithProjectsSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountsWithProjectsSearchQuery, AccountsWithProjectsSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountsWithProjectsSearchQuery, AccountsWithProjectsSearchQueryVariables>(AccountsWithProjectsSearchDocument, baseOptions);
        }
export type AccountsWithProjectsSearchQueryHookResult = ReturnType<typeof useAccountsWithProjectsSearchQuery>;
export type AccountsWithProjectsSearchLazyQueryHookResult = ReturnType<typeof useAccountsWithProjectsSearchLazyQuery>;
export type AccountsWithProjectsSearchQueryResult = ApolloReactCommon.QueryResult<AccountsWithProjectsSearchQuery, AccountsWithProjectsSearchQueryVariables>;
export const ProjectsForUserByUserIdDocument = gql`
    query ProjectsForUserByUserId($userId: String!) {
  projects(where: {project_users: {user_id: {_eq: $userId}}}, order_by: {project_name: asc}) {
    project_name
    project_location
    project_description
    project_alias
    id
    account {
      account_name
      account_image_base64
      id
    }
  }
}
    `;

/**
 * __useProjectsForUserByUserIdQuery__
 *
 * To run a query within a React component, call `useProjectsForUserByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForUserByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForUserByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProjectsForUserByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsForUserByUserIdQuery, ProjectsForUserByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectsForUserByUserIdQuery, ProjectsForUserByUserIdQueryVariables>(ProjectsForUserByUserIdDocument, baseOptions);
      }
export function useProjectsForUserByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsForUserByUserIdQuery, ProjectsForUserByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectsForUserByUserIdQuery, ProjectsForUserByUserIdQueryVariables>(ProjectsForUserByUserIdDocument, baseOptions);
        }
export type ProjectsForUserByUserIdQueryHookResult = ReturnType<typeof useProjectsForUserByUserIdQuery>;
export type ProjectsForUserByUserIdLazyQueryHookResult = ReturnType<typeof useProjectsForUserByUserIdLazyQuery>;
export type ProjectsForUserByUserIdQueryResult = ApolloReactCommon.QueryResult<ProjectsForUserByUserIdQuery, ProjectsForUserByUserIdQueryVariables>;
export const ProjectsForUserByUserIdWithUsersDocument = gql`
    query ProjectsForUserByUserIdWithUsers($userId: String!) {
  projects(where: {project_users: {user_id: {_eq: $userId}}}, order_by: {project_name: asc}) {
    project_name
    project_location
    project_description
    project_alias
    id
    account {
      account_name
      account_image_base64
      id
    }
    project_users {
      user {
        name
      }
    }
  }
}
    `;

/**
 * __useProjectsForUserByUserIdWithUsersQuery__
 *
 * To run a query within a React component, call `useProjectsForUserByUserIdWithUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsForUserByUserIdWithUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsForUserByUserIdWithUsersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProjectsForUserByUserIdWithUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsForUserByUserIdWithUsersQuery, ProjectsForUserByUserIdWithUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectsForUserByUserIdWithUsersQuery, ProjectsForUserByUserIdWithUsersQueryVariables>(ProjectsForUserByUserIdWithUsersDocument, baseOptions);
      }
export function useProjectsForUserByUserIdWithUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsForUserByUserIdWithUsersQuery, ProjectsForUserByUserIdWithUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectsForUserByUserIdWithUsersQuery, ProjectsForUserByUserIdWithUsersQueryVariables>(ProjectsForUserByUserIdWithUsersDocument, baseOptions);
        }
export type ProjectsForUserByUserIdWithUsersQueryHookResult = ReturnType<typeof useProjectsForUserByUserIdWithUsersQuery>;
export type ProjectsForUserByUserIdWithUsersLazyQueryHookResult = ReturnType<typeof useProjectsForUserByUserIdWithUsersLazyQuery>;
export type ProjectsForUserByUserIdWithUsersQueryResult = ApolloReactCommon.QueryResult<ProjectsForUserByUserIdWithUsersQuery, ProjectsForUserByUserIdWithUsersQueryVariables>;
export const UpdateProjectIsShownDocument = gql`
    mutation UpdateProjectIsShown($userId: String!, $projectId: String!, $isShown: Boolean!) {
  update_project_users(where: {_and: {project_id: {_eq: $projectId}, user_id: {_eq: $userId}}}, _set: {is_shown: $isShown}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateProjectIsShownMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectIsShownMutation, UpdateProjectIsShownMutationVariables>;

/**
 * __useUpdateProjectIsShownMutation__
 *
 * To run a mutation, you first call `useUpdateProjectIsShownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectIsShownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectIsShownMutation, { data, loading, error }] = useUpdateProjectIsShownMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *      isShown: // value for 'isShown'
 *   },
 * });
 */
export function useUpdateProjectIsShownMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectIsShownMutation, UpdateProjectIsShownMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectIsShownMutation, UpdateProjectIsShownMutationVariables>(UpdateProjectIsShownDocument, baseOptions);
      }
export type UpdateProjectIsShownMutationHookResult = ReturnType<typeof useUpdateProjectIsShownMutation>;
export type UpdateProjectIsShownMutationResult = ApolloReactCommon.MutationResult<UpdateProjectIsShownMutation>;
export type UpdateProjectIsShownMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectIsShownMutation, UpdateProjectIsShownMutationVariables>;
export const AllProjectsForUserIdIncludingNotShownDocument = gql`
    query AllProjectsForUserIdIncludingNotShown($userId: String!) {
  project_users(where: {user_id: {_eq: $userId}}, order_by: {project: {project_name: asc}}) {
    id
    is_shown
    project {
      project_alias
      project_name
      project_description
      id
    }
  }
}
    `;

/**
 * __useAllProjectsForUserIdIncludingNotShownQuery__
 *
 * To run a query within a React component, call `useAllProjectsForUserIdIncludingNotShownQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsForUserIdIncludingNotShownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsForUserIdIncludingNotShownQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllProjectsForUserIdIncludingNotShownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllProjectsForUserIdIncludingNotShownQuery, AllProjectsForUserIdIncludingNotShownQueryVariables>) {
        return ApolloReactHooks.useQuery<AllProjectsForUserIdIncludingNotShownQuery, AllProjectsForUserIdIncludingNotShownQueryVariables>(AllProjectsForUserIdIncludingNotShownDocument, baseOptions);
      }
export function useAllProjectsForUserIdIncludingNotShownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllProjectsForUserIdIncludingNotShownQuery, AllProjectsForUserIdIncludingNotShownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllProjectsForUserIdIncludingNotShownQuery, AllProjectsForUserIdIncludingNotShownQueryVariables>(AllProjectsForUserIdIncludingNotShownDocument, baseOptions);
        }
export type AllProjectsForUserIdIncludingNotShownQueryHookResult = ReturnType<typeof useAllProjectsForUserIdIncludingNotShownQuery>;
export type AllProjectsForUserIdIncludingNotShownLazyQueryHookResult = ReturnType<typeof useAllProjectsForUserIdIncludingNotShownLazyQuery>;
export type AllProjectsForUserIdIncludingNotShownQueryResult = ApolloReactCommon.QueryResult<AllProjectsForUserIdIncludingNotShownQuery, AllProjectsForUserIdIncludingNotShownQueryVariables>;
export const SearchUsersWithPostgresDocument = gql`
    query SearchUsersWithPostgres($searchText: String!) {
  search_users(args: {search: $searchText}) {
    name
    id
    badge_awards_aggregate {
      aggregate {
        count
      }
    }
    badge_awards {
      badge_id
    }
    user_images {
      image_base64
    }
  }
}
    `;

/**
 * __useSearchUsersWithPostgresQuery__
 *
 * To run a query within a React component, call `useSearchUsersWithPostgresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersWithPostgresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersWithPostgresQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useSearchUsersWithPostgresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersWithPostgresQuery, SearchUsersWithPostgresQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUsersWithPostgresQuery, SearchUsersWithPostgresQueryVariables>(SearchUsersWithPostgresDocument, baseOptions);
      }
export function useSearchUsersWithPostgresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersWithPostgresQuery, SearchUsersWithPostgresQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUsersWithPostgresQuery, SearchUsersWithPostgresQueryVariables>(SearchUsersWithPostgresDocument, baseOptions);
        }
export type SearchUsersWithPostgresQueryHookResult = ReturnType<typeof useSearchUsersWithPostgresQuery>;
export type SearchUsersWithPostgresLazyQueryHookResult = ReturnType<typeof useSearchUsersWithPostgresLazyQuery>;
export type SearchUsersWithPostgresQueryResult = ApolloReactCommon.QueryResult<SearchUsersWithPostgresQuery, SearchUsersWithPostgresQueryVariables>;
export const UsersForProjectByIdDocument = gql`
    query UsersForProjectById($projectId: String!) {
  projects(limit: 1, where: {id: {_eq: $projectId}}) {
    id
    project_name
    project_location
    project_description
    project_alias
    is_hidden
    project_users(order_by: {user: {name: asc}}, where: {is_shown: {_eq: true}}) {
      user_id
      user {
        id
        name
        user_images(limit: 1) {
          image_base64
        }
      }
    }
    account {
      account_name
    }
    project_teches {
      tech_id
      tech_tag {
        id
        tech_description
        tech_name
      }
    }
  }
}
    `;

/**
 * __useUsersForProjectByIdQuery__
 *
 * To run a query within a React component, call `useUsersForProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersForProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersForProjectByIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUsersForProjectByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersForProjectByIdQuery, UsersForProjectByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersForProjectByIdQuery, UsersForProjectByIdQueryVariables>(UsersForProjectByIdDocument, baseOptions);
      }
export function useUsersForProjectByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersForProjectByIdQuery, UsersForProjectByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersForProjectByIdQuery, UsersForProjectByIdQueryVariables>(UsersForProjectByIdDocument, baseOptions);
        }
export type UsersForProjectByIdQueryHookResult = ReturnType<typeof useUsersForProjectByIdQuery>;
export type UsersForProjectByIdLazyQueryHookResult = ReturnType<typeof useUsersForProjectByIdLazyQuery>;
export type UsersForProjectByIdQueryResult = ApolloReactCommon.QueryResult<UsersForProjectByIdQuery, UsersForProjectByIdQueryVariables>;
export const SingleProjectPageDocument = gql`
    subscription SingleProjectPage($projectId: String!) {
  projects(limit: 1, where: {id: {_eq: $projectId}}) {
    id
    project_name
    project_location
    project_description
    is_hidden
    project_users(order_by: {user: {name: asc}}) {
      user_id
      user {
        id
        name
        user_images(limit: 1) {
          image_base64
        }
      }
    }
    account {
      account_name
    }
  }
}
    `;

/**
 * __useSingleProjectPageSubscription__
 *
 * To run a query within a React component, call `useSingleProjectPageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSingleProjectPageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleProjectPageSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSingleProjectPageSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<SingleProjectPageSubscription, SingleProjectPageSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<SingleProjectPageSubscription, SingleProjectPageSubscriptionVariables>(SingleProjectPageDocument, baseOptions);
      }
export type SingleProjectPageSubscriptionHookResult = ReturnType<typeof useSingleProjectPageSubscription>;
export type SingleProjectPageSubscriptionResult = ApolloReactCommon.SubscriptionResult<SingleProjectPageSubscription>;
export const UpdateProjectDescriptionDocument = gql`
    mutation UpdateProjectDescription($projectId: String!, $newDescription: String!, $is_hidden: Boolean = false, $newAlias: String = "") {
  update_projects_by_pk(pk_columns: {id: $projectId}, _set: {project_description: $newDescription, is_hidden: $is_hidden, project_alias: $newAlias}) {
    project_description
  }
}
    `;
export type UpdateProjectDescriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectDescriptionMutation, UpdateProjectDescriptionMutationVariables>;

/**
 * __useUpdateProjectDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateProjectDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectDescriptionMutation, { data, loading, error }] = useUpdateProjectDescriptionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      newDescription: // value for 'newDescription'
 *      is_hidden: // value for 'is_hidden'
 *      newAlias: // value for 'newAlias'
 *   },
 * });
 */
export function useUpdateProjectDescriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectDescriptionMutation, UpdateProjectDescriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectDescriptionMutation, UpdateProjectDescriptionMutationVariables>(UpdateProjectDescriptionDocument, baseOptions);
      }
export type UpdateProjectDescriptionMutationHookResult = ReturnType<typeof useUpdateProjectDescriptionMutation>;
export type UpdateProjectDescriptionMutationResult = ApolloReactCommon.MutationResult<UpdateProjectDescriptionMutation>;
export type UpdateProjectDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectDescriptionMutation, UpdateProjectDescriptionMutationVariables>;
export const InsertTechTagDocument = gql`
    mutation InsertTechTag($tech_description: String!, $tech_name: String!) {
  insert_tech_tags(objects: {tech_name: $tech_name, tech_description: $tech_description}) {
    returning {
      id
    }
  }
}
    `;
export type InsertTechTagMutationFn = ApolloReactCommon.MutationFunction<InsertTechTagMutation, InsertTechTagMutationVariables>;

/**
 * __useInsertTechTagMutation__
 *
 * To run a mutation, you first call `useInsertTechTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTechTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTechTagMutation, { data, loading, error }] = useInsertTechTagMutation({
 *   variables: {
 *      tech_description: // value for 'tech_description'
 *      tech_name: // value for 'tech_name'
 *   },
 * });
 */
export function useInsertTechTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertTechTagMutation, InsertTechTagMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertTechTagMutation, InsertTechTagMutationVariables>(InsertTechTagDocument, baseOptions);
      }
export type InsertTechTagMutationHookResult = ReturnType<typeof useInsertTechTagMutation>;
export type InsertTechTagMutationResult = ApolloReactCommon.MutationResult<InsertTechTagMutation>;
export type InsertTechTagMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertTechTagMutation, InsertTechTagMutationVariables>;
export const GetMostPopularTagsDocument = gql`
    query GetMostPopularTags {
  tech_tags(order_by: {project_teches_aggregate: {count: desc_nulls_last}, tech_name: asc}) {
    tech_name
    tech_description
    id
  }
}
    `;

/**
 * __useGetMostPopularTagsQuery__
 *
 * To run a query within a React component, call `useGetMostPopularTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostPopularTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostPopularTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMostPopularTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMostPopularTagsQuery, GetMostPopularTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMostPopularTagsQuery, GetMostPopularTagsQueryVariables>(GetMostPopularTagsDocument, baseOptions);
      }
export function useGetMostPopularTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMostPopularTagsQuery, GetMostPopularTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMostPopularTagsQuery, GetMostPopularTagsQueryVariables>(GetMostPopularTagsDocument, baseOptions);
        }
export type GetMostPopularTagsQueryHookResult = ReturnType<typeof useGetMostPopularTagsQuery>;
export type GetMostPopularTagsLazyQueryHookResult = ReturnType<typeof useGetMostPopularTagsLazyQuery>;
export type GetMostPopularTagsQueryResult = ApolloReactCommon.QueryResult<GetMostPopularTagsQuery, GetMostPopularTagsQueryVariables>;
export const SearchTechTagsDocument = gql`
    query SearchTechTags($searchQuery: String!) {
  tech_tags(order_by: {tech_name: asc}, where: {tech_name: {_ilike: $searchQuery}}, limit: 5) {
    tech_name
    tech_description
    id
  }
}
    `;

/**
 * __useSearchTechTagsQuery__
 *
 * To run a query within a React component, call `useSearchTechTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTechTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTechTagsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useSearchTechTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchTechTagsQuery, SearchTechTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchTechTagsQuery, SearchTechTagsQueryVariables>(SearchTechTagsDocument, baseOptions);
      }
export function useSearchTechTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchTechTagsQuery, SearchTechTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchTechTagsQuery, SearchTechTagsQueryVariables>(SearchTechTagsDocument, baseOptions);
        }
export type SearchTechTagsQueryHookResult = ReturnType<typeof useSearchTechTagsQuery>;
export type SearchTechTagsLazyQueryHookResult = ReturnType<typeof useSearchTechTagsLazyQuery>;
export type SearchTechTagsQueryResult = ApolloReactCommon.QueryResult<SearchTechTagsQuery, SearchTechTagsQueryVariables>;
export const InsertProjectTechDocument = gql`
    mutation InsertProjectTech($toInsert: [project_tech_insert_input!]!) {
  insert_project_tech(objects: $toInsert) {
    returning {
      tech_tag {
        id
        tech_name
      }
    }
  }
}
    `;
export type InsertProjectTechMutationFn = ApolloReactCommon.MutationFunction<InsertProjectTechMutation, InsertProjectTechMutationVariables>;

/**
 * __useInsertProjectTechMutation__
 *
 * To run a mutation, you first call `useInsertProjectTechMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectTechMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectTechMutation, { data, loading, error }] = useInsertProjectTechMutation({
 *   variables: {
 *      toInsert: // value for 'toInsert'
 *   },
 * });
 */
export function useInsertProjectTechMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertProjectTechMutation, InsertProjectTechMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertProjectTechMutation, InsertProjectTechMutationVariables>(InsertProjectTechDocument, baseOptions);
      }
export type InsertProjectTechMutationHookResult = ReturnType<typeof useInsertProjectTechMutation>;
export type InsertProjectTechMutationResult = ApolloReactCommon.MutationResult<InsertProjectTechMutation>;
export type InsertProjectTechMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertProjectTechMutation, InsertProjectTechMutationVariables>;
export const DeleteProjectTechDocument = gql`
    mutation DeleteProjectTech($project_id: String!, $tech_id: uuid!) {
  delete_project_tech_by_pk(project_id: $project_id, tech_id: $tech_id) {
    project_id
    tech_id
  }
}
    `;
export type DeleteProjectTechMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectTechMutation, DeleteProjectTechMutationVariables>;

/**
 * __useDeleteProjectTechMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTechMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTechMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTechMutation, { data, loading, error }] = useDeleteProjectTechMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      tech_id: // value for 'tech_id'
 *   },
 * });
 */
export function useDeleteProjectTechMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectTechMutation, DeleteProjectTechMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectTechMutation, DeleteProjectTechMutationVariables>(DeleteProjectTechDocument, baseOptions);
      }
export type DeleteProjectTechMutationHookResult = ReturnType<typeof useDeleteProjectTechMutation>;
export type DeleteProjectTechMutationResult = ApolloReactCommon.MutationResult<DeleteProjectTechMutation>;
export type DeleteProjectTechMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectTechMutation, DeleteProjectTechMutationVariables>;
export const DeleteProjectTechTagsDocument = gql`
    mutation DeleteProjectTechTags($project_id: String!) {
  delete_project_tech(where: {project_id: {_eq: $project_id}}) {
    affected_rows
  }
}
    `;
export type DeleteProjectTechTagsMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectTechTagsMutation, DeleteProjectTechTagsMutationVariables>;

/**
 * __useDeleteProjectTechTagsMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTechTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTechTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTechTagsMutation, { data, loading, error }] = useDeleteProjectTechTagsMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useDeleteProjectTechTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectTechTagsMutation, DeleteProjectTechTagsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectTechTagsMutation, DeleteProjectTechTagsMutationVariables>(DeleteProjectTechTagsDocument, baseOptions);
      }
export type DeleteProjectTechTagsMutationHookResult = ReturnType<typeof useDeleteProjectTechTagsMutation>;
export type DeleteProjectTechTagsMutationResult = ApolloReactCommon.MutationResult<DeleteProjectTechTagsMutation>;
export type DeleteProjectTechTagsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectTechTagsMutation, DeleteProjectTechTagsMutationVariables>;
export const GetProjectsForTagDocument = gql`
    query GetProjectsForTag($id: uuid!) {
  tech_tags(where: {id: {_eq: $id}}) {
    tech_name
    tech_description
    id
    project_teches {
      project {
        project_name
        project_teches {
          tech_tag {
            id
            tech_name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectsForTagQuery__
 *
 * To run a query within a React component, call `useGetProjectsForTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsForTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsForTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectsForTagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectsForTagQuery, GetProjectsForTagQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectsForTagQuery, GetProjectsForTagQueryVariables>(GetProjectsForTagDocument, baseOptions);
      }
export function useGetProjectsForTagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectsForTagQuery, GetProjectsForTagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectsForTagQuery, GetProjectsForTagQueryVariables>(GetProjectsForTagDocument, baseOptions);
        }
export type GetProjectsForTagQueryHookResult = ReturnType<typeof useGetProjectsForTagQuery>;
export type GetProjectsForTagLazyQueryHookResult = ReturnType<typeof useGetProjectsForTagLazyQuery>;
export type GetProjectsForTagQueryResult = ApolloReactCommon.QueryResult<GetProjectsForTagQuery, GetProjectsForTagQueryVariables>;
export const GetUserSocialByIdDocument = gql`
    query GetUserSocialById($userId: String!) {
  users(where: {id: {_eq: $userId}}, limit: 1) {
    extended_profiles {
      alternate_phone
      social_url
      website_url
    }
  }
}
    `;

/**
 * __useGetUserSocialByIdQuery__
 *
 * To run a query within a React component, call `useGetUserSocialByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSocialByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSocialByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserSocialByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserSocialByIdQuery, GetUserSocialByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserSocialByIdQuery, GetUserSocialByIdQueryVariables>(GetUserSocialByIdDocument, baseOptions);
      }
export function useGetUserSocialByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSocialByIdQuery, GetUserSocialByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserSocialByIdQuery, GetUserSocialByIdQueryVariables>(GetUserSocialByIdDocument, baseOptions);
        }
export type GetUserSocialByIdQueryHookResult = ReturnType<typeof useGetUserSocialByIdQuery>;
export type GetUserSocialByIdLazyQueryHookResult = ReturnType<typeof useGetUserSocialByIdLazyQuery>;
export type GetUserSocialByIdQueryResult = ApolloReactCommon.QueryResult<GetUserSocialByIdQuery, GetUserSocialByIdQueryVariables>;
export const UpsertUserSocialDocument = gql`
    mutation UpsertUserSocial($socialUrl: String, $alternatePhone: String, $websiteUrl: String) {
  insert_extended_profile_one(object: {social_url: $socialUrl, alternate_phone: $alternatePhone, website_url: $websiteUrl}, on_conflict: {constraint: extended_profile_pkey, update_columns: [social_url, website_url, alternate_phone]}) {
    about_me
    social_url
    alternate_phone
    website_url
  }
}
    `;
export type UpsertUserSocialMutationFn = ApolloReactCommon.MutationFunction<UpsertUserSocialMutation, UpsertUserSocialMutationVariables>;

/**
 * __useUpsertUserSocialMutation__
 *
 * To run a mutation, you first call `useUpsertUserSocialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserSocialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserSocialMutation, { data, loading, error }] = useUpsertUserSocialMutation({
 *   variables: {
 *      socialUrl: // value for 'socialUrl'
 *      alternatePhone: // value for 'alternatePhone'
 *      websiteUrl: // value for 'websiteUrl'
 *   },
 * });
 */
export function useUpsertUserSocialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertUserSocialMutation, UpsertUserSocialMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertUserSocialMutation, UpsertUserSocialMutationVariables>(UpsertUserSocialDocument, baseOptions);
      }
export type UpsertUserSocialMutationHookResult = ReturnType<typeof useUpsertUserSocialMutation>;
export type UpsertUserSocialMutationResult = ApolloReactCommon.MutationResult<UpsertUserSocialMutation>;
export type UpsertUserSocialMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertUserSocialMutation, UpsertUserSocialMutationVariables>;
export const GetUserWithAchievementsDocument = gql`
    query GetUserWithAchievements($userId: String!) {
  users(where: {id: {_eq: $userId}}) {
    user_achievements {
      url
      name
      image_base64
      id
      description
    }
  }
}
    `;

/**
 * __useGetUserWithAchievementsQuery__
 *
 * To run a query within a React component, call `useGetUserWithAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWithAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWithAchievementsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserWithAchievementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserWithAchievementsQuery, GetUserWithAchievementsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserWithAchievementsQuery, GetUserWithAchievementsQueryVariables>(GetUserWithAchievementsDocument, baseOptions);
      }
export function useGetUserWithAchievementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserWithAchievementsQuery, GetUserWithAchievementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserWithAchievementsQuery, GetUserWithAchievementsQueryVariables>(GetUserWithAchievementsDocument, baseOptions);
        }
export type GetUserWithAchievementsQueryHookResult = ReturnType<typeof useGetUserWithAchievementsQuery>;
export type GetUserWithAchievementsLazyQueryHookResult = ReturnType<typeof useGetUserWithAchievementsLazyQuery>;
export type GetUserWithAchievementsQueryResult = ApolloReactCommon.QueryResult<GetUserWithAchievementsQuery, GetUserWithAchievementsQueryVariables>;
export const InsertUserAchievementsDocument = gql`
    mutation InsertUserAchievements($objects: [user_achievements_insert_input!]!) {
  insert_user_achievements(objects: $objects) {
    affected_rows
    returning {
      name
    }
  }
}
    `;
export type InsertUserAchievementsMutationFn = ApolloReactCommon.MutationFunction<InsertUserAchievementsMutation, InsertUserAchievementsMutationVariables>;

/**
 * __useInsertUserAchievementsMutation__
 *
 * To run a mutation, you first call `useInsertUserAchievementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserAchievementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserAchievementsMutation, { data, loading, error }] = useInsertUserAchievementsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertUserAchievementsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUserAchievementsMutation, InsertUserAchievementsMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUserAchievementsMutation, InsertUserAchievementsMutationVariables>(InsertUserAchievementsDocument, baseOptions);
      }
export type InsertUserAchievementsMutationHookResult = ReturnType<typeof useInsertUserAchievementsMutation>;
export type InsertUserAchievementsMutationResult = ApolloReactCommon.MutationResult<InsertUserAchievementsMutation>;
export type InsertUserAchievementsMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUserAchievementsMutation, InsertUserAchievementsMutationVariables>;
export const DeleteUserAchievementsDocument = gql`
    mutation DeleteUserAchievements($userId: String!) {
  delete_user_achievements(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
}
    `;
export type DeleteUserAchievementsMutationFn = ApolloReactCommon.MutationFunction<DeleteUserAchievementsMutation, DeleteUserAchievementsMutationVariables>;

/**
 * __useDeleteUserAchievementsMutation__
 *
 * To run a mutation, you first call `useDeleteUserAchievementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAchievementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAchievementsMutation, { data, loading, error }] = useDeleteUserAchievementsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserAchievementsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserAchievementsMutation, DeleteUserAchievementsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserAchievementsMutation, DeleteUserAchievementsMutationVariables>(DeleteUserAchievementsDocument, baseOptions);
      }
export type DeleteUserAchievementsMutationHookResult = ReturnType<typeof useDeleteUserAchievementsMutation>;
export type DeleteUserAchievementsMutationResult = ApolloReactCommon.MutationResult<DeleteUserAchievementsMutation>;
export type DeleteUserAchievementsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserAchievementsMutation, DeleteUserAchievementsMutationVariables>;
export const FireTeamsMeetingNotificationDocument = gql`
    mutation FireTeamsMeetingNotification($input: TeamsMeetingNotificationInput!) {
  teamsMeetingNotification(arg1: $input) {
    id
  }
}
    `;
export type FireTeamsMeetingNotificationMutationFn = ApolloReactCommon.MutationFunction<FireTeamsMeetingNotificationMutation, FireTeamsMeetingNotificationMutationVariables>;

/**
 * __useFireTeamsMeetingNotificationMutation__
 *
 * To run a mutation, you first call `useFireTeamsMeetingNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFireTeamsMeetingNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fireTeamsMeetingNotificationMutation, { data, loading, error }] = useFireTeamsMeetingNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFireTeamsMeetingNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FireTeamsMeetingNotificationMutation, FireTeamsMeetingNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<FireTeamsMeetingNotificationMutation, FireTeamsMeetingNotificationMutationVariables>(FireTeamsMeetingNotificationDocument, baseOptions);
      }
export type FireTeamsMeetingNotificationMutationHookResult = ReturnType<typeof useFireTeamsMeetingNotificationMutation>;
export type FireTeamsMeetingNotificationMutationResult = ApolloReactCommon.MutationResult<FireTeamsMeetingNotificationMutation>;
export type FireTeamsMeetingNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<FireTeamsMeetingNotificationMutation, FireTeamsMeetingNotificationMutationVariables>;
export const AllProjectsDocument = gql`
    query AllProjects {
  projects(order_by: {project_name: asc}) {
    project_name
    project_alias
    id
  }
}
    `;

/**
 * __useAllProjectsQuery__
 *
 * To run a query within a React component, call `useAllProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllProjectsQuery, AllProjectsQueryVariables>(AllProjectsDocument, baseOptions);
      }
export function useAllProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllProjectsQuery, AllProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllProjectsQuery, AllProjectsQueryVariables>(AllProjectsDocument, baseOptions);
        }
export type AllProjectsQueryHookResult = ReturnType<typeof useAllProjectsQuery>;
export type AllProjectsLazyQueryHookResult = ReturnType<typeof useAllProjectsLazyQuery>;
export type AllProjectsQueryResult = ApolloReactCommon.QueryResult<AllProjectsQuery, AllProjectsQueryVariables>;
export const UsersWithAnImageDocument = gql`
    query UsersWithAnImage {
  users(where: {user_images: {image_base64: {_is_null: false}}}) {
    name
    user_images {
      image_base64
    }
  }
}
    `;

/**
 * __useUsersWithAnImageQuery__
 *
 * To run a query within a React component, call `useUsersWithAnImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersWithAnImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersWithAnImageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersWithAnImageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersWithAnImageQuery, UsersWithAnImageQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersWithAnImageQuery, UsersWithAnImageQueryVariables>(UsersWithAnImageDocument, baseOptions);
      }
export function useUsersWithAnImageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersWithAnImageQuery, UsersWithAnImageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersWithAnImageQuery, UsersWithAnImageQueryVariables>(UsersWithAnImageDocument, baseOptions);
        }
export type UsersWithAnImageQueryHookResult = ReturnType<typeof useUsersWithAnImageQuery>;
export type UsersWithAnImageLazyQueryHookResult = ReturnType<typeof useUsersWithAnImageLazyQuery>;
export type UsersWithAnImageQueryResult = ApolloReactCommon.QueryResult<UsersWithAnImageQuery, UsersWithAnImageQueryVariables>;
export const MyQueryDocument = gql`
    query MyQuery {
  getAllChats(arg1: {password: "", username: ""}) {
    data
  }
}
    `;

/**
 * __useMyQueryQuery__
 *
 * To run a query within a React component, call `useMyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyQueryQuery, MyQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<MyQueryQuery, MyQueryQueryVariables>(MyQueryDocument, baseOptions);
      }
export function useMyQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyQueryQuery, MyQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyQueryQuery, MyQueryQueryVariables>(MyQueryDocument, baseOptions);
        }
export type MyQueryQueryHookResult = ReturnType<typeof useMyQueryQuery>;
export type MyQueryLazyQueryHookResult = ReturnType<typeof useMyQueryLazyQuery>;
export type MyQueryQueryResult = ApolloReactCommon.QueryResult<MyQueryQuery, MyQueryQueryVariables>;