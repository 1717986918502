import React from "react";

export const generateClientSidePassword = () => {
  return (
    Array(10)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("") +
    Array(10)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("") +
    Array(10)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("")
  );
};

export const escapeXml = (s: any | undefined) => {
  if (!s) {
    return s;
  }

  return s.toString().replace(/[<>&"]/g, (c: string): string => {
    let char: string = c;

    switch (c) {
      case "<":
        char = "&lt;";
        break;
      case ">":
        char = "&gt;";
        break;
      case "&":
        char = "&amp;";
        break;
      case '"':
        char = "&quot;";
        break;
    }

    return char;
  });
};

var BASE64_MARKER = ";base64,";

export function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export const blobToBase64 = function (blob): Promise<string> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result as any;
      var base64 = dataUrl.split(",")[1];
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: Array<any> = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getAzureADResourcePath = () => {
  // let hostname = window.location.hostname;
  // if (hostname === "localhost") {
  //   hostname = process.env.REACT_APP_DEFAULT_AZ_RESOURCE_URL || hostname;
  // }
  let hostname = process.env.REACT_APP_DEFAULT_AZ_RESOURCE_URL;
  let resourceApi = `api://${hostname}/${process.env.REACT_APP_AZUREAD_APP_ID}`;
  return resourceApi;
};
