import React, { useState } from "react";
import { useGetUserEducationQuery, useGetUserExperiencesQuery } from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";
import { EditButton } from "../Buttons/EditButton";
import { AchievementsModal } from "../ProfileEdits/AchievementsModal";
import { ExperienceModal } from "../ProfileEdits/ExperienceModal";
import { format } from "date-fns";
import { useModalContext } from "../../utility/useModalLauncherContext";
const Resume = ({ userId }) => {
  const auth = useAuth();
  const modalContext = useModalContext();
  const { data } = useGetUserExperiencesQuery({ variables: { userId: userId } });
  let isEditable = auth.userId === userId;

  return (
    <div className="row resume">
      <div className="col-md-4 col-12">
        <h4 className="mb-5">Experience</h4>
        {isEditable && (
          <div className="mb-3 button-min">
            <EditButton
              onClick={() => {
                modalContext.openModal("experience");
              }}
            />
          </div>
        )}
        {data?.users[0].user_experiences.map((exp) => {
          return (
            <div className="row mb-3" key={exp.name}>
              <div className="col-3">
                {/* TODO - handle empty images better */}
                {exp.org_image_base64 && <img src={`data:image/png;base64${exp.org_image_base64}`} />}
              </div>
              <div className="col-9 job-content">
                <p className="label title">{exp.name}</p>
                <p className="label">{exp.org_name}</p>
                <p>{`${format(new Date(exp.start_date), "MMM yyyy")} - ${format(
                  new Date(exp.end_date),
                  "MMM yyyy"
                )}`}</p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="col-7 offset-md-1">
        <h4 className="mb-5">Achievements</h4>
        <div className="row achievements">
          {isEditable && (
            <div className="mb-3 button-min">
              <EditButton
                onClick={() => {
                  modalContext.openModal("achievements");
                }}
              />
            </div>
          )}
          {/* TODO - Add image picker */}
          {data?.users[0].user_achievements.map((achievement) => {
            return (
              <>
                <div className="col-3">
                  <img src="https://via.placeholder.com/130x130" />
                </div>
                <div className="col-9">
                  <p className="label title">{achievement.name}</p>
                  <p>{achievement.description}</p>
                  <div className="link-container">
                    <a href={achievement.url || "#"}>Link 1</a>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Resume;
