import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import {
  useDeleteUserEducationsMutation,
  useGetUserEducationLazyQuery,
  useGetUserEducationQuery,
  useInsertUserEducationMutation,
  useUpsertUserAboutMeMutation,
  useUpsertUserExperienceStartMutation,
  useUpsertUserPersonalMutation,
  useUserAboutMeLazyQuery,
  useUserAboutMeQuery,
} from "../../generated/graphql";
import { useAuth } from "../../utility/useMsalAuth";
import { Controller, useFieldArray, useForm } from "react-hook-form";

export const PersonalModal = ({ showModal, handleModalClose }) => {
  const auth = useAuth();
  const [loadData, { called, loading, data }] = useUserAboutMeLazyQuery({
    fetchPolicy: "no-cache",
  });
  const [mutation] = useUpsertUserPersonalMutation();
  const { register, control, handleSubmit, reset, trigger, setError, errors } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    if (!called) loadData({ variables: { userId: auth.userId } });

    if (data && data.users[0] && data.users[0].extended_profiles) {
      reset({
        lifeOutsideWorkText: data.users[0].extended_profiles.life_outside_work_text,
      });
    }
  }, [data]);

  let defaultLifeValue = "";
  if (
    data &&
    data.users[0] &&
    data.users[0].extended_profiles &&
    data.users[0].extended_profiles.life_outside_work_text
  ) {
    defaultLifeValue = data.users[0].extended_profiles.life_outside_work_text;
  }

  const handleFormSubmit = (data) => {
    mutation({
      variables: {
        lifeOutsideWorkText: data.lifeOutsideWorkText as string,
      },
      refetchQueries: ["UserAboutMe"],
    })
      .catch((err) => {
        alert(JSON.stringify(err));
      })
      .then((res) => {
        handleModalClose();
      });
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleModalClose} aria-labelledby="life-outside-work-modal">
      <Modal.Header closeButton>
        <h4>My Life Outside Work</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-5">
            <Form.Label className="form-label-modal">Write about your life outside work</Form.Label>
            <Form.Control
              name={"lifeOutsideWorkText"}
              as="textarea"
              rows={5}
              ref={register()}
              defaultValue={defaultLifeValue}
            />
          </Form.Group>
          <Button variant="modal-submit" onClick={handleSubmit(handleFormSubmit)}>
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
