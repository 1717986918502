import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import {
  useInsertTenantWithUserMutation,
  useInsertUserPhotoMutation,
  useInsertUserWithTenantMutation,
} from "../generated/graphql";
import { useAuth } from "./useMsalAuth";

export const useUserStartup = () => {
  const auth = useAuth();
  const [isFirstRunState, setFirstRunState] = useState(false);
  const [insertUserFailed, setInsertUserFailed] = useState(false);
  const [mutation] = useInsertUserWithTenantMutation();

  useMemo(() => {
    if (auth.user) {
      mutation({
        variables: {
          userName: auth.userId,
          tenantName: auth.user.tenantId,
        },
      })
        .catch((err) => {
          let errorMessage = err.graphQLErrors[0].extensions.code;
          if (errorMessage === "permission-error") {
            // we couldn't insert the user account, because the tenant is not allowed
            setInsertUserFailed(true);
          }
        })
        .then((data) => {
          if (data && data.data?.insert_users_one) {
            if (data.data?.insert_users_one.first_run === null || data.data?.insert_users_one.first_run === false) {
              // FIRST RUN EXPERIENCE
              setFirstRunState(true);
            }
          }
        });
    }
  }, [auth.user]);
  return {
    isFirstRun: isFirstRunState,
    insertUserFailed: insertUserFailed,
  };
};
