import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from "@apollo/client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import React, { useEffect, useState } from "react";

import { useAuth } from "./useMsalAuth";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export function GraphQLProvider({ children }: { children: React.ReactChild }) {
  const auth = useAuth();
  const [apolloClient, setApolloClient] = useState<any>(null);

  const setupClient = async () => {
    let acquireConnectionParams = async () => {
      console.log("ACQUIRING NEW WEBSOCKET CONNECTION PARAMS");

      let token = await auth.acquireSilentIdToken();
      return {
        headers: {
          authorization: `Bearer ${token}`,
          // "X-Hasura-Role": "User",
        },
      };
    };
    const wsLink = new WebSocketLink({
      uri: `${process.env["REACT_APP_HASURA_BACKEND_WEBSOCKET_URL"]}`,
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: acquireConnectionParams,
      },
    });

    (wsLink as any).subscriptionClient.onDisconnected((err) => {
      console.log("onDisconnect", err);
    });

    (wsLink as any).subscriptionClient.onReconnecting((err) => {
      console.log("onReconnect", err);
    });

    (wsLink as any).subscriptionClient.onError((err) => {
      console.log("onError", err);
    });

    // const httpLink = new HttpLink({
    //   uri: `${process.env["REACT_APP_HASURA_BACKEND_HTTPS_URL"]}`,
    //   headers: {
    //     authorization: `Bearer ${token.idToken}`,
    //     "X-Hasura-Role": "User",
    //   },
    // });

    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wsLink as any,
      wsLink as any
    );

    const client = new ApolloClient({
      link: link,

      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
    setApolloClient(client);
  };

  useEffect(() => {
    if (auth.user) {
      try {
        setupClient();
      } catch (err) {
        console.log("UNABLE TO SETUP GRAPHQL CLIENT", err);
        auth.setUser(null as any);
      }
    }
  }, [auth.user]);

  if (!apolloClient || !auth.user) return null;
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
